import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { WHITE } from 'constants/colors'
import { DEEP_200_LIGHT, DEEP_300_LIGHT } from 'constants/colorsLightMode'
import { FONT_SIZE_LARGE, FONT_WEIGHT_REGULAR, LETTER_SPACING_03 } from 'theme/constanst'

const muiLink: {
  defaultProps?: ComponentsProps['MuiLink']
  styleOverrides?: ComponentsOverrides<Theme>['MuiLink']
  variants?: ComponentsVariants['MuiLink']
} = {
  styleOverrides: {},
  variants: [
    {
      props: { variant: 'inherit' },
      style: {
        fontSize: FONT_SIZE_LARGE,
        color: DEEP_200_LIGHT,
        cursor: 'pointer',
        fontWeight: FONT_WEIGHT_REGULAR,
        letterSpacing: LETTER_SPACING_03,
        '&:hover': {
          color: DEEP_300_LIGHT
        },
        '&:active': {
          color: WHITE
        }
      }
    }
  ]
}

export default muiLink
