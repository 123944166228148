import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_100_LIGHT, DEEP_300_LIGHT, DEEP_600_LIGHT } from 'constants/colorsLightMode'

const MuiTab: {
  defaultProps?: ComponentsProps['MuiTab']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTab']
  variants?: ComponentsVariants['MuiTab']
} = {
  styleOverrides: {
    root: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 1,
      color: DEEP_300_LIGHT,
      opacity: 1,
      padding: '4px 24px',
      minHeight: 'auto',
      textTransform: 'none',
      '&.Mui-selected': {
        color: DEEP_100_LIGHT,
        backgroundColor: DEEP_600_LIGHT,
        borderRadius: '5px 5px 0px 0px'
      },
      '&.MuiTab-wrapped': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
      }
    }
  },
  variants: []
}

export default MuiTab
