import ACTION_TYPES from './actionTypes'
import { AlertReducer, SetAlertAction, GetAlertSuccessAction, GetAlertFailedAction, ChangeNewAlert } from './types'

const initialState = {
  isLoading: false,
  selectedInstrument: '',
  marketTypeId: null,
  valueTypeId: null,
  alertDetails: null,
  instrumentId: '',
  instrumentFull: undefined,
  alert: false,
  newAlert: false
}

const reducer: AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case ACTION_TYPES.STOP_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case ACTION_TYPES.SET_ALERT_SUCCESS: {
      const newAction = action as SetAlertAction
      return {
        ...state,
        selectedInstrument: newAction.selectedInstrument,
        marketTypeId: newAction.marketTypeId,
        valueTypeId: newAction.valueTypeId,
        instrumentId: newAction.instrumentId,
        instrumentFull: newAction.instrumentFull,
        isAlert: newAction.isAlert
      }
    }
    case ACTION_TYPES.GET_ALERT_SUCCESS: {
      const newAction = action as GetAlertSuccessAction
      return {
        ...state,
        alertDetails: newAction.alertDetails,
        isAlert: newAction.isAlert
      }
    }
    case ACTION_TYPES.GET_ALERT_FAILED: {
      const newAction = action as GetAlertFailedAction
      return {
        ...state,
        error: newAction.errors
      }
    }
    case ACTION_TYPES.SET_NEW_ALERT: {
      const newAction = action as ChangeNewAlert
      return {
        ...state,
        newAlert: newAction.seenAlert
      }
    }
    default:
      return state
  }
}

export default reducer
