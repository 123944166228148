import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_100_LIGHT, DEEP_500_LIGHT } from 'constants/colorsLightMode'

import { FONT_WEIGHT_REGULAR } from '../../constanst'

const muiListItemButton: {
  defaultProps?: ComponentsProps['MuiListItemButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiListItemButton']
  variants?: ComponentsVariants['MuiListItemButton']
} = {
  styleOverrides: {
    root: {
      color: `${DEEP_100_LIGHT} !important`,
      fontWeight: FONT_WEIGHT_REGULAR,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: DEEP_500_LIGHT
      },
      '& .MuiTypography-root': {
        fontWeight: FONT_WEIGHT_REGULAR
      }
    }
  },
  variants: []
}

export default muiListItemButton
