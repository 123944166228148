import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import {
  TREND_ACENT_100,
  TREND_ACENT_200,
  DEEP_300,
  DEEP_100,
  DEEP_200,
  DEEP_500,
  DEEP_400,
  DEEP_600
} from 'constants/colorsDarkMode'
import {
  FONT_SIZE_LARGE,
  BORDER_RADIUS_BUTTON,
  LETTER_SPACING_03,
  FONT_WEIGHT_500,
  FONT_SIZE_SMALL,
  FONT_SIZE_MEDIUM
} from '../../constanst'

const muiButton: {
  defaultProps?: ComponentsProps['MuiButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton']
  variants?: ComponentsVariants['MuiButton']
} = {
  defaultProps: { disableElevation: true },
  styleOverrides: {
    root: {
      fontFamily: `${['Roboto Flex'].join(',')} !important`,
      padding: '12px 32px',
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      borderRadius: BORDER_RADIUS_BUTTON,
      textTransform: 'none',
      letterSpacing: LETTER_SPACING_03,
      fontStyle: 'normal'
    },
    sizeSmall: {
      height: '24px',
      fontSize: FONT_SIZE_SMALL,
      padding: '6px 16px'
    },
    sizeMedium: {
      fontSize: FONT_SIZE_MEDIUM,
      padding: '8px 20px'
    },
    sizeLarge: {
      fontSize: FONT_SIZE_LARGE,
      padding: '11px 24px',
      height: '48px',
      fontWeight: 500,
      letterSpacing: LETTER_SPACING_03,
      fontStyle: 'normal'
    },
    textSizeSmall: {
      padding: '7px 12px'
    },
    textSizeMedium: {
      padding: '9px 16px'
    },
    textSizeLarge: {
      padding: '12px 16px'
    }
  },
  variants: [
    {
      props: { variant: 'text' },
      style: {
        color: TREND_ACENT_100,
        '&:hover, &:active, &:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    {
      props: { variant: 'contained', color: 'primary' },
      style: {
        backgroundColor: TREND_ACENT_200,
        color: DEEP_100,
        '&:hover, &:active, &:focus': {
          color: DEEP_200
        },
        '&:active, &:focus': {
          backgroundColor: TREND_ACENT_200
        }
      }
    },
    {
      props: { variant: 'text', color: 'secondary' },
      style: {
        backgroundColor: 'transparent',
        color: DEEP_200,
        '&:hover, &:active, &:focus': {
          color: DEEP_300
        },
        '&:disabled': {
          color: DEEP_500
        }
      }
    },
    {
      props: { variant: 'outlined', color: 'primary' },
      style: {
        color: DEEP_200,
        borderColor: DEEP_500,
        '&:hover': {
          borderColor: TREND_ACENT_100,
          color: TREND_ACENT_100
        },
        '&:active, &:focus': {
          color: DEEP_200,
          borderColor: DEEP_500
        },
        '&:disabled': {
          borderColor: DEEP_400,
          color: DEEP_400
        }
      }
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: {
        backgroundColor: DEEP_600,
        color: DEEP_200,
        '&:hover, &:active': {
          backgroundColor: DEEP_500,
          color: DEEP_100
        },
        '&:focus': {
          backgroundColor: DEEP_600,
          color: DEEP_200
        },
        '&:disabled': {
          color: DEEP_400,
          backgroundColor: DEEP_600
        }
      }
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        backgroundColor: 'transparent',
        color: DEEP_200,
        borderColor: DEEP_400,
        '&:hover, &:active': {
          color: DEEP_300
        },
        '&:focus': {
          color: DEEP_400
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          color: DEEP_500,
          borderColor: DEEP_500
        }
      }
    }
  ]
}

export default muiButton
