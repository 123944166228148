import ACTION_TYPES from './actionTypes'
import { TickerReducer, getTickerSuccessAction, getTickerFailedAction } from './types'

const initialState = {
  tickerId: '',
  size: '',
  hide: false,
  instruments: [],
  error: ''
}

const reducer: TickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_TICKER_SUCCESS: {
      const newAction = action as getTickerSuccessAction
      return {
        ...state,
        tickerId: newAction.tickerId,
        size: newAction.size,
        hide: newAction.hide,
        instruments: newAction.instruments
      }
    }
    case ACTION_TYPES.GET_TICKER_FAILED: {
      const newAction = action as getTickerFailedAction
      return { ...state, error: newAction.error }
    }

    default:
      return state
  }
}

export default reducer
