const ACTION_TYPES = {
  GET_WORKSPACES_SUCCESS: 'GET_WORKSPACES_SUCCESS',
  GET_WORKSPACES_FAILED: 'GET_WORKSPACES_FAILED',
  SET_WORKSPACE_SUCCESS: 'SET_WORKSPACE_SUCCESS',
  SET_WORKSPACE_FAILED: 'SET_WORKSPACE_FAILED',
  SET_NEW_CARD: 'SET_NEW_CARD',
  SET_DELETE_CARD_ID: 'SET_DELETE_CARD_ID',
  SET_ACTIVE_WORKSPACE_WIDGETS: 'SET_ACTIVE_WORKSPACE_WIDGETS',
  SET_NEW_WORKSPACE: 'SET_NEW_WORKSPACE',
  SET_NEW_WORKSPACE_NAME: 'SET_NEW_WORKSPACE_NAME',
  SET_UNDO_CONFIGURATION: 'SET_UNDO_CONFIGURATION',
  SET_FUNC_ADD_NEW_WIDGET: 'SET_FUNC_ADD_NEW_WIDGET',
  SET_LOADING_CHANGE_WORKSPACE: 'SET_LOADING_CHANGE_WORKSPACE',
  SET_NEW_CARD_FROM_HEADER: 'SET_NEW_CARD_FROM_HEADER'
}

export default ACTION_TYPES
