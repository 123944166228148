import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_100_LIGHT, DEEP_400_LIGHT } from 'constants/colorsLightMode'

import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'

const muiPickersDay: {
  defaultProps?: ComponentsProps['MuiPickersDay']
  styleOverrides?: ComponentsOverrides<Theme>['MuiPickersDay']
  variants?: ComponentsVariants['MuiPickersDay']
} = {
  styleOverrides: {
    root: {
      color: DEEP_100_LIGHT,
      '&.Mui-disabled': {
        color: `${DEEP_400_LIGHT} !important`
      }
    }
  },
  variants: []
}

export default muiPickersDay
