import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_200 } from 'constants/colorsDarkMode'

const muiCircularProgress: {
  defaultProps?: ComponentsProps['MuiCircularProgress']
  styleOverrides?: ComponentsOverrides<Theme>['MuiCircularProgress']
  variants?: ComponentsVariants['MuiCircularProgress']
} = {
  styleOverrides: {
    root: {
      color: DEEP_200
    }
  },
  variants: []
}

export default muiCircularProgress
