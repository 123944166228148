import ACTION_TYPES from './actionTypes'
import type {
  SetInstrumentAction,
  InstrumentDetailsReducer,
  GetInstrumentDetailsSuccessAction,
  GetInstrumentDetailsFailedAction
} from './types'

const initialState = {
  isLoading: false,
  selectedInstrument: '',
  widgetTypeId: null,
  marketTypeId: null,
  valueTypeId: null,
  exchangeId: null,
  instrumentDetails: null,
  row: null,
  error: '',
  instrumentId: '',
  instrumentFull: null,
  selectedWidgetId: ''
}

const reducer: InstrumentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case ACTION_TYPES.STOP_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case ACTION_TYPES.SET_INSTRUMENT_SUCCESS: {
      const newAction = action as SetInstrumentAction
      return {
        ...state,
        selectedInstrument: newAction.selectedInstrument,
        widgetTypeId: newAction.widgetTypeId,
        marketTypeId: newAction.marketTypeId,
        valueTypeId: newAction.valueTypeId,
        exchangeId: newAction.exchangeId,
        row: newAction.row,
        instrumentId: newAction.instrumentId,
        instrumentFull: newAction.instrumentFull,
        selectedWidgetId: newAction.selectedWidgetId
      }
    }
    case ACTION_TYPES.GET_INSTRUMENT_SUCCESS: {
      const newAction = action as GetInstrumentDetailsSuccessAction

      return {
        ...state,
        instrumentDetails: newAction.instrumentDetails
      }
    }
    case ACTION_TYPES.GET_INSTRUMENT_FAILED: {
      const newAction = action as GetInstrumentDetailsFailedAction

      return {
        ...state,
        error: newAction.errors
      }
    }
    default:
      return state
  }
}

export default reducer
