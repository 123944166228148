import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_100_LIGHT, DEEP_400_LIGHT, DEEP_700_LIGHT } from 'constants/colorsLightMode'
import { heightRowTable } from 'constants/theme'

const MuiTableCell: {
  defaultProps?: ComponentsProps['MuiTableCell']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableCell']
  variants?: ComponentsVariants['MuiTableCell']
} = {
  styleOverrides: {
    root: {
      fontFamily: `${['Roboto Flex'].join(',')} !important`,
      borderBottomColor: DEEP_400_LIGHT,
      borderBottomWidth: 1,
      padding: '0px',
      fontWeight: 400,
      height: `${heightRowTable}px !important`,
    },
    head: {
      fontWeight: '600 !important',
      fontSize: '10px !important',
      lineHeight: '12px !important',
      letterSpacing: '0.25px !important',
      height: '20px !important'
    },
    stickyHeader: {
      backgroundColor: DEEP_700_LIGHT,
      color: DEEP_100_LIGHT,
      fontFamily: `${['Roboto Flex'].join(',')} !important`,
      padding: '0px 5px 0px 0px',
      borderBottomWidth: 2,
      borderBottomColor: DEEP_400_LIGHT,
      fontSize: '12px',
      cursor: 'pointer',
      '& p:hover': {
        filter: 'brightness(150%)'
      },
      '& p:active': {
        backgroundColor: DEEP_700_LIGHT
      },
      minWidth: 45
    }
  }
}

export default MuiTableCell
