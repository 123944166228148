/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, createStore } from 'redux'
import { Action, Store } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import combinedReducers from './combinedReducers'
import { ReduxState, Reducers } from './types'

const middlewares = []
middlewares.push(thunk)

if (import.meta.env.VITE_ENABLE_MIDDLEWARE_LOGGER === 'true') {
  const logger = createLogger()
  middlewares.push(logger)
}

const store: Store<ReduxState, Action<Reducers>> = createStore(
  combinedReducers,
  applyMiddleware(...(middlewares as any))
) as Store<ReduxState, Action<Reducers>>

export default store
