/* eslint-disable @typescript-eslint/no-var-requires */
import i18n from 'i18n/index'
import { getFortmatTime } from 'components/Common/Table/helpers'
import { TREND_UP_200, TREND_DOWN_200 } from 'constants/colorsDarkMode'
import {
  TOKEN_DATA,
  CDT_TIMEZONE,
  USA_LOCALE,
  EncodedCharacters,
  InstrumentPrefixes,
  widgetType,
  sortTypes,
  cardTypes,
  specialCharacters,
  MarketTypesArray,
  USD_CURRENCY,
  MEXICAN_CURRENCY,
  marketTypesIdsForIndexes,
  marketTypesIdsForInternationalBags,
  marketTypesIdsForCurrencies,
  marketTypesIdsForIndicators,
  marketTypesIdsForPreciousMetals,
  SYMBOL_REAL_TIME_TYPES,
  valueTypeIdForInterbankDollar,
  valueTypeIdsForCurrencies,
  MARKETS_IDS_ADDING_CHART,
  MarketTypes,
  HOUR_FORMAT,
  DATE_FORMAT_SHORT_YEAR,
  SHOW_LABELS,
  compareColors,
  currencyDefault,
  CURRENCY_VALUES,
  MarketTypesIds,
  valueTypesBmvSic,
  exchangeIds,
  TYPE_ROLES_HUB,
  exchangesByMarketType
} from 'constants/globals'
import { DataConfiguration } from 'constants/types'
import { formats } from 'components/Terminal/Table/constants'
import { CONFIGURATION_MAX_CHARS } from 'apis/constants'
import {
  getYearFromDateFunc,
  getDateStringFromDateFunc,
  getHourStringFromDateFunc,
  colorFormattingFunc,
  currentTimestampFunc,
  formatNumberFunc
} from './types'

import { getFormatCustomDate, convertToDayMonthYear } from './helpersDates'
import { logoutUser } from './user'
import InstrumentApi from 'apis/instrumentApi'
import logoDefaultImgSrc from 'assets/logos/HubDefault.svg'
import hubDefaultLightImgSrc from 'assets/logos/HubDefaultLight.svg'
import hubSmallImgSrc from 'assets/logos/Hub-small.png'
import hubSmallImgSrcBussines from 'assets/logos/hubSmallImgSrc-bussines.png'
import hubSmallImgSrcPlus from 'assets/logos/hubSmallImgSrcPlus.png'
import logoProImgSrc from 'assets/logos/HubPro.svg'
import logoProLightImgSrc from 'assets/logos/HubProLight.svg'
import logoPlusImgSrc from 'assets/logos/HubPlus.svg'
import logoPlusLightImgSrc from 'assets/logos/HubPlusLight.svg'
import logoBussinesImgSrc from 'assets/logos/HubBussines.svg'
import logoBussinesLightImgSrc from 'assets/logos/HubBussinesLight.svg'
import logoGBMDark from 'assets/logosGrupos/Logo_de_GBM 2.svg'
import logoGBMLight from 'assets/logosGrupos/Logo_de_GBM 2-light.svg'
import { Instrument } from 'apis/models/instrument'

export const getYearFromDate: getYearFromDateFunc = (date) => date && date.slice(0, 4)

export const getDateStringFromDate: getDateStringFromDateFunc = (date) => {
  const dateFormat = new Date(`${date.slice(0, 11)}00:00:00`)

  let dateMonth = dateFormat.getMonth() + 1
  let dateDay = dateFormat.getDate()
  const dateYear = dateFormat.getFullYear()

  if (dateMonth < 10) dateMonth = Number(`0${dateMonth}`)
  if (dateDay < 10) dateDay = Number(`0${dateDay}`)

  return `${dateDay}/${dateMonth}/${dateYear}`
}

export const getHourStringFromDate: getHourStringFromDateFunc = (date) => date && date.slice(11, 16)

export const formatHourFromNumber = (time: string | number) => {
  let timeString = time.toString()
  timeString = timeString.length === 3 ? `0${timeString}` : timeString

  return `${timeString.substring(0, 2)}:${timeString.substring(2, 4)}`
}

export const currentTimestamp: currentTimestampFunc = () =>
  `${new Date().getHours()}:${new Date().getMinutes()} ${CDT_TIMEZONE}`

export const colorFormatting: colorFormattingFunc = (value) => {
  if (value > 0) {
    return TREND_UP_200
  }
  if (value) {
    return TREND_DOWN_200
  }
  return ''
}

export const formatNumber: formatNumberFunc = (value, isInteger = false, numberOfDecimals = 2) => {
  if (!value && value !== 0) {
    return '0'
  }
  return isInteger
    ? value.toLocaleString(USA_LOCALE)
    : value.toLocaleString(USA_LOCALE, {
        minimumFractionDigits: numberOfDecimals || 2,
        maximumFractionDigits: numberOfDecimals || 2
      })
}

// "TODO: 06.05.20 (A.G.) join functions formatNumber && formatWithoutRounding"
export const formatWithoutRounding: formatNumberFunc = (
  value,
  isInteger = false,
  numberOfDecimals = 2,
  completeDecimals = true
) => {
  if (!value && value !== 0) {
    return '0'
  }
  if (isInteger) {
    return value.toLocaleString(USA_LOCALE)
  }

  let minimumFractionDigits = numberOfDecimals || 2

  const newValue =
    value.toString().indexOf('.') === -1
      ? value
      : parseFloat(value.toString().substring(0, value.toString().indexOf('.') + numberOfDecimals + 1))
  if (!completeDecimals) {
    const decimals = value.toString().replace('-', '').split('.')
    minimumFractionDigits = decimals ? decimals.length : 0
  }

  return newValue.toLocaleString(USA_LOCALE, {
    minimumFractionDigits,
    maximumFractionDigits: numberOfDecimals || 2
  })
}

export const formatFigure = (value: number) => {
  if (!value) return '-'

  const figures = value.toString().replace('-', '').split('.')[0]

  if (figures.length < 3) {
    return formatWithoutRounding(value, false, 4)
  }
  if (figures.length === 3) {
    return formatWithoutRounding(value, false, 2)
  }
  return formatWithoutRounding(value, true)
}

export const getPrefixForLs = (widgetTypeId: string) => {
  switch (widgetTypeId) {
    case widgetType.bmvStocks:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForLS
    case widgetType.currencies:
      return InstrumentPrefixes.CurrencyInstrumentPrefixForLS
    case widgetType.indexes:
      return InstrumentPrefixes.IndexInstrumentPrefixForLS
    case widgetType.generalIndicators:
      return InstrumentPrefixes.GeneralIndicatorsInstrumentPrefixForLS
    case widgetType.bmvEquitySIC:
      return InstrumentPrefixes.BmvEquitySicInstrumentPrefixForLS
    case widgetType.SICBIVA:
      return InstrumentPrefixes.SICBIVAInstrumentPrefixForLS
    case widgetType.nyseNasdaq:
      return InstrumentPrefixes.SICBIVAInstrumentPrefixForLS
    default:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForLS
  }
}

export const getPrefixForApi = (widgetTypeId?: string) => {
  switch (widgetTypeId) {
    case widgetType.bmvStocks:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForApi
    case widgetType.currencies:
      return InstrumentPrefixes.CurrencyInstrumentPrefixForApi
    case widgetType.indexes:
      return InstrumentPrefixes.IndexInstrumentPrefixForApi
    case widgetType.bmvEquitySIC:
      return InstrumentPrefixes.BmvEquitySicInstrumentPrefixForApi
    case widgetType.SICBIVA:
      return InstrumentPrefixes.SICBIVAInstrumentPrefixForApi
    default:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForApi
  }
}

export const generateUniqueKey = (marketTypeId: string, valueTypeId: string, exchangeId: string, symbol: string) => {
  return `${marketTypeId}/${valueTypeId}/${exchangeId}/${symbol}`
}

export const formatInstrumentForLS = (
  instrument: string,
  widgetTypeId?: string,
  marketType?: number | string,
  valueType?: number | string,
  stockExchange?: number | string,
  oportunidadInformacion?: string
): string => {
  let subscriptionFormat
  if (!instrument) {
    return ''
  }
  if (marketType !== undefined && valueType !== undefined && stockExchange !== undefined) {
    subscriptionFormat = `${marketType}.${valueType}.${stockExchange}.${instrument
      .replace('*', EncodedCharacters.ASTERISK)
      .replace('&', EncodedCharacters.AMPERSAND)
      .replace('-', EncodedCharacters.DASH)
      .replace(/\s/g, EncodedCharacters.SPACE)
      .replace(/(.BI)$/, '')}`
  } else {
    subscriptionFormat =
      (widgetTypeId ? getPrefixForLs(widgetTypeId) : '') +
      instrument
        .replace('*', EncodedCharacters.ASTERISK)
        .replace('&', EncodedCharacters.AMPERSAND)
        .replace('-', EncodedCharacters.DASH)
        .replace(/\s/g, EncodedCharacters.SPACE)
        .replace(/(.BI)$/, '')
  }
  return `${oportunidadInformacion === SYMBOL_REAL_TIME_TYPES.DELAY ? 'D.' : ''}${subscriptionFormat}`
}

export const formatInstrumentForUseLS = (instrumentKey: string, informationOpportunity?: string): string => {
  if (!instrumentKey) {
    return ''
  }
  const subscriptionFormat = `${instrumentKey
    .replaceAll('/', '.')
    .replace('*', EncodedCharacters.ASTERISK)
    .replace('&', EncodedCharacters.AMPERSAND)
    .replace('-', EncodedCharacters.DASH)
    .replace(/\s/g, EncodedCharacters.SPACE)
    .replace(/(.BI)$/, '')}`
  return `${informationOpportunity === SYMBOL_REAL_TIME_TYPES.DELAY ? 'D.' : ''}${subscriptionFormat}`
}

export const formatInstrumentForUseLSMD2 = (instrumentKey: string, informationOpportunity?: string): string => {
  if (!instrumentKey) {
    return ''
  }
  const subscriptionFormat = `${instrumentKey.replaceAll('/', '.')}`
  return `${informationOpportunity === SYMBOL_REAL_TIME_TYPES.DELAY ? 'D.' : ''}${subscriptionFormat}`
}

export const formatInstrumentWithPositionFieldsForLS = (
  instrument: string,
  marketType?: number | string,
  valueType?: number | string,
  stockExchange?: number | string,
  oportunidadInformacion?: string
): string => {
  const prefix = oportunidadInformacion === SYMBOL_REAL_TIME_TYPES.DELAY ? 'D.' : ''
  return (
    prefix +
    InstrumentPrefixes.InstrumentPrefixForLSPositionFields +
    formatInstrumentForLS(instrument, '', marketType, valueType, stockExchange, '')
  )
}

export const formatInstrumentPPForLS = (instrument: string, lado: string, marketTypeId: number): string => {
  if (!instrument) {
    return ''
  }
  let key = '32779.120.1009'
  if (marketTypeId === MarketTypesIds.BIVA) key = '32780.120.1011'

  if (marketTypeId === MarketTypesIds.CONSOLIDATED) key = '32780.120.1010'

  const subscriptionFormat = `PP${lado ? `.${lado}` : ''}.${key}.${instrument
    .replace('*', EncodedCharacters.ASTERISK)
    .replace('&', EncodedCharacters.AMPERSAND)
    .replace('-', EncodedCharacters.DASH)
    .replace(/\s/g, EncodedCharacters.SPACE)
    .replace(/(.BI)$/, '')}`
  return subscriptionFormat
}

export const sortArrayOfObjects = (
  data: Record<string, unknown>[],
  type: string,
  property: string,
  dataType?: string
) => {
  let sortedData: Record<string, unknown>[] = []

  if (!data.some((row: Record<string, unknown>) => row[property])) {
    return data
  }

  if (property === 'maturity') {
    const compareFunction = (a, b) => a.maturity.localeCompare(b.maturity, undefined, { numeric: true })

    sortedData = [...data].sort(type === sortTypes.ASCENDING ? compareFunction : (a, b) => compareFunction(b, a))
    return sortedData
  }

  sortedData = [...data].sort((a: Record<string, unknown>, b: Record<string, unknown>) => {
    // if property is null it should be placed after all others
    let firstValue: string | Date = a[property] as string
    let secondValue: string | Date = b[property] as string

    if ((!firstValue && Number(firstValue) !== 0) || firstValue === '-') {
      return 1
    }
    if ((!secondValue && Number(secondValue) !== 0) || secondValue === '-') {
      return -1
    }

    if (dataType === formats.DATE) {
      firstValue = firstValue?.split('T')[0] /* getFormatDate(firstValue) */
      secondValue = secondValue?.split('T')[0] /* getFormatDate(secondValue) */
    }

    if (dataType === formats.FORMATDATEYYYY) {
      firstValue = new Date(firstValue)
      secondValue = new Date(secondValue)
    }
    if (dataType === formats.TIME_FULL) {
      firstValue = getFormatCustomDate(String(firstValue || ''), HOUR_FORMAT)
      secondValue = getFormatCustomDate(String(secondValue || ''), HOUR_FORMAT)
    }

    if (type === sortTypes.ASCENDING) {
      if (dataType === formats.STRING) {
        return firstValue.toString().localeCompare(secondValue.toString())
      }
      return firstValue < secondValue ? -1 : 1
    }

    if (dataType === formats.STRING) {
      return secondValue.toString().localeCompare(firstValue.toString())
    }

    return firstValue < secondValue ? 1 : -1
  })

  return sortedData
}

export const comparer = (otherArray) => (current: Record<string, string>) => {
  return (
    otherArray.filter((other) => {
      return other.descripcion === current.descripcion
    }).length === 0
  )
}

export const bodyToString = (body: Record<string, unknown>): string => {
  if (!body) {
    return ''
  }
  const bodyString = JSON.stringify(body)

  if (bodyString.length > CONFIGURATION_MAX_CHARS) {
    return ''
  }
  return bodyString
}

export const getScreenWidth = () => {
  if (document.body) {
    return Math.max(document.body.scrollWidth, document.body.offsetWidth)
  }

  if (document.documentElement) {
    return Math.max(
      document.documentElement.scrollWidth,

      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )
  }

  return 0
}

export const getScreenHeight = () => {
  if (document.body) {
    return Math.max(document?.body?.scrollHeight || 0, document?.body?.offsetHeight || 0)
  }

  if (document.documentElement) {
    return Math.max(
      document?.documentElement?.scrollHeight || 0,
      document?.documentElement?.offsetHeight || 0,
      document?.documentElement?.clientHeight || 0
    )
  }

  return 0
}

export const defaultTableTitle = (marketId: number | string): string => {
  let title = ''
  const marketType = MarketTypesArray.find((market) => market.id === Number(marketId))
  if (!marketType) {
    title = i18n.t('tableTitle')
  } else {
    title = marketType.name
  }
  return title
}

export const defaultCardTitle = (newCard: Record<string, number>): string => {
  let title = ''
  switch (newCard.type) {
    case cardTypes.TABLE:
    case cardTypes.PREDEFINED_TABLE:
      title = defaultTableTitle(Number(newCard.marketId || newCard.predefinedTableId))
      break
    case cardTypes.CHART:
      title = i18n.t('chartTitle')
      break
    case cardTypes.NEWS:
      title = i18n.t('newsTitle')
      break
    case cardTypes.COMPARATIVE_TABLE:
      title = 'Tabla precio teórico'
      break
    case cardTypes.BROKERAGE_FIRMS_REPORT:
      title = i18n.t('reportCenterTitle')
      break
    case cardTypes.CALENDAR:
      title = i18n.t('calendarTitle')
      break
    case cardTypes.SNAPSHOT:
      title = i18n.t('snapshot')
      break
    case cardTypes.FINANCIAL:
      title = i18n.t('financial')
      break
    default:
      title = 'Nuevo Card'
      break
  }
  return title.toUpperCase()
}

export const IsJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getTimestampFromString = (str: string) => {
  const date = str.split(specialCharacters.HYPHEN_MINUS).reverse()
  return new Date(date)
}

export const arrayValidation = (array: unknown[] | unknown) => {
  return array && Array.isArray(array) && array.length
}

export const sortArrayOfAlphaNum = (data: Record<string, unknown>[], type: string, property: string) => {
  if (!data.some((row) => row[property])) {
    return data
  }

  const regexA = /[^a-zA-Z]/g
  const regexN = /[^0-9]/g

  return [...data].sort((a, b) => {
    const aA = a[property] && a[property].replace(regexA, '')
    const bA = b[property] && b[property].replace(regexA, '')
    if (aA === bA) {
      const aN = a[property] && parseInt(a[property].replace(regexN, ''), 10)
      const bN = b[property] && parseInt(b[property].replace(regexN, ''), 10)
      if (aN === bN) {
        return 0
      }
      if (type === sortTypes.ASCENDING && aN > bN) {
        return 1
      }
      if (type === sortTypes.DESCENDING && aN < bN) {
        return 1
      }
      return -1
    }
    return aA > bA ? 1 : -1
  })
}

export const formatTwoDecimals = (value) => {
  return parseFloat(value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])
}

export const htmlEntitiesDecode = (text: string) => {
  return text
    .replace(/&ntilde;/g, 'ñ')
    .replace(/&Ntilde;/g, 'Ñ')
    .replace(/&amp;/g, '&')
    .replace(/&Ntilde;/g, 'Ñ')
    .replace(/&ntilde;/g, 'ñ')
    .replace(/&Ntilde;/g, 'Ñ')
    .replace(/&Agrave;/g, 'À')
    .replace(/&Aacute;/g, 'Á')
    .replace(/&Acirc;/g, 'Â')
    .replace(/&Atilde;/g, 'Ã')
    .replace(/&Auml;/g, 'Ä')
    .replace(/&Aring;/g, 'Å')
    .replace(/&AElig;/g, 'Æ')
    .replace(/&Ccedil;/g, 'Ç')
    .replace(/&Egrave;/g, 'È')
    .replace(/&Eacute;/g, 'É')
    .replace(/&Ecirc;/g, 'Ê')
    .replace(/&Euml;/g, 'Ë')
    .replace(/&Igrave;/g, 'Ì')
    .replace(/&Iacute;/g, 'Í')
    .replace(/&Icirc;/g, 'Î')
    .replace(/&Iuml;/g, 'Ï')
    .replace(/&ETH;/g, 'Ð')
    .replace(/&Ntilde;/g, 'Ñ')
    .replace(/&Ograve;/g, 'Ò')
    .replace(/&Oacute;/g, 'Ó')
    .replace(/&Ocirc;/g, 'Ô')
    .replace(/&Otilde;/g, 'Õ')
    .replace(/&Ouml;/g, 'Ö')
    .replace(/&Oslash;/g, 'Ø')
    .replace(/&Ugrave;/g, 'Ù')
    .replace(/&Uacute;/g, 'Ú')
    .replace(/&Ucirc;/g, 'Û')
    .replace(/&Uuml;/g, 'Ü')
    .replace(/&Yacute;/g, 'Ý')
    .replace(/&THORN;/g, 'Þ')
    .replace(/&szlig;/g, 'ß')
    .replace(/&agrave;/g, 'à')
    .replace(/&aacute;/g, 'á')
    .replace(/&acirc;/g, 'â')
    .replace(/&atilde;/g, 'ã')
    .replace(/&auml;/g, 'ä')
    .replace(/&aring;/g, 'å')
    .replace(/&aelig;/g, 'æ')
    .replace(/&ccedil;/g, 'ç')
    .replace(/&egrave;/g, 'è')
    .replace(/&eacute;/g, 'é')
    .replace(/&ecirc;/g, 'ê')
    .replace(/&euml;/g, 'ë')
    .replace(/&igrave;/g, 'ì')
    .replace(/&iacute;/g, 'í')
    .replace(/&icirc;/g, 'î')
    .replace(/&iuml;/g, 'ï')
    .replace(/&eth;/g, 'ð')
    .replace(/&ntilde;/g, 'ñ')
    .replace(/&ograve;/g, 'ò')
    .replace(/&oacute;/g, 'ó')
    .replace(/&ocirc;/g, 'ô')
    .replace(/&otilde;/g, 'õ')
    .replace(/&ouml;/g, 'ö')
    .replace(/&oslash;/g, 'ø')
    .replace(/&ugrave;/g, 'ù')
    .replace(/&uacute;/g, 'ú')
    .replace(/&ucirc;/g, 'û')
    .replace(/&uuml;/g, 'ü')
    .replace(/&yacute;/g, 'ý')
    .replace(/&thorn;/g, 'þ')
    .replace(/&yuml;/g, 'ÿ')
    .replace(/&ldquo;/g, '"')
    .replace(/&rdquo;/g, '"')
}

export const getCurrencyByMarketTypeId = (marketTypeId: number, marketId?: number) => {
  if (marketId === MarketTypes.GOVERNMENT_DEBT.id) return ''

  if (
    marketTypesIdsForIndexes.includes(Number(marketTypeId)) ||
    marketTypesIdsForCurrencies.includes(Number(marketTypeId)) ||
    marketTypesIdsForIndicators.includes(Number(marketTypeId))
  ) {
    return ''
  }

  if (marketTypesIdsForInternationalBags.includes(Number(marketTypeId))) {
    return USD_CURRENCY
  }

  return MEXICAN_CURRENCY
}

export function logoutUserInternal() {
  logoutUser()
  setTimeout(() => {
    window.location.reload()
  }, 500)
}

export function isAnIndicator(marketTypeId: number) {
  if (marketTypesIdsForIndicators.includes(Number(marketTypeId))) {
    return true
  }
  return false
}

export function isAPreciousMetal(marketTypeId: number) {
  if (marketTypesIdsForPreciousMetals.includes(Number(marketTypeId))) {
    return true
  }
  return false
}

export function isInterbankDollar(marketTypeId: number, valueTypeId: number) {
  return (
    marketTypesIdsForCurrencies.includes(Number(marketTypeId)) &&
    valueTypeIdForInterbankDollar.includes(Number(valueTypeId))
  )
}

export const isCurrency = (marketTypeId: number, valueTypeId: number) => {
  return marketTypesIdsForCurrencies.includes(marketTypeId) && valueTypeIdsForCurrencies.includes(valueTypeId)
}

export const isSicInstrument = (marketId: number, valueTypeId: number) => {
  return marketId === MarketTypes.CAPITALS.id && (valueTypesBmvSic.includes(String(valueTypeId)) || valueTypeId === 20)
}

export const isLiborRate = (marketTypeId: number, exchangeId: number) => {
  return MarketTypesIds.LIBOR_RATE === marketTypeId && exchangeIds.LIBOR_RATE === exchangeId
}

export const getCurrentDateFormatDownloadFile = () => {
  const dt = new Date()
  return `${dt.getFullYear().toString().padStart(4, '0')}${(dt.getMonth() + 1).toString().padStart(2, '0')}${dt
    .getDate()
    .toString()
    .padStart(2, '0')}${dt.getHours().toString().padStart(2, '0')}${dt.getMinutes().toString().padStart(2, '0')}${dt
    .getSeconds()
    .toString()
    .padStart(2, '0')}`
}

export const getMarketsForChart = () => {
  const newMarkets = [...MARKETS_IDS_ADDING_CHART]
  if (TOKEN_DATA.hasPermissionToGovernmentDebt) {
    newMarkets.push(MarketTypes.GOVERNMENT_DEBT.id)
  }
  if (TOKEN_DATA.hasPermissionToInvestmentFunds) {
    newMarkets.push(MarketTypes.INVESTMENT_FUNDS.id)
  }
  return newMarkets.join()
}

export const formatValue = (
  value: string | number,
  newFormat: string,
  numberOfDecimals: number = 2,
  nameColumn: string = '',
  showTablePorfolio: boolean = false,
  newFormatDate?: string,
  completeDecimals: boolean = true
): string | number => {
  let newVal = ''

  if (SHOW_LABELS.includes(nameColumn) && showTablePorfolio) {
    return ''
  }

  if (value || parseInt(String(value)) === 0) {
    if (value === null) {
      return ''
    }

    switch (newFormat) {
      case formats.FLOAT:
        newVal = String(formatWithoutRounding(parseFloat(String(value)), false, numberOfDecimals, completeDecimals))
        break
      case formats.INT:
        newVal = formatNumber(parseInt(String(value), 10), true)
        break
      case formats.STRING:
        newVal = String(value)
        break
      case formats.TIME:
        newVal = getFortmatTime(value ? `${value}` : '')
        break
      case formats.TIME_FULL:
        newVal = getFormatCustomDate(String(value || ''), HOUR_FORMAT)
        break
      case formats.DATE:
        newVal = convertToDayMonthYear(String(value))
        break
      case formats.FIGURE:
        newVal = formatFigure(parseFloat(String(value)))
        break
      case formats.FORMATDATEYYYY:
        newVal = getFormatCustomDate(String(value || ''), newFormatDate || DATE_FORMAT_SHORT_YEAR)
        break
      default:
        newVal = formatNumber(parseInt(String(value)), true)
        break
    }
    return newVal
  }
  return '-'
}

export const getDataByConfiguration = (configuration) => {
  if (!configuration) return null

  const { data } = configuration

  if (!data) return null

  let options: DataConfiguration | null = null
  if (typeof data === 'string') {
    options = JSON.parse(data)
  } else if (typeof data === 'object') {
    options = data
  }

  return { data: options }
}

function getRandomColor(): string {
  const letters = '0123456789ABCDEF'
  let color = '#'
  let random = 0

  for (let i = 0; i < 6; i += 1) {
    random = Math.floor(Math.random() * 16)
    color += letters[random]
  }

  return color
}

export function getNextColor(comparisons: Record<string, unknown>[], random?: boolean): string {
  let color = ''
  for (let i = 0; i < compareColors.length; i += 1) {
    if (!comparisons.find((comp) => comp && comp.color === compareColors[i])) {
      color = compareColors[i]
      break
    }
  }
  if (color === '') {
    color = getRandomColor()
  }
  return random ? getRandomColor() : color
}

export function getIntrumentKeyByLS(symbol: string): string {
  if (!symbol) return ''

  return symbol
    .replaceAll('.', '/')
    .replace(EncodedCharacters.ASTERISK, '*')
    .replace(EncodedCharacters.AMPERSAND, '&')
    .replace(EncodedCharacters.DASH, '-')
}

export const getCurrency = (marketTypeId: number) => {
  let returnCurrency = currencyDefault
  switch (Number(marketTypeId)) {
    case MarketTypesIds.BMV:
    case MarketTypesIds.BIVA:
      returnCurrency = CURRENCY_VALUES[1].value
      break
    case MarketTypesIds.INTERNATIONAL_BAGS:
      returnCurrency = CURRENCY_VALUES[0].value
      break
    default:
      break
  }
  return returnCurrency
}

export const getLastTick = (subscription) => {
  const fields = subscription.getFields()
  const items = typeof subscription.getItems === 'function' ? subscription.getItems() : [subscription.ik.LS_id]
  const uniqueKey = items[0]

  return {
    By: uniqueKey,
    forEachField(callback: (field: string, name: string, value) => void) {
      fields.forEach((field) => {
        const value = subscription.getValue(uniqueKey, field)
        if (value) callback(field, '', value)
      })
    },
    forEachChangedField(callback: (field: string, name: string, value) => void) {
      fields.forEach((field) => {
        const value = subscription.getValue(uniqueKey, field)
        if (value) callback(field, '', value)
      })
    },
    getValue(field: string) {
      return subscription.getValue(uniqueKey, field)
    },
    getItemName() {
      return uniqueKey
    }
  }
}

export const replaceCharacter = (str: string) => {
  try {
    let searchRegExp = str

    const specialCharactersReplace = str.match(new RegExp(/\W/, 'gi')) || []
    if (specialCharactersReplace && arrayValidation(specialCharactersReplace)) {
      specialCharactersReplace.forEach((character) => {
        searchRegExp = str.replaceAll(new RegExp(/\W/, 'gi'), `\\&&&${character}`)
      })
      return searchRegExp.replaceAll('&&&', '')
    }
    return searchRegExp
  } catch (error) {
    return str
  }
}

export const downloadLog = (log) => {
  const fileData = JSON.stringify(log)
  const blob = new Blob([fileData], { type: 'text/plain' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = 'log.json'
  link.href = url
  link.click()
}

export const delayTime = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

export const isMobileBrowser = () => {
  if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
    return true
  }
  return false
}

const imageUrlGroupDark = {
  gbm: logoGBMDark
}
const imageUrlGroupLight = {
  gbm: logoGBMLight
}

export const getLogo = (returnDefault = false, isDark = true, group = '', open = false) => {
  if (open) {
    switch (TOKEN_DATA.hubRol) {
      case TYPE_ROLES_HUB.HUB_PRO:
        return hubSmallImgSrc
      case TYPE_ROLES_HUB.HUB_PLUS:
        return hubSmallImgSrcPlus
      case TYPE_ROLES_HUB.HUB_BUSSINES:
        return hubSmallImgSrcBussines
      default:
        return hubSmallImgSrc
    }
  }
  const urlImg = isDark ? imageUrlGroupDark[group] : imageUrlGroupLight[group]
  if (urlImg) {
    return urlImg
  }
  if (!TOKEN_DATA || !TOKEN_DATA.hubRol || returnDefault) return isDark ? logoDefaultImgSrc : hubDefaultLightImgSrc
  switch (TOKEN_DATA.hubRol) {
    case TYPE_ROLES_HUB.HUB_PRO:
      return isDark ? logoProImgSrc : logoProLightImgSrc
    case TYPE_ROLES_HUB.HUB_PLUS:
      return isDark ? logoPlusImgSrc : logoPlusLightImgSrc
    case TYPE_ROLES_HUB.HUB_BUSSINES:
      return isDark ? logoBussinesImgSrc : logoBussinesLightImgSrc
    default:
      return isDark ? logoDefaultImgSrc : hubDefaultLightImgSrc
  }
}

// Review if the window has already been refreshed
export const hasRefreshed = JSON.parse(sessionStorage.getItem('hub-refreshed') || 'false')

export const getInstrumentBySearch = async (value: string, marketTypeId: number) => {
  try {
    let response: Instrument | null = null
    const { data } = await InstrumentApi.searchInstrumentsByMarketType(String(marketTypeId), value.toUpperCase())
    if (!data) return response
    response = data.find((instrument) => instrument.displaySymbol === value.toUpperCase()) as Instrument
    return response
  } catch (error) {
    return null
  }
}

export const getExchangeValue = (exchange: string, marketTypeId: number) => {
  return exchange || exchangesByMarketType[marketTypeId]
}
