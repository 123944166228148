import {
  FONT_SIZE_LARGE,
  FONT_SIZE_10,
  FONT_SIZE_12,
  FONT_SIZE_14,
  FONT_SIZE_18,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_SIZE_24,
  LETTER_SPACING_03,
  LETTER_SPACING_02,
  LETTER_SPACING_075,
  LETTER_SPACING_05,
  FONT_WEIGHT_SEMI,
  FONT_WEIGHT_LIGHT,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_MEDIUM,
  LINE_HEIGHT_20,
  FONT_SIZE_13
} from './constanst'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
    content: true
    value: true
    ticker: true
    head: true
  }
}

const fontFamilyDefault = ['Roboto Flex', 'Helvetica', 'Arial', 'sans-serif'].join(',')

const typography = {
  fontFamily: `${fontFamilyDefault} !important`,
  fontSize: FONT_SIZE_12,
  fontWeight: FONT_WEIGHT_REGULAR,
  body1: {
    fontSize: FONT_SIZE_LARGE,
    fontWeight: FONT_WEIGHT_REGULAR,
    letterSpacing: LETTER_SPACING_03
  },
  body2: {
    fontSize: FONT_SIZE_LARGE,
    fontWeight: FONT_WEIGHT_SEMI,
    letterSpacing: LETTER_SPACING_02
  },
  subtitle1: {
    fontSize: FONT_SIZE_12,
    fontWeight: FONT_WEIGHT_SEMI,
    letterSpacing: LETTER_SPACING_02
  },
  subtitle2: {
    fontSize: FONT_SIZE_12,
    fontWeight: FONT_WEIGHT_LIGHT,
    letterSpacing: LETTER_SPACING_02
  },
  h1: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_24,
    letterSpacing: LETTER_SPACING_075
  },
  h2: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_20,
    letterSpacing: LETTER_SPACING_05
  },
  h3: {
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: FONT_SIZE_10,
    letterSpacing: LETTER_SPACING_02,
    lineHeight: LINE_HEIGHT_20
  },
  h4: {
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: FONT_SIZE_16,
    letterSpacing: LETTER_SPACING_03
  },
  h5: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_16,
    letterSpacing: LETTER_SPACING_03
  },
  h6: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_18,
    letterSpacing: LETTER_SPACING_03
  },
  button: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_14,
    letterSpacing: LETTER_SPACING_03
  },
  title: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_20,
    letterSpacing: LETTER_SPACING_075
  },
  content: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_14,
    letterSpacing: LETTER_SPACING_02
  },
  caption: {
    fontSize: FONT_SIZE_10,
    fontWeight: FONT_WEIGHT_MEDIUM,
    letterSpacing: LETTER_SPACING_02
  },
  value: {
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: FONT_SIZE_14,
    letterSpacing: LETTER_SPACING_02,
    lineHeight: LINE_HEIGHT_20
  },
  ticker: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_13,
    letterSpacing: LETTER_SPACING_02,
    lineHeight: LINE_HEIGHT_20,
    textTransform: 'uppercase'
  },
  head: {
    fontWeight: FONT_WEIGHT_SEMI,
    fontSize: FONT_SIZE_13,
    letterSpacing: LETTER_SPACING_02,
    lineHeight: LINE_HEIGHT_20
  }
}

export default typography
