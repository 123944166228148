import ACTION_TYPES from './actionTypes'
import {
  DragAndDropReducer,
  StartDraggingAction,
  SetInstrumentsAction,
  StopDropAction,
  EnableDropAction,
  StartAction,
  DnDReducerState
} from './types'

const initialState: DnDReducerState = {
  dragging: false,
  widgetIdDrag: null,
  marketIdDrag: null,
  instrumentsToDrag: [],
  targetType: '',
  widgetIdDrop: null,
  enableDropWidgets: false,
  isCompare: false
}

const reducer: DragAndDropReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_DRAGGING: {
      const newAction = action as StartDraggingAction
      return {
        ...state,
        dragging: true,
        widgetIdDrag: newAction.widgetIdDrag,
        marketIdDrag: newAction.marketIdDrag
      }
    }
    case ACTION_TYPES.STOP_DRAGGING:
      return {
        ...state,
        dragging: false,
        widgetIdDrag: null,
        marketIdDrag: null,
        enableDropWidgets: false
      }
    case ACTION_TYPES.SET_INSTRUMENTS: {
      const newAction = action as SetInstrumentsAction
      return { ...state, instrumentsToDrag: newAction.instrumentsToDrag }
    }
    case ACTION_TYPES.START_DROP: {
      const newAction = action as StartAction
      return {
        ...state,
        targetType: newAction.targetType,
        instrumentsToDrag: newAction.instrumentsToDrag,
        widgetIdDrop: newAction.widgetIdDrop,
        isCompare: newAction.isCompare
      }
    }
    case ACTION_TYPES.STOP_DROP: {
      const newAction = action as StopDropAction
      return {
        ...state,
        targetType: '',
        instrumentsToDrag: newAction.instrumentsToDrag,
        widgetIdDrop: null,
        dragging: false,
        enableDropWidgets: false
      }
    }
    case ACTION_TYPES.ENABLE_WIDGETS: {
      const newAction = action as EnableDropAction
      return {
        ...state,
        enableDropWidgets: newAction.enableDropWidgets
      }
    }
    default:
      return state
  }
}

export default reducer
