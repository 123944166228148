export const DEEP_100_LIGHT = '#2B3139'
export const DEEP_200_LIGHT = '#404855'
export const DEEP_300_LIGHT = '#697183'
export const DEEP_400_LIGHT = '#BFCADD'
export const DEEP_500_LIGHT = 'rgb(191, 202, 221)'
export const DEEP_600_LIGHT = '#F0F3FB'
export const DEEP_700_LIGHT = '#FBFCFF'
export const DEEP_800_LIGHT = '#FFFFFF'
export const DEEP_900_LIGHT = '#FFFFFF'

export const TREND_UP_100_LIGHT = '#D9F7E1'
export const TREND_UP_200_LIGHT = 'rgb(89, 202, 117)'
export const TREND_UP_300_LIGHT = '#009024'

export const TREND_DOWN_100_LIGHT = '#FFD3D0'
export const TREND_DOWN_200_LIGHT = 'rgb(240, 81, 70)'
export const TREND_DOWN_300_LIGHT = '#B8281E'

export const TREND_ACENT_100_LIGHT = '#518CFF'
export const TREND_ACENT_200_LIGHT = '#1967FD'
export const TREND_ACENT_300_LIGHT = '#0A51DA'

export const CHART_CANARY_LIGHT = '#F0C75C'
export const CHART_SALMON_LIGHT = '#F59267'
export const CHART_ORCHID_LIGHT = '#E381F3'
export const CHART_GRAPE_LIGHT = '#887DFF'
export const CHART_ROYAL_LIGHT = '#2E50F2'
export const CHART_MINT_LIGHT = '#28786D'
