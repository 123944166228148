import ACTION_TYPES from './actionTypes'
import { BulkDownloadReducer, openNewBulkDownload } from './types'

const initialState = {
  typeBulkDownload: 0,
  openDownload: false
}

const reducer: BulkDownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CLOSE_BULK_DOWNLOAD: {
      return {
        ...state,
        openDownload: false,
        typeBulkDownload: 0
      }
    }
    case ACTION_TYPES.OPEN_NEW_BULK_DOWNLOAD: {
      const newAction = action as openNewBulkDownload
      return {
        ...state,
        openDownload: newAction.openDownload.openDownload,
        typeBulkDownload: newAction.openDownload.typeBulkDownload
      }
    }
    default:
      return state
  }
}

export default reducer
