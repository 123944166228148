import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

const muiPaper: {
  defaultProps?: ComponentsProps['MuiPaper']
  styleOverrides?: ComponentsOverrides<Theme>['MuiPaper']
  variants?: ComponentsVariants['MuiPaper']
} = {
  styleOverrides: {
    root: {
      backgroundImage: 'none'
    }
  },
  variants: []
}

export default muiPaper
