import './App.css'

import { Suspense, useState } from 'react'
import { BrowserRouter, Routes } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ColorModeContext } from 'ducks/themeContext'
import ErrorFallback from 'components/Terminal/ErrorFallback'
import useTheme from 'theme/useTheme'
import useColorMode from 'hooks/useColorMode'
//import { updateConfigWidgets } from 'utils/widget'
import { renderRoutes } from './routes'
import { routes } from './routes/contants'
import SnackProvider from 'components/Common/SnackProvider'
import useAuthRedirect from 'hooks/useAuthRedirect'
/* import UserService from './service/auth' */

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<'light' | 'dark'>(
    String(localStorage.getItem('mui-mode') || 'dark') as 'dark' | 'light'
  )

  const colorMode = useColorMode(prefersDarkMode, setMode)
  const theme = useTheme(mode)

  return (
    <Suspense fallback={null}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <CssBaseline />
            <SnackProvider>
              <BrowserRouter>
                <MainComponent />
              </BrowserRouter>
            </SnackProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Suspense>
  )
}

function MainComponent() {
  useAuthRedirect()

  return <Routes>{renderRoutes(routes)}</Routes>
}

export default App
