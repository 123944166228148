import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_700_LIGHT } from 'constants/colorsLightMode'

const MuiMenu: {
  defaultProps?: ComponentsProps['MuiMenu']
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenu']
  variants?: ComponentsVariants['MuiMenu']
} = {
  styleOverrides: {
    root: {},
    paper: {
      backgroundColor: DEEP_700_LIGHT,
      backgroundImage: 'none',
      boxShadow: 'none'
    }
  },
  variants: []
}

export default MuiMenu
