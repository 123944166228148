import React from 'react'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { TREND_ACENT_100, CHART_SALMON, CHART_CANARY, CHART_MINT } from '../../../constants/colorsDarkMode'
import useStyles, { SSnackbarProvider } from './style'

type Props = {
  children: React.ReactNode
}

const SnackProvider = ({ children }: Props) => {
  const classes = useStyles()

  return (
    <SSnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      classes={{
        containerRoot: classes.root,
        containerAnchorOriginBottomLeft: classes.containerAnchorOriginBottomLeft
      }}
      iconVariant={{
        success: <TaskAltOutlinedIcon style={{ color: CHART_MINT, marginRight: 10 }} />,
        error: <ErrorOutlineIcon style={{ color: CHART_SALMON, marginRight: 10 }} />,
        warning: <WarningAmberOutlinedIcon style={{ color: CHART_CANARY, marginRight: 10 }} />,
        default: <DoDisturbOutlinedIcon style={{ marginRight: 10 }} />,
        info: <InfoOutlinedIcon style={{ color: TREND_ACENT_100, marginRight: 10 }} />
      }}
      hideIconVariant={false}
    >
      {children}
    </SSnackbarProvider>
  )
}

export default SnackProvider
