import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { LINKED_WATER } from 'constants/colors'
import { DEEP_100, TREND_ACENT_200 } from 'constants/colorsDarkMode'

const muiSelect: {
  defaultProps?: ComponentsProps['MuiSelect']
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect']
  variants?: ComponentsVariants['MuiSelect']
} = {
  styleOverrides: {
    root: {
      '&.Mui-focused': {
        backgroundColor: TREND_ACENT_200
      },
    },
    standard: {
      color: DEEP_100,
      '&:focus': {
        backgroundColor: 'transparent'
      }
    },
    icon: {
      color: LINKED_WATER
    }
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        fontSize: '14px',
        fontWeight: 500,
        '& .MuiSelect-select': {
          padding: '8px 5px',
          paddingRight: '0px',
        },
        '&.MuiInputBase-root': {
          '& > svg': {
            fontSize: '20px'
          }
        }
      }
    },
    {
      props: { size: 'medium' },
      style: {
        fontSize: '16px',
        fontWeight: 500,
        '& .MuiSelect-select': {
          padding: '10px 28px',
          paddingRight: '0px',
        },
        '&.MuiInputBase-root': {
          '& > svg': {
            fontSize: '20px'
          }
        }
      }
    }
  ]
}

export default muiSelect

