import { Theme, ComponentsOverrides } from '@mui/material/styles'

import {
  DEEP_100_LIGHT,
  DEEP_200_LIGHT,
  DEEP_300_LIGHT,
  DEEP_500_LIGHT,
  TREND_ACENT_100_LIGHT
} from 'constants/colorsLightMode'

const MuiPagination: {
  styleOverrides?: ComponentsOverrides<Theme>['MuiPagination']
} = {
  styleOverrides: {
    root: {
      '& ul > li:not(:first-of-type):not(:last-child) > button:not(.Mui-selected)': {
        backgroundColor: DEEP_500_LIGHT,
        color: DEEP_200_LIGHT
      },
      '& .MuiPaginationItem-root': {
        border: 0,
        color: DEEP_200_LIGHT,
        minWidth: 20,
        height: 20,
        fontWeight: 500,
        fontSize: 14,
        '& .MuiSvgIcon-root': {
          color: DEEP_300_LIGHT,
          fontSize: '1.75rem'
        }
      },
      '& .MuiPaginationItem-page.Mui-selected': {
        color: DEEP_100_LIGHT,
        backgroundColor: TREND_ACENT_100_LIGHT
      },
      '&.MuiPaginationItem-rounded': {
        color: DEEP_100_LIGHT
      },
      '&.MuiPaginationItem-page.Mui-disabled': {
        color: DEEP_100_LIGHT
      }
    }
  }
}

export default MuiPagination
