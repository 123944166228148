import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_500 } from 'constants/colorsDarkMode'

const muiTableRow: {
  defaultProps?: ComponentsProps['MuiTableRow']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableRow']
  variants?: ComponentsVariants['MuiTableRow']
} = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      padding: 0,
      '&.Mui-selected': {
        backgroundColor: DEEP_500
      },
      '&.Mui-hover': {
        backgroundColor: DEEP_500
      }
    }
  }
}

export default muiTableRow
