import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_200_LIGHT } from 'constants/colorsLightMode'

const muiListItemIcon: {
  defaultProps?: ComponentsProps['MuiListItemIcon']
  styleOverrides?: ComponentsOverrides<Theme>['MuiListItemIcon']
  variants?: ComponentsVariants['MuiListItemIcon']
} = {
  styleOverrides: {
    root: {
      color: DEEP_200_LIGHT
    }
  },
  variants: []
}

export default muiListItemIcon
