import { PaletteOptions } from '@mui/material/styles'

import {
  TREND_ACENT_100_LIGHT,
  TREND_ACENT_200_LIGHT,
  TREND_ACENT_300_LIGHT,
  TREND_UP_100_LIGHT,
  TREND_UP_200_LIGHT,
  TREND_UP_300_LIGHT,
  TREND_DOWN_100_LIGHT,
  TREND_DOWN_200_LIGHT,
  TREND_DOWN_300_LIGHT,
  DEEP_100_LIGHT,
  DEEP_200_LIGHT,
  DEEP_300_LIGHT,
  DEEP_400_LIGHT,
  DEEP_500_LIGHT,
  DEEP_600_LIGHT,
  DEEP_700_LIGHT,
  DEEP_800_LIGHT,
  DEEP_900_LIGHT,
  CHART_MINT_LIGHT
} from 'constants/colorsLightMode'

// Augment the palette to include a violet color
declare module '@mui/material/styles' {
  interface Palette {
    green: Palette['primary']
  }

  interface PaletteOptions {
    green?: PaletteOptions['primary']
  }
}

export const neutral = {
  100: DEEP_100_LIGHT,
  200: DEEP_200_LIGHT,
  300: DEEP_300_LIGHT,
  400: DEEP_400_LIGHT,
  500: DEEP_500_LIGHT,
  600: DEEP_600_LIGHT,
  700: DEEP_700_LIGHT,
  800: DEEP_800_LIGHT,
  900: DEEP_900_LIGHT
}

export const background = {
  default: neutral[800],
  paper: neutral[700]
}

export const divider = neutral[500]

export const primary = {
  main: TREND_ACENT_100_LIGHT,
  light: TREND_ACENT_200_LIGHT,
  dark: TREND_ACENT_300_LIGHT,
  contrastText: neutral[700]
}

export const secondary = {
  main: neutral[200],
  light: neutral[400],
  dark: neutral[500],
  contrastText: neutral[100]
}

export const success = {
  main: TREND_UP_300_LIGHT,
  light: TREND_UP_200_LIGHT,
  dark: TREND_UP_100_LIGHT,
  contrastText: neutral[900]
}

export const info = {
  main: TREND_ACENT_300_LIGHT,
  light: TREND_ACENT_200_LIGHT,
  dark: TREND_ACENT_100_LIGHT,
  contrastText: neutral[900]
}

export const warning = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText: neutral[900]
}

export const error = {
  main: TREND_DOWN_100_LIGHT,
  light: TREND_DOWN_200_LIGHT,
  dark: TREND_DOWN_300_LIGHT,
  contrastText: neutral[900]
}

export const grey = {
  100: DEEP_100_LIGHT,
  200: DEEP_200_LIGHT,
  300: DEEP_300_LIGHT,
  400: DEEP_400_LIGHT,
  500: DEEP_600_LIGHT,
  600: DEEP_600_LIGHT,
  800: neutral[600],
  900: DEEP_900_LIGHT
}

export const blue = {
  100: TREND_ACENT_100_LIGHT
}

export const text = {
  primary: neutral[100],
  secondary: neutral[300],
  disabled: 'rgba(255, 255, 255, 0.48)',
  hint: neutral[400]
}

const light: PaletteOptions = {
  mode: 'light',
  action: {
    active: neutral[500],
    focus: 'rgba(55, 65, 81, 0.12)',
    selected: primary.main,
    disabledBackground: 'rgba(55, 65, 81, 0.12)',
    disabled: 'rgba(55, 65, 81, 0.26)'
  },
  background,
  divider,
  error,
  info,
  grey,
  primary,
  secondary,
  success,
  text,
  warning,
  green: {
    main: CHART_MINT_LIGHT
  }
}

export default light
