const nameReportsBrokerageHouses = [
  'AC*',
  'ACCELSAB',
  'ACTINVRB',
  'AEROMEX*',
  'AGUA*',
  'ALEATIC*',
  'ALFAA',
  'ALPEKA',
  'ALSEA*',
  'AMXA',
  'AMXAA',
  'AMXL',
  'ARA*',
  'ARCA*',
  'ASURB',
  'AUTLANB',
  'AXTELCPO',
  'AZTECACPO',
  'BACHOCOB',
  'BACHOCOUB',
  'BACHOCOUBL',
  'BAFARB',
  'BEVIDESB',
  'BIMBOA',
  'BOLSAA',
  'CABLECPO',
  'CADUA',
  'CEMEXA',
  'CEMEXB',
  'CEMEXCPO',
  'CERAMICB',
  'CERAMICD',
  'CHDRAUIB',
  'CICSAB-1',
  'CIEB',
  'CMOCTEZ*',
  'CMRB',
  'COLLADO*',
  'COMERCIB',
  'COMERCIUBC',
  'CONVERA',
  'CUERVO*',
  'CULTIBAB',
  'CYDSASAA',
  'DANHOS13',
  'DINEA',
  'DINEB',
  'EDUCA18',
  'ELEKTRA*',
  'ELEMENT*',
  'FEMSAB',
  'FEMSAUB',
  'FEMSAUBD',
  'FIBRAHD15',
  'FIBRAMQ12',
  'FIBRAPL14',
  'FIHO12',
  'FINN13',
  'FMTY14',
  'FPLUS16',
  'FRAGUAB',
  'FSHOP13',
  'FUNO11',
  'GAPB',
  'GBMO',
  'GCARSOA1',
  'GCC*',
  'GENTERA*',
  'GEOB',
  'GFAMSAA',
  'GFINBURO',
  'GFINTERO',
  'GFNORTEO',
  'GFREGIOO',
  'GICSAB',
  'GIGANTE*',
  'GISSA*',
  'GISSAA',
  'GMD*',
  'GMDB',
  'GMEXICOB',
  'GMODELOC',
  'GMXT*',
  'GPH1',
  'GPROFUT*',
  'GRUMAB',
  'GSANBORB-1',
  'HCITY*',
  'HERDEZ*',
  'HILASALA',
  'HILASALB',
  'HOGARB',
  'HOMEX*',
  'HOTEL*',
  'ICA*',
  'ICHB',
  'IDEALB-1',
  'IENOVA*',
  'INCARSOB-1',
  'INVEXA',
  'JAVER*',
  'KIMBERA',
  'KIMBERB',
  'KOFL',
  'KOFUBL',
  'KUOA',
  'KUOB',
  'LABB',
  'LACOMERUB',
  'LACOMERUBC',
  'LALAB',
  'LAMOSA*',
  'LAMOSAB',
  'LIVEPOL1',
  'LIVEPOLC-1',
  'MASECAB',
  'MAXCOMA',
  'MAXCOMCPO',
  'MEDICAB',
  'MEGACPO',
  'MEXCHEM*',
  'MFRISCOA-1',
  'MINSAB',
  'MONEXB',
  'NEMAKA',
  'NMKA',
  'OHLMEX*',
  'OMAB',
  'ORBIA*',
  'PAPPEL*',
  'PASAB',
  'PE&OLES*',
  'PINFRA*',
  'PINFRAL',
  'PLANI*',
  'PMCPACA',
  'POCHTECB',
  'POSADASA',
  'PV*',
  'Q*',
  'RA',
  'RA*',
  'RASSINIA',
  'RASSINICPO',
  'RCENTROA',
  'SANLUISA',
  'SANLUISCPO',
  'SANMEXB',
  'SAREB',
  'SIMECB',
  'SITESA',
  'SITESB-1',
  'SITESL',
  'SORIANAB',
  'SPORTS',
  'TEAKCPO',
  'TERRA13',
  'TLEVISAA',
  'TLEVISACPO',
  'TMMA',
  'TRAXIONA',
  'TVAZTCACPO',
  'URBI*',
  'VALUEGFO',
  'VASCONI*',
  'VESTA*',
  'VINTE*',
  'VISTAA',
  'VITROA',
  'VOLARA',
  'WALMEX*',
  'WALMEXV',
  'FORTALE*',
  'ELEMAT*'
]

export default nameReportsBrokerageHouses
