import requestTypes from 'constants/requestTypes'
import { fetchNext } from 'utils/request'
import { WorkspaceLayout } from 'constants/types'
import { Workspace } from './models/workspace'
import { WorkspaceInner } from './models/workspaceInner'

export default class WorkspaceApi {
  static getUserWorkspaceList = (): Promise<{ data: Workspace }> => fetchNext(requestTypes.GET, `/users/workspaces`)

  static getWorkspace = (workspaceId: string): Promise<{ data: WorkspaceInner }> =>
    fetchNext(requestTypes.GET, `/users/workspaces/${workspaceId}`)

  static getWorkspaceV2 = (workspaceId: string, idSignalController?: string): Promise<{ data: WorkspaceInner }> =>
    fetchNext(requestTypes.GET, `/users/workspaces/${workspaceId}`, { idSignalController }, undefined, true)

  static updateWorkspace = (workspace: WorkspaceInner, layout?: WorkspaceLayout): Promise<{ data: WorkspaceInner }> => {
    const bodyString = layout ? JSON.stringify({ data: { layout } }) : workspace.configuration

    const reqBody = {
      description: workspace.description,
      configuration: bodyString,
      isDefault: workspace.isDefault ? 1 : 0
    }

    return fetchNext(requestTypes.PUT, `/users/workspaces/${workspace.workspaceId}`, reqBody)
  }

  static createWorkspace = (description: string, layout: WorkspaceLayout = []): Promise<{ data: WorkspaceInner }> => {
    const bodyString = JSON.stringify({ data: { layout } })
    const reqBody = {
      description,
      configuration: bodyString
    }
    return fetchNext(requestTypes.POST, `/users/workspaces`, reqBody)
  }

  static duplicateWorkspace = (workspaceId: string) => {
    return fetchNext(requestTypes.POST, `/users/workspaces/${workspaceId}/duplicate`)
  }

  static deleteWorkspace = (workspaceId: number) => {
    return fetchNext(requestTypes.DELETE, `/users/workspaces/${workspaceId}`)
  }

  static orderWorkspaces = (newOrder: Array<Object>) => {
    const reqBody = {
      workspaces: newOrder
    }
    return fetchNext(requestTypes.POST, `/users/workspaces/sort`, reqBody)
  }

  static loginKeyclock = () => {
    return fetchNext(requestTypes.POST, `/auth/login/keycloak?platform=web`)
  }
}
