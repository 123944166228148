import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import {
  TREND_ACENT_100_LIGHT,
  TREND_ACENT_200_LIGHT,
  DEEP_300_LIGHT,
  DEEP_200_LIGHT,
  DEEP_500_LIGHT,
  DEEP_400_LIGHT,
  DEEP_600_LIGHT,
  DEEP_700_LIGHT,
  DEEP_800_LIGHT
} from 'constants/colorsLightMode'
import {
  FONT_SIZE_LARGE,
  BORDER_RADIUS_BUTTON,
  LETTER_SPACING_03,
  FONT_WEIGHT_500,
  FONT_SIZE_SMALL,
  FONT_SIZE_MEDIUM
} from '../../constanst'

const muiButton: {
  defaultProps?: ComponentsProps['MuiButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton']
  variants?: ComponentsVariants['MuiButton']
} = {
  defaultProps: { disableElevation: true },
  styleOverrides: {
    root: {
      fontFamily: `${['Roboto Flex'].join(',')} !important`,
      padding: '12px 32px',
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      borderRadius: BORDER_RADIUS_BUTTON,
      textTransform: 'none',
      letterSpacing: LETTER_SPACING_03,
      fontStyle: 'normal'
    },
    sizeSmall: {
      height: '24px',
      fontSize: FONT_SIZE_SMALL,
      padding: '8px 20px'
    },
    sizeMedium: {
      fontSize: FONT_SIZE_MEDIUM,
      padding: '10px 28px'
    },
    sizeLarge: {
      fontSize: FONT_SIZE_LARGE,
      padding: '12px 23px',
      height: '48px',
      fontWeight: 500,
      letterSpacing: LETTER_SPACING_03,
      fontStyle: 'normal'
    },
    textSizeSmall: {
      padding: '8px 20px'
    },
    textSizeMedium: {
      padding: '10px 28px'
    },
    textSizeLarge: {
      padding: '12px 30px'
    }
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        height: '35px !important',
        fontSize: FONT_SIZE_MEDIUM,
      }
    },
    {
      props: { variant: 'text' },
      style: {
        '&:hover, &:active, &:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    {
      props: { variant: 'contained', color: 'primary' },
      style: {
        backgroundColor: TREND_ACENT_200_LIGHT,
        color: DEEP_800_LIGHT,
        '&:active, &:focus': {
          backgroundColor: TREND_ACENT_200_LIGHT,
          color: DEEP_800_LIGHT
        },
        '&:hover': {
          color: DEEP_700_LIGHT
        },
        '&:disabled': {
          color: DEEP_300_LIGHT
        }
      }
    },
    {
      props: { variant: 'text', color: 'primary' },
      style: {
        color: TREND_ACENT_200_LIGHT,
        '&:hover': {
          color: TREND_ACENT_200_LIGHT
        },
        '&:disabled': {
          color: DEEP_200_LIGHT
        }
      }
    },
    {
      props: { variant: 'text', color: 'secondary' },
      style: {
        color: DEEP_200_LIGHT,
        '&:disabled': {
          color: DEEP_300_LIGHT
        }
      }
    },
    {
      props: { variant: 'outlined', color: 'primary' },
      style: {
        color: DEEP_700_LIGHT,
        borderColor: DEEP_500_LIGHT,
        '&:hover': {
          borderColor: TREND_ACENT_100_LIGHT
        },
        '&:disabled': {
          borderColor: DEEP_400_LIGHT,
          color: DEEP_400_LIGHT
        }
      }
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: {
        backgroundColor: DEEP_600_LIGHT,
        color: DEEP_200_LIGHT,
        '&:hover': {
          backgroundColor: DEEP_500_LIGHT,
          color: DEEP_300_LIGHT
        },
        '&:focus': {
          backgroundColor: DEEP_400_LIGHT,
          color: DEEP_300_LIGHT
        },
        '&:disabled': {
          color: DEEP_500_LIGHT,
          backgroundColor: DEEP_600_LIGHT
        }
      }
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        backgroundColor: 'transparent',
        color: DEEP_200_LIGHT,
        borderColor: DEEP_200_LIGHT,
        '&:hover': {
          color: DEEP_300_LIGHT
        },
        '&:active': {
          color: DEEP_300_LIGHT
        },
        '&:disabled': {
          color: DEEP_600_LIGHT
        }
      }
    }
  ]
}

export default muiButton
