export const typesUrlsCache = {
  workspace: 'workspace',
  MarketApi: 'MarketApi',
  NewQueriesApi: 'NewQueriesApi',
  NewsApi: 'NewsApi',
  StudiesApi: 'StudiesApi',
  TableListApi: 'TableListApi',
  TickerApi: 'TickerApi',
  UserSettingsApi: 'UserSettingsApi',
  WidgetApi: 'WidgetApi',
  InstrumentApi: 'InstrumentApi',
  MexderApi: 'MexderApi'
}

//tiempo por horas
export const listHabilityCacheUrl = [
  {
    type: typesUrlsCache.workspace,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: false,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.MarketApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.NewQueriesApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.NewsApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.StudiesApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.TableListApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: false,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.TickerApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.UserSettingsApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.WidgetApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  },
  {
    type: typesUrlsCache.InstrumentApi,
    expire: 24,
    timeRefresh: 2,
    closeMarket: 13,
    status: false,
    typesCacheDelete: [typesUrlsCache.WidgetApi]
  },
  {
    type: typesUrlsCache.MexderApi,
    expire: 24,
    timeRefresh: 1,
    closeMarket: 13,
    status: true,
    typesCacheDelete: []
  }
]
