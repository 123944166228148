export default class FetchController {
  private static abostsControllerFetchs = {}
  1
  static fetchAbordController(keyController) {
    if (this.abostsControllerFetchs[keyController] && this.abostsControllerFetchs[keyController].length > 0) {
      this.abostsControllerFetchs[keyController].map((abort) => abort?.abort())
      delete this.abostsControllerFetchs[keyController]
    }
    return
  }

  static setAbortController(keyController) {
    const controller = new AbortController()
    if (!this.abostsControllerFetchs[keyController]) {
      this.abostsControllerFetchs[keyController] = [controller]
    }
    this.abostsControllerFetchs[keyController].push(controller)
    return controller
  }
}
