import { User } from 'constants/types'

export default class Token {
  accessToken: string = ''
  refreshToken: string = ''

  constructor(user?: User) {
    if (user) {
      this.accessToken = user.accessToken
      this.refreshToken = user.refreshToken
    }
  }

  setToken(accessToken: string, refreshToken: string): Token {
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    return this
  }

  // noinspection JSUnusedGlobalSymbols
  $key: string = ''

  // noinspection JSUnusedGlobalSymbols
  $value: string = ''
}
