import { Theme, ComponentsOverrides, ComponentsVariants, ComponentsProps } from '@mui/material/styles'
import { GREY_LOW } from 'constants/colors'

import { DEEP_700_LIGHT, DEEP_300_LIGHT, DEEP_400_LIGHT } from 'constants/colorsLightMode'

const MuiIconButton: {
  defaultProps?: ComponentsProps['MuiIconButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton']
  variants?: ComponentsVariants['MuiIconButton']
} = {
  styleOverrides: {
    root: {
      backgroundColor: DEEP_700_LIGHT,
      color: DEEP_300_LIGHT,
      '&:active, &:focus': {
        backgroundColor: DEEP_400_LIGHT,
      },
      '&:hover': {
        backgroundColor: DEEP_400_LIGHT
      },
      '&:disabled': {
        color: GREY_LOW,
        opacity: 0.6
      },
      '& .MuiSvgIcon-root': {
        fontSize: '20px',
        margin: 5
      }
    }
  }
}

export default MuiIconButton
