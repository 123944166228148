export const PATH_LOGIN = '/'
export const PATH_RETO_ACTINVER = '/reto-actinver-2024'
export const PATH_TERMINAL = '/terminal'
export const PATH_ADVANCE_GRAPHICS = '/graficas'
export const PATH_ANALYSIS = '/analisis'
export const PATH_ANALYSIS_RESUMEN = '/analisis/resumen'
export const PATH_ANALYSIS_NOTICIAS = '/analisis/noticias'
export const PATH_ANALYSIS_FINANCIAL = '/analisis/financieros'
export const PATH_ANALYSIS_PRICES = '/analisis/precios'
export const PATH_ANALYSIS_RECOMENDATIONS = '/analisis/recomendaciones'
export const PATH_ANALYSIS_DIVIDENDS = '/analisis/dividendos'
export const PATH_ANALYSIS_METRICS = '/analisis/metricas'
export const PATH_ANALYSIS_SECTORIAL = '/analisis/sectorial'
export const PATH_ANALYSIS_CARTERA = '/analisis/cartera'

export const KEY_STORAGE_LAST_PATH = 'last-path'
