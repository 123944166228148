import { combineReducers } from 'redux'
import account from './account/reducer'
import news from './news/reducer'
import workspaces from './workspaces/reducer'
import instrumentDetails from './instrumentDetails/reducer'
import alert from './alert/reducer'
import newsDetails from './newsDetails/reducer'
import ticker from './ticker/reducer'
import settings from './userSettings/reducer'
import dragAndDrop from './dragAndDrop/reducer'
import sidebarMenu from './sidebarMenu/reducer'
import bulkDownload from './bulkDownloads/reducer'
import analysis from './analysis/reducer'
import chatbot from './chatbot/redecuer'
import { Reducers } from './types'

const reducers: Reducers = {
  account,
  news,
  workspaces,
  instrumentDetails,
  alert,
  newsDetails,
  ticker,
  settings,
  dragAndDrop,
  sidebarMenu,
  bulkDownload,
  analysis,
  chatbot
}

const combined: any = combineReducers(reducers)

export default combined
