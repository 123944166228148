import ACTION_TYPES from './actionTypes'
import type { SidebarMenuReducer } from './types'

const initialState = {
  showMenu: false,
  typeCollapse: 0
}

const reducer: SidebarMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.OPEN_SIDEBAR_MENU:
      return { ...state, showMenu: true, typeCollapse: action.typeCollapse }

    case ACTION_TYPES.CLOSE_SIDEBAR_MENU:
      return { ...state, showMenu: false, typeCollapse: 0 }

    default:
      return state
  }
}

export default reducer
