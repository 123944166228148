import i18n from 'i18n/index'
import { DEFAULT_TABLE_ROW_LENGHT, MONTHS, specialCharacters, SYMBOL_REAL_TIME_TYPES } from 'constants/globals'
import { BLACK, WHITE } from 'constants/colors'
import { arrayValidation, colorFormatting, formatValue } from 'utils/helpers'
import { formatCustomDate, getJulianDateStringFromDate } from 'utils/helpersDates'
import { formats } from '../../Terminal/Table/constants'
import { initialColors } from './constants'
import { DataTableColumn } from '../../Terminal/Table/types'

export const selectAndCopyTable = (el: HTMLElement) => {
  const range = document.createRange()
  const sel = window.getSelection()

  if (!sel) return

  sel.removeAllRanges()
  try {
    range.selectNode(el)
    sel.addRange(range)
  } catch (e) {
    range.selectNodeContents(el)
    sel.addRange(range)
  }

  document.execCommand('Copy')
  sel.removeAllRanges()
}

const loadMoreInstruments = (
  rows: Array<Record<string, any>>,
  visibleRows: Array<Record<string, unknown>>
): Array<Record<string, unknown>> => {
  let newRows: Array<Record<string, unknown>> = []
  rows.forEach((rowElement) => {
    const found = visibleRows.find((visibleRowElement) => rowElement.descripcion === visibleRowElement.descripcion)
    if (!found) {
      newRows.push(rowElement)
    }
  })
  if (newRows.length > DEFAULT_TABLE_ROW_LENGHT) {
    newRows = newRows.slice(0, DEFAULT_TABLE_ROW_LENGHT)
  }
  const newVisibleRows = visibleRows.concat(newRows)
  return newVisibleRows
}

export const getValueByFormat = (format: string, value: any, isRealTime?: boolean) => {
  const innerText = String(value)
  let newValue
  let replaced
  switch (format) {
    case formats.STRING:
      newValue = innerText
      break
    case formats.FLOAT:
      replaced = innerText.replace(/,/g, '').replace('%', '')

      newValue = parseFloat(replaced)
      break
    case formats.DATE:
      if (isRealTime && !isNaN(Number(innerText))) {
        newValue = getJulianDateStringFromDate(Number(innerText))
      } else {
        newValue = innerText
      }
      break
    case formats.TIME:
      newValue = innerText.slice(1, 2) === specialCharacters.COLON ? `0${innerText}` : innerText
      newValue = newValue.length === 5 ? `${newValue}:00` : newValue
      break
    case formats.INT:
      replaced = innerText.replace(/,/g, '')
      newValue = Number(replaced)
      break
    case formats.FIGURE:
      replaced = innerText.replace(/,/g, '')
      newValue = parseFloat(replaced)
      break
    case formats.FORMATDATEYYYY:
      newValue = formatCustomDate(new Date(innerText), 'YYYY-MM-DD')
      break
    default:
      break
  }
  return newValue
}

export const isTodayData = (date: string | null) => {
  if (date === null || date === undefined || date === '') {
    return false
  }
  const currentDate = new Date().setHours(0, 0, 0, 0)
  let newDate = date
  const dateSplit = newDate.split('-')

  if (arrayValidation(dateSplit) && dateSplit.length > 2 && isNaN(Number(dateSplit[1]))) {
    const month = MONTHS.find((item) => item.name === String(dateSplit[1]))
    newDate = `${dateSplit[0]}-${month?.id || ''}-${dateSplit[2]}`
  }

  const dateFormated = newDate.split('-').reverse().join('/')
  const dataDate = new Date(dateFormated)

  if (dataDate.getTime() < currentDate) {
    return false
  }

  return true
}

const currentDate = new Date()
export const currentDataDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`

export default loadMoreInstruments

export const getColorInstrument = (validateNoOperation: boolean, value: any, dataDate: string | null) => {
  if (!validateNoOperation) return colorFormatting(value)

  return isTodayData(dataDate || null) ? colorFormatting(value) : initialColors.noOperation
}

export const getMessageByRealTimeSymbol = {
  [`${SYMBOL_REAL_TIME_TYPES.DELAY}`]: `${i18n.t('priceWithDelay')}`,
  [`${SYMBOL_REAL_TIME_TYPES.CLOSING}`]: `${i18n.t('sidebarInformationTimestamp')}`.replace('.', '')
}

export const getFortmatTime = (value: string) => {
  if (!value) return ''
  let newVal = String(value).slice(1, 2) === specialCharacters.COLON ? `0${value}` : value
  if (newVal) {
    newVal = newVal.length === 5 ? `${newVal}:00` : newVal
  }

  return newVal
}

export const generateTableToCopy = (
  tableRef: React.RefObject<HTMLTableElement>,
  otherRows: Array<Record<string, unknown>>,
  header: DataTableColumn[]
) => {
  if (!tableRef || !tableRef.current) {
    return
  }

  const newTable = document.createElement('table')

  for (let i = 0; i < tableRef.current.rows.length; i += 1) {
    const currentRow = tableRef.current.rows[i].cloneNode(true) as HTMLTableRowElement

    for (let y = 0; y < currentRow.cells.length; y += 1) {
      const cell = currentRow.cells[y]
      cell.style.backgroundColor = WHITE
      cell.style.color = BLACK
    }

    newTable.appendChild(currentRow)
  }
  if (otherRows.length > 0) {
    for (let i = 0; i < otherRows.length; i += 1) {
      const currentRow = tableRef.current.rows[1].cloneNode(true) as HTMLTableRowElement

      for (let y = 0; y < currentRow.cells.length; y += 1) {
        const column = header[y]
        const cell = currentRow.cells[y]
        let value = String(otherRows[i][column.apiField])
        value = String(formatValue(String(value), column.format, Number(column.decimals)))

        cell.textContent = value
        cell.style.backgroundColor = WHITE
        cell.style.color = BLACK
      }

      newTable.appendChild(currentRow)
    }
  }

  if (document.body) {
    document.body.appendChild(newTable)
    selectAndCopyTable(newTable)

    if (document.body) {
      document.body.removeChild(newTable)
    }
  }
}
