import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_400_LIGHT, DEEP_600_LIGHT, DEEP_300_LIGHT, DEEP_700_LIGHT, DEEP_200_LIGHT, DEEP_500_LIGHT } from 'constants/colorsLightMode'

import { FONT_SIZE_LARGE, FONT_SIZE_MEDIUM, FONT_SIZE_SMALL, FONT_WEIGHT_500 } from '../../constanst'

const MuiChip: {
  defaultProps?: ComponentsProps['MuiChip']
  styleOverrides?: ComponentsOverrides<Theme>['MuiChip']
  variants?: ComponentsVariants['MuiChip']
} = {
  styleOverrides: {
    root: {
      color: `${DEEP_400_LIGHT}`,
      backgroundColor: `transparent`,
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      textTransform: 'none',
      fontStyle: 'normal',
      '&:hover': {
        backgroundColor: `${DEEP_600_LIGHT}`
      }
    }
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        color: `${DEEP_300_LIGHT} !important`,
        backgroundColor: `${DEEP_700_LIGHT} !important`,
        border: `1px solid ${DEEP_300_LIGHT}`,
        fontSize: FONT_SIZE_MEDIUM,
        '& .MuiChip-deleteIcon': {
          color: DEEP_300_LIGHT,
          zIndex: 10
        }
      }
    },
    {
      props: { variant: 'filled' },
      style: {
        color: `${DEEP_200_LIGHT} !important`,
        backgroundColor: `${DEEP_500_LIGHT} !important`,
        border: `0px solid ${DEEP_400_LIGHT}`,
        fontSize: FONT_SIZE_MEDIUM,
        '& .MuiChip-deleteIcon': {
          color: DEEP_300_LIGHT,
          zIndex: 10
        }
      }
    },
    {
      props: { size: 'medium' },
      style: {
        height: `36px`,
        padding: '12px 16px',
        fontSize: FONT_SIZE_MEDIUM
      }
    },
    {
      props: { size: 'small' },
      style: {
        height: `24px`,
        padding: '6px 12px',
        fontSize: FONT_SIZE_SMALL
      }
    }
  ]
}

export default MuiChip
