// @flow

import styled from 'styled-components'
import type { ComponentType } from 'react'
import { ALMOST_WHITE } from 'constants/colors'

export const ProgressContainer: ComponentType<any> = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ProgressBar: ComponentType<any> = styled.div`
  margin-top: 54px;
  width: 400px;
  height: 4px;
`

export const Container: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
`

export const Image: ComponentType<any> = styled.img`
  object: contain;
  height: 100%;
  width: 100%;
`

export const ImageContainer: ComponentType<any> = styled.div`
  height: 47.5px;
  width: 47.5px;
`

export const Title: ComponentType<any> = styled.p`
  font-size: 56px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${ALMOST_WHITE};
`
