import requestTypes from 'constants/requestTypes'
import { API_MARKETS_TYPES, API_STOCKS_FIELDS } from 'apis/constants'
import { fetchNext } from 'utils/request'
import { getApiMarketUrl, getApiWorkspaceUrl, getFactsetUrl } from 'utils/endpoints'
import { LastLevel1CapitalBMV } from 'apis/models/lastLevel1CapitalBMV'
import { BrokerageFirmResponse, BrokerageFirmCalalogResponse } from 'apis/models/brokerageFirm'
import { QueryResponse } from 'apis/models/QueryResponse'
import { IntrumentSicResponse } from 'apis/models/instrumentSicResponse'
import {
  GetInstrumentResponse,
  InstrumentObjectResponse,
  Instrument,
  IntrumentRecommendation
} from './models/instrument'
import { GetRecentInstrumentResponse } from './models/recentInstrument'
import { PriceTargetData } from './models/priceTarget'
import { ProfileFundsData } from './models/profileFundsData'
import { LocalRecommendationsResponse } from './models/localRecommendations'
import { BrokerageFirmsReportResponse } from './models/brokerageFirmsReportResponse'
import { LastDynamicResponse } from './models/lastDynamic'
import { ColumnsResponse } from './models/columnsResponse'
import { typesUrlsCache } from './listCache'
import { RatiosResponse } from './models/ratios'
import { InstrumentData } from './models/instrumentV3'

const marketUrl = getApiMarketUrl()

export default class InstrumentApi {
  static getInstrumentListByWidgetType = (widgetTypeId: string): Promise<GetInstrumentResponse> =>
    fetchNext(requestTypes.GET, `/instruments/widgetType/${widgetTypeId}`, undefined, marketUrl)

  static getInstrumentData = (
    instruments: string
  ): Promise<{
    data: LastLevel1CapitalBMV[]
  }> =>
    fetchNext(
      requestTypes.GET,
      `/instruments/last?instrumentKey=${instruments}&fields=${API_STOCKS_FIELDS}`,
      undefined,
      marketUrl
    )

  static getLastForDollar = (
    instruments: string
  ): Promise<{
    data: Array<LastLevel1CapitalBMV>
  }> => fetchNext(requestTypes.GET, `/instruments/last?instrumentKey=${instruments}&fields=`, undefined, marketUrl)

  static getInstrumentsearchQueryData = (
    searchQuery: string
  ): Promise<{
    data: Array<Instrument>
  }> => fetchNext(requestTypes.GET, `/instruments?searchQuery=${searchQuery}&markets=${API_MARKETS_TYPES}`)

  static sendSearchSelectedSymbolId = (
    id: number
  ): Promise<{
    data: { message: string }
  }> => {
    return fetchNext(
      requestTypes.POST,
      `/searches/instruments`,
      {
        symbolId: id
      },
      marketUrl
    )
  }

  static fetchRecentResults = (searchTerm: string, numberOfRows?: number): Promise<GetRecentInstrumentResponse> => {
    let url = `/searches?word=${searchTerm}`

    if (numberOfRows) {
      url = `${url}&rows=${numberOfRows}`
    }

    return fetchNext(requestTypes.GET, url, undefined, marketUrl)
  }

  static addInstrument = (
    workspaceId: string,
    widgetId: string,
    instrumentId: string,
    order: number
  ): Promise<InstrumentObjectResponse> => {
    const reqBody = {
      instrumentId,
      order
    }

    return fetchNext(
      requestTypes.POST,
      `/users/workspaces/${workspaceId}/widgets/${widgetId}/instrument`,
      reqBody,
      undefined,
      false,
      typesUrlsCache.InstrumentApi
    )
  }

  static addInstrumentsToTable = (
    workspaceId: string,
    widgetId: string,
    instrumentIds: Array<Object>
  ): Promise<InstrumentObjectResponse> => {
    const reqBody = {
      instrumentIds
    }

    return fetchNext(
      requestTypes.POST,
      `/users/workspaces/${workspaceId}/widgets/${widgetId}/instruments`,
      reqBody,
      undefined,
      false,
      typesUrlsCache.InstrumentApi
    )
  }

  static deleteInstrument = (workspaceId: string, widgetId: string, instrumentId: string, order: number) => {
    const reqBody = {
      instrumentId,
      order
    }

    return fetchNext(
      requestTypes.DELETE,
      `/users/workspaces/${workspaceId}/widgets/${widgetId}/instrument`,
      reqBody,
      undefined,
      false,
      typesUrlsCache.InstrumentApi
    )
  }

  static searchInstrumentsByMarketType = (
    marketTypeId: string,
    searchTerm: string,
    assetClass?: string,
    excludeAssetClass?: string,
    isSearched: boolean = false,
    limit?: number,
    filterOnlySymbol?: boolean
  ): Promise<GetInstrumentResponse> => {
    const searchValueEncoded = encodeURIComponent(searchTerm)

    let uri = `/instruments?searchQuery=${searchValueEncoded}&markets=${marketTypeId}&includeInactive=true`
    if (assetClass) {
      uri = `${uri}&assetClass=${assetClass}`
    }
    if (excludeAssetClass) uri = `${uri}&excludeAssetClass=${excludeAssetClass}`

    // Tmp Fix: Add limit for strings < 3 chars
    if (!isSearched) {
      if (searchValueEncoded.length < 3) uri = `${uri}&limit=3000`
    }

    if (limit) uri = `${uri}&limit=${limit}`

    if (filterOnlySymbol) uri = `${uri}&searchInSymbolOnly=true`

    return fetchNext(requestTypes.GET, uri)
  }

  static getProfileData = (instrumentId: string): Promise<any> =>
    fetchNext(
      requestTypes.GET,
      `/instruments/detail/profile?instrumentKey=${instrumentId}`,
      undefined,
      getApiMarketUrl()
    )

  static getProfileFundsData = (instrumentId: string): Promise<ProfileFundsData> =>
    fetchNext(
      requestTypes.GET,
      `/instruments/detail/funds/profile?instrumentId=${encodeURIComponent(instrumentId)}`,
      undefined,
      getFactsetUrl()
    )

  static getBrokerageFirms = (): Promise<BrokerageFirmResponse> =>
    fetchNext(requestTypes.GET, '/instruments/detail/brokerageFirms/catalog', undefined, getApiMarketUrl())

  static getBrokerageFirmsCatalog = (): Promise<BrokerageFirmCalalogResponse> =>
    fetchNext(requestTypes.GET, '/instruments/report/brokerageFirms/catalog', undefined, getApiMarketUrl())

  static getQueries = (
    typeQuery: string,
    rows?: number,
    page?: number,
    brokerageFirms?: Array<number>,
    valueTypeIds?: Array<number>
  ): Promise<QueryResponse> => {
    let uri = `/report/brokerageFirms/${typeQuery}`
    if (rows) uri = `${uri}?rows=${rows}`
    if (page) uri = `${uri}&page=${page}`
    if (valueTypeIds && valueTypeIds.length > 0) uri = `${uri}&valueTypeId=${valueTypeIds.join()}`
    if (brokerageFirms && brokerageFirms.length > 0) uri = `${uri}&brokerageFirm=${brokerageFirms.join()}`

    return fetchNext(requestTypes.GET, uri, undefined, getApiMarketUrl())
  }

  static getInstrumentsSic = (instrumentIds: string, signalAbort: any, invert?: any): Promise<IntrumentSicResponse> => {
    let uri = `/instruments/sic/mapping?instrumentIds=${instrumentIds}`
    if (invert) uri = `${uri}&invert=${invert}`
    return fetchNext(
      requestTypes.GET,
      uri,
      {
        signal: signalAbort
      },
      getApiWorkspaceUrl()
    )
  }

  static getInstrumentRecommendation = (instrumentKey: string): Promise<IntrumentRecommendation> =>
    fetchNext(
      requestTypes.GET,
      `/instruments/stocks/recommendation-trends?instrumentKey=${instrumentKey}`,
      undefined,
      getFactsetUrl()
    )

  static getPriceTarget = (instrumentKey: string, targetCurrency: string): Promise<PriceTargetData> =>
    fetchNext(
      requestTypes.GET,
      `/instruments/stocks/price-target/?instrumentKey=${instrumentKey}&targetCurrency=${targetCurrency}`,
      undefined,
      getFactsetUrl()
    )

  static getLocalRecommendations = (instrumentKey: string): Promise<LocalRecommendationsResponse> =>
    fetchNext(
      requestTypes.GET,
      `/instruments/report/local/recommendations?instrumentId=${
        instrumentKey === '1/12576/0/PE%26OLES%2A' ? instrumentKey : encodeURIComponent(instrumentKey)
      }`,
      undefined,
      getFactsetUrl()
    )

  static getBrokerageFirmsReport = (
    startDate: string,
    endDate: string,
    brokerageFirm?: string,
    instrumentkeys?: string
  ): Promise<BrokerageFirmsReportResponse> => {
    let url = `/instruments/report/brokerageFirms?startDate=${startDate}&endDate=${endDate}`

    if (brokerageFirm) {
      url = `${url}&brokerageFirm=${brokerageFirm}`
    }
    if (instrumentkeys) {
      url = `${url}&instrumentKey=${instrumentkeys}`
    }
    return fetchNext(requestTypes.GET, url, undefined, getApiMarketUrl())
  }

  static getLastDynamic = (
    marketTypeId: number,
    condition: string,
    value: string,
    fields: string,
    limit: number,
    valueTypeIds?: Array<number>
  ): Promise<LastDynamicResponse> => {
    let url = `/instruments/last/dynamic?marketTypeId=${marketTypeId}&condition=${condition}&value=${value}&limit=${limit}&fields=${fields}`
    if (valueTypeIds && valueTypeIds.length > 0) {
      url = `${url}&valueTypeIds=${valueTypeIds.join()}`
    }
    return fetchNext(requestTypes.GET, url, undefined, marketUrl)
  }

  static getColumnsByParams = (
    marketId?: number,
    marketTypeId?: number,
    valueTypeId?: number,
    exchangeId?: number
  ): Promise<ColumnsResponse> => {
    let url = '/instruments/columns?'
    if (marketId) {
      url = `${url}market=${marketId}`
    }
    if (marketTypeId) {
      url = `${url}marketTypeId=${marketTypeId}`
    }
    if (valueTypeId) {
      url = `${url}valueTypeId=${valueTypeId}`
    }
    if (exchangeId) {
      url = `${url}exchangeId=${exchangeId}`
    }
    return fetchNext(requestTypes.GET, url, undefined, getApiWorkspaceUrl(), false, typesUrlsCache.InstrumentApi)
  }

  static getValuationRatios = (
    sectorId: string,
    subsectorId: string,
    signalAbort: AbortSignal
  ): Promise<RatiosResponse> => {
    let url = `/instruments/valuation-ratios?sectorId=${sectorId}`
    if (subsectorId) {
      url = `${url}&subSectorId=${subsectorId}`
    }
    return fetchNext(
      requestTypes.GET,
      url,
      {
        signal: signalAbort
      },
      getApiWorkspaceUrl()
    )
  }

  static getInstrument = (
    instrumentKeys: string
  ): Promise<{
    data: Array<InstrumentData>
  }> => fetchNext(requestTypes.GET, `/instruments?instrumentKeys=${instrumentKeys}`, undefined, marketUrl)
}
