import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCurrentUser, setTokenData } from 'utils/user'
import { PATH_LOGIN, PATH_TERMINAL, KEY_STORAGE_LAST_PATH } from 'routes/paths'

const useAuthRedirect = () => {
  const navigate = useNavigate()
  const user = getCurrentUser()

  const redirectUser = () => {
    const lastPath = localStorage.getItem(KEY_STORAGE_LAST_PATH)

    if (!user) {
      if (location.pathname !== PATH_LOGIN) {
        navigate(lastPath || PATH_LOGIN)
      }
      return
    }

    const token = user.tokenData
    if (token) {
      setTokenData(token)
    }

    if (location.pathname === PATH_LOGIN || location.pathname === '/') {
      navigate(lastPath || PATH_TERMINAL)
    }
    if (location.pathname !== lastPath) {
      if (!location.pathname.includes('/analisis')) {
        localStorage.setItem(KEY_STORAGE_LAST_PATH, location.pathname)
      }
    }
  }

  useEffect(() => {
    redirectUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, user])
}

export default useAuthRedirect
