// reducer.ts
import ACTION_TYPES from './actionTypes';
import { ChatBotAction, ChatBotState } from './types';



const initialState: ChatBotState = {
  open: false,
  size: 'medium', // Puede ser 'small', 'medium', 'large', etc.
  historyOpen: false,
};

const chatBotSidebarReducer = (state = initialState, action: ChatBotAction): ChatBotState => {
  switch (action.type) {
    case ACTION_TYPES.OPEN_SIDEBAR:
      return { ...state, open: true };
    case ACTION_TYPES.CLOSE_SIDEBAR:
      return { ...state, open: false };
    case ACTION_TYPES.SET_SIZE:
      return { ...state, size: action.payload };
    case ACTION_TYPES.TOGGLE_HISTORY:
      return { ...state, historyOpen: !state.historyOpen };
    default:
      return state;
  }
};

export default chatBotSidebarReducer;