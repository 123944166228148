import { HttpStatusCode, ErrorMessage } from 'constants/globals'
import { setCurrentUser, getCurrentUser } from './user'
import { logoutUserInternal } from './helpers'
import { getApiWorkspaceUrl, getApiWorkspaceUrlV2 } from './endpoints'
import { Action, State, TypesArray } from './types'
import { listHabilityCacheUrl } from 'apis/listCache'
import FetchController from './fetchController'
import { User } from 'constants/types'

const PENDING_REQUESTS_DELAY = 100

const loginApp = 'hub-app'

let tokenExpired = false

const ApiUrl = getApiWorkspaceUrl()
const ApiUrlV2 = getApiWorkspaceUrlV2()

const intervals: any[] = []

function checkStatus(response: Response) {
  if (response.status >= HttpStatusCode.OK && response.status < HttpStatusCode.MultipleChoices) {
    return response
  }

  const error: Error & { response?: Response; status?: number } = new Error(response.statusText)

  error.response = response
  error.status = response.status
  throw error
}

// noinspection JSUnusedGlobalSymbols
export function genericResponseHandler(state: State | any, action: Action, typesArray: TypesArray[]): State {
  if (state.execCall === true) {
    let upState: any = {}
    typesArray.forEach((typeFromArr: Record<string, string>) => {
      const o: any = {}
      if (action.type === typeFromArr.successType) {
        o[typeFromArr.stateObj] = action[typeFromArr.stateObj]
        upState = { ...o }
      }
    })

    state.assign(...state, ...upState)
  }
  return state
}

function parseJSON(response: Response, options?: any, typeRequest?: string): Promise<any> {
  if (!response) return Promise.resolve({})
  return response.text().then((text) => {
    const saveCache = listHabilityCacheUrl.find((item) => item.type === typeRequest)

    if (saveCache && options?.method === 'GET' && response.status === 200) {
      setCacheResponse(response.url, text ? JSON.parse(text) : {}, typeRequest)
    }

    return text ? JSON.parse(text) : {}
  })
}

/**
 * I removed api logout api call since at this point access and refresh token are invalid, so request will fail
 * It is enough to remove user from local storage and redirect to login page.
 */

const requestsCounter = { count: 0 }

function request(url: string, options: Object, typeRequest?: string): Promise<any> {
  const user = getCurrentUser()
  return fetch(url, options)
    .then(checkStatus)
    .then((a) => parseJSON(a, options, typeRequest))
    .then((data) => data)
    .catch(async (error) => {
      if (user && error.status === HttpStatusCode.Unauthorized) {
        if (requestsCounter.count > 3) {
          //   logoutUserInternal()
          return {}
        }
        requestsCounter.count += 1
        // eslint-disable-next-line no-use-before-define
        return obtainNewAccessToken(url, options)
      }
      return parseJSON(error.response || '', typeRequest)
        .then((data) => Promise.reject(data))
        .catch((message) => {
          if (
            message &&
            message.error &&
            message.error.status === 401 &&
            message.error.reason === 'AuthenticationError'
          ) {
            return Promise.reject(message.error)
          }

          if (message && message.data) {
            return Promise.reject(message)
          }

          if (message && (message.nonFieldErrors || message.detail)) {
            return Promise.reject(message)
          }

          if (message && message.error) {
            return Promise.reject(message)
          }

          const newMessage = ErrorMessage[error.status] || ErrorMessage.Default
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ nonFieldErrors: newMessage })
        })
    })
}

export function getToken(url: string, body: Record<string, any>): Promise<any> {
  const query = !body.refresh ? `?app=${loginApp}` : ''

  let fullUrl = String(ApiUrl) + url + query
  const { username } = body
  const password = btoa(body.password)

  // Login
  let options: any = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, password })
  }

  // refreshToken
  if (body.refresh) {
    fullUrl = `${String(ApiUrl)}/auth/refreshToken`
    options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${body.refresh}`
      }
    }
  }

  return request(fullUrl, options)
}

export function getSSOToken(url: string, token: string): Promise<any> {
  const fullUrl = String(ApiUrl) + url
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `${'Bearer '}${token}`
    }
  }
  return request(fullUrl, options)
}

export function requestForgotPassword(url: string, body: Object): Promise<any> {
  return fetch(url, body)
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => data)
    .catch(async (error) => {
      return error
    })
}

export function getServers(url: string): Promise<any> {
  const fullUrl = String(import.meta.env.VITE_WORKSPACE_URL) + url
  const user = getCurrentUser()

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `${'Bearer '}${user ? user.accessToken : ''}`
    }
  }
  return request(fullUrl, options)
}

// noinspection JSUnusedGlobalSymbols
export function verifyToken(url: string): Promise<any> {
  const fullUrl = String(ApiUrl) + url
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  return request(fullUrl, options)
}

/**
 * Obtains new access token and executes the same request with new access token
 * In case that refresh token is expired and getToken returns error user is logged out
 * @param fullUrl
 * @param options
 * @returns {*}
 */
function obtainNewAccessToken(fullUrl: string, options: any): Promise<any> {
  const user = getCurrentUser()

  const token = {
    refresh: user ? user.refreshToken : ''
  }

  const newUser: User = {
    ...user,
    token: user?.token,
    accessToken: user?.accessToken || '',
    exp: user?.exp || 0,
    iat: user?.iat || 0,
    expRefresh: user?.expRefresh || 0,
    tickerVisible: user?.tickerVisible || false,
    refreshToken: user?.refreshToken || '',
    userId: user?.userId || '',
    tokenData: user?.tokenData || null,
    diferenceTime: user?.diferenceTime || 0
  }

  return getToken(`/auth/login`, token)
    .then((response) => {
      const accessToken = response.access_token
      // eslint-disable-next-line
      options.headers.Authorization = `${'Bearer '}${accessToken}`
      newUser.token = accessToken
      newUser.refreshToken = response.refresh_token || ''
      sessionStorage.setItem('user', JSON.stringify(newUser))
      tokenExpired = false
      setCurrentUser(newUser, accessToken)
      return request(fullUrl, options)
    })
    .catch(async () => {
      tokenExpired = false
      // Clear intervals in case that refresh token is expired, so requests won't be sent.
      if (intervals.length) {
        intervals.forEach((interval) => clearInterval(interval))
      }
      logoutUserInternal()
    })
}

// Replace the direct caches reference with a function that checks environment
const getCacheStorage = () => {
  if (typeof caches !== 'undefined') {
    return caches.open(cacheName)
  }
  // Return a mock cache for non-browser environments
  return Promise.resolve({
    match: () => Promise.resolve(null),
    put: () => Promise.resolve(),
    keys: () => Promise.resolve([]),
    delete: () => Promise.resolve(true)
  })
}

const cacheName = 'hub-cache'
const cachePromise = getCacheStorage()

// Creamos una función para obtener una respuesta de la caché
async function getCachedResponse(url: string): Promise<Response | null> {
  const cacheStorage = await cachePromise
  const cachedResponse = await cacheStorage.match(url)

  if (!cachedResponse || !cachedResponse.ok) {
    return null
  }

  return await cachedResponse.json()
}

// Creamos una función para almacenar una respuesta en caché
async function setCacheResponse(url: string, response: Response, typeRequest?: string): Promise<any> {
  try {
    const cache = await cachePromise
    await cache.put(url, new Response(JSON.stringify({ response, typeRequest })))
  } catch (error) {
    return
  }
}

async function removeCache(typeRequest: string): Promise<any> {
  const cache = await cachePromise
  const cacheKeys = await cache.keys()
  // Filtrar las entradas de la caché que tienen más de una hora de antigüedad
  const keysToDelete = cacheKeys.filter(async (cacheKey) => {
    const cacheResponse = await cache.match(cacheKey)
    if (!cacheResponse || !cacheResponse.ok) {
      return null
    }

    const responseCache = await cacheResponse.json()
    return responseCache.typeRequest === typeRequest
  })
  // Eliminar las entradas de la caché que tienen más de una hora de antigüedad
  await Promise.all(keysToDelete.map((key) => cache.delete(key)))
}

// Creamos una función para intercepta una petición y administrar la caché
async function fetchWithCache(fullUrl: string, options: any, typeRequest: string): Promise<Response> {
  try {
    const saveCache = listHabilityCacheUrl.find((item) => item.type === typeRequest)
    if (saveCache && saveCache.status && options.method === 'GET') {
      const cachedResponse = getCachedResponse(fullUrl)
      const responseCache: any = (await cachedResponse?.then((res) => res)) || null
      if (responseCache && responseCache?.response) return responseCache?.response as any
    }

    if (saveCache) {
      if (options.method !== 'GET') {
        removeCache(typeRequest)
      }
    }

    return request(fullUrl, options, typeRequest)
  } catch (error) {
    return request(fullUrl, options, typeRequest)
  }
}

export function fetchNext(
  type: string,
  url: string,
  body?: Record<string, any>,
  host?: string,
  urlV2: boolean = false,
  typeRequest: string = ''
): Promise<any> {
  let user = getCurrentUser()
  const { idSignalController, ...bodyData } = body || {}
  let fullUrl = ''
  if (host) {
    fullUrl = host + url
  } else {
    fullUrl = ApiUrl ? ApiUrl + url : String(import.meta.env.VITE_WORKSPACE_URL) + url
  }

  if (urlV2) fullUrl = ApiUrlV2 ? ApiUrlV2 + url : String(import.meta.env.VITE_WORKSPACE_URL_V2) + url

  if (type === 'GET' && fullUrl.includes('PE&OLES*')) {
    fullUrl = fullUrl.replace('PE&OLES*', 'PE%26OLES*')
  }

  let options: any = {
    method: type,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${'Bearer '}${user ? user.accessToken : ''}`,
      Accept: `application/json;`
    }
  }

  if (idSignalController) {
    const abortCon = (FetchController as any).setAbortController(idSignalController)
    if (abortCon) {
      options.signal = abortCon.signal
    }
  }

  if (bodyData && type !== 'GET') {
    options = { ...options, body: JSON.stringify(bodyData) }
  }
  const diferenceTime = user ? user.diferenceTime : 0
  let currentTime = (Date.now() + diferenceTime) / 1000

  // Checks if access token expiration time is bigger then current time
  if (user && currentTime < user.exp) {
    requestsCounter.count = 0

    return fetchWithCache(fullUrl, options, typeRequest)
  }

  // Sets interval for all request that has invalid access token. Once refresh token is completed, repeat the same
  // request with new access token
  if (tokenExpired) {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        currentTime = Date.now() / 1000
        user = getCurrentUser()
        if (user && currentTime < user.exp) {
          options.headers.Authorization = `${'Bearer '}${user.accessToken}`
          clearInterval(interval)
          resolve(request(fullUrl, options))
        }
      }, PENDING_REQUESTS_DELAY)
      // Adding each interval to intervals list so we can clear it when user is logged out.
      intervals.push(interval)
    })
  }
  tokenExpired = true
  return obtainNewAccessToken(fullUrl, options)
}

export const refreshTokenUser = () => {
  const user = getCurrentUser()
  if (user) {
    const token = { refresh: user.refreshToken || '' }
    const newUser: User = {
      token: user.token,
      accessToken: user.accessToken,
      exp: user.exp,
      iat: user.iat,
      expRefresh: user.expRefresh,
      tickerVisible: user.tickerVisible,
      refreshToken: user.refreshToken,
      userId: user.userId,
      tokenData: user.tokenData,
      diferenceTime: user.diferenceTime
    }

    getToken('/auth/login', token)
      .then((response) => {
        const accessToken = response.access_token
        // eslint-disable-next-line
        newUser.token = accessToken
        newUser.refreshToken = response.refresh_token || ''
        sessionStorage.setItem('user', JSON.stringify(newUser))
        tokenExpired = false
        setCurrentUser(newUser, accessToken)
      })
      .catch(() => logoutUserInternal())
  } else {
    logoutUserInternal()
  }
}
