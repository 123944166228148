import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_100_LIGHT, DEEP_700_LIGHT, TREND_ACENT_200_LIGHT } from 'constants/colorsLightMode'

const muiSelect: {
  defaultProps?: ComponentsProps['MuiSelect']
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect']
  variants?: ComponentsVariants['MuiSelect']
} = {
  styleOverrides: {
    standard: {
      color: DEEP_100_LIGHT,
      '&:focus': {
        backgroundColor: 'transparent'
      },
      '&.MuiInputBase-root': {
        '&.Mui-focused': {
          backgroundColor: TREND_ACENT_200_LIGHT,
          '& > div': {
            color: `${DEEP_700_LIGHT} !important`,
          }
        },

      },
    }
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        fontSize: '14px',
        fontWeight: 500,
        '& .MuiSelect-select': {
          padding: '8px 5px',
          paddingRight: '0px',
        },
        '&.MuiInputBase-root': {
          '& > svg': {
            fontSize: '20px'
          }
        }
      }
    },
    {
      props: { size: 'medium' },
      style: {
        fontSize: '16px',
        fontWeight: 500,
        '& .MuiSelect-select': {
          padding: '10px 28px',
          paddingRight: '0px',
        },
        '&.MuiInputBase-root': {
          '& > svg': {
            fontSize: '20px'
          }
        }
      }
    }
  ]
}

export default muiSelect
