import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_100, DEEP_200, DEEP_400, TREND_ACENT_200, TREND_DOWN_200 } from 'constants/colorsDarkMode'

import { FONT_SIZE_LARGE, FONT_WEIGHT_500 } from '../../constanst'

const muiTextfield: {
  defaultProps?: ComponentsProps['MuiTextField']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField']
  variants?: ComponentsVariants['MuiTextField']
} = {
  styleOverrides: {
    root: {
      color: `${DEEP_100}`,
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      textTransform: 'none',
      fontStyle: 'normal',
      '& label.Mui-focused': {
        color: DEEP_200,
        height: 40
      },
      '&.MuiInput-underline:after': {
        borderBottomColor: TREND_ACENT_200
      },
      '&.MuiOutlinedInput-root': {
        height: 24,
        '& fieldset': {
          borderColor: DEEP_200
        },
        '&:hover fieldset': {
          borderColor: DEEP_200
        },
        '&.Mui-focused fieldset': {
          borderColor: TREND_ACENT_200
        }
      },
      '&.MuiOutlinedInput-input': {
        border: 'none',
        height: 40,
        color: DEEP_200
      },
      '&.MuiTextField-root': {
        color: DEEP_200
      },
      '&:hover .MuiTextField-root': {
        borderColor: 'grey'
      },
      '&.MuiFormLabel-root.Mui-focused': {
        color: TREND_ACENT_200
      },
      '&.MuiFormLabel-root.Mui-disabled': {
        color: DEEP_400
      },
      '&.MuiInput-underline.Mui-disabled:before': {
        borderBottomColor: `${DEEP_400} !important`,
        borderBottomStyle: `solid !important`
      },
      '&.MuiInputBase-root': {
        height: 24,
        color: DEEP_200
      },
      '&.MuiFormLabel-root': {
        color: DEEP_200
      },
      '&.MuiOutlinedInput-notchedOutline': {
        color: TREND_ACENT_200,
        borderColor: DEEP_200
      },
      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        color: DEEP_200
      },
      '&.MuiFormLabel-root.Mui-error': {
        color: `${DEEP_200} !important`
      },
      '&.MuiInput-underline.Mui-error:after': {
        borderBottomColor: `${TREND_DOWN_200} !important`
      },
      '&.MuiFormHelperText-root.Mui-error': {
        color: `${TREND_DOWN_200} !important`
      }
    }
  },
  variants: []
}

export default muiTextfield
