import ACTION_TYPES from './actionTypes'
import { NewsReducer, GetNewsSuccessAction, RefreshNewsAction } from './types'

const initialState = {
  news: [],
  newsDetails: null,
  services: [],
  refreshNews: 0
}

const reducer: NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_NEWS_SUCCESS: {
      const newAction = action as GetNewsSuccessAction
      return {
        ...state,
        news: newAction.news,
        services: newAction.services
      }
    }
    case ACTION_TYPES.REFRESH_NEWS: {
      const newAction = action as RefreshNewsAction
      return {
        ...state,
        refreshNews: newAction.refreshNews
      }
    }
    default:
      return state
  }
}

export default reducer
