import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_300_LIGHT, TREND_ACENT_100_LIGHT, TREND_DOWN_200_LIGHT } from 'constants/colorsLightMode'

const MuiFormControl: {
  defaultProps?: ComponentsProps['MuiFormControl']
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormControl']
  variants?: ComponentsVariants['MuiFormControl']
} = {
  styleOverrides: {
    root: {
      minWidth: 80,
      '& .MuiInput-root::after': {
        borderBottom: `2px solid ${TREND_ACENT_100_LIGHT} !important`
      },
      '& .MuiInput-root::before': {
        borderBottom: `1px solid ${DEEP_300_LIGHT} !important`
      },
      '& .MuiInput-root:hover::before': {
        borderBottom: `2px solid ${DEEP_300_LIGHT} !important`
      },
      '& .Mui-disabled:hover::before': {
        borderBottom: `1px solid ${DEEP_300_LIGHT} !important`
      },
      '& .Mui-error::after': {
        borderBottom: `2px solid ${TREND_DOWN_200_LIGHT} !important`
      }
    }
  },
  variants: []
}

export default MuiFormControl
