import { MarketTypes } from 'constants/globals'

export const API_LEVEL_1_INSTRUMENTS =
  '1/12576/0/ACK__42__,1/12576/0/ALFAA,1/12576/0/ALPEKA,1/12576/0/ALSEAK__42__,1/12576/0/AMXL,1/12576/0/ARAK__42__,1/12576/0/ASURB,1/12576/0/AXTELCPO,1/12576/0/AZTECACPO,1/12576/0/BIMBOA,1/12576/0/BOLSAA,1/12576/0/CEMEXCPO,1/12576/0/CHDRAUIB,1/12576/0/ELEKTRAK__42__,1/12576/0/FEMSAUBD,1/12576/0/GAPB,1/12576/0/GCARSOA1,1/12576/0/GENTERAK__42__,1/12576/0/GFINBURO,1/12576/0/GFNORTEO,1/12576/0/GMEXICOB,1/12576/0/GRUMAB,1/12576/0/HOMEXK__42__,1/12576/0/BACHOCOB,1/12576/0/IENOVAK__42__,1/12576/0/KIMBERA,1/12576/0/LABB,1/12576/0/LALAB,1/12576/0/LIVEPOLCK__45__1,1/12576/0/ALEATICK__42__,1/12576/0/MFRISCOAK__45__1,1/12576/0/NEMAKA,1/12576/0/ORBIAK__42__,1/12576/0/PEK__38__OLESK__42__,1/12576/0/PINFRAK__42__,1/12576/0/SORIANAB,1/12576/0/TLEVISACPO,1/12576/0/VITROA,1/12576/0/VOLARA,1/12576/0/WALMEXK__42__'

export const API_STOCKS_FIELDS =
  'descripcion,porcentaje,precioActual,precioAnterior,variacion,descripcionInstrumento,rendimientoAnual,precioMaximo,precioMinimo,precioMaximoDia,precioMinimoDia,precioApertura,volumenTotal,isin,oportunidadInformacion,volumenAcumulado,instrumento,tasaId,nombre,valorActual,valorAnterior,vencimiento,diponibilidad,fuente,unidades,fecha,uniqueKey,hora,fechaPrecioActual,rendimiento12M,precioMinimo12M,precioMaximo12M,valorTextoActual,valorTextoAnterior,instrumentKey,rendimientoAlAnio,'

export const API_INSTRUMENTS_FIELDS =
  'instrumento,folio,descripcionInstrumento,valorActual,valorAnterior, ultimaActualizacion,frecuencia,unidad,primeraFecha,ultimaFecha,tipoSerie,grupo,descripcion,uniqueKey,oportunidadInformacion'

export const API_CURRENCIES_FIELDS =
  'IDInstrumento,precioActual,variacion,porcentaje,precioMaximoDia,precioMinimoDia,hora,fechaprecioactual,precioAnterior,fechaPrecioAnterior,posturaPrecioCompra,posturafechacompra,posturahoracompra,posturaPrecioVenta,posturafechaventa,posturahoraventa,descripcion'

export const API_INDEXES_FIELDS =
  'IDInstrumento,precioactual,variacion,porcentaje,precioMaximoDia,precioMinimoDia,hora,fechaprecioactual,precioanterior,fechaPrecioAnterior,posturaPrecioCompra,posturafechacompra,posturahoracompra,posturaPrecioVenta,posturafechaventa,posturahoraventa,descripcion'

export const PERFORMANCE_FIELDS = 'rendimientoAlAnio,rendimientoSemanal,rendimientoAnual,rendimientoMensual'

export const alarmsFields = 'precioActual,porcentaje,variacion,oportunidadInformacion'

export const DLR_FIX_FIELDS = 'horaPrecioActual'

export const API_MARKETS_TYPES = `${MarketTypes.CAPITALS.id},${MarketTypes.CURRENCY.id},${MarketTypes.DERIVATIVES.id},${MarketTypes.INDEX.id},${MarketTypes.GENERAL_INDICATORS.id}`
export const API_MARKETS_FOR_CHART = `${MarketTypes.CAPITALS.id},${MarketTypes.CURRENCY.id},${MarketTypes.FIXED_INCOME.id},${MarketTypes.DERIVATIVES.id},${MarketTypes.INDEX.id},${MarketTypes.GENERAL_INDICATORS.id},${MarketTypes.INTERBANK_DOLLAR.id}`

export const LS_LEVEL_1_INSTRUMENTS = [
  '1.12576.0.CEMEXCPO',
  '1.12576.0.ALFAA',
  '1.12576.0.AMXL',
  '1.12576.0.GMEXICOB',
  '1.12576.0.GFNORTEO',
  '1.12576.0.FEMSAUBD',
  '1.12576.0.BIMBOA',
  '1.12576.0.TLEVISACPO',
  '1.12576.0.ELEKTRA*',
  '1.12576.0.GRUMAB',
  '1.12576.0.MEXCHEM*',
  '1.12576.0.PE&OLES*',
  '1.12576.0.ASURB',
  '1.12576.0.KIMBERA',
  '1.12576.0.AC*',
  '1.12576.0.GAPB',
  '1.12576.0.LIVEPOLC-1',
  '1.12576.0.WALMEX*',
  '1.12576.0.LABB',
  '1.12576.0.BOLSAA',
  '1.12576.0.ALSEA*',
  '1.12576.0.ICA*',
  '1.12576.0.ALPEKA',
  '1.12576.0.OHLMEX*',
  '1.12576.0.LALAB',
  '1.12576.0.IENOVA*',
  '1.12576.0.KOFL',
  '1.12576.0.SORIANAB',
  '1.12576.0.GFINBURO',
  '1.12576.0.GCARSOA1',
  '1.12576.0.GENTERA*',
  '1.12576.0.PINFRA*',
  '1.12576.0.AXTELCPO',
  '1.12576.0.HOMEX*',
  '1.12576.0.ARA*',
  '1.12576.0.CHDRAUIB',
  '1.12576.0.MFRISCOA-1',
  '1.12576.0.NEMAKA',
  '1.12576.0.VITROA',
  '1.12576.0.VOLARA',
  '1.12576.0.AZTECACPO'
]

export const NEWS_PARAMS = '&words=""&filter=0&tag=""&period=3&listType=9&'

export const CONFIGURATION_MAX_CHARS = 3999
