import React, { Suspense, Fragment } from 'react'
import { Route } from 'react-router-dom'

import { RouteProps } from './types'
import { Box } from '@mui/material'
import ProgressBar from 'components/Common/ProgressBar'

export const renderRoutes = (routes: RouteProps[]) => {
  return routes
    .filter((route) => route?.enabled)
    .map((route, index) => {
      const Component = route.element || Fragment
      const Layout = route.layout || Fragment
      const Guard = route.guard || Fragment

      return (
        <Route
          key={index}
          path={route.path}
          element={
            <Suspense
              fallback={
                <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh">
                  <ProgressBar loadingCompleted={() => {}} />
                </Box>
              }
            >
              <Guard>
                <Layout>{route.layout ? (props) => <Component {...props} /> : <Component />}</Layout>
              </Guard>
            </Suspense>
          }
        />
      )
    })
}
