import {
  keyStorage,
  TOKEN_DATA,
  MarketTypes,
  MarketTypesIds,
  SIDEBAR_OPTIONS,
  MarketsValueTypeIds,
  SYMBOL_REAL_TIME_TYPES
} from 'constants/globals'
import INDEX_FTSE_UNIQUE_KEYS from 'constants/uniqueKeys'
import { User, TokenData } from 'constants/types'
import Token from './token'
import jwtDecode from 'jwt-decode'
import UserService from '../services/auth'

export function parseJwt(token: Token) {
  return jwtDecode(JSON.stringify(token))
}

export function getCurrentUser(): User | null {
  const user = sessionStorage.getItem(keyStorage.user)
  if (!user) {
    return null
  }
  const userToken = JSON.parse(user)
  const token = userToken.token
  const tokenParsed: TokenData = parseJwt(token)

  return {
    ...userToken,
    username: tokenParsed.preferred_username,
    firstName: tokenParsed.given_name,
    lastName: tokenParsed.family_name,
    email: tokenParsed.email
  }
}

export function getIfTickerVisible(): boolean {
  const user = sessionStorage.getItem(keyStorage.user)
  return user ? JSON.parse(user).tickerVisible : false
}

export function getLSUrl(): string {
  return String(import.meta.env.VITE_LS_URL)
}

export function getLSUrlMD(): string {
  return String(import.meta.env.VITE_MD2_URL)
}

export function getApiUrl(): string {
  return String(import.meta.env.VITE_WORKSPACE_URL)
}

const cleanTokenData = (): void => {
  TOKEN_DATA.hasPermissionToPosturesBMV = false
  TOKEN_DATA.hasPermissionToInternationalExchanges = false
  TOKEN_DATA.hasPermissionToSectors = false
  TOKEN_DATA.hasPermissionToInvestmentFunds = false
  TOKEN_DATA.isBmvDelay = false
  TOKEN_DATA.isBivaDelay = false
  TOKEN_DATA.isInternationalBagsDelay = false
  TOKEN_DATA.isAmexDelay = false
  TOKEN_DATA.isNyseDelay = false
  TOKEN_DATA.isNasdaqDelay = false
  TOKEN_DATA.isIndexBmvDelay = false
  TOKEN_DATA.isIndexJdiAndSPDelay = false
  TOKEN_DATA.data = null

  SIDEBAR_OPTIONS.forEach((option) => {
    if (option && option.value === 'Posturas') {
      option.disabled = false
      option.iconType = ''
      option.titleTooltip = ''
    }
  })
}

const ROLE_INTERNATIONAL_BAGS_TR = 'BI_RT'
const ROLE_POSTURAS = 'DEPTH_20_BMV'
const ROLE_POSTURAS_BIVA = 'DEPTH_20_BIVA'
const ROLE_POSTURAS_CONSOLIDATED = 'DEPTH_20_CONS'
const ROLE_SECTORIAL = 'SECTOR'
const ROLE_USER = 'user'
const ROLE_FONDOS = 'FONDOS'
const ROLE_BMV_DELAY = 'BMV_DL'
const ROLE_BIVA_DELAY = 'BIVA_DL'
const ROLE_INTERNATIONAL_BAGS_DELAY = 'BI_DL'
const ROLE_AMEX_DELAY = 'AMEX_DL'
const ROLE_NYSE_DELAY = 'NYSE_DL'
const ROLE_NASDAQ_DELAY = 'NASDAQ_DL'
const ROLE_INDEX_BMV_DELAY = 'INDICES_BMV_DL'
const ROLE_INDEX_DJI_SP_DELAY = 'DJI_SP_DL'
const ROLE_MT_NEWSWIRES = 'MTN'
const ROLE_GUBERNAMENTAL_DEBT = 'GUBERNAMENTAL_DEBT_RT'
const ROLE_INTERNATIONAL_DEBT = 'INTERNATIONAL_DEBT_RT'
const ROLE_PRIVATE_DEBT = 'PRIVATE_DEBT_RT'
const ROLE_BROKERAGE_FIRMS = 'BROKERAGE_FIRMS_REPORTS'
const ROLE_CONSOLIDATED_RT = 'CONS_RT'
const ROLE_CONSOLIDATED_DL = 'CONS_DL'

const setPermissionMarket = (roles: Array<string>): void => {
  TOKEN_DATA.permissions = [...TOKEN_DATA.permissions, ...roles]
  const hasPermissionToPosturesBMV = roles.includes(ROLE_POSTURAS)
  const hasPermissionToPosturesBIVA = roles.includes(ROLE_POSTURAS_BIVA)
  const hasPermissionToPosturesCons = roles.includes(ROLE_POSTURAS_CONSOLIDATED)
  const hasPermissionToInternationalExchanges = roles.includes(ROLE_INTERNATIONAL_BAGS_TR)
  const hasPermissionToSectors = roles.includes(ROLE_SECTORIAL)
  const hasPermissionToInvestmentFunds = roles.includes(ROLE_FONDOS)
  const isBmvDelay = roles.includes(ROLE_BMV_DELAY)
  const isBivaDelay = roles.includes(ROLE_BIVA_DELAY)
  const isInternationalBagsDelay = roles.includes(ROLE_INTERNATIONAL_BAGS_DELAY)
  const isAmexDelay = roles.includes(ROLE_AMEX_DELAY)
  const isNyseDelay = roles.includes(ROLE_NYSE_DELAY)
  const isNasdaqDelay = roles.includes(ROLE_NASDAQ_DELAY)
  const isIndexBmvDelay = roles.includes(ROLE_INDEX_BMV_DELAY)
  const isIndexJdiAndSPDelay = roles.includes(ROLE_INDEX_DJI_SP_DELAY)
  const hasPermissionToGovernmentDebt = roles.includes(ROLE_GUBERNAMENTAL_DEBT)
  const hasPermissionToInternationalDebt = roles.includes(ROLE_INTERNATIONAL_DEBT)
  const hasPermissionToBrokerageFirms = roles.includes(ROLE_BROKERAGE_FIRMS)
  const hasPermissionToPrivateDebt = roles.includes(ROLE_PRIVATE_DEBT)
  const hasPermissionToConsolidated = roles.includes(ROLE_CONSOLIDATED_RT) || roles.includes(ROLE_CONSOLIDATED_DL)

  TOKEN_DATA.hasPermissionToPosturesBMV = hasPermissionToPosturesBMV
  TOKEN_DATA.hasPermissionToPosturesBIVA = hasPermissionToPosturesBIVA
  TOKEN_DATA.hasPermissionToPosturesCons = hasPermissionToPosturesCons
  TOKEN_DATA.hasPermissionToInternationalExchanges = hasPermissionToInternationalExchanges
  TOKEN_DATA.hasPermissionToSectors = hasPermissionToSectors
  TOKEN_DATA.hasPermissionToInvestmentFunds = hasPermissionToInvestmentFunds
  TOKEN_DATA.isBmvDelay = isBmvDelay
  TOKEN_DATA.isBivaDelay = isBivaDelay
  TOKEN_DATA.isInternationalBagsDelay = isInternationalBagsDelay
  TOKEN_DATA.isAmexDelay = isAmexDelay
  TOKEN_DATA.isNyseDelay = isNyseDelay
  TOKEN_DATA.isNasdaqDelay = isNasdaqDelay
  TOKEN_DATA.isIndexBmvDelay = isIndexBmvDelay
  TOKEN_DATA.isIndexJdiAndSPDelay = isIndexJdiAndSPDelay
  TOKEN_DATA.hasPermissionToGovernmentDebt = hasPermissionToGovernmentDebt
  TOKEN_DATA.hasPermissionToInternationalDebt = hasPermissionToInternationalDebt
  TOKEN_DATA.hasPermissionToPrivateDebt = hasPermissionToPrivateDebt
  TOKEN_DATA.hasPermissionToBrokerageFirms = hasPermissionToBrokerageFirms
  TOKEN_DATA.hasPermissionToConsolidated = hasPermissionToConsolidated
}

const setPermissionFactset = (): void => {
  let hasPermissionToRecommendation = false
  const apiFactset = 'api-factset'
  if (
    TOKEN_DATA.data &&
    TOKEN_DATA.data.resource_access &&
    TOKEN_DATA.data.resource_access[apiFactset] &&
    TOKEN_DATA.data.resource_access[apiFactset].roles
  ) {
    TOKEN_DATA.permissions = [...TOKEN_DATA.permissions, ...TOKEN_DATA.data.resource_access[apiFactset].roles]
    hasPermissionToRecommendation = TOKEN_DATA.data.resource_access[apiFactset].roles.includes(ROLE_USER)
  }
  TOKEN_DATA.hasPermissionToRecommendation = hasPermissionToRecommendation
}

const setPermissionNews = (): void => {
  let hasPermissionToNewsMtNewswires = false
  const apiNews = 'api-news'
  if (
    TOKEN_DATA.data &&
    TOKEN_DATA.data.resource_access &&
    TOKEN_DATA.data.resource_access[apiNews] &&
    TOKEN_DATA.data.resource_access[apiNews].roles
  ) {
    TOKEN_DATA.permissions = [...TOKEN_DATA.permissions, ...TOKEN_DATA.data.resource_access[apiNews].roles]
    TOKEN_DATA.permissions = TOKEN_DATA.permissions.filter((rol) => {
      const nameRol = rol.replace(/_TR|_RT|_DL/g, '')
      if (rol.includes('DL')) return !TOKEN_DATA.permissions.includes(`${nameRol}_RT`)
      return true
    })
    hasPermissionToNewsMtNewswires = TOKEN_DATA.data.resource_access[apiNews].roles.includes(ROLE_MT_NEWSWIRES)
  }
  TOKEN_DATA.hasPermissionToNewsMtNewswires = hasPermissionToNewsMtNewswires
}

const setPermissionByToken = (): void => {
  const apiMarketData = 'api-market-data'
  const apiHubRol = 'hub-roles'
  if (
    TOKEN_DATA.data &&
    TOKEN_DATA.data.resource_access &&
    TOKEN_DATA.data.resource_access[apiMarketData] &&
    TOKEN_DATA.data.resource_access[apiMarketData].roles
  ) {
    let roleMarkets = TOKEN_DATA.data.resource_access[apiMarketData].roles || []
    roleMarkets = roleMarkets.filter((rol) => {
      const nameRol = rol.replace(/_TR|_RT|_DL/g, '')
      if (rol.includes('_DL')) {
        return !roleMarkets.includes(`${nameRol}_RT`)
      }
      return true
    })
    setPermissionMarket(roleMarkets)
  }
  if (
    TOKEN_DATA.data &&
    TOKEN_DATA.data.resource_access &&
    TOKEN_DATA.data.resource_access[apiHubRol] &&
    TOKEN_DATA.data.resource_access[apiHubRol].roles &&
    TOKEN_DATA.data.resource_access[apiHubRol].roles.length > 0
  ) {
    const hubRol = TOKEN_DATA.data.resource_access[apiHubRol].roles.find((rol) => rol !== 'hub-md2') || ''
    const hubMd2 = !!TOKEN_DATA.data.resource_access[apiHubRol].roles.find((rol) => rol === 'hub-md2')
    TOKEN_DATA.hubRol = hubRol || ''
    TOKEN_DATA.hasPermissionToMd2 = hubMd2
  }
  setPermissionFactset()
  setPermissionNews()
}

export const setTokenData = (token: Token): void => {
  if (token && !TOKEN_DATA.data) {
    const tokenParsed: TokenData = parseJwt(token)
    TOKEN_DATA.data = tokenParsed
    setPermissionByToken()
  }
}

export function setCurrentUser(user: User, token: Token): void {
  const accessToken = user.token
  const tokenParsed: TokenData = parseJwt(token)
  setTokenData(token)
  const serverDate = new Date(tokenParsed.iat * 1000)
  const currentDate = new Date()
  const diferenceTime = serverDate.getTime() - currentDate.getTime()
  const obj = {
    token: user.token,
    accessToken,
    exp: tokenParsed.exp,
    iat: tokenParsed.iat,
    expRefresh: user.expRefresh,
    tickerVisible: user?.tickerVisible || false,
    refreshToken: user.refreshToken,
    diferenceTime,
    userId: tokenParsed.sub,
    tokenData: accessToken
  }
  return sessionStorage.setItem(keyStorage.user, JSON.stringify(obj))
}

export function setCurrentUserTickerVisible(tickerVisible: boolean): void {
  const user = sessionStorage.getItem(keyStorage.user)
  let obj = { tickerVisible: false }
  if (user) {
    obj = JSON.parse(user)
    obj.tickerVisible = tickerVisible
  }

  return sessionStorage.setItem(keyStorage.user, JSON.stringify(obj))
}

export function logoutUser(): void {
  cleanTokenData()
  sessionStorage.clear()
  if (sessionStorage.getItem('isUserItesm') === 'true') UserService.doLogout()
}

const validatePermissionsInternationalBagsByUser = (valueTypeId: number): string => {
  let newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.REAL_TIME
  switch (valueTypeId) {
    case MarketsValueTypeIds.AMEX:
      if (TOKEN_DATA.isAmexDelay) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      }
      break
    case MarketsValueTypeIds.NYSE:
      if (TOKEN_DATA.isNyseDelay) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      }
      break
    case MarketsValueTypeIds.NASDAQ:
      if (TOKEN_DATA.isNasdaqDelay) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      }
      break
    default:
      break
  }
  return newOpportunityInformation
}

const validatePermissionsIndexByUser = (
  opportunityInformation: string,
  marketTypeId: number,
  uniqueKey: string
): string => {
  let newOpportunityInformation = opportunityInformation || SYMBOL_REAL_TIME_TYPES.REAL_TIME
  switch (marketTypeId) {
    case MarketTypesIds.INDEX:
      if (TOKEN_DATA.isIndexBmvDelay) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      }
      break
    case MarketTypesIds.INDEX_DJI_SP:
      if (INDEX_FTSE_UNIQUE_KEYS.includes(uniqueKey)) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      }
      break
    default:
      break
  }
  return newOpportunityInformation
}

const getValuesByUniqueKey = (uniqueKey: string): { marketTypeId: number; valueTypeId: number } => {
  if (!uniqueKey) return { marketTypeId: 0, valueTypeId: 0 }
  const uniqueKeys = uniqueKey.split('/')
  if (uniqueKey) {
    return {
      marketTypeId: Number(uniqueKeys[0]),
      valueTypeId: Number(uniqueKeys[1])
    }
  }
  return { marketTypeId: 0, valueTypeId: 0 }
}

export const getOpportunityInformationByMarketId = (
  opportunityInformation: string,
  uniqueKey: string,
  marketId: number,
  doubleCheck?: boolean
): string => {
  if (opportunityInformation && !doubleCheck) return opportunityInformation
  let newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.REAL_TIME
  switch (marketId) {
    case MarketTypes.BMV.id:
      if (TOKEN_DATA.isBmvDelay) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      }
      break
    case MarketTypes.BIVA.id:
      if (TOKEN_DATA.isBivaDelay) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      }
      break
    case MarketTypes.INTERNATIONAL_BAGS.id:
      if (TOKEN_DATA.isInternationalBagsDelay) {
        newOpportunityInformation = SYMBOL_REAL_TIME_TYPES.DELAY
      } else if (!TOKEN_DATA.hasPermissionToInternationalExchanges) {
        const { valueTypeId } = getValuesByUniqueKey(uniqueKey)
        newOpportunityInformation = validatePermissionsInternationalBagsByUser(valueTypeId)
      }
      break
    case MarketTypes.INDEX.id: {
      const { marketTypeId } = getValuesByUniqueKey(uniqueKey)
      newOpportunityInformation = validatePermissionsIndexByUser(opportunityInformation, marketTypeId, uniqueKey)
      break
    }
    default:
      break
  }
  return newOpportunityInformation
}
