import ACTION_TYPES from './actionTypes'
import {
  WorkspacesReducer,
  setNewCardAction,
  SetDeleteCardIDAction,
  SetNewWorkspaceAction,
  SetWorkspaceSuccessAction,
  GetWorkspacesSuccessAction,
  SetNewWorkspaceNameAction,
  SetUndoConfigurationAction,
  SetActiveWorkspaceWidgetsAction,
  SetLoadingChangeWorkspace,
  setNewCardFromHeaderAction
} from './types'

const initialState = {
  workspaces: [],
  activeWorkspace: {
    workspaceId: '',
    layout: [],
    widgets: [],
    order: 1
  },
  activeWorkspaceWidgets: [],
  newCard: null,
  deleteWidget: null,
  newLayout: null,
  message: '',
  typeModal: '',
  undoConfiguration: null,
  isLoadingChangedWorkspace: false,
  newCardFromHeader: null
}

const reducer: WorkspacesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_WORKSPACES_SUCCESS: {
      const newAction = action as GetWorkspacesSuccessAction

      return {
        ...state,
        workspaces: newAction.workspaces
      }
    }
    case ACTION_TYPES.SET_WORKSPACE_SUCCESS: {
      const newAction = action as SetWorkspaceSuccessAction
      return {
        ...state,
        activeWorkspace: newAction.activeWorkspace,
        activeWorkspaceWidgets: newAction.activeWorkspaceWidgets,
        message: newAction.message,
        typeModal: newAction.typeModal
      }
    }
    case ACTION_TYPES.SET_NEW_CARD: {
      const newAction = action as setNewCardAction
      return {
        ...state,
        newCard: newAction.newCard
      }
    }
    case ACTION_TYPES.SET_DELETE_CARD_ID: {
      const newAction = action as SetDeleteCardIDAction
      return {
        ...state,
        deleteWidget: newAction.deleteWidget,
        newLayout: newAction.newLayout
      }
    }
    case ACTION_TYPES.SET_ACTIVE_WORKSPACE_WIDGETS: {
      const newAction = action as SetActiveWorkspaceWidgetsAction

      if (
        newAction.workspaceId &&
        state.activeWorkspace &&
        Number(newAction.workspaceId) !== Number(state.activeWorkspace.workspaceId)
      ) {
        return state
      }

      return {
        ...state,
        activeWorkspaceWidgets: newAction.activeWorkspaceWidgets
      }
    }
    case ACTION_TYPES.SET_NEW_WORKSPACE: {
      const newAction = action as SetNewWorkspaceAction

      return {
        ...state,
        workspaces: newAction.workspaces
      }
    }
    case ACTION_TYPES.SET_NEW_WORKSPACE_NAME: {
      const newAction = action as SetNewWorkspaceNameAction

      return {
        ...state,
        activeWorkspace: state.activeWorkspace
          ? {
              ...state.activeWorkspace,
              description: newAction.newWorkspaceName
            }
          : null
      }
    }
    case ACTION_TYPES.SET_UNDO_CONFIGURATION: {
      const newAction = action as SetUndoConfigurationAction

      return {
        ...state,
        undoConfiguration: newAction.undoConfiguration
      }
    }
    case ACTION_TYPES.SET_LOADING_CHANGE_WORKSPACE: {
      const newAction = action as SetLoadingChangeWorkspace

      return {
        ...state,
        isLoadingChangedWorkspace: newAction.isLoadingChangedWorkspace
      }
    }
    case ACTION_TYPES.SET_NEW_CARD_FROM_HEADER: {
      const newAction = action as setNewCardFromHeaderAction
      return {
        ...state,
        newCardFromHeader: newAction.newCardFromHeader
      }
    }
    default:
      return state
  }
}

export default reducer
