import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { TREND_DOWN_200_LIGHT } from 'constants/colorsLightMode'

const muiFormHelperText: {
  defaultProps?: ComponentsProps['MuiFormHelperText']
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormHelperText']
  variants?: ComponentsVariants['MuiFormHelperText']
} = {
  styleOverrides: {
    root: {
      '&.Mui-error': {
        color: `${TREND_DOWN_200_LIGHT}`
      }
    }
  },
  variants: []
}

export default muiFormHelperText
