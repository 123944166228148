import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_300, DEEP_400, DEEP_500, DEEP_700, DEEP_800 } from 'constants/colorsDarkMode'
import { heightRowHeaderTable } from 'constants/theme'

const muiTableCell: {
  defaultProps?: ComponentsProps['MuiTableCell']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableCell']
  variants?: ComponentsVariants['MuiTableCell']
} = {
  styleOverrides: {
    root: {
      fontFamily: `${['Roboto Flex'].join(',')} !important`,
      borderBottomColor: DEEP_500,
      borderBottomWidth: 1,
      padding: '0px',
      fontWeight: 400,
      fontSize: 12
    },
    head: {
      fontWeight: '600 !important',
      fontSize: '10px !important',
      lineHeight: '12px !important',
      letterSpacing: '0.25px !important',
      height: `${heightRowHeaderTable}px !important`
    },
    stickyHeader: {
      backgroundColor: DEEP_700,
      color: DEEP_300,
      fontFamily: `${['Roboto Flex'].join(',')} !important`,
      padding: '0px 5px 0px 0px',
      borderBottomWidth: 2,
      borderBottomColor: DEEP_400,
      fontSize: '12px',
      cursor: 'pointer',
      '&:selected, &:hover': {
        backgroundColor: DEEP_800
      },
      minWidth: 45
    }
  }
}

export default muiTableCell
