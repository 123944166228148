import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_200 } from 'constants/colorsDarkMode'

const muiTooltip: {
  defaultProps?: ComponentsProps['MuiTooltip']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip']
  variants?: ComponentsVariants['MuiTooltip']
} = {
  styleOverrides: {
    tooltip: {
      color: DEEP_200
    }
  }
}

export default muiTooltip
