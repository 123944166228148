/* eslint-disable */

import Keycloak from 'keycloak-js'
import Token from 'utils/token'
import { setCurrentUser } from 'utils/user'
import WorkspaceApi from 'apis/workspaceApi'

// @ts-ignore
const _kc = new Keycloak('/keycloak.json')

const tokenSecondsExpiration = 1800
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback: any) => {
  _kc
    .init({
      onLoad: 'login-required',
      promiseType: 'native',
      silentCheckSsoRedirectUri: window.location.origin + '/',
      pkceMethod: 'S256',
      checkLoginIframe: false
    })
    .then(async (authenticated: any) => {
      if (authenticated) {
        const token = new Token().setToken(_kc.token, _kc.refreshToken)
        const exp = _kc.tokenParsed.exp
        const expRefresh = _kc.refreshTokenParsed.exp
        const obj = {
          token: _kc.token,
          accessToken: _kc.token,
          refreshToken: _kc.refreshToken,
          tickerVisible: false,
          exp,
          iat: 0,
          expRefresh,
          userId: '',
          tokenData: null,
          diferenceTime: 0,
          group: ''
        }
        setCurrentUser(obj, token)
        localStorage.setItem('isUserItesm', 'true')
        try {
          await WorkspaceApi.loginKeyclock()
        } catch {}

        if (window.location.pathname === '/') _kc.login({ redirectUri: window.location.origin + '/terminal' })
      } else {
        console.warn('not authenticated!')
        doLogin()
      }
    })
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const getRefreshToken = () => _kc.refreshToken

const updateToken = (successCallback: any) => {
  return _kc.updateToken(tokenSecondsExpiration).then(successCallback).catch(doLogin)
}

const getUsername = () => _kc.tokenParsed.preferred_username

const getCurrentUser = () => _kc.tokenParsed

const clearToken = () => _kc.clearToken()

const logout = () => _kc.logout({ redirectUri: window.location.origin + '/' })

export default {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  getRefreshToken,
  updateToken,
  getUsername,
  getCurrentUser,
  clearToken,
  logout
}
