import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_100, DEEP_600, DEEP_500, DEEP_200, DEEP_300, DEEP_700, DEEP_400 } from 'constants/colorsDarkMode'

import { FONT_SIZE_LARGE, FONT_SIZE_MEDIUM, FONT_SIZE_SMALL, FONT_WEIGHT_500 } from '../../constanst'

const MuiChip: {
  defaultProps?: ComponentsProps['MuiChip']
  styleOverrides?: ComponentsOverrides<Theme>['MuiChip']
  variants?: ComponentsVariants['MuiChip']
} = {
  styleOverrides: {
    root: {
      color: `${DEEP_100}`,
      backgroundColor: `transparent`,
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      textTransform: 'none',
      fontStyle: 'normal',
      '&:hover': {
        backgroundColor: `${DEEP_600}`
      },

    }
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        color: `${DEEP_200} !important`,
        backgroundColor: `${DEEP_700} !important`,
        border: `1px solid ${DEEP_300}`,
        fontSize: FONT_SIZE_MEDIUM,
        '& .MuiChip-deleteIcon': {
          color: DEEP_300,
          zIndex: 10
        }
      }
    },
    {
      props: { variant: 'filled' },
      style: {
        padding: '6px 12px',
        color: `${DEEP_200} !important`,
        backgroundColor: `${DEEP_400} !important`,
        border: `0px solid ${DEEP_300} !important`,
        fontSize: FONT_SIZE_MEDIUM,
        '& .MuiChip-deleteIcon': {
          color: DEEP_300,
          zIndex: 10
        }
      }
    },
    {
      props: { size: 'medium' },
      style: {
        height: `36px`,
        padding: '6px 12px',
        fontSize: FONT_SIZE_MEDIUM
      }
    },
    {
      props: { size: 'small' },
      style: {
        padding: '6px 12px',
        height: `24px`,
        fontSize: FONT_SIZE_SMALL
      }
    }
  ]
}

export default MuiChip
