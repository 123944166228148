import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_200_LIGHT, DEEP_500_LIGHT } from 'constants/colorsLightMode'

const muiTooltip: {
  defaultProps?: ComponentsProps['MuiTooltip']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip']
  variants?: ComponentsVariants['MuiTooltip']
} = {
  styleOverrides: {
    tooltip: {
      backgroundColor: DEEP_500_LIGHT,
      color: DEEP_200_LIGHT
    }
  }
}

export default muiTooltip
