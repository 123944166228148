import ACTION_TYPES from './actionTypes'
import { AnalisysReducer } from './types'

const initialState = {
  isLoading: false,
  instrument: null
}

const reducer: AnalisysReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case ACTION_TYPES.STOP_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case ACTION_TYPES.GET_INSTRUMENT_ANALYSIS:
      return {
        ...state,
        instrument: action.instrument
      }
    default:
      return state
  }
}

export default reducer
