import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import {
  DEEP_100_LIGHT,
  DEEP_200_LIGHT,
  DEEP_400_LIGHT,
  TREND_ACENT_200_LIGHT,
  TREND_DOWN_200_LIGHT
} from 'constants/colorsLightMode'

import { FONT_SIZE_LARGE, FONT_WEIGHT_500 } from '../../constanst'

const muiTextfield: {
  defaultProps?: ComponentsProps['MuiTextField']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField']
  variants?: ComponentsVariants['MuiTextField']
} = {
  styleOverrides: {
    root: {
      color: `${DEEP_100_LIGHT}`,
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      textTransform: 'none',
      fontStyle: 'normal',
      '& .Mui-error': {
        color: `${TREND_DOWN_200_LIGHT} !important`
      },
      error: {
        color: `${TREND_DOWN_200_LIGHT}`,
        '&.MuiOutlinedInput-input': {
          color: `${TREND_DOWN_200_LIGHT} !important`
        }
      },
      '& label.Mui-focused': {
        color: DEEP_200_LIGHT,
        height: 24
      },
      '&.MuiInput-underline:after': {
        borderBottomColor: TREND_ACENT_200_LIGHT
      },
      '&.MuiOutlinedInput-root': {
        height: 24,
        '& fieldset': {
          borderColor: DEEP_200_LIGHT
        },
        '&:hover fieldset': {
          borderColor: DEEP_200_LIGHT
        },
        '&.Mui-focused fieldset': {
          borderColor: TREND_ACENT_200_LIGHT
        }
      },
      '&.MuiOutlinedInput-input': {
        border: 'none',
        height: 24,
        color: DEEP_200_LIGHT
      },
      '&.MuiTextField-root': {
        color: DEEP_200_LIGHT
      },
      '&:hover .MuiTextField-root': {
        borderColor: DEEP_200_LIGHT
      },
      '&.MuiFormLabel-root.Mui-focused': {
        color: TREND_ACENT_200_LIGHT
      },
      '&.MuiFormLabel-root.Mui-disabled': {
        color: DEEP_400_LIGHT
      },
      '&.MuiInput-underline.Mui-disabled:before': {
        borderBottomColor: `${DEEP_400_LIGHT} !important`,
        borderBottomStyle: `solid !important`
      },
      '&.MuiInputBase-root': {
        height: 24,
        color: DEEP_200_LIGHT
      },
      '&.MuiFormLabel-root': {
        color: DEEP_200_LIGHT
      },
      '&.MuiOutlinedInput-notchedOutline': {
        color: TREND_ACENT_200_LIGHT,
        borderColor: DEEP_200_LIGHT
      },
      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        color: DEEP_200_LIGHT
      },
      '&.MuiFormLabel-root.Mui-error': {
        color: `${TREND_DOWN_200_LIGHT} !important`
      },
      '&.MuiFormLabel-root-MuiInputLabel-root.Mui-error': {
        color: `${TREND_DOWN_200_LIGHT} !important`
      },
      '&.MuiInput-underline.Mui-error:after': {
        borderBottomColor: `${TREND_DOWN_200_LIGHT} !important`
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: `${TREND_DOWN_200_LIGHT} !important`
      }
    }
  },
  variants: []
}

export default muiTextfield
