const ACTION_TYPES = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  SET_ALERT_SUCCESS: 'SET_ALERT_SUCCESS',
  SET_ALERT_FAILED: 'SET_ALERT_FAILED',
  GET_ALERT_SUCCESS: 'GET_ALERT_SUCCESS',
  GET_ALERT_FAILED: 'GET_ALERT_FAILED',
  SET_NEW_ALERT: 'SET_NEW_ALERT'
}

export default ACTION_TYPES
