import { dataTableColumns, alignment, formats } from 'components/Terminal/Table/constants'
import { DECREASING_RED_RGBA, INCREASING_GREEN_RGBA } from 'constants/colors'
import { TREND_DOWN_200, TREND_UP_200 } from 'constants/colorsDarkMode'
import { LsSubscriptionTableFields } from 'constants/globals'
import i18n from 'i18n/index'

export const VALUE_BAG_INTERNATIONAL = 'BI'

const CAPITALS_TABLE = [
  { ...dataTableColumns.DISPLAY_SYMBOL, id: 1 },
  { ...dataTableColumns.CLOSE_MOVEMENT, id: 2 },
  { ...dataTableColumns.ACTUAL_PRICE, id: 3 },
  { ...dataTableColumns.VOLUME_DONE, id: 4 },
  { ...dataTableColumns.PREVIOUS_PRICE, id: 5 },
  { ...dataTableColumns.OPENING_PRICE, id: 6 },
  { ...dataTableColumns.CHANGE_UNIT, id: 7 },
  { ...dataTableColumns.CHANGE_PROCENTAGE, id: 8 },
  { ...dataTableColumns.ACCUMULATED_VOLUME, id: 9 },
  { ...dataTableColumns.TOTAL_AMOUNT, id: 10, decimals: 3 },
  { ...dataTableColumns.TIME, id: 11, label: i18n.t('timeDone') },
  { ...dataTableColumns.DATE, id: 12, label: i18n.t('dateDone') },
  { ...dataTableColumns.PURCHASE_PRICE, id: 13 },
  { ...dataTableColumns.PURCHASE_VOLUME, id: 14 },
  { ...dataTableColumns.SALE_PRICE, id: 15 },
  { ...dataTableColumns.SALE_VOLUME, id: 16 },
  { ...dataTableColumns.POSTURE_TIME, id: 17 },
  { ...dataTableColumns.POSTURE_DATE, id: 18 },
  { ...dataTableColumns.MAXIMUM_DAY_PRICE, id: 19 },
  { ...dataTableColumns.MINIMUM_DAY_PRICE, id: 20 },
  { ...dataTableColumns.MAXIMUM_12_MONTHS, id: 21 },
  { ...dataTableColumns.MINIMUM_12_MONTHS, id: 22 },
  { ...dataTableColumns.PP_PRICE, id: 23 },
  { ...dataTableColumns.WEEKLY_NUMBER, id: 24 },
  { ...dataTableColumns.MONTHLY_NUMBER, id: 25 },
  { ...dataTableColumns.ANNUAL_NUMBER, id: 26 },
  { ...dataTableColumns.ANNUAL_YIELD_NUMBER, id: 27 },
  { ...dataTableColumns.BUYER, id: 28 },
  { ...dataTableColumns.SELLER, id: 29 },
  { ...dataTableColumns.PREVIOUS_PRICE_DATE, id: 30 },
  { ...dataTableColumns.INSTRUMENT_TYPE, id: 31 },
  { ...dataTableColumns.DESCRIPTION, id: 32 },
  { ...dataTableColumns.ISIN, id: 33 },
  { ...dataTableColumns.NUMBER_OPERATIONS, id: 34 }
]

export const CLOSE_VALUES = { isCloseValue: 1, notCloseValue: 0 }

export const BMV_NEW_QUERIES_MULTIPLES_TABLE = [
  { ...dataTableColumns.DISPLAY_SYMBOL, id: 1, flex: 1 },
  { ...dataTableColumns.ANNUAL_YIELD_NUMBER, id: 2, flex: 1, align: alignment.RIGHT, format: formats.STRING },
  {
    id: 3,
    label: 'P/U',
    align: alignment.RIGHT,
    name: 'priceToEarnings',
    format: formats.STRING,
    apiField: 'priceToEarnings',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    flex: 1
  },
  {
    id: 4,
    label: 'P/VL',
    align: alignment.RIGHT,
    name: 'priceToBookValue',
    format: formats.STRING,
    apiField: 'priceToBookValue',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    flex: 1
  },
  {
    id: 5,
    label: 'P/V',
    align: alignment.RIGHT,
    name: 'priceToSales',
    format: formats.STRING,
    apiField: 'priceToSales',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    flex: 1
  }
]

export const COMPARATIVE_TABLE_SIC = [
  { ...dataTableColumns.DISPLAY_SYMBOL, id: 1 },
  { ...dataTableColumns.CHANGE_UNIT, id: 2, label: 'Variación unitaria MXN' },
  { ...dataTableColumns.CHANGE_PROCENTAGE, id: 3, label: 'Variación % MXN' },
  { ...dataTableColumns.ACTUAL_PRICE, id: 4, label: 'Precio MXN' },
  {
    id: 5,
    label: 'Precio teórico MXN',
    align: alignment.RIGHT,
    name: 'theoreticalPriceMxn',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'theoreticalPriceMxn',
    lsField: 'NA',
    lsFormat: null,
    width: 'auto'
  },
  {
    id: 6,
    label: 'Precio en USD',
    align: alignment.RIGHT,
    name: `actualPrice${VALUE_BAG_INTERNATIONAL}`,
    format: formats.FLOAT,
    decimals: 2,
    apiField: `precioActual${VALUE_BAG_INTERNATIONAL}`,
    lsField: `${LsSubscriptionTableFields.CURRENT_PRICE}`,
    lsFormat: null,
    width: 'auto',
    bagInternational: true
  },
  {
    id: 7,
    label: 'Variación % USD',
    align: alignment.RIGHT,
    name: `changePercentage${VALUE_BAG_INTERNATIONAL}`,
    format: formats.FLOAT,
    decimals: 4,
    apiField: `porcentaje${VALUE_BAG_INTERNATIONAL}`,
    lsField: `${LsSubscriptionTableFields.CHANGE_PROCENTAGE}`,
    lsFormat: null,
    width: 'auto',
    bagInternational: true
  },
  {
    id: 8,
    label: 'Variación unitaria USD',
    align: alignment.RIGHT,
    name: `change${VALUE_BAG_INTERNATIONAL}`,
    format: formats.FLOAT,
    decimals: 2,
    apiField: `variacion${VALUE_BAG_INTERNATIONAL}`,
    lsField: `${LsSubscriptionTableFields.CHANGE_UNIT}`,
    lsFormat: null,
    width: 'auto',
    bagInternational: true
  },
  { ...dataTableColumns.SPREAD, id: 9 },
  { ...dataTableColumns.SPREAD_VARIATION, id: 10, isPercentage: true },
  { ...dataTableColumns.DESCRIPTION, id: 11 }
]

export const initialColors = {
  background: 'transparent',
  text: 'secondary.contrasText',
  noOperation: 'secondary.main'
}

export const valueIncreaseColors = {
  background: INCREASING_GREEN_RGBA,
  text: TREND_UP_200
}
export const valueDecreaseColors = {
  background: DECREASING_RED_RGBA,
  text: TREND_DOWN_200
}

export default CAPITALS_TABLE
