import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_700_LIGHT, DEEP_200_LIGHT, DEEP_600_LIGHT } from 'constants/colorsLightMode'
import { heightRowTable } from 'constants/theme'

const MuiTableRow: {
  defaultProps?: ComponentsProps['MuiTableRow']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableRow']
  variants?: ComponentsVariants['MuiTableRow']
} = {
  styleOverrides: {
    root: {
      backgroundColor: DEEP_700_LIGHT,
      color: DEEP_200_LIGHT,
      padding: 0,
      height: `${heightRowTable}px !important`,
      '&.Mui-hover': {
        backgroundColor: DEEP_600_LIGHT
      },
      '& .Mui-selected': {
        backgroundColor: DEEP_600_LIGHT
      }
    }
  }
}

export default MuiTableRow
