import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_200_LIGHT, DEEP_400_LIGHT, DEEP_600_LIGHT } from 'constants/colorsLightMode'

import { FONT_SIZE_LARGE, FONT_WEIGHT_500 } from '../../constanst'

const MuiOutlinedInput: {
  defaultProps?: ComponentsProps['MuiOutlinedInput']
  styleOverrides?: ComponentsOverrides<Theme>['MuiOutlinedInput']
  variants?: ComponentsVariants['MuiOutlinedInput']
} = {
  styleOverrides: {
    root: {
      color: DEEP_200_LIGHT,
      backgroundColor: `${DEEP_600_LIGHT}`,
      padding: '0px 16px',
      borderRadius: 50,
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      textTransform: 'none',
      fontStyle: 'normal',
      '& .MuiInputBase-input.Mui-disabled': {
        color: DEEP_400_LIGHT,
        WebkitTextFillColor: DEEP_400_LIGHT
      },
      '& .MuiInputBase-input': {
        color: DEEP_200_LIGHT,
        '&:placeholder': {
          textOverflow: 'ellipsis !important',
          color: DEEP_400_LIGHT
        }
      },

      '& .MuiInputAdornment-root': {
        color: DEEP_200_LIGHT
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0px solid transparent'
      },
      '& .MuiSvgIcon-root': {
        //fontSize: '25px'
      }
    }
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        padding: '0px 16px',
        height: '30px !important',
      }
    }
  ]
}

export default MuiOutlinedInput
