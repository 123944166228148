export function getApiWorkspaceUrl() {
  return import.meta.env.VITE_WORKSPACE_URL
}
export function getApiWorkspaceUrlV2() {
  return import.meta.env.VITE_WORKSPACE_URL_V2
}

export function getApiMarketUrl() {
  return import.meta.env.VITE_MARKET_URL
}

export function getApiNewsUrl() {
  return import.meta.env.VITE_NEWS_URL
}

export function getFactsetUrl() {
  return import.meta.env.VITE_FACTSET_URL
}

export function getAlertsUrl() {
  return import.meta.env.VITE_ALERTS_URL
}

export function getCalendarUrl() {
  return import.meta.env.VITE_CALENDAR_URL
}

export function getIdGoogleFormNps() {
  return import.meta.env.VITE_BASE_ID_GOOGLE_FORM_NPS
}

export function getFundsUrl() {
  return import.meta.env.VITE_FUNDS_URL
}

export function getETFsUrl() {
  return import.meta.env.VITE_ETF_URL
}

export function getKeyRecaptchaUrl() {
  return import.meta.env.VITE_SITE_RECAPTCHA_KEY
}

export function getKeGoogleFormCreateUserl() {
  return import.meta.env.VITE_ID_GOOGLE_FORM_CREATE_USER
}

export function getFinancialMetricsUrl() {
  return import.meta.env.VITE_FINANCIAL_METRICS
}

export function getMexderUrl() {
  return import.meta.env.VITE_MEXDER_URL
}
