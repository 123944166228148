import ACTION_TYPES from './actionTypes'
import { NewsDetailsReducer, SetNewsAction, GetNewsDetailsSuccessAction, GetNewsDetailsFailedAction } from './types'

const initialState = {
  selectedNews: 0,
  newsDetails: [],
  newsFile: [],
  error: '',
  selectedWidgetId: ''
}

const reducer: NewsDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_NEWS_DETAILS_SUCCESS: {
      const newAction = action as SetNewsAction
      return {
        ...state,
        selectedNews: newAction.selectedNews,
        selectedWidgetId: newAction.selectedWidgetId
      }
    }
    case ACTION_TYPES.GET_NEWS_DETAILS_SUCCESS: {
      const newAction = action as GetNewsDetailsSuccessAction
      return {
        ...state,
        newsDetails: newAction.newsDetails,
        newsFile: newAction.newsFile,
        isRelevantNews: newAction.isRelevantNews
      }
    }
    case ACTION_TYPES.GET_NEWS_DETAILS_FAILED: {
      const newAction = action as GetNewsDetailsFailedAction

      return {
        ...state,
        error: newAction.errors
      }
    }
    default:
      return state
  }
}

export default reducer
