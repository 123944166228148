import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_100, DEEP_300, DEEP_600 } from 'constants/colorsDarkMode'

const muiTab: {
  defaultProps?: ComponentsProps['MuiTab']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTab']
  variants?: ComponentsVariants['MuiTab']
} = {
  styleOverrides: {
    root: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 1,
      color: DEEP_300,
      opacity: 1,
      padding: '4px 24px',
      minHeight: 'auto',
      textTransform: 'none',
      '&.Mui-selected': {
        color: DEEP_100,
        backgroundColor: DEEP_600,
        borderRadius: '5px 5px 0px 0px'
      },
      '&.MuiTab-wrapped': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
      }
    }
  },
  variants: []
}

export default muiTab
