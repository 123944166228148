import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { FONT_SIZE_MEDIUM, BORDER_RADIUS_BUTTON, LETTER_SPACING_03, FONT_WEIGHT_500 } from '../../constanst'
import { DEEP_400_LIGHT } from 'constants/colorsLightMode'

const muiFormControlLabel: {
  defaultProps?: ComponentsProps['MuiFormControlLabel']
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormControlLabel']
  variants?: ComponentsVariants['MuiFormControlLabel']
} = {
  styleOverrides: {
    label: {
      padding: '0px 7px',
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_MEDIUM,
      borderRadius: BORDER_RADIUS_BUTTON,
      textTransform: 'none',
      letterSpacing: LETTER_SPACING_03,
      fontStyle: 'normal',
      '&.Mui-disabled': {
        color: `${DEEP_400_LIGHT}`
      }
    }
  },
  variants: []
}

export default muiFormControlLabel
