/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy } from 'react'
import { RouteProps } from './types'
import {
  PATH_ADVANCE_GRAPHICS,
  PATH_LOGIN,
  PATH_TERMINAL,
  PATH_ANALYSIS,
  PATH_ANALYSIS_RESUMEN,
  PATH_ANALYSIS_NOTICIAS,
  PATH_ANALYSIS_FINANCIAL,
  PATH_ANALYSIS_PRICES,
  PATH_ANALYSIS_RECOMENDATIONS,
  PATH_ANALYSIS_DIVIDENDS,
  PATH_ANALYSIS_METRICS,
  PATH_ANALYSIS_SECTORIAL,
  PATH_ANALYSIS_CARTERA,
  PATH_RETO_ACTINVER
} from './paths'

const Login = lazy(() => import('../components/Login'))
const LoginReto = lazy(() => import('../components/RetoActinver'))
const TerminalContainer = lazy(() => import('../components/Terminal/LayoutTerminal'))
const AdvancedGraphics = lazy(() => import('../components/AdvancedGraphics'))
const Analysis = lazy(() => import('../components/Analysis'))

const AuthGuard = lazy(() => import('../guards/AuthGuard'))
const Layout = lazy(() => import('../components/Layout'))

export const routes: RouteProps[] = [
  {
    layout: null,
    guard: null,
    path: PATH_LOGIN,
    element: Login as any,
    enabled: true
  },
  {
    layout: null,
    guard: null,
    path: PATH_RETO_ACTINVER,
    element: LoginReto as any,
    enabled: import.meta.env.VITE_ENABLE_RETO_ACTINVER === 'true'
  },
  // protected routes
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_TERMINAL,
    element: TerminalContainer as any,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ADVANCE_GRAPHICS,
    element: AdvancedGraphics,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_RESUMEN,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_NOTICIAS,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_FINANCIAL,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_PRICES,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_RECOMENDATIONS,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_DIVIDENDS,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_METRICS,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_SECTORIAL,
    element: Analysis,
    enabled: true
  },
  {
    layout: Layout as any,
    guard: AuthGuard as any,
    path: PATH_ANALYSIS_CARTERA,
    element: Analysis,
    enabled: true
  },
  {
    path: '*',
    element: null,
    enabled: true
  }
]
