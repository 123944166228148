import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_600 } from 'constants/colorsDarkMode'

const MuiMenu: {
  defaultProps?: ComponentsProps['MuiMenu']
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenu']
  variants?: ComponentsVariants['MuiMenu']
} = {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        backgroundColor: DEEP_600,
        backgroundImage: 'none',
        boxShadow: 'none'
      }
    }
  },
  variants: []
}

export default MuiMenu
