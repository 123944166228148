import ACTION_TYPES from './actionTypes'
import { SettingsReducer } from './types'

const initialState = {
  settings: {
    createdAt: '',
    id: 0,
    readingView: 'comfort',
    textSize: 'medium',
    themeMode: 'dark',
    updatedAt: '',
    userId: 0
  }
}

const reducer: SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.settings
      }
    default:
      return state
  }
}

export default reducer
