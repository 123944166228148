import { useMemo } from 'react'

const useColorMode = (prefersDarkMode: boolean, setMode: (mode: 'light' | 'dark') => void) =>
  useMemo(
    () => ({
      toggleColorMode: (mode: string = 'dark') => {
        if (mode === 'system') {
          setMode(prefersDarkMode ? 'dark' : 'light')
          return
        }
        setMode(mode as 'light' | 'dark')
      },
      colorMode: String(localStorage.getItem('mui-mode') || 'dark') as 'dark' | 'light'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

export default useColorMode
