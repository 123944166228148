import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { TREND_ACENT_100, DEEP_300, DEEP_400 } from 'constants/colorsDarkMode'

const muiCheckbox: {
  defaultProps?: ComponentsProps['MuiCheckbox']
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox']
  variants?: ComponentsVariants['MuiCheckbox']
} = {
  styleOverrides: {
    root: {
      color: DEEP_300,
      '&.Mui-checked': {
        color: TREND_ACENT_100
      },
      '&.Mui-disabled': {
        color: DEEP_400
      }
    }
  },
  variants: []
}

export default muiCheckbox
