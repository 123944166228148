import i18n from 'i18n/index'
import { cardTypes, sortTypes, MarketTypes, LsSubscriptionTableFields } from 'constants/globals'

import { DataTableColumns } from './types'

export const formats = {
  INT: 'int',
  FLOAT: 'float',
  FLOAT_ROUNDING: 'floatRounding',
  STRING: 'string',
  FIGURE: 'figure',
  DATE: 'date',
  TIME: 'time',
  FORMATDATEYYYY: 'formatDateYYYY',
  TIME_FULL: 'timeFull',
  BUTTON: 'Button'
}

export const alignment = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify'
}

export const direction = {
  LEFT: 'left',
  RIGHT: 'right'
}

export const marketIdsRefresData = [
  MarketTypes.ECONOMIC_INDICATORS.id,
  MarketTypes.INVESTMENT_FUNDS.id,
  MarketTypes.GOVERNMENT_DEBT.id,
  MarketTypes.INTERNATIONAL_DEBT.id,
  MarketTypes.PRIVATE_DEBT.id,
  MarketTypes.PRECIOUS_METALS.id
]

export const cardTypesAllowSortWithOrder = [cardTypes.TABLE, cardTypes.PREDEFINED_TABLE, cardTypes.NEW_QUERIES]

export const labels = {
  SYMBOL: i18n.t('descripcion'),
  TRANSACTIONS_NUMBER: i18n.t('transactionsNumber'),
  CURRENT_PRICE: i18n.t('precioactual'),
  CHANGE_PROCENTAGE: i18n.t('porcentaje'),
  CHANGE_UNIT: i18n.t('variacion'),
  VOLUMEN_TOTAL: i18n.t('volumentotal'),
  VOLUMEN_DONE: i18n.t('volumenhecho'),
  PURCHASE_PRICE: i18n.t('purchasePrice'),
  PURCHASE_VOLUME: i18n.t('purchaseVolume'),
  SALE_PRICE: i18n.t('salePrice'),
  SALE_VOLUME: i18n.t('saleVolume'),
  ISIN: i18n.t('ISIN'),
  PREVIOUS_PRICE_DATE: i18n.t('fechaprecioanterior'),
  DATE: i18n.t('fechaprecioactual'),
  HOUR: i18n.t('hora'),
  FULL_NAME: i18n.t('nombrelargo'),
  SPREAD: i18n.t('spread'),
  VALUE: i18n.t('value'),
  POSTURE_ASK_TIME: i18n.t('postureAskTime'),
  PURCHASE_DATE: i18n.t('purchaseDate'),
  SALE_DATE: i18n.t('saleDate'),
  PREVIOUS_DATE: i18n.t('previousDate'),
  MINIMUM: i18n.t('minimumDayPrice'),
  PREVIOUS_PRICE: i18n.t('previousPrice'),
  PREVIOUS_PRICE_VALUE: i18n.t('previousPriceValue'),
  MAXIMUM: i18n.t('maximumDayPrice'),
  TOTAL_AMOUNT: i18n.t('importetotal'),
  OPENING_PRICE: i18n.t('precioapertura'),
  ACTUAL_PRICE: i18n.t('actualPrice'),
  ACTUAL_PRICE_VALUE: i18n.t('actualPriceValue'),
  PERIODICITY: i18n.t('periodicity'),
  DESCRIPTION: i18n.t('fulldescription'),
  LIQUID_PRICE: i18n.t('precioliquido'),
  PREVIUS_LIQUID_PRICE: i18n.t('precioanteriorliquido'),
  COMULATIVE_VOLUME: i18n.t('volumenacumulado'),
  NUMBER_OPER: i18n.t('numOfOperations'),
  EXPIRATION_DATE: i18n.t('fechaexpiracion'),
  VOLUME: i18n.t('volume'),
  PPP_VALUE: i18n.t('pppValue'),
  PPP_NUMBER: i18n.t('pppNumber'),
  BUYER: i18n.t('buyer'),
  LAST_DATE: i18n.t('lastDate'),
  PEAK_AMOUNT: i18n.t('peakAmount'),
  PURCHASE_TIME: i18n.t('purchaseTime'),
  OPERATION: i18n.t('operation'),
  MAXIMUM_12_MONTHS: i18n.t('maxPrice12Months'),
  MINIMUM_12_MONTHS: i18n.t('minPrice12Months'),
  PEAK_PRICE: i18n.t('peakPrice'),
  SELLER: i18n.t('seller'),
  PEAK_VOLUME: i18n.t('peakVolume'),
  AVERAGE_PRICE: i18n.t('averagePrice'),
  MONTHLY_YIELD: i18n.t('monthlyYield'),
  ANNUAL_YIELD: i18n.t('annualYield'),
  WEEKLY_YIELD: i18n.t('weeklyYield'),
  YIELD_PER_YEAR: i18n.t('yieldPerYear'),
  MAX_VOLUME_52S: i18n.t('maxVol52s'),
  MIN_VOLUME_52S: i18n.t('minVol52s'),
  AVERAGE_VOLUME_3A: i18n.t('averageVol3a'),
  AVERAGE_VOLUME_3M: i18n.t('averageVol3m'),
  SERIE: i18n.t('serie'),
  FIXED_INCOME_DAYS_TO_EXPIRE: i18n.t('daysToExpire'),
  VALUE_TYPE: i18n.t('valueType'),
  FIXED_INCOME_RELEASE_DATE: i18n.t('emissionDate'),
  FIXED_INCOME_CLEAN_PRICE: i18n.t('cleanPrice'),
  FIXED_INCOME_DIRTY_PRICE: i18n.t('dirtyPrice'),
  SURTAX_PIP: i18n.t('surtaxPIP'),
  MD_CLEAN_PRICE: i18n.t('mdCleanPrice'),
  MD_DIRTY_PRICE: i18n.t('mdDirtyPrice'),
  FIXED_INCOME_INTEREST_24: i18n.t('interest24H'),
  FIXED_INCOME_SCORE_FITCH: i18n.t('scoreFitch'),
  FIXED_INCOME_SCORE_MOODY: i18n.t('scoreMoodys'),
  FIXED_INCOME_SCORE_SP: i18n.t('scoreSP'),
  FIXED_INCOME_SCORE_HR: i18n.t('scoreHR'),
  FIXED_INCOME_EXPIRATION_DATE: i18n.t('expirationDate'),
  FIXED_INCOME_YIELD_RATE: i18n.t('yieldRate'),
  COUPON_RATE: i18n.t('couponRate'),
  AMOUNT: i18n.t('amount'),
  STOCK: i18n.t('bolsa'),
  PRICE: i18n.t('price'),
  ACTUAL_VALUE: i18n.t('value'),
  NUM_ORDER: i18n.t('numOrder'),
  PURCHASE_VOLUME_PP: i18n.t('purchaseVolumePP'),
  PURCHASE: i18n.t('purchaseDepth'),
  SALE: i18n.t('saleDepth'),
  VOL_SALE: i18n.t('volSale'),
  NUM_ORDERS: i18n.t('numOrders'),
  QUANTITY: i18n.t('quantity'),
  PPP: i18n.t('ppp'),
  LIQUIDATION: i18n.t('liquidation'),
  SEVEN_DAYS: i18n.t('sevenDays'),
  THIRTY_DAYS: i18n.t('thirtyDays'),
  OF_YEAR: i18n.t('ofYear'),
  LAST_TWELVE_MONTHS: i18n.t('lastTwelveMonths'),
  VOLATILITY: i18n.t('volatility'),
  PUPA: i18n.t('pupa'),
  PVL: i18n.t('PVL'),
  TRANSMITTER: i18n.t('transmitter'),
  INSTRUMENT_TYPE: i18n.t('instrumentType'),
  SPREAD_VARIATION: i18n.t('spreadVariation'),
  LAST_DONE: i18n.t('lastDone'),
  CLOSING_PRICE: i18n.t('closingPrice'),
  CHANGE: i18n.t('change'),
  BROKERAGE_HOUSE: i18n.t('brokerageHouse'),
  VALOR: i18n.t('valor'),
  PARTICIPATION_SHARE: i18n.t('percentageShare'),
  SALE_RATE: i18n.t('saleRate'),
  PURCHASE_RATE: i18n.t('purchaseRate'),
  WEEKLY_NUMBER: i18n.t('weeklyNumber'),
  MONTHLY_NUMBER: i18n.t('monthlyNumber'),
  ANNUAL_NUMBER: i18n.t('annualNumber'),
  ANNUAL_YIELD_NUMBER: i18n.t('rendEnAnoNumber'),
  ACCUMULATED_VOLUME: i18n.t('accumulatedVolume'),
  POSTURE_DATE: i18n.t('posturafecha'),
  CURRENT_LEVEL: i18n.t('currentLevel'),
  PREVIOUS_LEVEL: i18n.t('previousLevel'),
  FRECUENCY: i18n.t('frecuency'),
  UNITS: i18n.t('units'),
  LAST_UPDATE: i18n.t('lastUpdate'),
  SERIE_TYPE: i18n.t('serieType'),
  PURCHASE_CASH: i18n.t('purchaseCash'),
  SALE_CASH: i18n.t('saleCash'),
  PURCHASE_CASH_24HRS: i18n.t('purchaseCash24hrs'),
  SALE_CASH_24HRS: i18n.t('saleCash24hrs'),
  PURCHASE_CASH_48HRS: i18n.t('purchaseCash48hrs'),
  SALE_CASH_48HRS: i18n.t('saleCash48hrs'),
  EXPIRATION: i18n.t('expiration'),
  AVAILABILITY: i18n.t('availability'),
  SOURCE: i18n.t('source'),
  PORTFOLIO: i18n.t('Cartera'),
  INSTRUMENT: i18n.t('instrument'),
  REND_YEAR_ANNUALIZED: i18n.t('rendYearAnnualized'),
  REND_MONTHLY_ANNUALIZED: i18n.t('rendMonthlyAnnualized'),
  REND_WEEKLY_ANNUALIZED: i18n.t('rendWeeklyAnnualized'),
  LAST_DONE_DATE: i18n.t('lastDoneDate'),
  LAST_DONE_TIME: i18n.t('lastDoneTime'),
  BUY_POSITION: i18n.t('buyPosition'),
  SALE_POSITION: i18n.t('salePosition'),
  OPENING_RATES: i18n.t('openingRate'),
  MAXIMUN_RATES_DAY: i18n.t('maximumRateDay'),
  MINIMUN_RATES_DAY: i18n.t('minimumRateDay'),
  PRE_CLOSING_RATES: i18n.t('preClosingRate'),
  NET_RATE: i18n.t('netRate'),
  YIELD: i18n.t('yield'),
  DONE_MAX_12M: i18n.t('doneMax12M'),
  DONE_DATE_MAX_12M: i18n.t('doneDateMax12M'),
  DONE_MIN_12M: i18n.t('doneMin12M'),
  DONE_DATE_MIN_12M: i18n.t('doneDateMin12M'),
  MARKET_SURCHARGE: i18n.t('marketSurcharge'),
  CURRENT_RATE: i18n.t('currentRate'),
  CURRENT_RATE_DATE: i18n.t('currentRateDate'),
  PREVIOUS_CLOSING_RATE: i18n.t('previousClosingRate'),
  MAXIMUN_VARIATIOS: i18n.t('maximumVariations'),
  MINIMUN_VARIATIOS: i18n.t('minimumVariations'),
  OPENING_VARIATIOS: i18n.t('openingVariations'),
  CLEAN_PRICE_24H: `${i18n.t('cleanPrice')} 24H`,
  DIRTY_PRICE_24H: `${i18n.t('dirtyPrice')} 24H`,
  PERCENTAGE_CHANGE: i18n.t('percentageChange'),
  MARK_TO_SEEN: i18n.t('markToSeen'),
  MAX_PRICE_OF_THE_DAY: i18n.t('maxPriceOfTheDay'),
  MIN_PRICE_OF_THE_DAY: i18n.t('minPriceOfTheDay'),
  SECTOR: i18n.t('sector'),
  SEVEN_DAY_QUOTE: i18n.t('sevenDayQuote'),
  THIRTY_DAY_QUOTE: i18n.t('thirtyDayQuote'),
  PREVIOUS_YEAR_PRICE: i18n.t('previousYearPrice'),
  CLOSE_MOVEMENT: i18n.t('closeMovement')
}

export const dataTableColumns: DataTableColumns = {
  SYMBOL: {
    label: labels.SYMBOL,
    align: alignment.LEFT,
    name: 'symbol',
    format: formats.STRING,
    apiField: 'descripcion',
    lsField: '',
    lsFormat: null,
    width: '120'
  },
  DISPLAY_SYMBOL: {
    label: labels.SYMBOL,
    align: alignment.LEFT,
    name: 'displaySymbol',
    format: formats.STRING,
    apiField: 'displaySymbol',
    lsField: '',
    lsFormat: null,
    width: '120'
  },
  CLOSE_MOVEMENT: {
    label: labels.CLOSE_MOVEMENT,
    align: alignment.LEFT,
    name: 'closeMovement',
    format: formats.STRING,
    apiField: 'aireCierre',
    lsField: 'Movimiento',
    lsFormat: null,
    width: '40'
  },
  DESCRIPTION: {
    label: labels.DESCRIPTION,
    align: alignment.LEFT,
    name: 'description',
    format: formats.STRING,
    apiField: 'descripcionInstrumento',
    lsField: 'Descripcion',
    lsFormat: null,
    width: '500'
  },
  ISIN: {
    label: labels.ISIN,
    align: alignment.LEFT,
    name: 'isin',
    format: formats.STRING,
    apiField: 'isin',
    lsField: '',
    lsFormat: null,
    width: '125'
  },
  PREVIOUS_PRICE: {
    label: labels.CLOSING_PRICE,
    align: alignment.RIGHT,
    name: 'previousPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioAnterior',
    lsField: LsSubscriptionTableFields.PREVIOUS_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_PRICE_FIGURE: {
    label: labels.PREVIOUS_PRICE,
    align: alignment.RIGHT,
    name: 'previousPrice',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'precioAnterior',
    lsField: LsSubscriptionTableFields.PREVIOUS_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_VALUE: {
    label: labels.PREVIOUS_PRICE,
    align: alignment.RIGHT,
    name: 'previousPrice',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'precioAnterior',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  ACTUAL_VALUE: {
    label: labels.ACTUAL_VALUE,
    align: alignment.RIGHT,
    name: 'actualPrice',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'precioActual',
    lsField: LsSubscriptionTableFields.CURRENT_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  ACTUAL_PRICE_FIGURE: {
    label: labels.PRICE,
    align: alignment.RIGHT,
    name: 'actualPrice',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'precioActual',
    lsField: LsSubscriptionTableFields.CURRENT_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  ACTUAL_PRICE: {
    label: labels.PRICE,
    align: alignment.RIGHT,
    name: 'actualPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioActual',
    lsField: LsSubscriptionTableFields.CURRENT_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  ACTUAL_PRICE_BIVA: {
    label: labels.PRICE,
    align: alignment.RIGHT,
    name: 'actualPrice',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'precioActual',
    lsField: LsSubscriptionTableFields.PRICE,
    lsFormat: null,
    width: 'auto'
  },
  ACTUAL_PRICE_VALUE: {
    label: labels.PRICE,
    align: alignment.RIGHT,
    name: 'actualPrice',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'precioActual',
    lsField: LsSubscriptionTableFields.CURRENT_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  VOLUME: {
    label: labels.VOLUMEN_DONE,
    align: alignment.RIGHT,
    name: 'volume',
    format: formats.INT,
    lsField: LsSubscriptionTableFields.VOLUMEN_DONE,
    apiField: 'volumenAcumulado',
    lsFormat: null,
    width: 'auto'
  },
  TRANSACTIONS_NUMBER: {
    label: labels.TRANSACTIONS_NUMBER,
    align: alignment.RIGHT,
    name: 'volume',
    format: formats.INT,
    lsField: LsSubscriptionTableFields.VOLUMEN_DONE,
    apiField: 'volumenAcumulado',
    lsFormat: null,
    width: 'auto'
  },
  CHANGE: {
    label: labels.CHANGE,
    align: alignment.RIGHT,
    name: 'change',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'variacion',
    lsField: LsSubscriptionTableFields.CHANGE_UNIT,
    lsFormat: null,
    width: 'auto'
  },
  CHANGE_UNIT: {
    label: labels.CHANGE,
    align: alignment.RIGHT,
    name: 'change',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'variacion',
    lsField: LsSubscriptionTableFields.CHANGE_UNIT,
    lsFormat: null,
    width: 'auto'
  },
  CHANGE_PROCENTAGE: {
    label: labels.CHANGE_PROCENTAGE,
    align: alignment.RIGHT,
    name: 'changePercentage',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'porcentaje',
    lsField: LsSubscriptionTableFields.CHANGE_PROCENTAGE,
    lsFormat: null,
    width: 'auto'
  },
  CHANGE_PROCENTAGE_GI: {
    label: labels.CHANGE_PROCENTAGE,
    align: alignment.RIGHT,
    name: 'changePercentage',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'variacion',
    lsField: LsSubscriptionTableFields.CHANGE_PROCENTAGE,
    lsFormat: null,
    width: 'auto'
  },
  TOTAL_VOLUME: {
    label: labels.VOLUMEN_TOTAL,
    align: alignment.RIGHT,
    name: 'totalVolume',
    format: formats.INT,
    apiField: 'volumenTotal',
    lsField: LsSubscriptionTableFields.VOLUMEN_TOTAL,
    lsFormat: null,
    width: 'auto'
  },
  INDEX_TOTAL_VOLUME: {
    label: labels.VOLUME,
    align: alignment.RIGHT,
    name: 'totalVolume',
    format: formats.INT,
    apiField: 'volumenTotal',
    lsField: LsSubscriptionTableFields.VOLUMEN_TOTAL,
    lsFormat: null
  },
  TIME: {
    label: labels.HOUR,
    align: alignment.RIGHT,
    name: 'time',
    format: formats.TIME,
    apiField: 'hora',
    lsField: LsSubscriptionTableFields.HOUR,
    lsFormat: null,
    width: 'auto'
  },
  TIME_GI: {
    label: labels.HOUR,
    align: alignment.RIGHT,
    name: 'time',
    format: formats.TIME,
    apiField: 'horaPrecioActual',
    lsField: LsSubscriptionTableFields.HOUR,
    lsFormat: null,
    width: 'auto'
  },
  DATE: {
    label: labels.DATE,
    align: alignment.RIGHT,
    name: 'date',
    format: formats.DATE,
    apiField: 'fechaPrecioActual',
    lsField: LsSubscriptionTableFields.DATE,
    lsFormat: null,
    width: 'auto'
  },
  MONITOR_DATE: {
    label: labels.LAST_DATE,
    align: alignment.RIGHT,
    name: 'lastDate',
    format: formats.DATE,
    apiField: 'monitorFecha',
    lsField: LsSubscriptionTableFields.LAST_DATE,
    lsFormat: null,
    width: 'auto'
  },
  CURRENT_PRICE_DATE: {
    label: labels.DATE,
    align: alignment.RIGHT,
    name: 'currentPriceDate',
    format: formats.DATE,
    apiField: 'fechaPrecioActual',
    lsField: LsSubscriptionTableFields.DATE,
    lsFormat: null,
    width: 'auto'
  },
  CURRENT_PRICE_DATE_INDEX: {
    label: labels.DATE,
    align: alignment.RIGHT,
    name: 'currentPriceDate',
    format: formats.DATE,
    apiField: 'fechaPrecioActual',
    lsField: LsSubscriptionTableFields.LAST_DATE,
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_PRICE_DATE: {
    label: labels.PREVIOUS_PRICE_DATE,
    align: alignment.RIGHT,
    name: 'previousPriceDate',
    format: formats.DATE,
    apiField: 'fechaPrecioAnterior',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MONITOR_TIME: {
    label: labels.HOUR,
    align: alignment.RIGHT,
    name: 'lastTime',
    format: formats.TIME,
    apiField: 'monitorHora',
    lsField: LsSubscriptionTableFields.LAST_TIME,
    lsFormat: null,
    width: 'auto'
  },
  POSTURE_DATE: {
    label: labels.POSTURE_DATE,
    align: alignment.RIGHT,
    name: 'postureDate',
    format: formats.DATE,
    apiField: 'posturaFecha',
    lsField: LsSubscriptionTableFields.DATE,
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  POSTURE_TIME: {
    label: labels.PURCHASE_TIME,
    align: alignment.RIGHT,
    name: 'postureTime',
    format: formats.TIME,
    apiField: 'posturaHora',
    lsField: LsSubscriptionTableFields.POSTURE_TIME,
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  PURCHASE_PRICE_FIGURE: {
    label: labels.PURCHASE_PRICE,
    align: alignment.RIGHT,
    name: 'purchaseValue',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'posturaPrecioCompra',
    lsField: LsSubscriptionTableFields.PURCHASE_VALUE,
    lsFormat: null,
    width: 'auto'
  },
  PURCHASE_PRICE: {
    label: labels.PURCHASE_PRICE,
    align: alignment.RIGHT,
    name: 'purchaseValue',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'posturaPrecioCompra',
    lsField: LsSubscriptionTableFields.PURCHASE_VALUE,
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  PURCHASE_PRICE_NYSE: {
    label: labels.PURCHASE_PRICE,
    align: alignment.RIGHT,
    name: 'purchaseValue',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'posturaPrecioCompra',
    lsField: LsSubscriptionTableFields.NYSE_PURCHASE_VALUE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_PURCHASE_RATE: {
    label: labels.PURCHASE_RATE,
    align: alignment.RIGHT,
    name: 'purchaseValue',
    format: formats.FLOAT,
    decimals: 6,
    apiField: 'precioCompra',
    lsField: LsSubscriptionTableFields.PURCHASE_VALUE,
    lsFormat: null,
    width: 'auto'
  },
  PURCHASE_VOLUME: {
    label: labels.PURCHASE_VOLUME,
    align: alignment.RIGHT,
    name: 'purchaseVolume',
    format: formats.INT,
    apiField: 'posturaVolumenCompra',
    lsField: LsSubscriptionTableFields.PURCHASE_VOLUME,
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  PURCHASE_VOLUME_NYSE: {
    label: labels.PURCHASE_VOLUME,
    align: alignment.RIGHT,
    name: 'purchaseVolume',
    format: formats.INT,
    apiField: 'posturaVolumenCompra',
    lsField: LsSubscriptionTableFields.NYSE_PURCHASE_VOLUME,
    lsFormat: null,
    width: 'auto'
  },
  SALE_PRICE: {
    label: labels.SALE_PRICE,
    align: alignment.RIGHT,
    name: 'saleValue',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'posturaPrecioVenta',
    lsField: LsSubscriptionTableFields.SALE_VALUE,
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  SALE_PRICE_NYSE: {
    label: labels.SALE_PRICE,
    align: alignment.RIGHT,
    name: 'saleValue',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'posturaPrecioVenta',
    lsField: LsSubscriptionTableFields.NYSE_SALE_VALUE,
    lsFormat: null,
    width: 'auto'
  },
  SALE_PRICE_FIGURE: {
    label: labels.SALE_PRICE,
    align: alignment.RIGHT,
    name: 'saleValue',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'posturaPrecioVenta',
    lsField: LsSubscriptionTableFields.SALE_VALUE,
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  SALE_VOLUME: {
    label: labels.SALE_VOLUME,
    align: alignment.RIGHT,
    name: 'saleVolume',
    format: formats.INT,
    apiField: 'posturaVolumenVenta',
    lsField: LsSubscriptionTableFields.SALE_VOLUME,
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  SALE_VOLUME_NYSE: {
    label: labels.SALE_VOLUME,
    align: alignment.RIGHT,
    name: 'saleVolume',
    format: formats.INT,
    apiField: 'posturaVolumenVenta',
    lsField: LsSubscriptionTableFields.NYSE_SALE_VOLUME,
    lsFormat: null,
    width: 'auto'
  },
  PP_PRICE: {
    label: labels.PPP_VALUE,
    align: alignment.RIGHT,
    name: 'pppValue',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioPP',
    lsField: LsSubscriptionTableFields.ACTUAL_VALUE_PPP,
    lsFormat: null,
    width: 'auto'
  },
  PP_NUMBER: {
    label: labels.PPP_NUMBER,
    align: alignment.RIGHT,
    name: 'pppNumber',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'pppOficial',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  BUYER: {
    label: labels.BUYER,
    align: alignment.RIGHT,
    name: 'buyer',
    format: formats.STRING,
    apiField: 'comprador',
    lsField: LsSubscriptionTableFields.BUYER,
    lsFormat: null,
    width: 'auto'
  },
  SELLER: {
    label: labels.SELLER,
    align: alignment.RIGHT,
    name: 'seller',
    format: formats.STRING,
    apiField: 'vendedor',
    lsField: LsSubscriptionTableFields.SELLER,
    lsFormat: null,
    width: 'auto'
  },
  PEAK_AMOUNT: {
    label: labels.PEAK_AMOUNT,
    align: alignment.RIGHT,
    name: 'peakAmount',
    format: formats.FLOAT,
    apiField: 'ImportePico',
    lsField: LsSubscriptionTableFields.PEAK_AMOUNT,
    lsFormat: null,
    width: 'auto'
  },
  TOTAL_AMOUNT: {
    label: labels.TOTAL_AMOUNT,
    align: alignment.RIGHT,
    name: 'totalAmount',
    format: formats.FLOAT,
    apiField: 'importeTotal',
    lsField: LsSubscriptionTableFields.TOTAL_AMOUNT,
    lsFormat: null,
    width: 'auto'
  },
  OPERATION: {
    label: labels.OPERATION,
    align: alignment.RIGHT,
    name: 'operation',
    format: formats.STRING,
    apiField: 'picoLote',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  OPENING_PRICE: {
    label: labels.OPENING_PRICE,
    align: alignment.RIGHT,
    name: 'openingPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioApertura',
    lsField: LsSubscriptionTableFields.OPENING_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  OPENING_PRICE_FIGURE: {
    label: labels.OPENING_PRICE,
    align: alignment.RIGHT,
    name: 'openingPrice',
    format: formats.FIGURE,
    apiField: 'precioApertura',
    decimals: 4,
    lsField: LsSubscriptionTableFields.OPENING_PRICE,
    lsFormat: null
  },
  MAXIMUM_DAY_PRICE: {
    label: labels.MAXIMUM,
    align: alignment.RIGHT,
    name: 'maxDayPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMaximoDia',
    lsField: LsSubscriptionTableFields.MAXIMUM,
    lsFormat: null,
    width: 'auto'
  },
  MAXIMUM_DAY_PRICE_NYSE: {
    label: labels.MAXIMUM,
    align: alignment.RIGHT,
    name: 'maxDayPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMaximoDia',
    lsField: LsSubscriptionTableFields.NYSE_MAX_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  MAXIMUM_DAY_PRICE_FIGURE: {
    label: labels.MAXIMUM,
    align: alignment.RIGHT,
    name: 'maxDayPrice',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'precioMaximoDia',
    lsField: LsSubscriptionTableFields.MAXIMUM,
    lsFormat: null
  },
  MAXIMUM_12_MONTHS: {
    label: labels.MAXIMUM_12_MONTHS,
    align: alignment.RIGHT,
    name: 'maxPrice12Months',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMaximo',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MINIMUM_DAY_PRICE: {
    label: labels.MINIMUM,
    align: alignment.RIGHT,
    name: 'minDayPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMinimoDia',
    lsField: LsSubscriptionTableFields.MINIMUM,
    lsFormat: null,
    width: 'auto'
  },
  MINIMUM_DAY_PRICE_NYSE: {
    label: labels.MINIMUM,
    align: alignment.RIGHT,
    name: 'minDayPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMinimoDia',
    lsField: LsSubscriptionTableFields.NYSE_MIN_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  MINIMUM_DAY_PRICE_FIGURE: {
    label: labels.MINIMUM,
    align: alignment.RIGHT,
    name: 'minDayPrice',
    format: formats.FIGURE,
    decimals: 4,
    apiField: 'precioMinimoDia',
    lsField: LsSubscriptionTableFields.MINIMUM,
    lsFormat: null
  },
  MINIMUM_12_MONTHS: {
    label: labels.MINIMUM_12_MONTHS,
    align: alignment.RIGHT,
    name: 'minPrice12Months',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMinimo',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PEAK_PRICE: {
    label: labels.PEAK_PRICE,
    align: alignment.RIGHT,
    name: 'peakPrice',
    format: formats.FLOAT,
    apiField: 'precioPico',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PEAK_VOLUME: {
    label: labels.PEAK_VOLUME,
    align: alignment.RIGHT,
    name: 'peakVolume',
    format: formats.FLOAT,
    apiField: 'volumenPico',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  AVERAGE_PRICE: {
    label: labels.AVERAGE_PRICE,
    align: alignment.RIGHT,
    name: 'averagePrice',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'precioPromedio',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MONTHLY_YIELD: {
    label: labels.MONTHLY_YIELD,
    align: alignment.RIGHT,
    name: 'monthlyYield',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'rendimientoMensual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  ANNUAL_YIELD: {
    label: labels.ANNUAL_YIELD,
    align: alignment.RIGHT,
    name: 'annualYield',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'rendimientoAnual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  WEEKLY_YIELD: {
    label: labels.WEEKLY_YIELD,
    align: alignment.RIGHT,
    name: 'weeklyYield',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'rendimientoSemanal',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  YIELD_PER_YEAR: {
    label: labels.YIELD_PER_YEAR,
    align: alignment.RIGHT,
    name: 'yieldPerYear',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'rendimientoAlAnio',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  FULL_NAME: {
    label: labels.DESCRIPTION,
    align: alignment.LEFT,
    name: 'fullName',
    format: formats.STRING,
    apiField: 'descripcionInstrumento',
    lsField: '',
    lsFormat: null,
    customStyle: {
      width: 400
    },
    width: '400'
  },
  NAME: {
    label: labels.FULL_NAME,
    align: alignment.LEFT,
    name: 'nombre',
    format: formats.STRING,
    apiField: 'descripcionInstrumento',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MAX_VOLUME_52S: {
    label: labels.MAX_VOLUME_52S,
    align: alignment.RIGHT,
    name: 'maximumVolume52s',
    format: formats.INT,
    apiField: 'volumenMaximo52s',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MIN_VOLUME_52S: {
    label: labels.MIN_VOLUME_52S,
    align: alignment.RIGHT,
    name: 'minimumVolume52s',
    format: formats.INT,
    apiField: 'volumenMinimo52s',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  AVERAGE_VOLUME_3A: {
    label: labels.AVERAGE_VOLUME_3A,
    align: alignment.RIGHT,
    name: 'averageVolume3a',
    format: formats.INT,
    apiField: 'volumenPromedio3a',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  AVERAGE_VOLUME_3M: {
    label: labels.AVERAGE_VOLUME_3M,
    align: alignment.RIGHT,
    name: 'averageVolume3m',
    format: formats.INT,
    apiField: 'volumenPromedio3m',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  POSTURE_ASK_TIME: {
    label: labels.POSTURE_ASK_TIME,
    align: alignment.RIGHT,
    name: 'postureAskTime',
    format: formats.TIME,
    apiField: 'posturaHoraVenta',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PURCHASE_DATE: {
    label: labels.PURCHASE_DATE,
    align: alignment.RIGHT,
    name: 'purchaseDate',
    format: formats.DATE,
    apiField: 'posturaFechaCompra',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SALE_DATE: {
    label: labels.SALE_DATE,
    align: alignment.RIGHT,
    name: 'saleDate',
    format: formats.DATE,
    apiField: 'posturaFechaVenta',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SPREAD: {
    label: labels.SPREAD,
    align: alignment.RIGHT,
    name: 'spread',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'spread',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PRICE: {
    label: labels.PRICE,
    align: alignment.RIGHT,
    name: 'price',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioActual',
    lsField: LsSubscriptionTableFields.PRICE,
    lsFormat: null,
    width: 'auto'
  },
  LIQUID_PRICE: {
    label: labels.LIQUID_PRICE,
    align: alignment.RIGHT,
    name: 'liquidPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioLiquidacion',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_LIQUID_PRICE: {
    label: labels.PREVIUS_LIQUID_PRICE,
    align: alignment.RIGHT,
    name: 'previousLiquidPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioLiquidacionAnterior',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  COMULATIVE_VOLUME: {
    label: labels.VOLUME,
    align: alignment.RIGHT,
    name: 'cumulativeVolume',
    format: formats.INT,
    apiField: 'volumenAcumulado',
    lsField: LsSubscriptionTableFields.VOLUMEN_TOTAL,
    lsFormat: null,
    width: 'auto'
  },
  MAXIMUM_DAY_PRICE_DER: {
    label: labels.MAXIMUM,
    align: alignment.RIGHT,
    name: 'maxDayPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMaximoDia',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MINIMUM_DAY_PRICE_DER: {
    label: labels.MINIMUM,
    align: alignment.RIGHT,
    name: 'minDayPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioMinimoDia',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  CHANGE_UNIT_DER: {
    label: labels.CHANGE,
    align: alignment.RIGHT,
    name: 'changeUnit',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'variacion',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  EXPIRATION_DATE: {
    label: labels.EXPIRATION_DATE,
    align: alignment.RIGHT,
    name: 'expirationDate',
    format: formats.DATE,
    apiField: 'fechaExpiracion',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  OPENING_PRICE_DER: {
    label: labels.OPENING_PRICE,
    align: alignment.RIGHT,
    name: 'openingPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioApertura',
    lsField: LsSubscriptionTableFields.OPENING_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  PREVIUS_PRICE_DER: {
    label: labels.PREVIOUS_PRICE,
    align: alignment.RIGHT,
    name: 'previousPrice',
    format: formats.FLOAT,
    decimals: 3,
    apiField: 'precioAnterior',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PERIODICITY: {
    label: labels.PERIODICITY,
    align: alignment.RIGHT,
    name: 'periodicity',
    format: formats.STRING,
    apiField: 'periodicidad',
    lsField: LsSubscriptionTableFields.PERIODICITY,
    lsFormat: null,
    width: 'auto'
  },
  SERIE: {
    label: labels.SERIE,
    align: alignment.RIGHT,
    name: 'serie',
    format: formats.STRING,
    apiField: 'Deuda_Serie',
    lsField: 'serie',
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_DAYS_TO_EXPIRE: {
    label: labels.FIXED_INCOME_DAYS_TO_EXPIRE,
    align: alignment.RIGHT,
    name: 'fixedIncomeDaysToExpire',
    format: formats.INT,
    apiField: 'diasPorVencer',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_DAYS_TO_WIN,
    lsFormat: null,
    width: 'auto'
  },
  VALUE_TYPE: {
    label: labels.VALUE_TYPE,
    align: alignment.RIGHT,
    name: 'valueType',
    format: formats.STRING,
    apiField: 'tipoValor',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_RELEASE_DATE: {
    label: labels.FIXED_INCOME_RELEASE_DATE,
    align: alignment.RIGHT,
    name: 'fixedIncomeReleaseDate',
    format: formats.DATE,
    apiField: 'Deuda_FechaEmision',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_DATE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_CLEAN_PRICE: {
    label: labels.FIXED_INCOME_CLEAN_PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeCleanPrice',
    format: formats.FLOAT,
    apiField: 'Deuda_PrecioLimpio',
    decimals: 3,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_CLEAN_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_DIRTY_PRICE: {
    label: labels.FIXED_INCOME_DIRTY_PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeDirtyPrice',
    format: formats.FLOAT,
    apiField: 'Deuda_PrecioSucio',
    decimals: 3,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_DIRTY_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  SURTAX_PIP: {
    label: labels.SURTAX_PIP,
    align: alignment.RIGHT,
    name: 'surtaxPIP',
    format: formats.FLOAT,
    apiField: 'sobretasaPIP',
    decimals: 6,
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MD_CLEAN_PRICE: {
    label: labels.MD_CLEAN_PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeMDCleanPrice',
    format: formats.FLOAT,
    apiField: 'precioLimpio',
    decimals: 3,
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MD_DIRTY_PRICE: {
    label: labels.MD_DIRTY_PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeMDDirtyPrice',
    format: formats.FLOAT,
    apiField: 'precioSucio',
    decimals: 3,
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  AMOUNT: {
    label: labels.AMOUNT,
    align: alignment.RIGHT,
    name: 'amount',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'importe',
    lsField: LsSubscriptionTableFields.AMOUNT,
    lsFormat: null,
    width: 'auto'
  },
  VOLUME_DONE: {
    label: labels.VOLUME,
    align: alignment.RIGHT,
    name: 'volume',
    format: formats.INT,
    apiField: 'volumenHecho',
    lsField: LsSubscriptionTableFields.VOLUMEN_DONE,
    lsFormat: null,
    width: 'auto'
  },
  NYSE_NASDAQ_EQUITY_VOLUME_DONE: {
    label: labels.VOLUME,
    align: alignment.RIGHT,
    name: 'volume',
    format: formats.INT,
    apiField: 'volumenHecho',
    lsField: LsSubscriptionTableFields.NYSE_NASDAQ_EQUITY_VOLUME_DONE,
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_DATE: {
    label: labels.PREVIOUS_DATE,
    align: alignment.RIGHT,
    name: 'previousPriceDate',
    format: formats.DATE,
    apiField: 'fechaPrecioAnterior',
    lsField: LsSubscriptionTableFields.DATE_UHA,
    lsFormat: null,
    width: 'auto'
  },
  NUMBER_OPERATIONS: {
    label: labels.NUMBER_OPER,
    align: alignment.RIGHT,
    name: 'numOfOperations',
    format: formats.INT,
    apiField: 'numeroOperaciones',
    lsField: LsSubscriptionTableFields.NUMBER_OPERATION,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_INTEREST_24: {
    label: labels.FIXED_INCOME_INTEREST_24,
    align: alignment.RIGHT,
    name: 'fixedIncomeInterest24',
    format: formats.FLOAT,
    apiField: 'interes24h',
    decimals: 4,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_INTEREST_24,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_DAY_MAX: {
    label: labels.MAXIMUM,
    align: alignment.RIGHT,
    name: 'fixedIncomeDayMaxValue',
    format: formats.FLOAT,
    apiField: 'precioMaximoDia',
    decimals: 3,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_DAY_MAX,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_DAY_MIN: {
    label: labels.MINIMUM,
    align: alignment.RIGHT,
    name: 'fixedIncomeDayMinValue',
    format: formats.FLOAT,
    apiField: 'precioMinimoDia',
    decimals: 3,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_DAY_MIN,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_PURCHASE_PRICE: {
    label: labels.PURCHASE_PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeBuy',
    format: formats.FLOAT,
    apiField: 'precioCompra',
    decimals: 3,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_PURCHASE_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_YIELD_RATE: {
    label: labels.FIXED_INCOME_YIELD_RATE,
    align: alignment.RIGHT,
    name: 'fixedIncomeYieldRate',
    format: formats.FLOAT,
    apiField: 'tasaRendimiento',
    decimals: 6,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_YIELD_RATE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_SALE_PRICE: {
    label: labels.SALE_PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeSale',
    format: formats.FLOAT,
    apiField: 'precioVenta',
    decimals: 3,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_SALE_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_SALE_RATE: {
    label: labels.SALE_RATE,
    align: alignment.RIGHT,
    name: 'fixedIncomeSale',
    format: formats.FLOAT,
    apiField: 'precioVenta',
    decimals: 6,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_SALE_PRICE,
    lsFormat: null,
    width: '120'
  },
  FIXED_INCOME_COUPON_RATE: {
    label: labels.COUPON_RATE,
    align: alignment.RIGHT,
    name: 'fixedCouponRate',
    format: formats.FLOAT,
    apiField: 'tasaCupon',
    decimals: 4,
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_OPENING_PRICE: {
    label: labels.OPENING_PRICE,
    align: alignment.RIGHT,
    name: 'openingPrice',
    format: formats.FLOAT,
    apiField: 'precioApertura',
    decimals: 6,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_OPENING_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_LAST_PRICE: {
    label: labels.PREVIOUS_PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeLastPrice',
    format: formats.FLOAT,
    apiField: 'precioAnterior',
    decimals: 6,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_PREVIOUS_PRICE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_LAST_DONE: {
    label: labels.PRICE,
    align: alignment.RIGHT,
    name: 'fixedIncomeLastDone',
    format: formats.FLOAT,
    apiField: 'precioActual',
    decimals: 6,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_LAST_DONE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_PORCENTUAL_VARIATION: {
    label: labels.CHANGE_PROCENTAGE,
    align: alignment.RIGHT,
    name: 'fixedIncomePorcentualVariation',
    format: formats.FLOAT,
    decimals: 4,
    apiField: 'porcentaje',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_PORCENTUAL_VARIATION,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_SCORE_FITCH: {
    label: labels.FIXED_INCOME_SCORE_FITCH,
    align: alignment.RIGHT,
    name: 'fixedIncomeScoreFitch',
    format: formats.STRING,
    apiField: 'calificacionFitch',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_SCORE_FITCH,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_SCORE_MOODY: {
    label: labels.FIXED_INCOME_SCORE_MOODY,
    align: alignment.RIGHT,
    name: 'fixedIncomeScoreMoody',
    format: formats.STRING,
    apiField: 'calificacionMoody',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_SCORE_MOODY,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_SCORE_HR: {
    label: labels.FIXED_INCOME_SCORE_HR,
    align: alignment.RIGHT,
    name: 'fixedIncomeScoreHR',
    format: formats.STRING,
    apiField: 'calificacionHR',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_SCORE_HR,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_SCORE_SP: {
    label: labels.FIXED_INCOME_SCORE_SP,
    align: alignment.RIGHT,
    name: 'fixedIncomeScoreSP',
    format: formats.STRING,
    apiField: 'calificacionSP',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_SCORE_SP,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_UNIT_VARIATION: {
    label: labels.CHANGE_UNIT,
    align: alignment.RIGHT,
    name: 'fixedIncomeUnitVariation',
    format: formats.FLOAT,
    apiField: 'variacion',
    decimals: 3,
    lsField: LsSubscriptionTableFields.FIXED_INCOME_UNIT_VARIATION,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_EXPIRATION_DATE: {
    label: labels.FIXED_INCOME_EXPIRATION_DATE,
    align: alignment.RIGHT,
    name: 'fixedIncomeExpirationDate',
    format: formats.DATE,
    apiField: 'fechaVencimiento',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_EXPIRATION_DATE,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_HOUR: {
    label: labels.HOUR,
    align: alignment.RIGHT,
    name: 'fixedIncomeHour',
    format: formats.TIME,
    apiField: 'horaPrecioActual',
    lsField: LsSubscriptionTableFields.FIXED_INCOME_HOUR,
    lsFormat: null,
    width: 'auto'
  },
  NUMBER_OPER_DERIVATIVES: {
    label: labels.NUMBER_OPER,
    align: alignment.RIGHT,
    name: 'numOfOperations',
    format: formats.INT,
    apiField: 'numOperaciones',
    lsField: LsSubscriptionTableFields.NUM_OF_OPERATIONS,
    lsFormat: null,
    width: 'auto'
  },
  FIXED_INCOME_DATE: {
    label: labels.DATE,
    align: alignment.RIGHT,
    name: 'date',
    format: formats.DATE,
    apiField: 'fechaPrecioActual',
    lsField: LsSubscriptionTableFields.DATE,
    lsFormat: null,
    width: 'auto'
  },
  MARKET: {
    label: labels.STOCK,
    align: alignment.LEFT,
    name: 'market',
    format: formats.STRING,
    apiField: 'descripcionMercado',
    lsField: '',
    lsFormat: null
  },
  INSTRUMENT: {
    label: labels.SYMBOL,
    align: alignment.LEFT,
    name: 'symbol',
    format: formats.STRING,
    apiField: 'descripcion',
    lsField: '',
    lsFormat: null
  },
  NUM_ORDER: {
    label: labels.NUM_ORDER,
    align: alignment.RIGHT,
    name: 'CNumOrdenes',
    format: formats.INT,
    apiField: 'NumOpCompra',
    lsField: 'CNumOrdenes',
    lsFormat: null
  },
  VOL_PURCHASE: {
    label: labels.PURCHASE_VOLUME_PP,
    align: alignment.RIGHT,
    name: 'CVolAcumulado',
    format: formats.INT,
    apiField: 'VolAcCompra',
    lsField: 'CVolAcumulado',
    lsFormat: null
  },
  PURCHASE: {
    label: labels.PURCHASE,
    align: alignment.RIGHT,
    name: 'CPrecio',
    format: formats.FLOAT,
    apiField: 'PrecioCompra',
    lsField: 'CPrecio',
    lsFormat: null
  },
  SALE: {
    label: labels.SALE,
    align: alignment.RIGHT,
    name: 'VPrecio',
    format: formats.FLOAT,
    apiField: 'PrecioVenta',
    lsField: 'VPrecio',
    lsFormat: null
  },
  VOL_SALE: {
    label: labels.VOL_SALE,
    align: alignment.RIGHT,
    name: 'VVolAcumulado',
    format: formats.INT,
    apiField: 'VolAcVenta',
    lsField: 'VVolAcumulado',
    lsFormat: null
  },
  NUM_ORDERS: {
    label: labels.NUM_ORDERS,
    align: alignment.RIGHT,
    name: 'VNumOrdenes',
    format: formats.INT,
    apiField: 'NumOpVenta',
    lsField: 'VNumOrdenes',
    lsFormat: null
  },
  HOUR: {
    label: labels.HOUR,
    align: alignment.RIGHT,
    name: 'hour',
    format: formats.TIME,
    apiField: 'hora',
    lsField: LsSubscriptionTableFields.HOUR,
    lsFormat: null
  },
  BUYER_INTRADAY: {
    label: labels.BUYER,
    align: alignment.LEFT,
    name: 'buyer',
    format: formats.STRING,
    apiField: 'comprador',
    lsField: LsSubscriptionTableFields.BUYER,
    lsFormat: null,
    width: 'auto'
  },
  SELLER_INTRADAY: {
    label: labels.SELLER,
    align: alignment.LEFT,
    name: 'seller',
    format: formats.STRING,
    apiField: 'vendedor',
    lsField: LsSubscriptionTableFields.SELLER,
    lsFormat: null,
    width: 'auto'
  },
  QUANTITY: {
    label: labels.VOLUME,
    align: alignment.RIGHT,
    name: 'quantity',
    format: formats.INT,
    apiField: 'cantidad',
    lsField: LsSubscriptionTableFields.VOLUMEN_DONE,
    lsFormat: null
  },
  VALUE: {
    label: labels.VALUE,
    align: alignment.RIGHT,
    name: 'value',
    format: formats.FLOAT,
    apiField: 'precio',
    lsField: LsSubscriptionTableFields.CURRENT_PRICE,
    lsFormat: null
  },
  PPP: {
    label: labels.PPP,
    align: alignment.RIGHT,
    name: 'ppp',
    format: formats.FLOAT,
    apiField: 'preciopp',
    lsField: '',
    lsFormat: null
  },
  OPERATION_INTRADAY: {
    label: labels.OPERATION,
    align: alignment.LEFT,
    name: 'operation',
    format: formats.STRING,
    apiField: 'PicoLote',
    lsField: LsSubscriptionTableFields.GRAPH,
    lsFormat: null,
    width: 'auto'
  },
  LIQUIDATION: {
    label: labels.LIQUIDATION,
    align: alignment.RIGHT,
    name: 'liquidation',
    format: formats.INT,
    apiField: 'Liquidacion',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  TRANSMITTER: {
    label: labels.TRANSMITTER,
    align: alignment.LEFT,
    name: 'Emisor',
    format: formats.STRING,
    apiField: 'Emisor',
    lsField: '',
    lsFormat: null,
    width: '75px'
  },
  SEVEN_DAYS: {
    label: labels.SEVEN_DAYS,
    align: alignment.LEFT,
    name: 'Rend7Dias',
    format: formats.FLOAT,
    apiField: 'Rend7Dias',
    lsField: '',
    lsFormat: null,
    width: '75px'
  },
  THIRTY_DAYS: {
    label: labels.THIRTY_DAYS,
    align: alignment.LEFT,
    name: 'Rend30Dias',
    format: formats.FLOAT,
    apiField: 'Rend30Dias',
    lsField: '',
    lsFormat: null,
    width: '75px'
  },
  OF_YEAR: {
    label: labels.OF_YEAR,
    align: alignment.LEFT,
    name: 'RendAno',
    format: formats.FLOAT,
    apiField: 'RendAno',
    lsField: '',
    lsFormat: null,
    width: '75px'
  },
  LAST_TWELVE_MONTHS: {
    label: labels.LAST_TWELVE_MONTHS,
    align: alignment.LEFT,
    name: 'Rend12Meses',
    format: formats.FLOAT,
    apiField: 'Rend12Meses',
    lsField: '',
    lsFormat: null,
    width: '75px'
  },
  VOLATILITY: {
    label: labels.VOLATILITY,
    align: alignment.LEFT,
    name: 'VolatilidadAnual',
    format: formats.FLOAT,
    apiField: 'VolatilidadAnual',
    lsField: '',
    lsFormat: null,
    width: '75px'
  },
  PUPA: {
    label: labels.PUPA,
    align: alignment.LEFT,
    name: 'PUPA',
    format: formats.FLOAT,
    apiField: 'PUPA',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PVL: {
    label: labels.PVL,
    align: alignment.LEFT,
    name: 'PVL',
    format: formats.FLOAT,
    apiField: 'PVL',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SPREAD_CAPITAL: {
    label: labels.SPREAD,
    align: alignment.RIGHT,
    name: 'spread',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'spread',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  SPREAD_VARIATION: {
    label: labels.SPREAD_VARIATION,
    align: alignment.RIGHT,
    name: 'spreadPorcentaje',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'spreadPorcentaje',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPosture: true
  },
  NYSE_NASDAQ_EQUITY_SPREAD: {
    label: labels.SPREAD,
    align: alignment.RIGHT,
    name: 'spread',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'spread',
    lsField: LsSubscriptionTableFields.SPREAD,
    lsFormat: null,
    width: 'auto'
  },
  NYSE_NASDAQ_EQUITY_SPREAD_VARIATION: {
    label: labels.SPREAD_VARIATION,
    align: alignment.RIGHT,
    name: 'spreadPorcentaje',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'spreadPorcentaje',
    lsField: LsSubscriptionTableFields.SPREAD_PORCENTAJE,
    lsFormat: null,
    width: 'auto'
  },
  INSTRUMENT_TYPE: {
    label: labels.INSTRUMENT_TYPE,
    align: alignment.LEFT,
    name: 'instrumentTpe',
    format: formats.STRING,
    apiField: 'tipoInstrumento',
    lsField: '',
    lsFormat: null
  },
  BROKERAGE_HOUSE: {
    label: labels.BROKERAGE_HOUSE,
    align: alignment.LEFT,
    name: 'Casa de bolsa',
    format: formats.STRING,
    apiField: 'casaBolsa',
    lsField: '',
    lsFormat: null
  },
  OPERATION_PARTICIPATION: {
    label: labels.OPERATION,
    align: alignment.LEFT,
    name: 'Operacion',
    format: formats.STRING,
    apiField: 'clave',
    lsField: LsSubscriptionTableFields.GRAPH,
    lsFormat: null,
    width: 'auto'
  },
  NUMBER_OPER: {
    label: labels.NUMBER_OPER,
    align: alignment.RIGHT,
    name: 'Numero de operaciones',
    format: formats.INT,
    apiField: 'numeroOperacion',
    lsField: LsSubscriptionTableFields.GRAPH,
    lsFormat: null,
    width: 'auto'
  },
  VOLUME_PARTICIPATION: {
    label: labels.VOLUME,
    align: alignment.RIGHT,
    name: 'Volumen',
    format: formats.INT,
    apiField: 'volumen',
    lsField: LsSubscriptionTableFields.GRAPH,
    lsFormat: null,
    width: 'auto'
  },
  PARTICIPATION_SHARE: {
    label: labels.PARTICIPATION_SHARE,
    align: alignment.RIGHT,
    name: 'Importe',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'porcentajeParticipacion',
    lsField: LsSubscriptionTableFields.GRAPH,
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  WEEKLY_NUMBER: {
    label: labels.WEEKLY_NUMBER,
    align: alignment.RIGHT,
    name: 'semunalNumber',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'rendimientoSemanal',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  MONTHLY_NUMBER: {
    label: labels.MONTHLY_NUMBER,
    align: alignment.RIGHT,
    name: 'monthlyNumber',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'rendimientoMensual',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  ANNUAL_NUMBER: {
    label: labels.ANNUAL_NUMBER,
    align: alignment.RIGHT,
    name: 'annualNumber',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'rendimientoAnual',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  ANNUAL_YIELD_NUMBER: {
    label: labels.ANNUAL_YIELD_NUMBER,
    align: alignment.RIGHT,
    name: 'rendEnAnoNumber',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'rendimientoAlAnio',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  ACCUMULATED_VOLUME: {
    label: labels.ACCUMULATED_VOLUME,
    align: alignment.RIGHT,
    name: 'accumulatedVolume',
    format: formats.INT,
    apiField: 'volumenTotal',
    lsField: LsSubscriptionTableFields.VOLUMEN_TOTAL,
    lsFormat: null,
    width: 'auto'
  },
  CURRENT_LEVEL: {
    label: labels.CURRENT_LEVEL,
    align: alignment.RIGHT,
    name: 'currentLevel',
    format: formats.FLOAT,
    apiField: 'valorActual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  CURRENT_LEVEL_TEXT: {
    label: labels.CURRENT_LEVEL,
    align: alignment.RIGHT,
    name: 'valorTextoActual',
    format: formats.STRING,
    apiField: 'valorTextoActual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PURCHASE_CASH: {
    label: labels.PURCHASE_CASH,
    align: alignment.RIGHT,
    name: 'purchaseCash',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'cashCompra',
    lsField: LsSubscriptionTableFields.BUY_CASH,
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_LEVEL: {
    label: labels.PREVIOUS_LEVEL,
    align: alignment.RIGHT,
    name: 'previousLevel',
    format: formats.FLOAT,
    apiField: 'valorAnterior',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_LEVEL_TEXT: {
    label: labels.PREVIOUS_LEVEL,
    align: alignment.RIGHT,
    name: 'valorTextoAnterior',
    format: formats.STRING,
    apiField: 'valorTextoAnterior',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SALE_CASH: {
    label: labels.SALE_CASH,
    align: alignment.RIGHT,
    name: 'saleCash',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'cashVenta',
    lsField: LsSubscriptionTableFields.SALE_CASH,
    lsFormat: null,
    width: 'auto'
  },
  FRECUENCY: {
    label: labels.FRECUENCY,
    align: alignment.RIGHT,
    name: 'frecuency',
    format: formats.STRING,
    apiField: 'frecuencia',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PURCHASE_CASH_24HRS: {
    label: labels.PURCHASE_CASH_24HRS,
    align: alignment.RIGHT,
    name: 'purchaseCash24hrs',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'compra24hrs',
    lsField: LsSubscriptionTableFields.BUY_24HR,
    lsFormat: null,
    width: 'auto'
  },
  UNITS: {
    label: labels.UNITS,
    align: alignment.RIGHT,
    name: 'units',
    format: formats.STRING,
    apiField: 'unidad',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SALE_CASH_24HRS: {
    label: labels.SALE_CASH_24HRS,
    align: alignment.RIGHT,
    name: 'saleCash24hrs',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'venta24hrs',
    lsField: LsSubscriptionTableFields.SALE_24HR,
    lsFormat: null,
    width: 'auto'
  },
  LAST_UPDATE: {
    label: labels.LAST_UPDATE,
    align: alignment.RIGHT,
    name: 'lastUpdate',
    format: formats.DATE,
    apiField: 'ultimaFecha',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PURCHASE_CASH_48HRS: {
    label: labels.PURCHASE_CASH_48HRS,
    align: alignment.RIGHT,
    name: 'purchaseCash48hrs',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'compra48hrs',
    lsField: LsSubscriptionTableFields.BUY_48HR,
    lsFormat: null,
    width: 'auto'
  },
  SERIE_TYPE: {
    label: labels.SERIE_TYPE,
    align: alignment.RIGHT,
    name: 'serieType',
    format: formats.STRING,
    apiField: 'tipoSerie',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SALE_CASH_48HRS: {
    label: labels.SALE_CASH_48HRS,
    align: alignment.RIGHT,
    name: 'saleCash48hrs',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'venta48hrs',
    lsField: LsSubscriptionTableFields.SALE_48HR,
    lsFormat: null,
    width: 'auto'
  },
  EXPIRATION: {
    label: labels.EXPIRATION,
    align: alignment.LEFT,
    name: 'expiration',
    format: formats.STRING,
    apiField: 'vencimiento',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  AVAILABILITY: {
    label: labels.AVAILABILITY,
    align: alignment.LEFT,
    name: 'availability',
    format: formats.STRING,
    apiField: 'diponibilidad',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SOURCE: {
    label: labels.SOURCE,
    align: alignment.LEFT,
    name: 'source',
    format: formats.STRING,
    apiField: 'fuente',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  BUTTON: {
    label: labels.INSTRUMENT,
    align: alignment.LEFT,
    name: 'Button',
    format: formats.BUTTON,
    apiField: 'instrumento',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PORTFOLIO: {
    label: labels.PORTFOLIO,
    align: alignment.RIGHT,
    name: 'cartera',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'cartera',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  REND_YEAR_ANNUALIZED: {
    label: labels.REND_YEAR_ANNUALIZED,
    align: alignment.RIGHT,
    name: 'rendYearAnnualized',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'rendimientoAnualAnualizado',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  REND_MONTHLY_ANNUALIZED: {
    label: labels.REND_MONTHLY_ANNUALIZED,
    align: alignment.RIGHT,
    name: 'rendMonthlyAnnualized',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'rendimientoMensualAnualizado',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  REND_WEEKLY_ANNUALIZED: {
    label: labels.REND_WEEKLY_ANNUALIZED,
    align: alignment.RIGHT,
    name: 'rendWeeklyAnnualized',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'rendimientoSemanalAnualizado',
    lsField: '',
    lsFormat: null,
    width: 'auto',
    isPercentage: true
  },
  LAST_DONE: {
    label: labels.LAST_DONE,
    align: alignment.RIGHT,
    name: 'lastDone',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'ultimoHecho',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  LAST_DONE_DATE: {
    label: labels.LAST_DONE_DATE,
    align: alignment.RIGHT,
    name: 'lastDoneDate',
    format: formats.FORMATDATEYYYY,
    apiField: 'fechaUltimoHecho',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  LAST_DONE_TIME: {
    label: labels.LAST_DONE_TIME,
    align: alignment.RIGHT,
    name: 'lastDoneTime',
    format: formats.TIME_FULL,
    apiField: 'fechaUltimoHecho',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  BUY_POSITION: {
    label: labels.BUY_POSITION,
    align: alignment.RIGHT,
    name: 'buyPosition',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'posturaCompra',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SALE_POSITION: {
    label: labels.SALE_POSITION,
    align: alignment.RIGHT,
    name: 'salePosition',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'posturaVenta',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  OPENING_RATES: {
    label: labels.OPENING_RATES,
    align: alignment.RIGHT,
    name: 'openingRate',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'tasaApertura',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MAXIMUN_RATES_DAY: {
    label: labels.MAXIMUN_RATES_DAY,
    align: alignment.RIGHT,
    name: 'maximumRateDay',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'tasaMaximaDia',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MINIMUN_RATES_DAY: {
    label: labels.MINIMUN_RATES_DAY,
    align: alignment.RIGHT,
    name: 'minimumRateDay',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'tasaMinimaDia',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PRE_CLOSING_RATES: {
    label: labels.PRE_CLOSING_RATES,
    align: alignment.RIGHT,
    name: 'preClosingRate',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'tasaCierrePrevio',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  NET_RATE: {
    label: labels.NET_RATE,
    align: alignment.RIGHT,
    name: 'netRate',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'tasaNeta',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  YIELD: {
    label: labels.YIELD,
    align: alignment.RIGHT,
    name: 'yield',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'yield',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  DONE_MAX_12M: {
    label: labels.DONE_MAX_12M,
    align: alignment.RIGHT,
    name: 'doneMax12M',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'hechoMax12M',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  DONE_DATE_MAX_12M: {
    label: labels.DONE_DATE_MAX_12M,
    align: alignment.RIGHT,
    name: 'doneDateMax12M',
    format: formats.DATE,
    apiField: 'fechaHechoMax12M',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  DONE_MIN_12M: {
    label: labels.DONE_MIN_12M,
    align: alignment.RIGHT,
    name: 'doneMin12M',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'hechoMin12M',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  DONE_DATE_MIN_12M: {
    label: labels.DONE_DATE_MIN_12M,
    align: alignment.RIGHT,
    name: 'doneDateMin12M',
    format: formats.DATE,
    apiField: 'fechaHechoMin12M',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MARKET_SURCHARGE: {
    label: labels.MARKET_SURCHARGE,
    align: alignment.RIGHT,
    name: 'marketSurcharge',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'sobretasa',
    lsField: '',
    lsFormat: null,
    width: '160'
  },
  CURRENT_RATE: {
    label: labels.CURRENT_RATE,
    align: alignment.RIGHT,
    name: 'currentRate',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'tasaActual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  CURRENT_RATE_DATE: {
    label: labels.CURRENT_RATE_DATE,
    align: alignment.RIGHT,
    name: 'currentRateDate',
    format: formats.DATE,
    apiField: 'fechaTasaActual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_CLOSING_RATE: {
    label: labels.PREVIOUS_CLOSING_RATE,
    align: alignment.RIGHT,
    name: 'previousClosingRate',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'tasaActual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MAXIMUN_VARIATIOS: {
    label: labels.MAXIMUN_VARIATIOS,
    align: alignment.RIGHT,
    name: 'maximumVariations',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'variacionesMaximo',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MINIMUN_VARIATIOS: {
    label: labels.MINIMUN_VARIATIOS,
    align: alignment.RIGHT,
    name: 'minimumVariations',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'variacionesMinimo',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  OPENING_VARIATIOS: {
    label: labels.OPENING_VARIATIOS,
    align: alignment.RIGHT,
    name: 'openingVariations',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'variacionesApertura',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  CLEAN_PRICE_24H: {
    label: labels.CLEAN_PRICE_24H,
    align: alignment.RIGHT,
    name: 'cleanPrice',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'precioLimpio',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  DIRTY_PRICE_24H: {
    label: labels.DIRTY_PRICE_24H,
    align: alignment.RIGHT,
    name: 'dirtyPrice',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'precioSucio',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PERCENTAGE_CHANGE: {
    label: labels.PERCENTAGE_CHANGE,
    align: alignment.RIGHT,
    name: 'percentageChange',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'variacionPorcentual',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MAX_PRICE_OF_THE_DAY: {
    label: labels.MAX_PRICE_OF_THE_DAY,
    align: alignment.RIGHT,
    name: 'maxPriceOfTheDay',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'maximoDia',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  MIN_PRICE_OF_THE_DAY: {
    label: labels.MIN_PRICE_OF_THE_DAY,
    align: alignment.RIGHT,
    name: 'minPriceOfTheDay',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'minimoDia',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SECTOR: {
    label: labels.SECTOR,
    align: alignment.LEFT,
    name: 'sector',
    format: formats.STRING,
    apiField: 'sector',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  SEVEN_DAY_QUOTE: {
    label: labels.SEVEN_DAY_QUOTE,
    align: alignment.RIGHT,
    name: 'sevenDayQuote',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'cotizacion7D',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  THIRTY_DAY_QUOTE: {
    label: labels.THIRTY_DAY_QUOTE,
    align: alignment.RIGHT,
    name: 'thirtyDayQuote',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'cotizacion30D',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  },
  PREVIOUS_YEAR_PRICE: {
    label: labels.PREVIOUS_YEAR_PRICE,
    align: alignment.RIGHT,
    name: 'previousYearPrice',
    format: formats.FLOAT,
    decimals: 2,
    apiField: 'cotizacionAnioAnterior',
    lsField: '',
    lsFormat: null,
    width: 'auto'
  }
}

export const defaultTableSort = {
  type: sortTypes.DESCENDING,
  property: ''
}
