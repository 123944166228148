import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_300_LIGHT, TREND_ACENT_200_LIGHT, TREND_DOWN_200_LIGHT } from 'constants/colorsLightMode'

import { LINE_HEIGHT_24, FONT_WEIGHT_REGULAR, FONT_SIZE_16, LETTER_SPACING_03 } from '../../constanst'

const muiInputLabel: {
  defaultProps?: ComponentsProps['MuiInputLabel']
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputLabel']
  variants?: ComponentsVariants['MuiInputLabel']
} = {
  styleOverrides: {
    root: {
      fontFamily: 'Roboto Flex',
      fontWeight: FONT_WEIGHT_REGULAR,
      fontSize: FONT_SIZE_16,
      letterSpacing: LETTER_SPACING_03,
      lineHeight: LINE_HEIGHT_24,
      color: DEEP_300_LIGHT,
      '&.Mui-error': {
        color: `${TREND_DOWN_200_LIGHT} !important`
      },
      '&.Mui-focused': {
        color: TREND_ACENT_200_LIGHT
      }
    }
  },
  variants: []
}

export default muiInputLabel
