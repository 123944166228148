import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material'

const variantStyles = {
  fontStyle: 'normal',
  fontSize: 14,
  lineHeight: '19px',
  padding: '0px 16px',
  margin: 0,
  minWidth: 'auto',
  width: 'auto'
}

const useStyles = makeStyles({
  root: { padding: 0, margin: 0, width: 'auto', minWidth: 'auto' },
  containerAnchorOriginBottomLeft: {
    bottom: '80px'
  }
})

export default useStyles

export const SSnackbarProvider = styled(SnackbarProvider)(({ theme, ...props }) => ({
  ...variantStyles,
  backgroundColor: `${theme.palette.background.default} !important`,
  color: `${theme.palette.text.primary} !important`
}))
