import { createTheme } from '@mui/material/styles'

import { baseThemeOptions } from './base-theme-options'
import { darkThemeOptions } from './dark-theme-options'
import { lightThemeOptions } from './light-theme-options'

const createCustomTheme = (themePreference: 'dark' | 'light') => {
  const theme = createTheme(baseThemeOptions, themePreference === 'dark' ? darkThemeOptions : lightThemeOptions)

  return theme
}

export default createCustomTheme
