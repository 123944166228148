export const DARKER_GREY = '#1c2129'
export const DARKER_GREY_ALPHA = '#1c212900'
export const DARK_GREY = '#232933'
export const DARK_GREY_ALPHA = '#23293380'
export const NEW_DARK_GREY = '#2e3135'
export const NEUTRAL_GREY = '#363d47'
export const DARK = '#3F4247'
export const DAT_GREY = '#374252'
export const GREY = '#3e4652'
export const GREY_TWO = '#87A0AF'
export const LIGHT_GREY = '#878e99'
export const ALMOST_WHITE = '#c6d1e0'
export const INCREASING_GREEN = '#7CF9B0'
export const INCREASING_GREEN_RGBA = 'rgba(78, 194, 171, 0.2)'
export const DECREASING_RED = '#F96464'
export const DECREASING_RED_RGBA = 'rgba(204, 78, 73, 0.2)'
export const ACCENT_BLUE = '#7a98eb'
export const ACCENT_BLUE_ALPHA = '#6387eb'
export const ACCENT_BLUE_BETA = '#455685'
export const ACCENT_BLUE_RGBA = 'rgba(122, 152, 235, 0.2)'
export const RANDOM_BLUE = '#5cd3ff'
export const BACKGROUND_COLOR = '#171a1f'
export const DARK_BACKGROUND_COLOR = '#0c0d0f'
export const LIGTHER_GREY = '#cedaeb'
export const TIMESTAMP_GREY = '#71787a'
export const WHITE = '#ffffff'
export const INDICATOR_BLUE = '#0144e3'
export const TICKER_TEXT_COLOR = '#fafeff'
export const BLACK = '#000000'
export const BLACK_APLHA = '#00000020'
export const LIGHTEST_GRAY = '#a2aab8'
export const BOTICELLI = '#c6d1e0'
export const YELLOW = '#fff202'
export const PURPLE = '#6B54D6'
export const SALMON = '#ED9077'
export const GREEN = '#A3C844'
export const PINK = '#F47CA3'
export const CYAN_BLUE = '#93D5F3'
export const SPINDLE = '#B4BDCC'
export const LIME_GREEN = '#c7de8e'
export const LILAC_PURPLE = '#a698e6'
export const GOLD = '#ffbe5d'
export const LIGHT_CYAN_BLUE = '#bee5f7'
export const ORANGE = '#ffd89d'
export const LIGHT_ORANGE = '#fc9d72'
export const LINK_WATER = '#c6d0e0'
export const HEATHER_LIGHT = '#b4bdcc'
export const CONTESSA = '#cc7874'
export const SHUTTLE_GRAY = '#5d6269'
export const HAWKES_BLUE = '#CFDAEB'
export const BRIGHT_ORANGE = '#FFE048'
export const NAVY_BLUE_LIGHT = '#374f91'
export const NAVY_BLUE = '#3c6cef'
export const LIGHT_SLATE_GREY = '#8A9199'
export const BRIGHT_GREY = '#5B5F66'
export const CHINESE_BLACK = '#0B1A0C'
export const BLUE_DARK = '#1B212B'
export const BEAU_SILVER = '#CFD9E6'
export const LIGHT_BLUE = '#A0C4FF'
export const DARK_GRAYISH = '#45484D'
export const ERROR_MESSAGE = '#F96464'
export const ERROR_MESSAGE_LIGHT = '#F8B0C8'
export const SUCCESS_MESSAGE_BG = '#54C2AB'
export const CORAL_GRAY = '#5C6166'
export const CADET_SILVER_GRAY = '#B8C1CC'
export const SALT_SILVER_GRAY = '#737980'
export const EBONY = '#2A313E'
export const PATTENS_BLUE = '#DDF2FD'
export const LIGHT_GRAYISH_BLUE = '#404E66'
export const LIGHT_GRAYISH_BLUE_90 = 'rgba(64,78,102,0.9)'
export const LIGHT_GRAYISH_BLUE_60 = 'rgba(64,78,102,0.6)'
export const SPRING_GREEN = '#7CF9B0'
export const BUNKER = '#1C2129'
export const LINKED_WATER = '#C6D1E0'
export const CORNFLOWER_BLUE = '#7A98EB'
export const CLOUD_BURST = '#363D47'
export const BLACK_PEARL = '#15191F'
export const APRICOT = '#FDC4AA'
export const BLUETIFUL = '#3C6BF0'
export const BABY_BLUE = '#BAD4FF'
export const STORM_GREY = '#727882'
export const BLACK_APLHA_75 = '#000000BF'
export const ALICE_BLUE = '#EDF4FF'
export const WAIKAWA_GREY = '#607599'
export const NEUTRAL_GREY_ALPHA_20 = '#363D4700'
export const NEUTRAL_GREY_ALPHA_50 = '#363d4780'
export const GREY_LOW = 'rgb(133 135 137)'
