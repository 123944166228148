import MuiButton from './components/componentMuiButton'
import MuiIconButton from './components/componentMuiIconButton'
import MuiTextfield from './components/componentMuiTextfield'
import MuiOutlinedInput from './components/componentMuiOutlinedInput'
import MuiListItemButton from './components/componentMuiListItemButton'
import MuiListItemText from './components/componentMuiListItemText'
import MuiSelect from './components/componentMuiSelect'
import MuiInputLabel from './components/componentMuiInputLabel'
import MuiFormControl from './components/componentMuiFormControl'
import MuiLink from './components/componentMuiLink'
import MuiCircularProgress from './components/componentMuiCircularProgress'
import MuiChip from './components/componentMuiChip'
import MuiMenu from './components/componentMuiMenu'
import MuiMenuItem from './components/componentMuiMenuItem'
import MuiCheckbox from './components/componentMuiCheckbox'
import MuiFormControlLabel from './components/componentMuiFormControlLabel'
import MuiPagination from './components/componentMuiPagination'
import MuiTableCell from './components/componentMuiTableCell'
import MuiTableHead from './components/componentMuiTableHead'
import MuiTableRow from './components/componentMuiTableRow'
import MuiPaper from './components/componentMuiPaper'
import MuiTab from './components/componentMuiTab'
import MuiTooltip from './components/componentMuiTooltip'
import MuiFormHelperText from './components/componentMuiFormHelperText'
import MuiPickersDay from './components/componentMuiPickersDay'
import MuiListItemIcon from './components/componentMuiListItemIcon'
import MuiDataGrid from './components/componentMuiDataGrid'

const light = {
  MuiButton,
  MuiIconButton,
  MuiTextfield,
  MuiOutlinedInput,
  MuiListItemButton,
  MuiListItemText,
  MuiSelect,
  MuiInputLabel,
  MuiFormControl,
  MuiLink,
  MuiCircularProgress,
  MuiChip,
  MuiMenu,
  MuiMenuItem,
  MuiCheckbox,
  MuiFormControlLabel,
  MuiPagination,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTab,
  MuiPaper,
  MuiTooltip,
  MuiFormHelperText,
  MuiPickersDay,
  MuiListItemIcon,
  MuiDataGrid,
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true
    }
  }
}

export default light
