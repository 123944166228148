import { PaletteOptions } from '@mui/material/styles'

import {
  TREND_ACENT_100,
  TREND_ACENT_200,
  TREND_ACENT_300,
  TREND_UP_100,
  TREND_UP_200,
  TREND_UP_300,
  TREND_DOWN_100,
  TREND_DOWN_200,
  TREND_DOWN_300,
  DEEP_100,
  DEEP_200,
  DEEP_300,
  DEEP_400,
  DEEP_500,
  DEEP_600,
  DEEP_700,
  DEEP_800,
  DEEP_900,
  CHART_MINT
} from 'constants/colorsDarkMode'

// Augment the palette to include a violet color
declare module '@mui/material/styles' {
  interface Palette {
    green: Palette['primary']
  }

  interface PaletteOptions {
    green?: PaletteOptions['primary']
  }
}

export const neutral = {
  100: DEEP_100,
  200: DEEP_200,
  300: DEEP_300,
  400: DEEP_400,
  500: DEEP_500,
  600: DEEP_600,
  700: DEEP_700,
  800: DEEP_800,
  900: DEEP_900
}

export const background = {
  default: neutral[800],
  paper: neutral[700]
}

export const divider = neutral[500]

export const primary = {
  main: TREND_ACENT_100,
  light: TREND_ACENT_200,
  dark: TREND_ACENT_300,
  contrastText: neutral[200]
}

export const secondary = {
  main: neutral[300],
  light: neutral[400],
  dark: neutral[500],
  contrastText: neutral[100]
}

export const success = {
  main: TREND_UP_100,
  light: TREND_UP_200,
  dark: TREND_UP_300,
  contrastText: neutral[900]
}

export const info = {
  main: TREND_ACENT_100,
  light: TREND_ACENT_200,
  dark: TREND_ACENT_300,
  contrastText: neutral[900]
}

export const warning = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText: neutral[900]
}

export const error = {
  main: TREND_DOWN_100,
  light: TREND_DOWN_200,
  dark: TREND_DOWN_300,
  contrastText: neutral[900]
}

export const grey = {
  100: neutral[100],
  200: neutral[200],
  300: neutral[300],
  400: DEEP_400,
  500: neutral[800],
  600: DEEP_600,
  800: neutral[600],
  900: DEEP_900
}

export const blue = {
  100: TREND_DOWN_100
}

export const text = {
  primary: neutral[100],
  secondary: neutral[300],
  disabled: 'rgba(255, 255, 255, 0.48)'
}

const dark: PaletteOptions = {
  mode: 'dark',
  action: {
    active: neutral[600],
    focus: 'rgba(55, 65, 81, 0.12)',
    selected: primary.main,
    disabledBackground: 'rgba(55, 65, 81, 0.12)',
    disabled: 'rgba(55, 65, 81, 0.26)'
  },
  background,
  divider,
  error,
  info,
  grey,
  primary,
  secondary,
  success,
  text,
  warning,
  green: {
    main: CHART_MINT
  }
}

export default dark
