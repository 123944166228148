import { Theme, ComponentsOverrides, ComponentsVariants, ComponentsProps } from '@mui/material/styles'

import { DEEP_300, DEEP_400, DEEP_600 } from 'constants/colorsDarkMode'

const MuiIconButton: {
  defaultProps?: ComponentsProps['MuiIconButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton']
  variants?: ComponentsVariants['MuiIconButton']
} = {
  styleOverrides: {
    root: {
      borderRadius: 50,
      padding: 8,
      backgroundColor: DEEP_600,
      color: DEEP_300,
      fontSize: '12px',
      '&:active, &:focus': {
        backgroundColor: DEEP_600
      },
      '&:hover': {
        backgroundColor: DEEP_400
      },
      '&:disabled': {
        color: DEEP_400
      },
      '& .MuiSvgIcon-root': {
        margin: 5
      }
    },
    sizeSmall: {
      padding: 4
    }
  }
}

export default MuiIconButton
