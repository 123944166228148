import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { TREND_ACENT_200_LIGHT, DEEP_300_LIGHT, DEEP_400_LIGHT } from 'constants/colorsLightMode'

const muiCheckbox: {
  defaultProps?: ComponentsProps['MuiCheckbox']
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox']
  variants?: ComponentsVariants['MuiCheckbox']
} = {
  styleOverrides: {
    root: {
      color: DEEP_300_LIGHT,
      '&.Mui-checked': {
        color: TREND_ACENT_200_LIGHT
      },
      '&.Mui-disabled': {
        color: DEEP_400_LIGHT
      }
    }
  },
  variants: []
}

export default muiCheckbox
