/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Typography } from '@mui/material'
import SyncProblemRoundedIcon from '@mui/icons-material/SyncProblemRounded'

import { hasRefreshed } from 'utils/helpers'
import { DEEP_300 } from 'constants/colorsDarkMode'

type FallbackProps = {
  error: Record<string, any>
}

function ErrorFallback({ error }: FallbackProps) {
  const { message } = error
  // Refresh the view if the error is about "Loading chunk" and if it sn't refreshed yet.
  if (message.includes('Loading chunk') && !hasRefreshed) {
    sessionStorage.setItem('hub-refreshed', 'true')
    // @ts-ignore
    window.location.reload(true) // Force the reload
  }
  return (
    <Box height="100vh" width="100vw" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <SyncProblemRoundedIcon sx={{ color: DEEP_300, fontSize: '150px' }} />
      <Typography sx={{ color: DEEP_300, fontSize: '40px' }} variant="h4">
        Algo salió mal :(
      </Typography>
      <Button
        sx={{ marginTop: '20px' }}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => window.location.reload()}
      >
        Actualizar
      </Button>
    </Box>
  )
}

export default ErrorFallback
