const INDEX_FTSE_UNIQUE_KEYS = [
  '2048/50/7/CH80',
  '2048/50/7/WIAUS',
  '2048/50/7/WIAUT',
  '2048/50/7/WIBEL',
  '2048/50/7/WIBRA',
  '2048/50/7/WICAN',
  '2048/50/7/WICHE',
  '2048/50/7/WICHN',
  '2048/50/7/WICOL',
  '2048/50/7/WICZH',
  '2048/50/7/WIDEN',
  '2048/50/7/WIDEU',
  '2048/50/7/WIEGY',
  '2048/50/7/WIESP',
  '2048/50/7/WIFIN',
  '2048/50/7/WIFRA',
  '2048/50/7/WIGBR',
  '2048/50/7/WIGRC',
  '2048/50/7/WIHKG',
  '2048/50/7/WIHUN',
  '2048/50/7/WIIDN',
  '2048/50/7/WIIND',
  '2048/50/7/WIIRL',
  '2048/50/7/WIISR',
  '2048/50/7/WIITA',
  '2048/50/7/WIJPN',
  '2048/50/7/WIKOR',
  '2048/50/7/WIKWT',
  '2048/50/7/WIMAL',
  '2048/50/7/WIMEX',
  '2048/50/7/WINLD',
  '2048/50/7/WINOR',
  '2048/50/7/WINZL',
  '2048/50/7/WIPAK',
  '2048/50/7/WIPHL',
  '2048/50/7/WIPOL',
  '2048/50/7/WIPTL',
  '2048/50/7/WIQAT',
  '2048/50/7/WIRUS',
  '2048/50/7/WISGP',
  '2048/50/7/WISWE',
  '2048/50/7/WITHA',
  '2048/50/7/WITUR',
  '2048/50/7/WITWN',
  '2048/50/7/WIUAE',
  '2048/50/7/WIUSA',
  '2048/50/7/WIZAF',
  '2048/50/7/WORLDS',
  '2048/50/7/UKX',
  '2048/50/7/FTBIVA'
]

export default INDEX_FTSE_UNIQUE_KEYS
