import { Theme, ComponentsOverrides } from '@mui/material/styles'

import { DEEP_100, DEEP_200, DEEP_300, DEEP_500, TREND_ACENT_100 } from 'constants/colorsDarkMode'

const MuiPagination: {
  styleOverrides?: ComponentsOverrides<Theme>['MuiPagination']
} = {
  styleOverrides: {
    root: {
      '& ul > li:not(:first-of-type):not(:last-child) > button:not(.Mui-selected)': {
        backgroundColor: DEEP_500,
        color: DEEP_200
      },
      '& .MuiPaginationItem-root': {
        border: 0,
        color: DEEP_200,
        minWidth: 20,
        height: 20,
        fontWeight: 500,
        fontSize: 14,
        '& .MuiSvgIcon-root': {
          color: DEEP_300,
          fontSize: '1.75rem'
        }
      },
      '& .MuiPaginationItem-page.Mui-selected': {
        color: DEEP_100,
        backgroundColor: TREND_ACENT_100
      },
      '&.MuiPaginationItem-rounded': {
        color: DEEP_100
      },
      '&.MuiPaginationItem-page.Mui-disabled': {
        color: DEEP_100
      }
    }
  }
}

export default MuiPagination
