export const FONT_SIZE_10 = 10
export const FONT_SIZE_12 = 12
export const FONT_SIZE_13 = 13
export const FONT_SIZE_14 = 14
export const FONT_SIZE_16 = 16
export const FONT_SIZE_18 = 18
export const FONT_SIZE_20 = 20
export const FONT_SIZE_24 = 24

export const FONT_SIZE_SMALL = 12
export const FONT_SIZE_MEDIUM = 14
export const FONT_SIZE_LARGE = 16

export const BORDER_RADIUS_BUTTON = 24

export const LETTER_SPACING_02 = 0.2
export const LETTER_SPACING_03 = 0.3
export const LETTER_SPACING_05 = 0.5
export const LETTER_SPACING_075 = 0.75

export const FONT_WEIGHT_LIGHT = 300
export const FONT_WEIGHT_REGULAR = 400
export const FONT_WEIGHT_MEDIUM = 500
export const FONT_WEIGHT_SEMI = 600
export const FONT_WEIGHT_500 = '500'

export const LINE_HEIGHT_16 = '16px'
export const LINE_HEIGHT_20 = '20px'
export const LINE_HEIGHT_24 = '24px'
export const LINE_HEIGHT_28 = '28px'
export const LINE_HEIGHT_32 = '32px'
