import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_700_LIGHT } from 'constants/colorsLightMode'
import { heightRowHeaderTable } from 'constants/theme'

const MuiTableHead: {
  defaultProps?: ComponentsProps['MuiTableHead']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableHead']
  variants?: ComponentsVariants['MuiTableHead']
} = {
  styleOverrides: {
    root: {
      backgroundColor: DEEP_700_LIGHT,
      fontFamily: 'Roboto Flex,Helvetica,Arial,sans-serif',
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '12px',
      letterSpacing: '0.25px',
      height: `${heightRowHeaderTable}px`,
      borderBottom: '2px solid #1d1d1d !important',
    }
  }
}

export default MuiTableHead
