import App from './App'
import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import i18n from './i18n/index'
import store from './ducks/store'
import React from 'react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'
import { isMobileBrowser } from 'utils/helpers'
import UserService from './services/auth'
import { getCurrentUser } from 'utils/user'

const url = window.location.origin

if (
  String(import.meta.env.VITE_SENTRY_ENABLED) === 'true' &&
  !isMobileBrowser() &&
  !String(url).includes('localhost')
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_KEY,
    environment: import.meta.env.VITE_ENVIRONMENT,
    release: import.meta.env.VITE_VERSION,
    ignoreErrors: ['ChunkLoadError', 'e.value', 'TypeError'],
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.25,
    beforeSend(event) {
      const { email } = getCurrentUser() || {}
      if (email) {
        event.user = {
          ...event.user,
          email
        }
      }
      return event
    }
  })
}

const root = document.getElementById('root')
const urlParams = new URLSearchParams(window.location.search)
const isItesm = urlParams.get('client') === 'itesm' || sessionStorage.getItem('isUserItesm') === 'true'
if (urlParams.get('test') === 'md2') sessionStorage.setItem('testMD2', 'true')
else sessionStorage.removeItem('testMD2')

if (root !== null) {
  if (isItesm)
    UserService.initKeycloak(
      ReactDOM.render(
        <React.StrictMode>
          <I18nextProvider {...{ i18n }}>
            <ReduxProvider {...{ store }}>
              <App />
            </ReduxProvider>
          </I18nextProvider>
        </React.StrictMode>,
        root
      )
    )

  if (!isItesm)
    ReactDOM.render(
      <React.StrictMode>
        <I18nextProvider {...{ i18n }}>
          <ReduxProvider {...{ store }}>
            <App />
          </ReduxProvider>
        </I18nextProvider>
      </React.StrictMode>,
      root
    )
}
