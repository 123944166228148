const ACTION_TYPES = {
  START_DRAGGING: 'START_DRAGGING',
  STOP_DRAGGING: 'STOP_DRAGGING',
  SET_INSTRUMENTS: 'SET_INSTRUMENTS',
  START_DROP: 'START_DROP',
  STOP_DROP: 'STOP_DROP',
  ENABLE_WIDGETS: 'ENABLE_WIDGETS'
}

export default ACTION_TYPES
