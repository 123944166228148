const ACTION_TYPES = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',

  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  UPDATE_CONNECTION_STATUS: 'UPDATE_CONNECTION_STATUS',

  GET_USER_SETTINGS_SUCCESS: 'GET_USER_SETTINGS_SUCCESS',
  GET_USER_SETTINGS_FAILED: 'GET_USER_SETTINGS_FAILED'
}

export default ACTION_TYPES
