import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { DEEP_700 } from 'constants/colorsDarkMode'

const muiTableHead: {
  defaultProps?: ComponentsProps['MuiTableHead']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableHead']
  variants?: ComponentsVariants['MuiTableHead']
} = {
  styleOverrides: {
    root: {
      backgroundColor: DEEP_700
    }
  }
}

export default muiTableHead
