const ACTION_TYPES = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  SET_INSTRUMENT_SUCCESS: 'SET_INSTRUMENT_SUCCESS',
  SET_INSTRUMENT_FAILED: 'SET_INSTRUMENT_FAILED',
  GET_INSTRUMENT_SUCCESS: 'GET_INSTRUMENT_SUCCESS',
  GET_INSTRUMENT_FAILED: 'GET_INSTRUMENT_FAILED'
}

export default ACTION_TYPES
