import i18n from 'i18n/index'
import {
  PURPLE,
  GREEN,
  SALMON,
  PINK,
  CYAN_BLUE,
  LIGHT_GREY,
  BRIGHT_ORANGE,
  SPRING_GREEN,
  DECREASING_RED,
  BABY_BLUE
} from 'constants/colors'
import { Tags } from 'apis/models/markets'
import nameReportsBrokerageHouses from './namesSymbols'
import { FiltersNews, TokenDataUser, QueriesSelectTap, FilterNewQueries, FiltersBrokerageFirmsReport } from './types'

export const LOGO_TEXT = 'Infosel'
export const LOGO_HUB_TEXT = 'HUB'
export const KEY_CALENDAR_COUNTRY = 'country'

export const WELCOME_PAGE_TEXT = 'Infosel'

export const SHOW_LABELS = ['cartera']

export const cardTypes = {
  TABLE: 1,
  CHART: 2,
  NEWS: 3,
  PREDEFINED_TABLE: 4,
  BID_DEPTH_TABLE: 6,
  COMPARATIVE_TABLE: 7,
  BROKERAGE_FIRMS_REPORT: 8,
  QUERIES: 9,
  NEW_QUERIES: 10,
  CALENDAR: 11,
  SNAPSHOT: 12,
  FINANCIAL: 13,
  SECTORIAL: 14
}

export const HttpStatusCode = {
  OK: 200,
  MultipleChoices: 300,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  InternalServerError: 500,
  UnprocessableEntity: 422
}

export const HttpReadyState = {
  UNSENT: 0,
  OPENED: 1,
  HEADERS_RECEIVED: 2,
  LOADING: 3,
  DONE: 4
}

export const ErrorMessage = {
  Default: 'Error while processing. Please try again.',
  [HttpStatusCode.NotFound]: '[404] This is not the web page you are looking for.',
  [HttpStatusCode.InternalServerError]: '[500] Sorry, something went wrong. Please try again, or refresh the page.',
  ParsingFailed: 'Error while parsing.',
  TokenExpired: 'Token has been expired.'
}

export const LsSubscriptionMode = {
  MERGE: 'MERGE',
  DISTINCT: 'DISTINCT',
  RAW: 'RAW',
  COMMAND: 'COMMAND'
}

export const EncodedCharacters = {
  ASTERISK: 'K__42__',
  AMPERSAND: 'K__38__',
  DASH: 'K__45__',
  SPACE: 'K__32__'
}

export const LsSubscriptionTableFields = {
  FOLIOBMV: 'NumOperaciones',
  FOLIOBIVA: 'FolioBIVA',
  CURRENT_PRICE: 'PrecioAcc',
  PREVIOUS_PRICE: 'PrecioUHA',
  CHANGE_PROCENTAGE: 'VariacionPorcentualAcc',
  CHANGE_UNIT: 'VariacionUnitaria',
  VOLUMEN_TOTAL: 'VolumenTotal',
  VOLUMEN_DONE: 'Volumen',
  LAST_VOLUMEN: 'VolumenUltimo',
  NYSE_NASDAQ_EQUITY_VOLUME_DONE: 'Hecho_Volumen',
  PURCHASE_VALUE: 'CompraPostura',
  PURCHASE: 'Compra',
  PURCHASE_VOLUME: 'VolCompraPostura',
  SALE_VALUE: 'VentaPostura',
  SALE: 'Venta',
  SALE_VOLUME: 'VolVentaPostura',
  HOUR: 'Hora',
  HOUR_COMPLETE: 'HoraCompleta',
  MAXIMUM: 'PrecioMaxDia',
  MINIMUM: 'PrecioMinDia',
  TOTAL_AMOUNT: 'ImporteTotal',
  TOTAL_AMOUNT_INDEX: 'ImporteTotal_IndiceSectorial',
  HOUR_INDEX: 'Hora_IndiceSectorial',
  DATE_INDEX: 'Fecha_IndiceSectorial',
  PREVIOUS_DATE_INDEX: 'FechaIndiceAnterior_IndiceSectorial',
  PREVIOUS_PRICE_INDEX: 'Cierre_IndiceSectorial',
  OPENING_PRICE: 'PrecioApertura',
  SAMPLE_ID: 'Muestra',
  DATE: 'Fecha',
  DATESTRING: 'Stringfecha',
  CURRENT: 'Actual',
  PREVIOUS: 'Anterior',
  CHANGE: 'Variacion',
  PERIODICITY: 'Periodicidad',
  PRICE: 'Precio',
  CHANGE_PERCENTAGE: 'VariacionPorcentual',
  DATE_UHA: 'FechaPrecioUHA',
  AMOUNT: 'Importe',
  LAST_AMOUNT: 'ImporteUltimo',
  NUMBER_OPERATION: 'NumOperaciones',
  SERIES_TRANSMITER: 'EmisoraSerie',
  NUM_OF_OPERATIONS: 'NumOperaciones',
  PEAK_AMOUNT: 'Importe',
  SELLER: 'DescripcionVendedor',
  BUYER: 'DescripcionComprador',
  LAST_TIME: 'HoraUltimo',
  LAST_DATE: 'FechaUltimo',
  FIXED_INCOME_TYPE_VALUE: 'DeudaTipoValor',
  FIXED_INCOME_KEY: 'DeudaClaveCotizacion',
  FIXED_INCOME_SERIE: 'DeudaSerie',
  FIXED_INCOME_DATE: 'DeudaFechaEmision',
  FIXED_INCOME_CLEAN_PRICE: 'DeudaPrecioLimpio',
  FIXED_INCOME_DIRTY_PRICE: 'DeudaPrecioSucio',
  FIXED_INCOME_DAYS_TO_WIN: 'DeudaDiasPorVencer',
  FIXED_INCOME_HOUR: 'DeudaHoraUltimoHecho',
  FIXED_INCOME_EXPIRATION_DATE: 'DeudaFechaVencimiento',
  FIXED_INCOME_PREVIOUS_PRICE: 'DeudaCierreAnterior',
  FIXED_INCOME_LAST_DONE: 'DeudaUltimoHecho',
  FIXED_INCOME_PORCENTUAL_VARIATION: 'DeudaVariacionPorcentual',
  FIXED_INCOME_UNIT_VARIATION: 'DeudaVariacionUnitaria',
  FIXED_INCOME_INTEREST_24: 'DeudaInteres24H',
  FIXED_INCOME_SCORE_FITCH: 'DeudaCalificacionFitch',
  FIXED_INCOME_SCORE_MOODY: 'DeudaCalificacionMoody',
  FIXED_INCOME_SCORE_SP: 'DeudaCalificacionSP',
  FIXED_INCOME_SCORE_HR: 'DeudaCalificacionHR',
  FIXED_INCOME_DAY_MAX: 'DeudaMaxDia',
  FIXED_INCOME_DAY_MIN: 'DeudaMinDia',
  FIXED_INCOME_PURCHASE_PRICE: 'DeudaPrecioCompra',
  FIXED_INCOME_SALE_PRICE: 'DeudaPrecioVenta',
  FIXED_INCOME_OPENING_PRICE: 'DeudaPrecioApertura',
  FIXED_INCOME_YIELD_RATE: 'DeudaTasaRendimiento',
  ISIN: 'isin',
  DAYS_TO_EXPIRE: 'fixedIncomeDaysToExpire',
  RELEASE_DATE: 'fixedIncomeReleaseDate',
  CLEAN_PRICE: 'fixedIncomeCleanPrice',
  MD_DIRTY_PRICE: 'fixedIncomeMDDirtyPrice',
  MD_CLEAN_PRICE: 'fixedIncomeMDCleanPrice',
  DIRTY_PRICE: 'fixedIncomeMDDirtyPrice',
  SURTAX_PIP: 'fixedIncomeSurtax',
  NYSE_PURCHASE_VALUE: 'PosturaCompra_Precio',
  NYSE_PURCHASE_VOLUME: 'PosturaCompra_Volumen',
  NYSE_SALE_VALUE: 'PosturaVenta_Precio',
  NYSE_SALE_VOLUME: 'PosturaVenta_Volumen',
  NYSE_SALE_DATE: 'PosturaVenta_Fecha',
  NYSE_SALE_TIME: 'PosturaVenta_Fecha',
  NYSE_PURCHASE_DATE: 'PosturaCompra_Fecha',
  NYSE_PURCHASE_TIME: 'PosturaCompra_Hora',
  NYSE_PURCHASE_STOCK: 'PosturaCompra_Bolsa',
  NYSE_SALE_STOCK: 'PosturaCompra_Hora',
  NYSE_STOCK_VALUE: 'Hecho_Fecha',
  NYSE_MIN_PRICE: 'PrecioMinimo',
  NYSE_MAX_PRICE: 'PrecioMaximo',
  ACTUAL_VALUE_PPP: 'ValorActualPPP',
  SIDE: 'Lado',
  PRICE_1: 'Precio1',
  PRICE_2: 'Precio2',
  PRICE_3: 'Precio3',
  PRICE_4: 'Precio4',
  PRICE_5: 'Precio5',
  PRICE_6: 'Precio6',
  PRICE_7: 'Precio7',
  PRICE_8: 'Precio8',
  PRICE_9: 'Precio9',
  PRICE_10: 'Precio10',
  PRICE_11: 'Precio11',
  PRICE_12: 'Precio12',
  PRICE_13: 'Precio13',
  PRICE_14: 'Precio14',
  PRICE_15: 'Precio15',
  PRICE_16: 'Precio16',
  PRICE_17: 'Precio17',
  PRICE_18: 'Precio18',
  PRICE_19: 'Precio19',
  PRICE_20: 'Precio20',
  NUM_ORDERS_1: 'NumOrdenes1',
  NUM_ORDERS_2: 'NumOrdenes2',
  NUM_ORDERS_3: 'NumOrdenes3',
  NUM_ORDERS_4: 'NumOrdenes4',
  NUM_ORDERS_5: 'NumOrdenes5',
  NUM_ORDERS_6: 'NumOrdenes6',
  NUM_ORDERS_7: 'NumOrdenes7',
  NUM_ORDERS_8: 'NumOrdenes8',
  NUM_ORDERS_9: 'NumOrdenes9',
  NUM_ORDERS_10: 'NumOrdenes10',
  NUM_ORDERS_11: 'NumOrdenes11',
  NUM_ORDERS_12: 'NumOrdenes12',
  NUM_ORDERS_13: 'NumOrdenes13',
  NUM_ORDERS_14: 'NumOrdenes14',
  NUM_ORDERS_15: 'NumOrdenes15',
  NUM_ORDERS_16: 'NumOrdenes16',
  NUM_ORDERS_17: 'NumOrdenes17',
  NUM_ORDERS_18: 'NumOrdenes18',
  NUM_ORDERS_19: 'NumOrdenes19',
  NUM_ORDERS_20: 'NumOrdenes20',
  VOL_ACCUMULATED_1: 'VolAcumulado1',
  VOL_ACCUMULATED_2: 'VolAcumulado2',
  VOL_ACCUMULATED_3: 'VolAcumulado3',
  VOL_ACCUMULATED_4: 'VolAcumulado4',
  VOL_ACCUMULATED_5: 'VolAcumulado5',
  VOL_ACCUMULATED_6: 'VolAcumulado6',
  VOL_ACCUMULATED_7: 'VolAcumulado7',
  VOL_ACCUMULATED_8: 'VolAcumulado8',
  VOL_ACCUMULATED_9: 'VolAcumulado9',
  VOL_ACCUMULATED_10: 'VolAcumulado10',
  VOL_ACCUMULATED_11: 'VolAcumulado11',
  VOL_ACCUMULATED_12: 'VolAcumulado12',
  VOL_ACCUMULATED_13: 'VolAcumulado13',
  VOL_ACCUMULATED_14: 'VolAcumulado14',
  VOL_ACCUMULATED_15: 'VolAcumulado15',
  VOL_ACCUMULATED_16: 'VolAcumulado16',
  VOL_ACCUMULATED_17: 'VolAcumulado17',
  VOL_ACCUMULATED_18: 'VolAcumulado18',
  VOL_ACCUMULATED_19: 'VolAcumulado19',
  VOL_ACCUMULATED_20: 'VolAcumulado20',
  GRAPH: 'Graficar',
  LIQUIDATION: 'Liquidacion',
  SPREAD: 'Spread',
  SPREAD_PORCENTAJE: 'SpreadPorcentaje',
  POSTURE_TIME: 'PosturaHora',
  CURRENCIES_PURCHASE: 'PosturaCompra_Precio',
  CURRENCIES_SALE: 'PosturaVenta_Precio',
  FULL_HOUR: 'HoraCompleta',
  PICO_LOTE: 'PicoLote',
  BUY_CASH: 'CompraCash',
  SALE_CASH: 'VentaCash',
  BUY_24HR: 'Compra24Hr',
  SALE_24HR: 'Venta24Hr',
  BUY_48HR: 'Compra48Hr',
  SALE_48HR: 'Venta48Hr',
  MOVEMENT: 'Movimiento',
  DATETIME: 'datetime',
  CLOSE: 'close'
}

export const LsSubscriptionCapitalsFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.TOTAL_AMOUNT,
  LsSubscriptionTableFields.GRAPH,
  LsSubscriptionTableFields.MOVEMENT,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.ACTUAL_VALUE_PPP,
  LsSubscriptionTableFields.SELLER,
  LsSubscriptionTableFields.BUYER,
  LsSubscriptionTableFields.BUYER,
  LsSubscriptionTableFields.FOLIOBMV
]

export const LsSubscriptionStocksFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.TOTAL_AMOUNT,
  LsSubscriptionTableFields.GRAPH,
  LsSubscriptionTableFields.MOVEMENT
]

export const LsSubscriptionInvestmentFunds = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PICO_LOTE
]

export const LsSubscriptionCurrenciesFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CURRENCIES_PURCHASE,
  LsSubscriptionTableFields.CURRENCIES_SALE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM
]

export const LsSubscriptionIndexesFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.PREVIOUS_PRICE_INDEX,
  LsSubscriptionTableFields.TOTAL_AMOUNT,
  LsSubscriptionTableFields.LAST_DATE,
  LsSubscriptionTableFields.HOUR_INDEX,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.PREVIOUS_PRICE
]

export const LsSubscriptionIntradayIndexFields = [
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR_COMPLETE,
  LsSubscriptionTableFields.MOVEMENT
]

export const LsSubscriptionGeneralIndicatorsFields = [
  LsSubscriptionTableFields.SAMPLE_ID,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.CURRENT,
  LsSubscriptionTableFields.PREVIOUS,
  LsSubscriptionTableFields.CHANGE,
  LsSubscriptionTableFields.PERIODICITY
]

export const LsSubscriptionEquityBivaFields = [
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PRICE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.DATE_UHA,
  LsSubscriptionTableFields.AMOUNT,
  LsSubscriptionTableFields.NUMBER_OPERATION,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.TOTAL_AMOUNT
]

export const LsSubscriptionBmvEquitySicFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.ACTUAL_VALUE_PPP,
  LsSubscriptionTableFields.CHANGE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.BUYER,
  LsSubscriptionTableFields.LAST_DATE,
  LsSubscriptionTableFields.LAST_TIME,
  LsSubscriptionTableFields.PEAK_AMOUNT,
  LsSubscriptionTableFields.TOTAL_AMOUNT,
  LsSubscriptionTableFields.NUM_OF_OPERATIONS,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.SELLER,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PICO_LOTE
]
export const LsSubscriptionSICBIVAFields = [
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PRICE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PERCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.DATE_UHA,
  LsSubscriptionTableFields.AMOUNT,
  LsSubscriptionTableFields.NUMBER_OPERATION,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PICO_LOTE
]

export const LsSubscriptionFixedIncomeFields = [
  LsSubscriptionTableFields.ISIN,
  LsSubscriptionTableFields.FIXED_INCOME_SERIE,
  LsSubscriptionTableFields.FIXED_INCOME_DATE,
  LsSubscriptionTableFields.FIXED_INCOME_PREVIOUS_PRICE,
  LsSubscriptionTableFields.FIXED_INCOME_LAST_DONE,
  LsSubscriptionTableFields.FIXED_INCOME_PORCENTUAL_VARIATION,
  LsSubscriptionTableFields.FIXED_INCOME_UNIT_VARIATION,
  LsSubscriptionTableFields.FIXED_INCOME_INTEREST_24,
  LsSubscriptionTableFields.FIXED_INCOME_SCORE_FITCH,
  LsSubscriptionTableFields.FIXED_INCOME_SCORE_MOODY,
  LsSubscriptionTableFields.FIXED_INCOME_SCORE_SP,
  LsSubscriptionTableFields.FIXED_INCOME_SCORE_HR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.DAYS_TO_EXPIRE,
  LsSubscriptionTableFields.RELEASE_DATE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.FIXED_INCOME_DAY_MAX,
  LsSubscriptionTableFields.FIXED_INCOME_DAY_MIN,
  LsSubscriptionTableFields.FIXED_INCOME_PURCHASE_PRICE,
  LsSubscriptionTableFields.FIXED_INCOME_SALE_PRICE,
  LsSubscriptionTableFields.FIXED_INCOME_OPENING_PRICE,
  LsSubscriptionTableFields.CLEAN_PRICE,
  LsSubscriptionTableFields.MD_DIRTY_PRICE,
  LsSubscriptionTableFields.MD_CLEAN_PRICE,
  LsSubscriptionTableFields.FIXED_INCOME_YIELD_RATE,
  LsSubscriptionTableFields.DIRTY_PRICE,
  LsSubscriptionTableFields.SURTAX_PIP,
  LsSubscriptionTableFields.FIXED_INCOME_TYPE_VALUE
]

export const LsSubscriptionNyseNasdaqFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PRICE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PERCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.NYSE_PURCHASE_VALUE,
  LsSubscriptionTableFields.NYSE_PURCHASE_VOLUME,
  LsSubscriptionTableFields.NYSE_SALE_VALUE,
  LsSubscriptionTableFields.NYSE_SALE_VOLUME,
  LsSubscriptionTableFields.NYSE_SALE_DATE,
  LsSubscriptionTableFields.NYSE_SALE_TIME,
  LsSubscriptionTableFields.NYSE_PURCHASE_DATE,
  LsSubscriptionTableFields.NYSE_PURCHASE_STOCK,
  LsSubscriptionTableFields.NYSE_STOCK_VALUE,
  LsSubscriptionTableFields.NYSE_MIN_PRICE,
  LsSubscriptionTableFields.NYSE_MAX_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE
]

export const LsSubscriptionDetailBmvFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.TOTAL_AMOUNT
]

export const LsSubscriptionPosturasFields = [
  LsSubscriptionTableFields.SIDE,
  LsSubscriptionTableFields.PRICE_1,
  LsSubscriptionTableFields.PRICE_2,
  LsSubscriptionTableFields.PRICE_3,
  LsSubscriptionTableFields.PRICE_4,
  LsSubscriptionTableFields.PRICE_5,
  LsSubscriptionTableFields.PRICE_6,
  LsSubscriptionTableFields.PRICE_7,
  LsSubscriptionTableFields.PRICE_8,
  LsSubscriptionTableFields.PRICE_9,
  LsSubscriptionTableFields.PRICE_10,
  LsSubscriptionTableFields.PRICE_11,
  LsSubscriptionTableFields.PRICE_12,
  LsSubscriptionTableFields.PRICE_13,
  LsSubscriptionTableFields.PRICE_14,
  LsSubscriptionTableFields.PRICE_15,
  LsSubscriptionTableFields.PRICE_16,
  LsSubscriptionTableFields.PRICE_17,
  LsSubscriptionTableFields.PRICE_18,
  LsSubscriptionTableFields.PRICE_19,
  LsSubscriptionTableFields.PRICE_20,
  LsSubscriptionTableFields.NUM_ORDERS_1,
  LsSubscriptionTableFields.NUM_ORDERS_2,
  LsSubscriptionTableFields.NUM_ORDERS_3,
  LsSubscriptionTableFields.NUM_ORDERS_4,
  LsSubscriptionTableFields.NUM_ORDERS_5,
  LsSubscriptionTableFields.NUM_ORDERS_6,
  LsSubscriptionTableFields.NUM_ORDERS_7,
  LsSubscriptionTableFields.NUM_ORDERS_8,
  LsSubscriptionTableFields.NUM_ORDERS_9,
  LsSubscriptionTableFields.NUM_ORDERS_10,
  LsSubscriptionTableFields.NUM_ORDERS_11,
  LsSubscriptionTableFields.NUM_ORDERS_12,
  LsSubscriptionTableFields.NUM_ORDERS_13,
  LsSubscriptionTableFields.NUM_ORDERS_14,
  LsSubscriptionTableFields.NUM_ORDERS_15,
  LsSubscriptionTableFields.NUM_ORDERS_16,
  LsSubscriptionTableFields.NUM_ORDERS_17,
  LsSubscriptionTableFields.NUM_ORDERS_18,
  LsSubscriptionTableFields.NUM_ORDERS_19,
  LsSubscriptionTableFields.NUM_ORDERS_20,
  LsSubscriptionTableFields.VOL_ACCUMULATED_1,
  LsSubscriptionTableFields.VOL_ACCUMULATED_2,
  LsSubscriptionTableFields.VOL_ACCUMULATED_3,
  LsSubscriptionTableFields.VOL_ACCUMULATED_4,
  LsSubscriptionTableFields.VOL_ACCUMULATED_5,
  LsSubscriptionTableFields.VOL_ACCUMULATED_6,
  LsSubscriptionTableFields.VOL_ACCUMULATED_7,
  LsSubscriptionTableFields.VOL_ACCUMULATED_8,
  LsSubscriptionTableFields.VOL_ACCUMULATED_9,
  LsSubscriptionTableFields.VOL_ACCUMULATED_10,
  LsSubscriptionTableFields.VOL_ACCUMULATED_11,
  LsSubscriptionTableFields.VOL_ACCUMULATED_12,
  LsSubscriptionTableFields.VOL_ACCUMULATED_13,
  LsSubscriptionTableFields.VOL_ACCUMULATED_14,
  LsSubscriptionTableFields.VOL_ACCUMULATED_15,
  LsSubscriptionTableFields.VOL_ACCUMULATED_16,
  LsSubscriptionTableFields.VOL_ACCUMULATED_17,
  LsSubscriptionTableFields.VOL_ACCUMULATED_18,
  LsSubscriptionTableFields.VOL_ACCUMULATED_19,
  LsSubscriptionTableFields.VOL_ACCUMULATED_20,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE
]

export const LsSubscriptionDollarFields = [
  LsSubscriptionTableFields.BUY_CASH,
  LsSubscriptionTableFields.SALE_CASH,
  LsSubscriptionTableFields.BUY_24HR,
  LsSubscriptionTableFields.SALE_24HR,
  LsSubscriptionTableFields.BUY_48HR,
  LsSubscriptionTableFields.SALE_48HR,
  LsSubscriptionTableFields.HOUR
]

export const LsSubscriptionIntradayFields = [
  LsSubscriptionTableFields.LAST_TIME,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.SELLER,
  LsSubscriptionTableFields.BUYER,
  LsSubscriptionTableFields.LAST_VOLUMEN,
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.LAST_AMOUNT,
  LsSubscriptionTableFields.GRAPH,
  LsSubscriptionTableFields.LIQUIDATION,
  LsSubscriptionTableFields.FOLIOBMV,
  LsSubscriptionTableFields.FOLIOBIVA,
  LsSubscriptionTableFields.MOVEMENT,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.NUMBER_OPERATION,
  LsSubscriptionTableFields.LAST_DATE,
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.AMOUNT,
  LsSubscriptionTableFields.VOLUMEN_DONE
]

export const LsSubscriptionChartFields = {
  PercentageChange: 'VariacionPorcentualAcc',
  AbsoluteChange: 'VariacionUnitaria',
  Time: 'Hora',
  PriceAcc: 'PrecioAcc', // Close
  Volume: 'Volumen',
  Open: 'PrecioUHA',
  High: 'PrecioMaxDia',
  Low: 'PrecioMinDia',
  TotalVolume: 'VolumenTotal',
  IsUsedForGraph: 'Graficar',
  Movement: 'Movimiento',
  OpeningPrice: 'PrecioApertura',
  CompleteTime: 'HoraCompleta',
  PicoLote: 'PicoLote'
}

export const LsSubscriptionNewsFields = {
  Headline: 'Encabezado', // or EncabezadoLargo
  Priority: 'Prioridad',
  Time: 'Hora',
  NewsId: 'NumNota',
  ServiceId: 'Servicio'
}

export const LsSubscriptionTickerFields = {
  CURRENT_PRICE: 'PrecioAcc',
  CHANGE_PROCENTAGE: 'VariacionPorcentualAcc',
  CHANGE: 'VariacionUnitaria'
}

export const NewsFields = {
  header: 'header',
  priority: 'priority',
  time: 'time',
  newsId: 'newsId',
  serviceId: 'serviceId'
}

export const PriceChangeApiFields = {
  price: 'precioActual',
  procentageChange: 'porcentaje',
  absoluteChange: 'variacion',
  oportunidadInformacion: 'oportunidadInformacion'
}

export const ComparisonApiFields = {
  price: 'precioActual',
  procentageChange: 'porcentaje',
  descripcion: 'descripcion',
  salesVariation48Hrs: 'salesVariation48Hrs',
  cashCompra: 'cashCompra'
}

export const InstrumentPrefixes = {
  BmvStockInstrumentPrefixForApi: '1/12576/0/',
  BmvStockInstrumentPrefixForLS: '1.12576.0.',
  BmvEquitySicInstrumentPrefixForApi: '1/12609/0/',
  BmvEquitySicInstrumentPrefixForLS: '1.12609.0.',
  CurrencyInstrumentPrefixForApi: '4096/50/25/',
  CurrencyInstrumentPrefixForLS: '4096.50.25.',
  SICBIVAInstrumentPrefixForLS: '2.20.1.',
  SICBIVAInstrumentPrefixForApi: '2/20/1/',
  GeneralIndicatorsInstrumentPrefixForLS: '32768.1.1000.',
  IndexInstrumentPrefixForApi: '64/1/0/',
  IndexInstrumentPrefixForLS: '64.1.0.',
  NyseNasdaqInstrumentPrefixForApi: '32/2/21/',
  NyseNasdaqInstrumentPrefixForLS: '32.2.21.',
  InstrumentPrefixForLSPositionFields: 'P.'
}

export const IntervalConstants = {
  day: 'D',
  week: 'W',
  month: 'M',
  quarter: 'Q',
  year: 'Y'
}

export const MEXICAN_CURRENCY = 'MXN'
export const USD_CURRENCY = 'USD'
export const CDT_TIMEZONE = 'CDT'
export const MEXICAN_LOCALE = 'es-MX'
export const USA_LOCALE = 'en-US'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_FORMAT_TEXT = 'DD-MMM-YYYY'
export const DATE_FORMAT_YY = 'DD-MM-YY'
export const DATE_FORMAT_SHORT_YEAR = 'dd-MM-yy'
export const DATE_FORMAT_YYYY = 'YYYY-MM-DD'
export const HOUR_FORMAT = 'HH:mm:ss'
export const HOUR_MINUTS = 'hh:mm'
export const DATE_TIME = 'DD-MM hh:mm'
export const TICKER_SPEED = 50
// WORKSPACE_OFFSET and SIDEBAR_WIDTH (height/width of header and footer elements)
// are subtracted from terminal height/width to get actual size of workspace
export const SIDEBAR_WIDTH = 420
export const SIDEBARLEFT_WIDTH = 300
export const WORKSPACE_HEIGHT_OFFSET = 130
export const CHART_FOOTER_HEIGHT = 40

export const MarketTypesIds = {
  BMV: 1,
  BIVA: 2,
  CONSOLIDATED: 4,
  INTERNATIONAL_BAGS: 32,
  INDEX: 64,
  CURRENCY: 4096,
  FIXED_INCOME_1: 32780,
  FIXED_INCOME_2: 32781,
  FIXED_INCOME_3: 32782,
  DERIVATIVES: 1024,
  ECONOMIC_INDICATORS: 32800,
  INVESTMENT_FUNDS: 123,
  REFERENCE_RATES: 8182,
  INDEX_DJI_SP: 2048,
  GOVERNMENT_DEBT: 32780,
  LIBOR_RATE: 32768,
  PETROLEUM: 32768
}

export const MarketsValueTypeIds = {
  NYSE: 2,
  NASDAQ: 101,
  AMEX: 102
}

export const exchangeIds = {
  LIBOR_RATE: 1003
}

export const MarketsCapitals = [
  {
    marketTypeId: MarketTypesIds.BMV,
    label: i18n.t('bmvStocks'),
    assetClass: i18n.t('bmvStocks'),
    status: true,
    default: true
  },
  {
    marketTypeId: MarketTypesIds.BIVA,
    label: i18n.t('bivaStocks'),
    assetClass: i18n.t('bivaStocks'),
    status: true,
    default: true
  },
  {
    marketTypeId: MarketTypesIds.CONSOLIDATED,
    label: i18n.t('consolidates'),
    assetClass: i18n.t('consolidate'),
    status: true,
    default: true
  },
  {
    marketTypeId: MarketTypesIds.INTERNATIONAL_BAGS,
    label: i18n.t('bmvInternationalBags'),
    assetClass: i18n.t('bmvInternationalBags'),
    status: true,
    default: false,
    valuesTypes: [
      {
        ValueId: MarketsValueTypeIds.NYSE,
        label: i18n.t('nyse'),
        assetClass: i18n.t('nyse'),
        status: true,
        default: true
      },
      {
        ValueId: MarketsValueTypeIds.NASDAQ,
        label: i18n.t('nasdaq'),
        assetClass: i18n.t('nasdaq'),
        status: true,
        default: true
      },
      {
        ValueId: MarketsValueTypeIds.AMEX,
        label: i18n.t('amex'),
        assetClass: i18n.t('amex'),
        status: true,
        default: true
      }
    ]
  }
]

export const RecommendationsIds = {
  BMV: [
    '12320', // 0
    '12576', // 1
    '12609', // 1A
    '12613', // 1E
    '13088', // 3
    '13361', // 41
    '17222' // CF;
  ],
  BIVA: ['10', '20', '30', '50', '80'],
  INTERNATIONAL_BAGS: ['2', '101', '102']
}

export const MarketTypes = {
  BMV: { id: 27, name: i18n.t('bmvStocks') },
  BMV_SIC: { id: 27, name: i18n.t('bmvSic') },
  BIVA: { id: 27, name: i18n.t('bivaStocks') },
  BIVA_SIC: { id: 27, name: i18n.t('bivaSic') },
  NYSE: { id: 27, name: i18n.t('nyseStocks') },
  AMEX: { id: 27, name: i18n.t('amex') },
  NASDAQ: { id: 27, name: i18n.t('nasdaq') },
  INDEX: { id: 8, name: i18n.t('indices') },
  CURRENCY: { id: 9, name: i18n.t('currencies') },
  DERIVATIVES: { id: 10, name: i18n.t('derivatives') },
  GENERAL_INDICATORS: { id: 11, name: i18n.t('generalIndicators') },
  FIXED_INCOME: { id: 12, name: i18n.t('fixedIncome') },
  NYSE_ONLY: { id: 13, name: i18n.t('nyse') },
  BANORTE: { id: 15, name: i18n.t('predefinedBanorte') },
  GOVERNMENT_DEBT: { id: 16, name: i18n.t('governmentDebt') },
  PRIVATE_DEBT: { id: 17, name: i18n.t('privateDebt') },
  INTERNATIONAL_BAGS: { id: 27, name: i18n.t('internationalBags') },
  ECONOMIC_INDICATORS: { id: 21, name: i18n.t('economicIndicators') },
  INTERBANK_DOLLAR: { id: 22, name: i18n.t('interbankDollar') },
  INVESTMENT_FUNDS: { id: 23, name: i18n.t('investmentFunds') },
  REFERENCE_RATES: { id: 24, name: i18n.t('referenceRates') },
  INTERNATIONAL_DEBT: { id: 25, name: i18n.t('internationalDebt') },
  PETROLEUM: { id: 28, name: 'petroleum' },
  CAPITALS: { id: 27, name: 'Capitales' },
  PRECIOUS_METALS: { id: 29, name: i18n.t('preciousMetals') },
  FUTURES: { id: 30, name: 'Futures' },
  SWAPS: { id: 31, name: 'Swaps' }
  // IPC: { id: 14, name: i18n.t("predefinedIpc") },
}

export const hideMarketTypesNewTable = [
  MarketTypes.PRECIOUS_METALS.id,
  MarketTypes.PETROLEUM.id,
  MarketTypes.DERIVATIVES.id
]

export const MarketsDeuda = [
  {
    marketTypeId: MarketTypes.GOVERNMENT_DEBT.id,
    label: i18n.t('governmentDebt'),
    assetClass: i18n.t('governmentDebt'),
    status: true,
    default: true
  },
  {
    marketTypeId: MarketTypes.PRIVATE_DEBT.id,
    label: i18n.t('privateDebt'),
    assetClass: i18n.t('privateDebt'),
    status: true,
    default: true
  },
  {
    marketTypeId: MarketTypes.INTERNATIONAL_DEBT.id,
    label: i18n.t('internationalDebt'),
    assetClass: i18n.t('internationalDebt'),
    status: true,
    default: true
  }
]

export const MarketsCurrencies = [
  {
    marketTypeId: MarketTypes.CURRENCY.id,
    label: i18n.t('currencies'),
    assetClass: i18n.t('currencies'),
    status: true,
    default: true
  },
  {
    marketTypeId: MarketTypes.INTERBANK_DOLLAR.id,
    label: i18n.t('interbankDollar'),
    assetClass: i18n.t('interbankDollar'),
    status: true,
    default: true
  }
]

export const marketsidExcluyedTags = [MarketTypes.BANORTE.id, MarketTypes.INTERNATIONAL_DEBT.id]

export const nameTagsMarkets = {
  CAPITALS: 'Capitales',
  FUNDS: 'Fondos',
  CURRENCIES: 'Divisas',
  INDICATORS: 'Indicadores',
  INDEX: 'Indices',
  REFERENCE_RATES: 'Tasas de Referencia',
  DEUDA: 'Deuda',
  RAWS_MATERIALS: 'Materias Primas'
}

export const MarketTypesArray = [
  MarketTypes.INDEX,
  MarketTypes.CURRENCY,
  MarketTypes.DERIVATIVES,
  MarketTypes.FIXED_INCOME,
  MarketTypes.GENERAL_INDICATORS,
  MarketTypes.BANORTE,
  MarketTypes.GOVERNMENT_DEBT,
  MarketTypes.ECONOMIC_INDICATORS,
  MarketTypes.INVESTMENT_FUNDS,
  MarketTypes.REFERENCE_RATES,
  MarketTypes.INTERNATIONAL_DEBT,
  MarketTypes.PRIVATE_DEBT,
  MarketTypes.CAPITALS,
  MarketTypes.PRECIOUS_METALS
  // MarketTypes.IPC,
]

export const ChartTypes = {
  historical: 'historical',
  intraday: 'intraday'
}

export const BasePeriodTypes = {
  minute: 'minute',
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year'
}

export const ServerTypes = {
  Api: 'API',
  LS: 'Lightstreamer'
}

export const InfoselApiPeriodTypes = {
  day: 'D',
  week: 'W',
  month: 'M',
  year: 'Y'
}

export const InfoselApiIntervals = {
  intraDay: '1',
  historical: 'D'
}

export const InfoselIntradayPeriods = {
  oneDay: 1,
  fiveDays: 5,
  oneMonth: 1,
  sixMonths: 6,
  twelveMonths: 12,
  fiveYears: 60,
  tenYears: 120,
  twentyYears: 240
}

export const NewsTypes = {
  debt: 600,
  stockMarket: 604,
  forex: 608,
  commodities: 614,
  business: 615,
  economy: 618,
  general: 620
}

export const AllNewsTypes = [
  `NN.${NewsTypes.debt}`,
  `NN.${NewsTypes.stockMarket}`,
  `NN.${NewsTypes.forex}`,
  `NN.${NewsTypes.commodities}`,
  `NN.${NewsTypes.business}`,
  `NN.${NewsTypes.economy}`,
  `NN.${NewsTypes.general}`
]

export const SidebarTypes = {
  default: 0,
  instrumentDetails: 1,
  newsSidebar: 2,
  alert: 3,
  chatbot: 4
}

export const enterKeyText = 'Enter'
export const escapeKeyText = 'Escape'
export const enterKeyValue = 13
export const escapeKeyValue = 27
export const C_KEY_VALUE = 67
export const DELETE_KEY_VALUE = 46

// Modal types
export const MODAL_WARNING = 'warning'
export const MODAL_SUCCESS = 'success'
export const MODAL_UNDO_ACTION = 'undo_action'
export const MODAL_CONCURRENT_USER = 'concurrent_user'
export const MODAL_CONVERT_TABLE = 'convert_table'

// Action for cards
export const MOVE_CARD = 'move'
export const DUPLICATE_CARD = 'duplicate'

export const SNACKBAR_DURATION_MS = 1000
export const widgetType = Object.freeze({
  bmvStocks: '1',
  chart: '2',
  news: '3',
  bivaSic: '4',
  ticker: '5',
  bmvEquity: '100',
  bmvEquitySIC: '103',
  equityBiva: '110',
  SICBIVA: '111',
  indexes: '120',
  nyseNasdaq: '130',
  generalIndicators: '140',
  currencies: '150',
  fixedIncome: '170',
  derivatives: '180'
})

export const serachStatusType = Object.freeze({
  searchNotStarted: 0,
  noResults: 1,
  oneResult: 1,
  moreResults: 2
})

// Constant below is used to limit number of rows shown in the table.
// In the docs it is specified to be 40, but since we have a table of only 40 rows in the current version
// for testing purposes, it is set to 10.

export const DEFAULT_TABLE_ROW_LENGHT = 40
export const MAX_INSTRUMENTS_API = 90
export const tableTypes = {
  STOCKS: 'stocks',
  CURRENCIES: 'currencies'
}

export const lsStatus = {
  CONNECTING: 'CONNECTING',
  CONNECTED_STREAM_SENSING: 'CONNECTED:STREAM-SENSING',
  DISCONNECTED: 'DISCONNECTED',
  DISCONNECTED_WILL_RETRY: 'DISCONNECTED:WILL-RETRY',
  CONNECTED_WS_STREAMING: 'CONNECTED:WS-STREAMING',
  CONNECTED_WS_STALLED: 'STALLED',
  DISCONNECTED_TRYING_RECOVERY: 'DISCONNECTED:TRYING-RECOVERY'
}

export const connectionStatus = {
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  CONNECTING: 'Connecting',
  DISCONNECTED: 'Disconnected'
}

export const keyStorage = {
  user: 'user',
  userLS: 'userLS',
  userApi: 'userApi',
  widgets: 'widgets'
}

export const DEFAULT_CHART_SPAN = {
  base: BasePeriodTypes.month,
  multiplier: 6,
  periodicity: {
    interval: 1,
    period: 1,
    timeUnit: BasePeriodTypes.day
  }
}

export const DEFAULT_CHART_HISTORICAL_SPAN = {
  base: BasePeriodTypes.month,
  multiplier: 12,
  periodicity: {
    interval: 1,
    period: 1,
    timeUnit: BasePeriodTypes.day
  }
}

export const defaultTypeChart = 'mountain'

export const SCHEDULE = {
  open: '8:00',
  close: '15:00'
}

export const BMVMARKETHOURS = {
  market_tz: 'America/Mexico_City',
  name: 'Mexico Stock Exchange',
  hour_aligned: false,
  rules: [
    {
      dayofweek: 1,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 2,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 3,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 4,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 5,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    { date: '*-01-01', open: '00:00', close: '00:00' },
    { date: '*-02-01', open: '00:00', close: '00:00' },
    { date: '*-03-15', open: '00:00', close: '00:00' },
    { date: '*-04-01', open: '00:00', close: '00:00' },
    { date: '*-04-02', open: '00:00', close: '00:00' }
  ]
}

export const priceUpdateAnimationDuration = '500ms'

export const sortTypes = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending'
}

export const snackbarTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  CONFIRMATION: 'confirmation',
  DELETE: 'delete'
}

export type TSnackbarPositions = 'bottom' | 'top'

export const snackbarPositions = {
  TOP: 'top',
  BOTTOM: 'bottom'
}

export const MAX_NUMBER_OF_WORKSPACES = 20

export const shortcuts = {
  DELETE: 'Del',
  COPY: 'CTRL + C (⌘ + C)'
}

export const MAX_WORKSPACE_NAME_LENGTH = 20

export const SPECIAL_CHARS_REGEX = /[^\w\sáéíóúñ]/gi

export const filterNewsDefaults: FiltersNews = {
  relevant: {
    id: 1,
    tabIndex: 0,
    isActive: true,
    filters: ''
  },
  mostRead: {
    id: 2,
    tabIndex: 1,
    isActive: false,
    filters: ''
  },
  all: {
    id: 3,
    tabIndex: 2,
    isActive: false,
    filters: ''
  },
  sector: {
    id: 4,
    tabIndex: 3,
    isActive: false,
    filters: ''
  },
  category: {
    id: 5,
    tabIndex: 4,
    isActive: false,
    filters: ''
  },
  english: {
    id: 6,
    tabIndex: 5,
    isActive: false,
    filters: ''
  }
}

export const FilterNewQueriesDefault: FilterNewQueries = {
  marketType: '',
  operatorMarketType: '',
  marketId: '',
  marketIds: '',
  operatorMarketId: '',
  instrumentsTypes: '',
  instrumentsTypesLabels: '',
  operatorInstrumentsTypes: '',
  sectors: '',
  sectorsLabels: '',
  operatorSectors: '',
  operatorType: '',
  operatorTypeLabels: '',
  operatorOperatorType: '',
  operatorTypeFund: '',
  typeFund: '',
  typeFundLabels: '',
  instrumentKey: '',
  includeInactiveInstruments: false
}

export const filterBrokerageFirmsReportDefaults: FiltersBrokerageFirmsReport = {
  latestReports: {
    id: 1,
    tabIndex: 0,
    isActive: true,
    filters: null
  },
  myReports: {
    id: 2,
    tabIndex: 1,
    isActive: false,
    filters: null
  }
}

export const defaultNewCard = {
  CHART: {
    id: 1,
    type: cardTypes.CHART,
    options: {
      symbol: 'AC*',
      widgetTypeId: widgetType.chart,
      instrumentId: '1/12576/0/AC*'
    },
    maximized: 0,
    elementId: 'chart'
  },
  TABLE: {
    id: 1,
    type: cardTypes.TABLE,
    options: {
      widgetTypeId: widgetType.bmvStocks
    },
    maximized: 0,
    elementId: 'table'
  },
  PREDEFINED_TABLE: {
    id: 1,
    type: cardTypes.PREDEFINED_TABLE,
    options: {
      widgetTypeId: widgetType.bmvStocks
    },
    maximized: 0,
    elementId: 'predefinedTable'
  },
  NEWS: {
    id: 1,
    type: cardTypes.NEWS,
    options: {
      widgetTypeId: widgetType.news,
      dataFilters: filterNewsDefaults,
      dataSearch: {
        active: false,
        value: ''
      }
    },
    maximized: 0,
    elementId: 'news'
  },
  COMPARATIVE_TABLE: {
    id: 1,
    type: cardTypes.COMPARATIVE_TABLE,
    options: {
      widgetTypeId: String(cardTypes.COMPARATIVE_TABLE)
    },
    maximized: 0,
    elementId: 'comparativeTable'
  },
  BROKERAGE_FIRMS_REPORT: {
    id: 1,
    type: cardTypes.BROKERAGE_FIRMS_REPORT,
    options: {
      widgetTypeId: String(cardTypes.BROKERAGE_FIRMS_REPORT),
      dataFilters: filterBrokerageFirmsReportDefaults
    },
    maximized: 0,
    elementId: 'reportsBrokerageHouses'
  },
  QUERIES: {
    id: 1,
    type: cardTypes.QUERIES,
    options: {
      widgetTypeId: String(cardTypes.QUERIES),
      selected: 'mostNegotiated'
    },
    maximized: 0,
    elementId: 'queries'
  },
  NEW_QUERIES: {
    id: 1,
    type: cardTypes.NEW_QUERIES,
    options: {
      widgetTypeId: String(cardTypes.NEW_QUERIES),
      dataFilters: FilterNewQueriesDefault
    },
    maximized: 0,
    elementId: 'newQueries'
  },
  CALENDAR: {
    id: 1,
    type: cardTypes.CALENDAR,
    options: {
      widgetTypeId: String(cardTypes.CALENDAR)
    },
    maximized: 0,
    elementId: 'calendar'
  },
  SNAPSHOT: {
    id: 1,
    type: cardTypes.SNAPSHOT,
    options: {
      widgetTypeId: String(cardTypes.SNAPSHOT)
    },
    maximized: 0,
    elementId: 'snapshot'
  },
  FINANCIAL: {
    id: 1,
    type: cardTypes.FINANCIAL,
    options: {
      widgetTypeId: String(cardTypes.FINANCIAL)
    },
    maximized: 0,
    elementId: 'financial'
  }
}
export const charsTabName = 13

export const updateType = {
  ADD: 'add',
  DELETE: 'delete'
}

export const marketsIds = {
  bmvId: 1
}

export const specialCharacters = {
  COLON: ':',
  HYPHEN_MINUS: '-'
}

export const moveDirection = {
  UP: 'up',
  DOWN: 'down'
}

export const maxAttempt = 5

export const compareColors = [
  `#C6D1DF`,
  `#77CFEF`,
  `#7A98E8`,
  `#FE7D76`,
  `#FAE771`,
  `#9DF882`,
  `#35D9C4`,
  `#FC9692`,
  `#62BFDF`,
  `#FE8A1E`
]

export const marketTypesIdsForIntradayIndexes = [64, 2048]
export const marketTypesIdsForIndexes = [32785, 2048, 64]
export const marketTypesIdsForCurrencies = [4096]
export const marketTypesIdsForCurrenciesHisticalChart = [4096]
export const marketTypesIdsForInternationalBags = [32]
export const marketTypesIdsForIndicators = [32800]
export const marketTypesIdsForPreciousMetals = [32768, 9999]
export const valueTypeIdForInterbankDollar = [8]
export const valueTypeIdsForCurrencies = [50]
export const marketTypesIdsWithPagination = [64, 2048, 32]

export const CARD_TITLE_MAX_CHARS = 40

export const MARKETS_IDS_ENABLE = `${MarketTypes.BMV.id}, ${MarketTypes.BMV_SIC.id}, ${MarketTypes.BIVA_SIC.id}, ${MarketTypes.BIVA.id}, ${MarketTypes.NASDAQ.id}, ${MarketTypes.AMEX.id}, ${MarketTypes.CURRENCY.id}, ${MarketTypes.DERIVATIVES.id}, ${MarketTypes.INDEX.id}, ${MarketTypes.GENERAL_INDICATORS.id},`

export const MARKETS_NATIONAL_AND_SIC_IDS = `${MarketTypes.BMV.id}, ${MarketTypes.BMV_SIC.id}, ${MarketTypes.BIVA_SIC.id}, ${MarketTypes.BIVA.id}`

export const MARKETS_IDS_ADDING_CHART = [
  MarketTypes.BMV.id,
  MarketTypes.BIVA.id,
  MarketTypes.CURRENCY.id,
  MarketTypes.DERIVATIVES.id,
  MarketTypes.INDEX.id,
  MarketTypes.GENERAL_INDICATORS.id,
  MarketTypes.INTERNATIONAL_BAGS.id,
  MarketTypes.INTERBANK_DOLLAR.id,
  MarketTypes.ECONOMIC_INDICATORS.id
]
export const SIDEBAR_OPTIONS_ICON_TYPE = {
  QUESTION: 'question'
}

export const valueTypesBmv = [
  '12320', // 0
  '12576', // 1
  '12613', // 1E
  '13088', // 3
  '13361', // 41
  '17222', // CF
  '17989', // FE
  '17990', // FF
  '17992', // FH
  '12609' // 1A
]
export const valueTypesBmvNews = [
  '12320', // 0
  '12576', // 1
  '12609', // 1A
  '12613', // 1E
  '13088', // 3
  '17222' // CF
]

export const valueTypesBivaNews = ['30']

export type SIDEBAR_OPTIONS_TYPE = {
  id: number
  value: string
  isSelected: boolean
  uri: string
  marketsIds: number[]
  marketTypeIds: number[]
  disabled: boolean
  iconType: string
  titleTooltip: string
}

export const SIDEBAR_OPTIONS = [
  {
    id: 0,
    value: 'Posturas',
    isSelected: false,
    uri: 'depth',
    marketsIds: [MarketTypes.CAPITALS.id],
    marketTypeIds: [MarketTypesIds.BMV, MarketTypesIds.CONSOLIDATED, MarketTypesIds.BIVA],
    disabled: false,
    iconType: '',
    titleTooltip: '',
    showSIC: true
  },
  {
    id: 1,
    value: 'Intradía',
    isSelected: false,
    uri: 'intraday',
    marketsIds: [
      MarketTypes.CAPITALS.id,
      MarketTypes.INDEX.id,
      MarketTypes.CURRENCY.id,
      MarketTypes.INTERBANK_DOLLAR.id
    ],
    marketTypeIds: [
      MarketTypesIds.BMV,
      MarketTypesIds.BIVA,
      MarketTypesIds.INTERNATIONAL_BAGS,
      MarketTypesIds.CONSOLIDATED,
      MarketTypesIds.CURRENCY,
      ...marketTypesIdsForIntradayIndexes
    ],
    disabled: false,
    iconType: '',
    titleTooltip: '',
    showSIC: true
  },
  {
    id: 2,
    value: 'Histórico',
    isSelected: false,
    uri: 'priceHistory',
    marketsIds: [
      MarketTypes.CAPITALS.id,
      MarketTypes.INDEX.id,
      MarketTypes.CURRENCY.id,
      MarketTypes.REFERENCE_RATES.id,
      MarketTypes.GOVERNMENT_DEBT.id,
      MarketTypes.INTERBANK_DOLLAR.id,
      MarketTypes.INVESTMENT_FUNDS.id,
      MarketTypes.ECONOMIC_INDICATORS.id,
      MarketTypes.PRECIOUS_METALS.id,
      MarketTypes.INTERNATIONAL_DEBT.id,
      MarketTypes.PRIVATE_DEBT.id,
      MarketTypes.PETROLEUM.id
    ],
    marketTypeIds: [],
    disabled: false,
    iconType: '',
    titleTooltip: '',
    showSIC: true
  },
  {
    id: 3,
    value: 'Participación',
    isSelected: false,
    uri: 'participation',
    marketsIds: [MarketTypes.CAPITALS.id],
    marketTypeIds: [MarketTypesIds.BMV, MarketTypesIds.CONSOLIDATED],
    disabled: false,
    iconType: '',
    titleTooltip: '',
    showSIC: true
  },
  {
    id: 4,
    value: 'Cartera',
    isSelected: false,
    disabled: false,
    uri: 'cartera',
    marketsIds: [MarketTypes.INVESTMENT_FUNDS.id],
    marketTypeIds: [],
    iconType: '',
    titleTooltip: '',
    showSIC: true
  },
  {
    id: 5,
    value: 'Dividendos',
    isSelected: false,
    disabled: false,
    uri: 'dividends',
    marketsIds: [MarketTypes.CAPITALS.id],
    marketTypeIds: [MarketTypesIds.BMV, MarketTypesIds.CONSOLIDATED],
    iconType: '',
    titleTooltip: '',
    showSIC: false
  },
  {
    id: 6,
    value: 'Calendario',
    isSelected: false,
    disabled: false,
    uri: 'calendar',
    marketsIds: [MarketTypes.GOVERNMENT_DEBT.id],
    marketTypeIds: [],
    iconType: '',
    titleTooltip: '',
    showSIC: true
  }
]

export const hideButtonDownload = [4]

export const SHEET_TAB_ELEMENTS = [
  {
    id: 0,
    value: 'Resumen',
    uri: '',
    marketsIds: [
      MarketTypes.BMV.id,
      MarketTypes.BIVA.id,
      MarketTypes.INVESTMENT_FUNDS.id,
      MarketTypes.INTERNATIONAL_BAGS.id,
      MarketTypes.GOVERNMENT_DEBT.id,
      MarketTypes.INTERNATIONAL_DEBT.id,
      MarketTypes.PRIVATE_DEBT.id
    ],
    valueTypeIds: [],
    isActive: true
  },
  {
    id: 1,
    value: 'Perfil',
    uri: '',
    marketsIds: [
      MarketTypes.BMV.id,
      MarketTypes.BIVA.id,
      MarketTypes.INVESTMENT_FUNDS.id,
      MarketTypes.INTERNATIONAL_BAGS.id
    ],
    valueTypeIds: [],
    isActive: true
  },
  {
    id: 2,
    value: 'Análisis',
    uri: 'sectorial',
    marketsIds: [],
    valueTypeIds: [],
    isActive: false
  },
  {
    id: 3,
    value: 'Noticias',
    uri: '',
    marketsIds: [MarketTypes.BMV.id, MarketTypes.BIVA.id],
    valueTypeIds: [],
    isActive: true
  },
  {
    id: 4,
    value: 'Financiero',
    uri: '',
    marketsIds: [MarketTypes.BMV.id, MarketTypes.BIVA.id, MarketTypes.INTERNATIONAL_BAGS.id],
    valueTypeIds: [],
    isActive: true
  },
  {
    id: 5,
    value: 'Análisis sectorial',
    uri: '',
    marketsIds: [MarketTypes.BMV.id],
    valueTypeIds: [...RecommendationsIds.BMV],
    isActive: true
  },
  {
    id: 6,
    value: 'Rendimientos',
    uri: '',
    marketsIds: [MarketTypes.CURRENCY.id, MarketTypes.INDEX.id],
    valueTypeIds: [],
    isActive: false
  },
  {
    id: 7,
    value: 'Recomendaciones',
    uri: '',
    marketsIds: [MarketTypes.BMV.id, MarketTypes.BIVA.id, MarketTypes.INTERNATIONAL_BAGS.id],
    valueTypeIds: [...RecommendationsIds.BMV, ...RecommendationsIds.BIVA, ...RecommendationsIds.INTERNATIONAL_BAGS],
    isActive: true
  },
  {
    id: 8,
    value: 'Reporte de analistas',
    uri: '',
    marketsIds: [],
    filterNamesInstrumens: [...nameReportsBrokerageHouses],
    valueTypeIds: [],
    isActive: true
  },
  {
    id: 9,
    value: 'Emisión',
    uri: '',
    marketsIds: [MarketTypes.GOVERNMENT_DEBT.id, MarketTypes.INTERNATIONAL_DEBT.id, MarketTypes.PRIVATE_DEBT.id],
    filterNamesInstrumens: [],
    valueTypeIds: [],
    isActive: true
  },
  {
    id: 10,
    value: 'Métricas',
    uri: '',
    marketsIds: [],
    filterNamesInstrumens: [],
    valueTypeIds: [],
    isActive: true
  }
]

export const tickerSmall = 'small'
export const tickerLarge = 'large'

export const NewsCategories = {
  ACTUALITY: { id: 1, name: 'Actualidad' },
  AP: { id: 2, name: 'AP Español' },
  STOCK: { id: 3, name: 'Bolsa' },
  FEATURED: { id: 4, name: 'Destacadas' },
  FIXED_INCOME: { id: 5, name: 'Deuda' },
  CURRENCY: { id: 6, name: 'Divisas' },
  ECONOMY: { id: 7, name: 'Economía' },
  BUSINESS: { id: 8, name: 'Empresas' },
  LATAM: { id: 9, name: 'LATAM' },
  MERCANCY: { id: 10, name: 'Mercancías' },
  PR_NEWS_WIRE: { id: 11, name: 'PR news Wire' }
}

export const NewsCategoriesArray = [
  NewsCategories.ACTUALITY,
  NewsCategories.AP,
  NewsCategories.STOCK,
  NewsCategories.FEATURED,
  NewsCategories.FIXED_INCOME,
  NewsCategories.CURRENCY,
  NewsCategories.ECONOMY,
  NewsCategories.BUSINESS,
  NewsCategories.LATAM,
  NewsCategories.MERCANCY,
  NewsCategories.PR_NEWS_WIRE
]

export const TagsTables = {
  ALL: new Tags(1, 'Todos', [], true, LIGHT_GREY, 0),
  CAPITALS: {
    id: 2,
    name: 'Capitales',
    tags: [],
    isSelected: false,
    color: PURPLE,
    elements: 0
  },
  CURRENCIES: new Tags(3, 'Divisas', [], false, GREEN, 0),
  DEBT: new Tags(4, 'Tasas de Referencia', [], false, SALMON, 0),
  INDEX: new Tags(5, 'Indices', [], false, PINK, 0),
  MONEY: new Tags(6, 'Fondos', [], false, CYAN_BLUE, 0),
  INDICADORES_GENERALES: new Tags(7, 'Indicadores Generales', [], false, BRIGHT_ORANGE, 0),
  DOLLAR: new Tags(8, 'Dólar interbancario', [], false, '#A3C844', 0)
}

export const PredefinedTagTableArray = [
  TagsTables.ALL,
  TagsTables.CAPITALS,
  TagsTables.CURRENCIES,
  TagsTables.DEBT,
  TagsTables.INDEX,
  TagsTables.MONEY,
  TagsTables.INDICADORES_GENERALES,
  TagsTables.DOLLAR
]

export const REGEX_FOR_SEARCHBAR = /[^\w\sáéíóúñ*\\&+-]/gi

export const MIN_INSTRUMENTS_PORTFOLIO = 1
export const MAX_INSTRUMENTS_PORTFOLIO = 30

export const CHART_TYPES = {
  kagi: { type: 'kagi', value: 'kagi', name: 'Kagi' },
  linebreak: { type: 'linebreak', value: 'priceLines', name: 'Line break' },
  rangebars: { type: 'rangebars', value: 'range', name: 'Range bars' },
  renko: { type: 'renko', value: 'renko', name: 'Renko' }
}

export const COUNTER_TYPES = {
  STUDIES: 'Estudios',
  DRAWINGS: 'Dibujo'
}

export const valueTypesBiva = ['10', '20', '30', '50', '80']

export const valueTypesBmvSic = ['12609', '12613', '12617', '20']

export const valueTypesBivaSic = ['20']

export const SYMBOL_REAL_TIME_TYPES = {
  DELAY: 'delay',
  CLOSING: 'cierre',
  REAL_TIME: 'realtime'
}

export const TYPE_ROLES_HUB = {
  HUB_PRO: 'hub_pro',
  HUB_PLUS: 'hub_plus',
  HUB_BUSSINES: 'hub_business'
}

export const TOKEN_DATA: TokenDataUser = {
  data: null,
  permissions: [],
  hasPermissionToPosturesBMV: false,
  hasPermissionToPosturesBIVA: false,
  hasPermissionToPosturesCons: false,
  hasPermissionToInternationalExchanges: false,
  hasPermissionToSectors: false,
  hasPermissionToRecommendation: false,
  hasPermissionToInvestmentFunds: false,
  isBmvDelay: false,
  isBivaDelay: false,
  isIndexBmvDelay: false,
  isIndexJdiAndSPDelay: false,
  isInternationalBagsDelay: false,
  isAmexDelay: false,
  isNyseDelay: false,
  isNasdaqDelay: false,
  hasPermissionToNewsMtNewswires: false,
  hasPermissionToGovernmentDebt: false,
  hasPermissionToInternationalDebt: false,
  hasPermissionToPrivateDebt: false,
  hasPermissionToBrokerageFirms: false,
  hasPermissionToConsolidated: false,
  hubRol: '',
  hasPermissionToMd2: false
}

export const configMomentLocaleEs = {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'Ene._Feb._Mar_Abr._May_Jun_Jul._Ago_Sep._Oct._Nov._Dic.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
}

export const INTRADAY_FREQUENCY = 'Diaria'

export const variants = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  ALERT: 'alert',
  DEFAULT: 'default'
}

export const CONDITIONS = {
  TOP: 'top',
  BOTTOM: 'bottom'
}

export const DefaultLimit = 10

export const InitPredefinedTables: QueriesSelectTap[] = [
  {
    color: '737980',
    name: 'Operaciones de casa de bolsa',
    widgetTypeId: 9,
    active: true,
    configuration: {
      selected: 'transactions',
      enableSort: true
    }
  },
  {
    color: '737980',
    name: 'Concentrado de operaciones',
    widgetTypeId: 9,
    active: true,
    configuration: {
      selected: 'transactionsExtract',
      enableSort: true
    }
  },
  {
    color: '737980',
    name: 'Casa de Bolsa más negociado',
    widgetTypeId: 9,
    active: true,
    configuration: {
      selected: 'mostNegotiated',
      enableSort: true
    }
  },
  {
    color: '737980',
    name: 'Casas de Bolsa: Resumen',
    widgetTypeId: 9,
    active: true,
    configuration: {
      selected: 'summary',
      enableSort: true
    }
  },
  {
    color: '737980',
    name: 'Valores con más cambios',
    widgetTypeId: 9,
    active: true,
    configuration: {
      selected: 'moreChanges',
      limitTop: 5,
      limitBottom: 5,
      marketId: MarketTypes.BMV.id,
      value: 'porcentaje',
      enableSort: false
    }
  },
  {
    color: '737980',
    name: 'Valores a la alza',
    widgetTypeId: 9,
    active: true,
    configuration: {
      selected: 'topValues',
      limitTop: 10,
      marketId: MarketTypes.BMV.id,
      value: 'porcentaje',
      condition: CONDITIONS.TOP,
      enableSort: false
    }
  },
  {
    color: '737980',
    name: 'Valores a la baja',
    widgetTypeId: 9,
    active: true,
    configuration: {
      selected: 'bottomValues',
      limitBottom: 10,
      marketId: MarketTypes.BMV.id,
      value: 'porcentaje',
      condition: CONDITIONS.BOTTOM,
      enableSort: false
    }
  }
]

export const UNIQUE_KEY_DLR_FIX = '32768/1/1000/DLR FIX'

export const DROP_TYPES = {
  SIDEBAR: 'SIDEBAR',
  TICKER: 'TICKER',
  TABLE: 'TABLE',
  CHART: 'CHART',
  COMPARATIVE_TABLE: 'COMPARATIVE_TABLE',
  PREDEFINED_TABLE: 'PREDEFINED_TABLE'
}

export const marketsIdsForTicker = [
  MarketTypes.CAPITALS.id,
  MarketTypes.INDEX.id,
  MarketTypes.CURRENCY.id,
  MarketTypes.INTERBANK_DOLLAR.id
]

export const MIN_INSTRUMENTS_TICKER = 5
export const MAX_INSTRUMENTS_TICKER = 20

export const currencyDefault = 'MXN'

export const CURRENCY_VALUES = [
  {
    id: 0,
    value: 'USD',
    label: 'USD (Dolar Americano)',
    status: true
  },
  {
    id: 1,
    value: 'MXN',
    label: 'MXN (Peso Mexicano)',
    status: true
  }
]

export const targetPriceSeries = [
  { display: 'Máximo', tvField: 'HIGHTARGET', apiField: 'highTargetCurrency', color: SPRING_GREEN },
  { display: 'Mínimo', tvField: 'LOWTARGET', apiField: 'lowTargetCurrency', color: DECREASING_RED },
  {
    display: 'Precio objetivo',
    tvField: 'MEANTARGET',
    apiField: 'meanTargetCurrency',
    color: BABY_BLUE
  }
]

export const originsGeneralSearched = {
  CREATE_NEW_CARD_TABLE: 'CREATE_NEW_CARD_TABLE',
  UPDATE_LIST_CARD_TABLE: 'UPDATE_LIST_CARD_TABLE',
  UPDATE_INSTRUMENT_GRAPHIC: 'UPDATE_INSTRUMENT_GRAPHIC',
  UPDATE_COMPARISION_INSTRUMENT_GRAPHIC: 'UPDATE_COMPARISION_INSTRUMENT_GRAPHIC'
}

export const disabledFiltersGeneralSearchedCurrency = [
  originsGeneralSearched.CREATE_NEW_CARD_TABLE,
  originsGeneralSearched.UPDATE_LIST_CARD_TABLE,
  originsGeneralSearched.UPDATE_INSTRUMENT_GRAPHIC,
  originsGeneralSearched.UPDATE_COMPARISION_INSTRUMENT_GRAPHIC
]

export const habilityGeneralSearch = [cardTypes.TABLE, cardTypes.COMPARATIVE_TABLE, cardTypes.PREDEFINED_TABLE]

export const hideComparisonButtonGraph = [MarketTypes.ECONOMIC_INDICATORS.id]

export const UserPermission = {
  ROLE_INTERNATIONAL_BAGS_TR: 'BI_RT',
  ROLE_POSTURAS: 'DEPTH_20_BMV',
  ROLE_POSTURAS_BIVA: 'DEPTH_20_BIVA',
  ROLE_POSTURAS_CONSOLIDATED: 'DEPTH_20_CONS',
  ROLE_SECTORIAL: 'SECTOR',
  ROLE_USER: 'user',
  ROLE_FONDOS: 'FONDOS',
  ROLE_BMV_DELAY: 'BMV_DL',
  ROLE_BMV_REAL_TIME: 'BMV_RT',
  ROLE_BIVA_DELAY: 'BIVA_DL',
  ROLE_BIVA_REAL_TIME: 'BIVA_RT',
  ROLE_INTERNATIONAL_BAGS_DELAY: 'BI_DL',
  ROLE_INTERNATIONAL_BAGS_REAL_TIME: 'BI_RT',
  ROLE_AMEX_DELAY: 'AMEX_DL',
  ROLE_NYSE_DELAY: 'NYSE_DL',
  ROLE_NASDAQ_DELAY: 'NASDAQ_DL',
  ROLE_INDEX_BMV_DELAY: 'INDICES_BMV_DL',
  ROLE_INDEX_DJI_SP_DELAY: 'DJI_SP_DL',
  ROLE_INDEX_DJI_SP_REAL_TIME: 'DJI_SP_RT',
  ROLE_MT_NEWSWIRES: 'MTN',
  ROLE_GUBERNAMENTAL_DEBT: 'GUBERNAMENTAL_DEBT_RT',
  ROLE_INTERNATIONAL_DEBT: 'INTERNATIONAL_DEBT_RT',
  ROLE_PRIVATE_DEBT: 'PRIVATE_DEBT_RT',
  ROLE_BROKERAGE_FIRMS: 'BROKERAGE_FIRMS_REPORTS',
  ROLE_CONSOLIDATED_RT: 'CONS_RT',
  ROLE_CONSOLIDATED_DL: 'CONS_DL',
  ROLE_FTSE: 'FTSE'
}

export const typeCollapse = {
  REPORT_PROBLEM: 0,
  SETTINGS: 1,
  ALERTS: 2,
  MY_HUB: 3
}

export const MONTHS = [
  {
    id: '01',
    name: 'Ene'
  },
  {
    id: '02',
    name: 'Feb'
  },
  {
    id: '03',
    name: 'Mar'
  },
  {
    id: '04',
    name: 'Abr'
  },
  {
    id: '05',
    name: 'May'
  },
  {
    id: '06',
    name: 'Jun'
  },
  {
    id: '07',
    name: 'Jul'
  },
  {
    id: '08',
    name: 'Ago'
  },
  {
    id: '09',
    name: 'Sep'
  },
  {
    id: '10',
    name: 'Oct'
  },
  {
    id: '11',
    name: 'Nov'
  },
  {
    id: '12',
    name: 'Dic'
  }
]

export const MONTHS_NAME_COMPLETE = [
  {
    id: '01',
    name: 'Enero'
  },
  {
    id: '02',
    name: 'Febrero'
  },
  {
    id: '03',
    name: 'Marzo'
  },
  {
    id: '04',
    name: 'Abril'
  },
  {
    id: '05',
    name: 'Mayo'
  },
  {
    id: '06',
    name: 'Junio'
  },
  {
    id: '07',
    name: 'Julio'
  },
  {
    id: '08',
    name: 'Agosto'
  },
  {
    id: '09',
    name: 'Septiembre'
  },
  {
    id: '10',
    name: 'Octubre'
  },
  {
    id: '11',
    name: 'Noviembre'
  },
  {
    id: '12',
    name: 'Diciembre'
  }
]

export const TIME_FRAME_DEFAULT = '6M'

export const TV_DRAWING_ACCESS = {
  type: 'black',
  tools: [
    { name: 'Patron XABCD' },
    { name: 'Patrón Cypher' },
    { name: 'Patrón ABCD' },
    { name: 'Patrón de triángulo' },
    { name: 'Patrón Three Drives' },
    { name: 'Cabeza y hombros' },
    { name: 'Onda de impulso de Elliott (12345)' },
    { name: 'Onda triangular de Elliott (ABCDE)' },
    { name: 'Onda de Elliott de triple combinación (WXYXZ)' },
    { name: 'Onda correctiva de Elliott (ABC)' },
    { name: 'Onda de Elliott de doble combinación (WXY)' },
    { name: 'Lineas cíclicas' },
    { name: 'Ciclos de tiempo' },
    { name: 'Línea del seno' }
  ]
}

export const BMV_MARKET = { assetClass: 'BMV', default: true, id: 1, label: 'BMV', marketTypeId: 1, status: true }

export const exchangesByMarketType = {
  '1': 'BMV',
  '2': 'BIVA',
  '4': 'CONSOLIDADO',
  '4096': 'DIVISAS'
}

export const internationlBagsAssetClass = ['NASDAQ', 'NYSE', 'AMEX']

export const marketTypeIdsDebt = [32780, 32781, 32782]

export const marketTypeGraphYield = [32781, 32782]

export const MarketTypesIdsEnableSecurities = [1, 2, 4]

export const marketTypeMapping = {
  1: 'BMV',
  2: 'BIVA',
  4: 'CMX',
  32: 'CMX'
}

export const adapterLTPMapping = {
  1: 'BMV_LTP',
  2: 'BIVA_LTP',
  4: 'CMX_LTP',
  32: 'CMX_LTP'
}

export const adapterBBOMapping = {
  1: 'BMV_BBO',
  2: 'BIVA_BBO'
}

export const adapterRETURNSMapping = {
  1: 'BMV_RETURNS',
  2: 'BIVA_RETURNS'
}

export const adapterDOBMapping = {
  1: 'BMV_DOB',
  2: 'BIVA_DOB'
}

export const adapterLTPALLMapping = {
  1: 'BMV_LTP_ALL',
  2: 'BIVA_LTP_ALL'
}

export const adapterGRAPHMapping = {
  1: 'BMV_LTP_ALL',
  2: 'BIVA_LTP_ALL'
}

export const adapterLTPMappingDelay = [
  adapterLTPMapping[1],
  adapterBBOMapping[1],
  adapterLTPALLMapping[1],
  adapterGRAPHMapping[1]
]

export const fieldsDOB = [
  'bidLevels',
  'offerLevels',
  'bidLevelsCount',
  'offerLevelCount',
  'timestamp',
  'symbol',
  'side'
]

export const sideValues = {
  COMPRA: 'B',
  VENTA: 'S'
}

export const intrumentUsdMxn = {
  descripcion: 'MXNUS',
  marketTypeId: '4096',
  valueTypeId: '50',
  exchangeId: '25',
  uniqueKey: '4096/50/25/MXNUS'
}

export const titlesBrowserTabs = {
  ANALYSIS: 'Infosel Hub Análisis',
  TERMINAL: 'Infosel Hub Terminal',
  GRAPHICS: 'Infosel Hub Gráficas'
}

export const localStorageKeysAnalysis = {
  instrumentAnalysis: 'instrumentAnalysis'
}
