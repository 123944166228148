import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'
import { ERROR_MESSAGE } from 'constants/colors'

import { DEEP_300, TREND_ACENT_100 } from 'constants/colorsDarkMode'

const MuiFormControl: {
  defaultProps?: ComponentsProps['MuiFormControl']
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormControl']
  variants?: ComponentsVariants['MuiFormControl']
} = {
  styleOverrides: {
    root: {
      minWidth: 80,
      '& .MuiInput-root::after': {
        borderBottom: `2px solid ${TREND_ACENT_100} !important`
      },
      '& .MuiInput-root::before': {
        borderBottom: `1px solid ${DEEP_300} !important`
      },
      '& .MuiInput-root:hover::before': {
        borderBottom: `2px solid ${DEEP_300} !important`
      },
      '& .Mui-disabled:hover::before': {
        borderBottom: `1px solid ${DEEP_300} !important`
      },
      '& .Mui-error::after': {
        borderBottom: `2px solid ${ERROR_MESSAGE} !important`
      }
    }
  },
  variants: []
}

export default MuiFormControl
