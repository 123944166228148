import React from 'react'
import { withStyles } from '@mui/styles'
import LinearProgress from '@mui/material/LinearProgress'
import { getLogo } from 'utils/helpers'
import type { Props, State } from './types'
import { Container, Image, ProgressContainer, ProgressBar } from './style'

class ProgressBarContainer extends React.Component<Props, State> {
  state = {
    completed: 0,
    interval: 0
  }

  componentDidMount() {
    this.setState({ interval: window.setInterval(() => this.progress(), 300) })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  progress = () => {
    if (this.state.completed === 100) {
      clearInterval(this.state.interval)
      this.props.loadingCompleted(true)
      return
    }
    const diff = Math.random() * 50
    this.setState((prevState) => ({
      completed: Math.min(prevState.completed + diff, 100)
    }))
  }

  render() {
    const { theme } = this.props

    const Logo = (
      <Container id="container-logo-hub">
        <Image id="logo-hub" src={getLogo(true, theme.palette.mode === 'dark')} alt="logo Infosel Hub" />
      </Container>
    )

    return (
      <ProgressContainer id="container-progressbar">
        {Logo}
        <ProgressBar id="progressbar">
          <LinearProgress id="linear_progress" variant="determinate" value={this.state.completed} />
        </ProgressBar>
      </ProgressContainer>
    )
  }
}

export default withStyles({}, { withTheme: true })(ProgressBarContainer)
