import { Theme, ComponentsProps, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles'

import { DEEP_500, DEEP_400 } from 'constants/colorsDarkMode'

import { FONT_SIZE_LARGE, LETTER_SPACING_03, FONT_WEIGHT_500 } from '../../constanst'

const MuiMenuItem: {
  defaultProps?: ComponentsProps['MuiMenuItem']
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenuItem']
  variants?: ComponentsVariants['MuiMenuItem']
} = {
  styleOverrides: {
    root: {
      minHeight: 'auto',
      padding: '8px 20px 8px 16px',
      fontWeight: FONT_WEIGHT_500,
      fontSize: FONT_SIZE_LARGE,
      textTransform: 'none',
      letterSpacing: LETTER_SPACING_03,
      fontStyle: 'normal',
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: 20
      },
      '&:hover': {
        backgroundColor: DEEP_500
      },
      '&.Mui-selected': {
        backgroundColor: DEEP_500,
        '&:hover': {
          backgroundColor: DEEP_400
        }
      }
    }
  },
  variants: []
}

export default MuiMenuItem
