import ACTION_TYPES from './actionTypes'
import { AccountReducer } from './types'

const initialState = {
  isLoading: false,
  isValidFromBackend: true,
  error: '',
  connectionStatus: ''
}

const reducer: AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      return { ...state, isLoading: true }

    case ACTION_TYPES.STOP_LOADING:
      return { ...state, isLoading: false }

    case ACTION_TYPES.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isValidFromBackend: false,
        connectionStatus: ''
      }

    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isValidFromBackend: true,
        error: '',
        connectionStatus: ''
      }

    default:
      return state
  }
}

export default reducer
