import i18n from 'i18next'

i18n.init({
  // we init with resources
  resources: {
    en: {
      translations: {
        LogOutButton: 'Log out',
        newFunction: 'News functions',
        changes: 'Changes',
        Introduction: 'Welcome to Infosel!',
        loginFormUsername: 'Email',
        loginFormPassword: 'Password',
        loginFormEnter: 'Log In',
        loginTextMessage: 'Enter your information to access your account',
        headerNewCardButton: 'ADD CARD',
        headerNewPortfolioButton: 'ADD NEW PORTFOLIO',
        headerSearchPlaceholder: 'Search for an instrument in Infosel Hub...',
        workspaceNoResult: 'Agrega tu primer tarjeta usando el botón de la derecha.',
        tipovalor: 'Tipo Valor',
        tipomercado: 'Tipo Mercado',
        bolsa: 'Stock',
        nombrelargo: 'Name',
        precioactual: 'Precio actual',
        importetotal: 'Importe total',
        hora: 'Hour',
        previousPrice: 'Previous price',
        porcentaje: 'Variación %',
        variacion: 'Variación unitaria',
        absoluteChange: 'Absolute change',
        volumentotal: 'Volume Total',
        purchaseValue: 'Precio de compra',
        purchaseVolume: 'Postura compra volumen',
        purchasePrice: 'Precio de compra',
        purchaseVolumbmv: 'Volumen de compra',
        saleValue: 'Precio de venta',
        saleVolume: 'Volumen de venta',
        posturapreciocompra: 'Post. Precio Cmpr',
        posturavolcompra: 'Post. Vol Cmpr',
        posturaprecioventa: 'Post. Prec. Vnt.',
        posturavolventa: 'Post. Volventa',
        preciomaxdia: 'Precio Máx.',
        maximum: 'Maximum',
        minimum: 'Minimum',
        preciomindia: 'Precio Mín.',
        numOfOperations: 'Número de operaciones',
        monitorvolumen: 'Monitor Vol.',
        foliocid: 'Folio Cid',
        fechaprecioactual: 'Date',
        posturafecha: 'Postura Fecha',
        preciopp: 'Precio Pp',
        ppp_oficial: 'Ppp',
        airecierre: 'Airecierre',
        descripcion: 'Instrumento',
        fulldescription: 'Description',
        precioapertura: 'Precio de apertura',
        preciomaximo: 'Precio Máx.',
        preciominimo: 'Precio Mín.',
        purchaseTime: 'Purchase time',
        fechaprecioanterior: 'Fecha precio anterior',
        previousDate: 'Previous date',
        volumenhecho: 'Vol. Hecho',
        seller: 'Seller',
        accion: 'Accion',
        PicoLote: 'Pico Lote',
        PrecioPico: 'Precio Pico',
        peakVolume: 'Peak volume',
        peakAmount: 'Peak amount',
        FechaPico: 'Fecha Pico',
        HoraPico: 'Hora Pico',
        newsFeatured: 'Featured',
        newsImportantTag: 'Important',
        sidebarDefaultHeader: 'Add new card',
        searchSymbolNoResults: 'No instruments were found that match your search',
        timestampMinutes: 'minutes',
        actualPrice: 'Current price',
        actualPriceValue: 'Value',
        variation: 'Variación',
        variationFixed: 'Variación Fija',
        volume: 'Volumen',
        lastClosure: 'Último cierre',
        amount: 'Importe',
        buyPosition: 'Posturas compra',
        salePosition: 'Posturas venta',
        industry: 'Industria',
        foundation: 'Fundación',
        founders: 'Fundadores',
        filiales: 'Filiales',
        employees: 'Empleados',
        location: 'Ubicación',
        address: 'Dirección',
        sidebarInformationTimestamp: 'Información al cierre.',
        low: 'Baja',
        high: 'Alta',
        chartTitle: 'Gráfica',
        tableTitle: 'Tabla',
        newsTitle: 'Noticias',
        comparativeTableTitle: 'Comparative table',
        generalIndicators: 'GENERAL INDICATORS',
        maxCardsLimitExceeded: 'Ya no puedes agregar más tarjetas',
        download: 'Descargar',
        newsletters: 'Newsletters',
        at: 'at',
        hour: 'hour',
        hours: 'hours',
        changeName: 'Change name',
        delete: 'Delete',
        undoText: 'Undo',
        okNewCard: 'Card created',
        concurrentUser: 'You logged in to another device, disconnecting ...',
        cardDeleted: 'Card has been removed',
        searchResults: 'Search results:',
        recentResults: 'Recent results:',
        period: 'Period',
        periodicity: 'Periodicity',
        interval: 'Interval',
        range: 'Range',
        Min: 'Min',
        Hour: 'Hour',
        Hours: 'Hours',
        seeMore: 'SEE MORE',
        seeLess: 'SEE LESS',
        purchase: 'Purchase price',
        sale: 'Sale price',
        purchaseVsSale: 'Purchase/Sale',
        spread: 'Spread',
        value: 'Current value',
        postureBidTime: 'Posture Bid Time',
        postureAskTime: 'Posture Ask Time',
        purchaseDate: 'Purchase Date',
        saleDate: 'Sale Date',
        instrument: 'Instrument',
        description: 'Description',
        lsconnection: 'I connect to the LS server: ',
        lsconnectionfail: 'Could not connect to server: ',
        monthlyNumber: 'Monthly Yiled',
        annualNumber: 'Anual Yield',
        weeklyNumber: 'Semanual Yield',
        volumen: 'Volume',
        rendEnAnoNumber: 'Yield per Year',
        precioliquido: 'Precio de liquidación',
        precioanteriorliquido: 'Precio anterior de liquidación',
        innerValue: 'Inner value',
        operation: 'Operation',
        maxPrice12Months: 'Maximum price (12 months)',
        minPrice12Months: 'Minimum price (12 months)',
        peakPrice: 'Peak price',
        averagePrice: 'Average price',
        monthlyYield: 'Monthly yield',
        weeklyYield: 'Weekly yield',
        annualYield: 'Annual yield',
        yieldPerYear: 'Yield per year',
        maxVol52s: 'Vol. Max. 52(s)',
        minVol52s: 'Vol. Min. 52(s)',
        averageVol3a: 'Vol. Avg. 3(a)',
        averageVol3m: 'Vol. Avg. 3(m)',
        isin: 'ISIN',
        serie: 'Fixed Income Serie',
        interest24H: 'Interest 24H',
        scoreFitch: 'Score Fitch',
        scoreMoodys: "Score Moody's",
        scoreSP: 'Score S&P',
        scoreHR: 'Score HR',
        daysToExpire: 'Days to expire',
        fixedIncomeDate: 'Date',
        dayMaxValue: 'Day Max Value',
        dayMinValue: 'Day Min Value',
        openingPrice: 'Opening price',
        yieldRate: 'Yield Rate',
        valueType: 'Value Type',
        fixedIncomeTM: 'TM',
        fixedIncomeTV: 'TV',
        fixedIncomeQuotationKey: 'Quotation Key',
        emissionDate: 'Emission date',
        cleanPrice: 'Clean price',
        dirtyPrice: 'Diry price',
        surtaxPIP: 'Surtax',
        mdCleanPrice: 'Clean price MD',
        mdDirtyPrice: 'Dirty price MD',
        volumeMade: 'Volume done',
        postureDate: 'Posture Date',
        postureHour: 'Posture Hour',
        unitVariation: 'Variación unitaria',
        stockpAsk: 'Posture Stock Ask',
        stockpBid: 'Posture Stock Bid',
        stockValue: 'Stock Value',
        noMoreInformationMessage: 'There is no more information to show.',
        pppValue: 'Value / PPP',
        pppNumber: 'PPP',
        minutes: 'minutes',
        day: 'day',
        widgetNotFound: 'Información del widget no encontrada',
        basic: 'BASIC',
        sortAZ: 'Sort A → Z',
        sortZA: 'Sort Z → A',
        moveRight: 'Move right',
        moveLeft: 'Move left',
        copy: 'Copy',
        copyToClipboard: 'Copied to clipboard',
        blockColumn: 'Block column',
        deleteColumn: 'Delete column',
        unblockColumn: 'Unblock column',
        compare: 'Compare',
        addComparison: 'Add Comparison',
        compareSmallSize: 'cf.',
        draw: 'Draw',
        studies: 'Studies',
        styles: 'Styles',
        view: 'View',
        withoutTool: 'Without tool',
        measure: 'Measure',
        fibArc: 'FibArc',
        fibFan: 'FibFan',
        gannFan: 'GannFan',
        channel: 'Channel',
        fibTimeUse: 'Fib time use',
        resistanceArc: 'Resistance arc',
        resistanceLine: 'Resistance line',
        timeCycle: 'Time cycle',
        shapes: 'Shapes',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        trendline: 'Trendline',
        diagonal: 'Diagonal',
        regression: 'Regression',
        annotation: 'Annotation',
        text: 'Text',
        trend: 'Triend',
        momentum: 'Momentum',
        volatility: 'Volatility',
        menuStudiesVolume: 'Volume',
        duplicate: 'Duplicate',
        block: 'Block',
        noConnection: 'No connection',
        deafultWorkspaceName: 'Workspace',
        newWorkspace: 'New workspace',
        viewDetails: 'View Details',
        copyRow: 'Copy Row',
        duplicateRow: 'Duplicate row in',
        blockRow: 'Block row',
        deleteRow: 'Delete row',
        moveUp: 'Move up',
        moveDown: 'Move down',
        addInstrument: 'Add instrument...',
        graph: 'Graph',
        workspaceHasBeenDeleted: 'The workspace has been deleted',
        workspaceDuplicated: 'Duplicated workspace',
        duplicateWorkspaceModal: 'Are you sure you want to duplicate',
        deleteWorkspaceModal: 'Are you sure you want to delete',
        agree: 'Agree',
        disagree: 'Disagree',
        workspace: 'workspace',
        removeStudy: 'Delete study',
        configStudy: 'Edit settings...',
        btnSave: 'Save',
        deleteAll: 'Delete All',
        sortNumberHighLow: 'Sort largest to smallest',
        sortNumberLowHigh: 'Sort smallest to highest',
        price: 'Precio',
        tickerSmall: 'Reduce size',
        tickerLarge: 'Expand size',
        hide: 'Hide',
        workspacesLimitExceeded: 'You have reached the limit of allowed Workspaces.',
        instrumentAdded: 'Instrument added',
        instrumentsAdded: 'Instruments added',
        yearShortcut: 'Y',
        bmvStocks: 'BMV',
        bivaStocks: 'BIVA',
        bmvSic: 'BMV (SIC)',
        nyseStocks: 'NYSE, AMEX and NASDAQ',
        currencies: 'Currency',
        bivaSic: 'BIVA (SIC)',
        indices: 'Indices',
        fixedIncome: 'FIXED INCOME',
        derivatives: 'DERIVATIVES',
        nyse: 'NYSE',
        amex: 'AMEX',
        nasdaq: 'NASDAQ',
        newChartTitle: 'What do you want to graph?',
        chooseMarket: 'Choose a market or instrument.',
        newTableTitle: 'Add new table.',
        predefinedTitle: 'Add predefined table.',
        chooseTableMarket: 'They have the contents of all markets',
        add: 'Add',
        bmvStocksInTitle: 'What stock of BMV do you want to graph?',
        bivaStocksInTitle: 'What stock of BIVA do you want to graph?',
        bmvSicInTitle: 'What stock of BMV - SIC do you want to graph?',
        nyseStocksInTitle: 'What stock of NYSE do you want to graph?',
        currenciesInTitle: 'What foreign exhange do you want to graph?',
        bivaSicInTitle: 'What stock of BIVA - SIC do you want to graph?',
        indicesInTitle: 'What index do you want to graph?',
        fixedIncomeInTitle: 'What stock of fixed income do you want to graph?',
        derivativesInTitle: 'What stock of derivatives do you want to graph?',
        generalIndicatorsInTitle: 'What stock of general indicators do you want to graph?',
        nasdaqInTitle: 'What stock of NASDAQ do you want to graph?',
        amexInTitle: 'What stock of AMEX do you want to graph?',
        show: 'To show ticker',
        onError: 'An error has occurred',
        noChartDataMessage: 'Loading instrument information.',
        deleteCard: 'Delete card',
        moveCard: 'Move card',
        workspaces: 'SPACES',
        addNewInstrumentTitle: 'Add new instruments:',
        duplicateCard: 'Card has been duplicated',
        pricesMaxAndMin: 'Máx/Mín',
        capitalization: 'Capitalization',
        companyValue: 'Company Value',
        annualPerformance: 'Annual Performance',
        last12MonthsPerformance: 'Last 12M performance',
        maxOfTheYear: 'Max Of The Year',
        maxLast12Months: 'Máx. Ult. 12M',
        minOfTheYear: 'Min Of The Year',
        minLast12Months: 'Min. Ult. 12M',
        averageDailyVolume: 'Average Daily Volume (3M)',
        divYield: 'Div. Yield',
        targetPrice: 'Target Price',
        recommendation: 'Recommendation',
        opening: 'Opening',
        numOrder: 'Num Order',
        purchaseVolumePP: 'Purchase volume',
        purchaseDepth: 'Purchase',
        saleDepth: 'Sale',
        volSale: 'Vol Sale',
        numOrders: 'Num Orders',
        ticker: 'Ticker',
        quantity: 'Quantity',
        ppp: 'PPP',
        liquidation: 'Liquidation',
        graphDesc: 'Graph',
        sevenDays: '7 days',
        thirtyDays: '30 days',
        ofYear: 'Of year',
        lastTwelveMonths: 'Last 12 m.',
        pupa: 'P/UPA',
        pvl: 'PVL',
        transmitter: 'Transmitter',
        suggestedForex: 'Suggested Forex',
        suggestedStocks: 'Suggested Stocks',
        predefinedIpc: 'IPC',
        predefinedBanorte: 'Banorte Funds',
        brokerageHouse: 'Brokerage house',
        percentageShare: 'Participación %',
        startWithTheName: "Let's start with the name",
        searchAndChooseInstruments: 'Search and select the instruments of your interest',
        searchAndChooseInstrument: 'Search and select the instrument of your interest',
        selectDetails: 'Select details',
        continue: 'Continue',
        leftArrow: 'Left arrow',
        rightArrow: 'Right arrow',
        calendarIcon: 'Calendar',
        studiesLists: 'Studies lists',
        deleteList: 'Delete list',
        addNewList: 'Add new list',
        deselectAll: 'Deselect all',
        couponRate: 'Coupon rate',
        saleRate: 'Sale rate',
        purchaseRate: 'Purchase rate',
        createYourPortfolio: 'Create your Portfolio',
        newPortfolio: 'New portfolio',
        inputPlaceholder: 'New Portfolio',
        instruments: 'Instruments',
        titlesPerInstrument: 'Select titles number per instruments',
        movementDate: 'Movemente date',
        toComplete: 'To complete',
        composicion: 'Composicion',
        newOperation: 'New operation',
        performance: 'Performance',
        calendar: 'Calendar',
        maxInstrumentsMessage: 'You can select up to 30 instruments',
        maxInstrumentsMessageTerminal: 'You can select up to 40 instruments',
        withOutResults: 'No results',
        applySameCommission: 'Would you like to apply this commission to all the instruments',
        dateLabel: 'Date',
        choose: 'Choose',
        labelFrom: 'From',
        labelTo: 'To',
        governmentDebt: 'Government Debt',
        instrumentsOfYourInterest: 'Instrumento de tu interés',
        orderDetailsTitle: 'Order details',
        selectInstrument: 'Select instrument',
        buyTitle: 'Buy',
        saleTitle: 'Sale',
        dateTitle: 'Date',
        tenTitles: '10 titles',
        titles: 'Titles',
        comission: 'Comission %',
        netAmount: 'Net Amount',
        registeredOrder: 'Successful registered order',
        type: 'Type',
        titlesNumber: 'Titles number',
        addAnother: 'Add another',
        goToPortfolio: 'Go to Portfolio',
        portfolio: 'Portfolio',
        movements: 'Movements',
        instrumentNewOperation: 'Use instrument for new operation',
        minimumOneTitle: 'Enter at least one title',
        percentageLessThanOneHundred: 'The commission percentage must be less than 100%',
        aggregateOrder: 'Aggregate order',
        portfolioCreated: 'Portfolio created',
        max30Instruments: 'You can have up to 30 instruments',
        noInstrumentsInPortfolio: 'There are no instruments in the Portfolio!',
        reportAProblem: 'Report a problem',
        myMarkets: 'My markets',
        portfolioOption: 'Portfolio',
        describeYourProblem: 'Describe your problem',
        addScrenshot: 'Add screenshots',
        duplicatedPortfolioName: 'The Portfolio name exists, please select another name',
        addGraphTo: 'Add graph to...',
        thisSpace: 'This space',
        InThisSpace: 'In this space',
        otherSpace: 'Other space',
        newSpace: 'New space',
        yourSpaces: 'Your spaces',
        addNewCardToSpace: 'You have added a new card, in the space',
        comissionWarning: 'Comission percentage should be minor to 100%',
        titulosWarning: 'You at least one share per instrument',
        precioActualWarning: 'You need a price greater than 0',
        onlySellTitlesHaveBuy: 'You can only sell the titles you have bought',
        informationNotAvailable: 'Information not available',
        rentability: 'RENTABILITY',
        valuation: 'Valuation',
        yearToYearGrowth: 'Year-on-year growth',
        showDetail: 'EXPAND DETAILS',
        incomeStatement: 'Income statement',
        balance: 'Balance sheet',
        cashFlow: 'Cash flow',
        allLabel: 'All',
        noteLabel: 'Note',
        financialReport: 'Financial report',
        keyCSV: 'Key',
        textCSV: 'Text',
        sendReport: 'Send report',
        chooseAView: 'Choose a view for your reading needs',
        appearance: 'Appearance',
        changeInterfaceStyle: 'Change the interface style',
        textSize: 'Text size',
        chooseSuitableSize: 'Choose the most suitable size for your vision',
        colors: 'Colors',
        chooseColor: 'Choose a color palette',
        compact: 'Compact',
        commode: 'Commode',
        dark: 'Dark',
        light: 'Light',
        small: 'Small',
        medium: 'Medium',
        big: 'Big',
        default: 'Default',
        colorful: 'Colorful',
        traditional: 'Traditional',
        colorBlind: 'Color blind',
        monochromatic: 'Monochromatic',
        restore: 'Restore',
        customization: 'Customization',
        settingsUpdated: 'Settings updated',
        maximumDayPrice: 'Maximum Day Price',
        minimumDayPrice: 'Minimum Day Price ',
        accumulatedVolume: 'Accumulated volume',
        maxNumberMessage: 'Number of selected images exceed 3',
        maxFileSizeMessage: 'Selected file size exceed maxFileSize',
        acceptTypeMessage: 'Your selected file type is not allow',
        resolutionMessage: 'Selected file is not match your desired resolution',
        successSubMessage: "Thank you, we'll review your comments",
        feedbackMessageError: 'Please enter a valid message',
        sent: 'Sent',
        generateAnotherOne: 'Generate another',
        reportSent: 'Report sent',
        sector: 'Sector',
        executives: 'Executives',
        website: 'Website',
        reportType: 'Report type',
        internationalBags: 'International Bags',
        loginError: 'Invalid user credentials',
        deleteInstrument: 'Instrument removed',
        xbrlTitleReport: 'Comparisons of Nominal Financial Reports',
        multiplesTitleReport: 'Multiples Reports',
        finalYear: 'Final year',
        initialYear: 'Initial year',
        socialReason: 'Social reason',
        allLabels: 'All',
        monthToDate: 'Month to date',
        monthly: 'Monthly',
        yearToDate: 'Year to date',
        last2lveMonths: 'Last 12 months',
        fromText: 'From: ',
        toText: 'To: ',
        currentLevel: 'Current level',
        newList: 'New List',
        myLists: 'My lists',
        newListName: 'Choose a name for your new studies list',
        analysisOption: 'Analysis',
        drawingOption: 'Drawing & annotations',
        marketCapitalization: 'marketCapitalization',
        marketValue: 'Market value of the company.',
        formulaMarketCapitalization: 'Chap. Market = Price x Shares in circulation',
        cardMaximumMessage: 'You can only have a maximum of 12 cards per workspace.',
        sameDay: 'Same day',
        enterAValidListName: 'Enter a valid list name',
        posturesDepth: 'Postures depth',
        addAsCard: 'Add As card',
        convertTable: 'Convert to predefined table',
        convertedTable: 'Table has been converted',
        studyKagi: 'Set reversal percentange',
        studyLineBreak: 'Set price lines',
        studyRenko: 'Set brick size',
        studyRange: 'Set range',
        enterANumber: 'Please enter a number',
        newComparisonTableTitle: 'Add comparison table',
        searchAndChooseInstrumentsSic: 'Search and choose the SIC instruments of your interest',
        todayLabel: 'Today',
        infoByInfosel: 'Info calculated by Infosel',
        infoLastQuarter: 'Info updated to last quarter',
        last12Months: 'Last 12M',
        economicIndicators: 'Economic Indicators',
        previousLevel: 'Previous level',
        units: 'Units',
        lastUpdate: 'Last update',
        serieType: 'Serie type',
        frecuency: 'Frecuency',
        indicators: 'Indicators',
        purchaseCash: 'Purchase cash',
        saleCash: 'Sale cash',
        purchaseCash24hrs: 'Purchase 24h',
        saleCash24hrs: 'Sale 24h',
        purchaseCash48hrs: 'Purchase 48h',
        saleCash48hrs: 'Sale 48h',
        interbankDollar: 'Interbank dollar',
        sourceFactset: 'Source: Factset',
        informationWithClosingPrices: 'Information with closing prices',
        Comprar: 'Buy',
        Vender: 'Sell',
        Mantener: 'Hold',
        labelRecomendation: 'The price and the recommendation shown are on the most stock series of each issuer',
        investmentFunds: 'Investment funds',
        doneLabel: 'done',
        referenceRates: 'Reference Rates',
        expiration: 'Expiration',
        availability: 'Availability',
        source: 'Source',
        priceWithDelay: 'Price with delay of [20] min.',
        viewInformation: 'You want to see this information?',
        noViewInformation: "Why can't I see this information?",
        unlockInformation: 'Want to unlock this information?',
        youDoNotHaveThisInformation: "Why can't I see this information?",
        total: 'Total (Show All)',
        participacion: 'Participation',
        verTabla: ' Show table',
        verGrafica: ' Show Graphic',
        sampleSize: 'Sample size',
        targetPriceInformation: 'The target price shown is the average price of the sample',
        investmentHorizon: 'Investment horizon',
        liquidity: 'Liquidity',
        qualification: 'Qualification',
        possibleAcquirers: 'Possible acquirers',
        minimumAmount: 'Minimum amount',
        typeOfInvestmentFund: 'Type of investment fund',
        investorType: 'Investor type',
        operator: 'Operator',
        netAsset: 'Activo neto',
        totalAmount: 'Total amount',
        closingTime: 'Closing time',
        authorizationDate: 'Authorization date',
        category: 'Category',
        investmentRegime: 'Investment regime',
        sharpeRatio: 'Sharpe ratio',
        rendYearAnnualized: 'Yield. in the Year (Annualized)',
        rendMonthlyAnnualized: 'Yield. Monthly (Annualized)',
        rendWeeklyAnnualized: 'Yield. Weekly (Annualized)',
        copyNews: 'Copy news',
        copiedNews: 'Copied news',
        reportsBrokerageHouses: 'Reports: Brokerage houses',
        reportCenterTitle: 'Report Center',
        latestReports: 'Latest reports',
        myReports: 'My reports',
        searchNews: 'Search news',
        noMatches: 'No matches',
        relatedNews: 'Related News',
        percentage: 'Percentage',
        X: 'X (Crosses)',
        C: 'C (Buys)',
        V: 'V (Sales)',
        Totale: 'Total',
        chooseTheStations: 'To get started, choose the stations',
        chooseTheBrokerageHouse: 'Choose the brokerage house',
        consult: 'Consult',
        results: 'Results',
        edit: 'Edit',
        financialReasons: 'Financial Reasons',
        reportBy: 'Report By',
        numRecords: 'Nro. Max',
        effectivePerformance: 'Effective performance. Does not consider commissions',
        median: 'Median',
        purchaseVariation: 'Purchase variation',
        salesVariation: 'Sales variation',
        informationInterbankDollar:
          'Reference prices. Quotes provided by financial intermediaries under their own responsibility.',
        expressedMillions: 'The information is expressed in millions',
        multiples: 'Multiples',
        intraday: 'Intraday',
        localAnalysts: 'Local analysts',
        recommendations: 'Recommendations',
        updateOnceAQuarterLabel: 'This information is updated once a quarter',
        repeatedInformation: `This section can display duplicate information with the "Local Analysts" section`,
        operationData: 'Operation data',
        summary: 'Summary',
        labelconversionFromPOtoUSDorMXN:
          'The conversion of PO to USD or MXN takes as reference the level of the previous close',
        expectedReturn: 'The expected return takes as a reference the price of the previous close',
        expectedPerformance: 'Expected performance',
        lastDoneDate: 'Last done date',
        lastDoneTime: 'Time último hecho',
        openingRate: 'Opening rate',
        maximumRateDay: 'Maximum rate of the day',
        minimumRateDay: 'Minimum rate of the day',
        preClosingRate: 'Pre-closing rate',
        netRate: 'Net rate',
        yield: 'Yield',
        doneMax12M: 'Max 12M (Done)',
        doneDateMax12M: 'Date Max 12M (Done)',
        doneMin12M: 'Min 12M (Done)',
        doneDateMin12M: 'Date min 12M (Done)',
        marketSurcharge: 'Market surcharge',
        newQueryTitle: 'Add query table',
        chooseOptionQueries: 'Choose from the available options',
        currentRate: 'Current rate',
        surcharge: 'Surcharge',
        dueDate: 'Due date',
        paymentFrequency: 'Payment frequency',
        issuanceAmount: 'Issuance amount',
        amountCirculation: 'Amount in circulation',
        currentFaceValue: 'Current face value',
        interest24h: '24H interest',
        issuerInformation: 'Issuer information',
        trade: 'Trade',
        subbranch: 'Subbranch',
        country: 'Country',
        instrumentInformation: 'Instrument information',
        model: 'Modelo',
        couponStart: 'Coupon start',
        couponEnd: 'Coupon end',
        currency: 'Currency',
        duration: 'Duration',
        convexity: 'Convexity',
        originalParValue: 'Original par value',
        validCoupon: 'Valid coupon',
        expiringCoupons: 'Expiring Coupons',
        couponPeriod: 'Coupon period',
        reviewRate: 'Review/Rate',
        emissionInformation: 'Emission information',
        countryHolidays: 'Convention (Country Holidays)',
        placementDate: 'Placement date',
        issuedSecurities: 'Issued securities',
        titlesCirculation: 'Titles in circulation',
        discountRate: 'Discount rate',
        amortization: 'Amortization',
        amortizationType: 'Amortization type',
        amortizationAmount: 'Amortization amount',
        investmentType: 'Investment type',
        quoteType: 'Quote type',
        qualifications: 'Qualifications',
        spLabel: 'S&P',
        hrlabel: 'HR',
        addAlert: 'Add alert',
        newAlert: 'New alert',
        greater: 'Greater',
        equal: 'Equal',
        less: 'Less',
        errorMessageInstrumentKey: 'Error get Instrument',
        errorExpirationDate: 'Date Required',
        required: 'Required',
        onlyNumbersPositives: 'Only positive numbers',
        by: 'By',
        validity: 'Validity',
        zeroNotAllowed: 'Zero not allowed',
        createAlertSuccess: 'Alert created successfully',
        createAlertError: 'Error creating alert',
        createAlertErrorExpirationDate: 'Alert not created! Expiration date cannot be less than current date',
        marketClosed: 'Upss... Market closed ! change the date and try again',
        saving: 'Saving...',
        configurationQuerie: 'Configure content',
        inQueries: 'In query card',
        apply: 'Apply',
        checkboxEmptys: 'You must select at least one group',
        checkboxEmptysBrokerage: 'You must select at least one brokerage house',
        minimumSample: 'The minimum sample is',
        maximumSample: 'The maximum sample is',
        actionGroup: 'Choose the groups of actions',
        maximTopInstruments: ' Number of instruments on the rise',
        maximBottomInstruments: 'Number of instruments down',
        see: 'See',
        internationalDebt: 'International debt',
        currentRateDate: 'Current rate date',
        previousClosingRate: 'Previous closing rate',
        maximumVariations: 'Maximum variations',
        minimumVariations: 'Minimum variations',
        openingVariations: 'Opening variations',
        percentageChange: 'Percentage change',
        privateDebt: 'Private Debt',
        dayCountFraction: 'Convention (Day Count Fraction)',
        markToSeen: 'Mark to seen',
        validateDrop: 'No se puede realizar la acción',
        helpUsImprove: 'Help us to improve',
        submitAndParticipate: 'Submit and Participate',
        titleModalNps: 'Participate and enter the draw for an Amazon card for $1,000 MXN',
        subtitleModalNps: 'Would you recommend this platform to a friend?',
        tellusAbaoutYouExperience: 'Tell us about your experience (optional)',
        youWould: 'You would not do that',
        yesWidely: 'Yes, widely',
        finishResponseFormNps: '¡Thank you for helping us keep improving.!',
        errorResponseFormNps: 'Error sending response',
        maxPriceOfTheDay: 'Max price of the day',
        minPriceOfTheDay: 'Min price of the day',
        alert: 'ALert to',
        created: 'created',
        deleteWidgetLabel: 'Are you sure you want to permanently delete this card?',
        showMore: 'Show more',
        myalerts: 'My alerts',
        allAlerts: 'My alerts',
        copyClipboard: 'Copy content',
        copyChartToClipboard: 'Graph copied to the clipboard',
        errorCopyToClipboard: 'An error occurred while copying to the clipboard',
        copyTableToClipboard: 'Table copied to the clipboard',
        alertPrice: 'Alert price',
        validateSIC: 'Only SIC instruments can be added to this card',
        newQueries: 'New queries',
        addNewQuery: 'Add new query',
        marketType: 'Market type',
        chooseLeastOneCondition: 'Choose at least one condition',
        chooseOneCondition: 'Choose one condition',
        market: 'Market',
        outstanding: 'Outstanding',
        printWidget: 'Print widget',
        errorPrint: 'Error printing widget',
        load: 'Load',
        compareMultiple: 'Compare chosen instruments',
        limitCompare: 'Cannot compare more than 6 instruments',
        mostRead: 'Most read',
        latestNews: 'Latest news',
        seeAllResults: 'See all results',
        refineYourSearchUsing: 'Refine your search using',
        conditionSearch: `“and”, “or”, “without” or the word in quotes “”`,
        typeOneOrMoreKeywords: 'Type one or more keywords',
        historic: 'Historic',
        noInformation: 'No information available for this station',
        writeSearchInstrument: 'Write clave or description...',
        outstandingShares: 'Outstanding shares*',
        timeDone: 'Time done',
        dateDone: 'Date done',
        noAlerts: 'No Alerts',
        BMVInformation: 'Report with BMV information',
        alls: 'Alls',
        graphs: 'Graphs',
        consolidate: 'Consolidate',
        consolidates: 'Consolidates',
        selectAll: 'Select all',
        selected: 'Selected',
        newTable: 'New table',
        preciousMetals: 'Precious metals',
        sevenDayQuote: 'Quote 7 days',
        thirtyDayQuote: 'Quote 30 days',
        previousYearPrice: 'Previous year price',
        newGraphicTitle: 'Add new graphic',
        newGraphical: 'New graphical',
        level: 'Level',
        infoLastDay: 'The information in this section is updated at the end of the day',
        typeFund: 'Type fund',
        capitals: 'Capitals',
        graphic: 'Graphic',
        compairInstrument: 'Compare instruments',
        reason: 'Reason',
        infoByMorningStar: 'Source: Morning Star',
        limitInstrumentConvert: 'Must have at least one instrument',
        infoQueries: 'Information is updated every 90 seconds, last update',
        infoQueriesCapitals: 'The instrument list is updated every 90 seconds, last. update',
        infoOperations: 'The last 4,000 operations are displayed',
        changeInstrument: 'Change instrument',
        effectiveYield: 'Effective yield',
        annualizedYield: 'Annualized yield',
        calendarTitle: 'Calendar',
        hub: 'Hub',
        contact: 'Contact us to extend your current package:',
        noReports: 'No reports',
        forgotPassword: 'Forgot your password',
        back: 'Back to',
        validate: 'Validate',
        whatEmailYouHave: 'What is the email you have registered?',
        responseRecoverPasswordSuccess: 'Check your inbox (or spam) and follow the instructions we have sent to',
        backToHome: 'Back to home',
        emailValid: 'Enter a valid email',
        editInstruments: 'Edit instruments',
        update: 'Update',
        addSectoralAnalysisTo: 'Add sectoral analysis to...',
        improveYourPlan: 'Do you want to improve your plan? contact us...',
        realTime: 'Real time',
        delayedPrice: 'Delayed price of',
        onlyShowActiveInstruments: 'Only show active instruments',
        resetPassword: 'Reset password',
        errorUpdatePassword: 'An error occurred while updating the password!',
        errorSecondPassword: 'The second password does not match the first, type it again',
        enterNewPassword: 'Enter your new password',
        repeatPassword: 'repeat password',
        mustContain: 'Must contain',
        updatePasswordSuccess: 'Password changed successfully!',
        Online: 'Online',
        closeMovement: 'Close movement',
        GetInto: 'Get into',
        petroleum: 'Petroleum',
        columnManager: 'Column manager',
        configurationFor: 'Configuration for',
        manageColumns: 'Manage columns',
        search: 'Search',
        findAvailableColumns: 'Find available columns',
        emptyStateSearch: 'There are no matches in the search, please try again',
        emptyStateInfo: 'Search among all available columns for your table configuration',
        availableColumns: 'Available columns',
        myColumns: 'My columns',
        searchResult: 'Resultados de la búsqueda',
        Offline: 'Offline',
        applyChanges: 'Apply changes',
        numberOfOperations: 'Num. of operations',
        seeMoreData: 'See more data',
        seeLessData: 'See less data',
        deleteAlert: 'Delete alert',
        deleteConfirm: '¿Are you sure to delete this alert permanently?',
        generalDirector: 'General  director',
        finantialDirector: 'Finantial director',
        updateInstruments: 'Updating instruments',
        errorUpdateInstruments: 'Error updated instruments',
        deleteAllImages: 'Delete all images',
        hideOptions: 'Hide options',
        moreOptions: 'More options',
        retry: 'Retry',
        errorGetInstruments: 'Error getting instruments',
        setting: 'Setting',
        colorTheme: 'Color theme',
        subtitleTheme: 'Choose the theme according to your needs and comfort.',
        system: 'System',
        tableTitlePredefined: 'Table predefined',
        tableTitleNewTable: 'Table new',
        queries: 'Query predefined',
        subtitleTicker: 'This is displayed at the bottom of the screen.',
        updateColumns: 'Changes have been applied',
        analysis: 'Analysis',
        subsector: 'Subsector',
        selectOneOption: 'Select one option',
        snapshotTitle: 'Add snapshot',
        snapshot: 'Snapshot',
        downloadFile: 'downloading files...',
        downloadError: 'There was a problem with the download, please try again',
        downloadSuccess: 'The file has been downloaded successfully',
        showMoreDetail: 'Show more details',
        financial: 'Financial',
        graphicalAdvance: 'Graphical advance',
        reduce: 'Reduce',
        increase: 'Increase',
        maximize: 'Maximize',
        minimize: 'Minimize'
      }
    },
    es: {
      translations: {
        LogOutButton: 'Cerrar sesión',
        newFunction: '¡Lo nuevo en Hub!',
        changes: 'Fixes',
        Introduction: 'Bienvenido a infosel!',
        loginFormUsername: 'Correo electrónico',
        loginFormPassword: 'Contraseña',
        loginTextMessage: 'Inicia sesión en HUB',
        loginFormEnter: 'Inicia sesión',
        headerNewCardButton: 'AGREGAR TARJETA',
        addAsCard: 'Agregar como tarjeta',
        headerNewPortfolioButton: 'AGREGAR PORTAFOLIO',
        headerSearchPlaceholder: 'Buscar un instrumento en Infosel Hub...',
        workspaceNoResult: 'Agrega tu primer tarjeta usando el botón de la derecha.',
        nombrelargo: 'Nombre',
        tipomercado: 'Tipo Mercado',
        tipovalor: 'Tipo Valor',
        bolsa: 'Bolsa',
        precioactual: 'Precio actual',
        importetotal: 'Importe total',
        hora: 'Hora',
        previousPriceValue: 'Valor anterior',
        previousPrice: 'Precio anterior',
        porcentaje: 'Variación %',
        variacion: 'Variación unitaria',
        absoluteChange: 'Variación unitaria',
        volumentotal: 'Volumen total',
        purchaseValue: 'Precio de compra',
        purchaseVolume: 'Volumen de compra',
        purchasePrice: 'Precio de compra',
        purchaseVolumen: 'Volumen de compra',
        saleValue: 'Precio de venta',
        saleVolume: 'Volumen de venta',
        posturapreciocompra: 'Post. Precio Cmpr',
        posturavolcompra: 'Post. Vol Cmpr',
        posturaprecioventa: 'Post. Prec. Vnt.',
        posturavolventa: 'Post. Volventa',
        preciomaxdia: 'Precio Máx.',
        maximum: 'Máximo',
        minimum: 'Mínimo',
        numOfOperations: 'Número de operaciones',
        monitorvolumen: 'Monitor Vol.',
        lastDate: 'Fecha',
        foliocid: 'Folio Cid',
        fechaprecioactual: 'Fecha',
        posturafecha: 'Fecha postura',
        preciopp: 'Precio Pp',
        ppp_oficial: 'Ppp',
        airecierre: 'Airecierre',
        descripcion: 'Instrumento',
        fulldescription: 'Descripción',
        precioapertura: 'Precio de apertura',
        preciomaximo: 'Precio Máx.',
        preciominimo: 'Precio Mín.',
        purchaseTime: 'Hora postura',
        fechaprecioanterior: 'Fecha precio anterior',
        previousDate: 'Fecha anterior',
        volumenhecho: 'Volumen hecho',
        buyer: 'Comprador',
        seller: 'Vendedor',
        accion: 'Accion',
        PicoLote: 'Pico Lote',
        PrecioPico: 'Precio Pico',
        peakVolume: 'Volumen Pico',
        peakAmount: 'Importe Pico',
        FechaPico: 'Fecha Pico',
        HoraPico: 'Hora Pico',
        newsFeatured: 'Destacados',
        newsImportantTag: 'Importante',
        sidebarDefaultHeader: 'Agregar nueva tarjeta',
        searchSymbolNoResults: 'No se encontraron instrumentos que coincidan con tu búsqueda',
        timestampMinutes: 'minutos',
        actualPrice: 'Precio actual',
        actualPriceValue: 'Valor',
        variation: 'Variación',
        variationFixed: 'Variación Fija',
        volume: 'Volumen',
        lastClosure: 'Último cierre',
        amount: 'Importe',
        buyPosition: 'Posturas compra',
        salePosition: 'Posturas venta',
        industry: 'Industria',
        foundation: 'Fundación',
        founders: 'Fundadores',
        filiales: 'Filiales',
        employees: 'Empleados',
        location: 'Ubicación',
        address: 'Dirección',
        sidebarInformationTimestamp: 'Información al cierre.',
        low: 'Baja',
        high: 'Alta',
        chartTitle: 'Gráfica',
        tableTitle: 'Tabla',
        newsTitle: 'Noticias',
        comparativeTableTitle: 'Tabla comparativa',
        generalIndicators: 'INDICADORES',
        maxCardsLimitExceeded: 'Ya no puedes agregar más tarjetas',
        download: 'Descargar',
        newsletters: 'Boletines',
        at: 'a las',
        hour: 'hora',
        hours: 'horas',
        changeName: 'Cambiar nombre',
        delete: 'Eliminar',
        undoText: 'DESHACER',
        okNewCard: 'Tarjeta creada',
        concurrentUser: 'Se inicio session en otro dispositivo, desconectando...',
        cardDeleted: 'Se ha eliminado la tarjeta',
        searchResults: 'Resultados de búsqueda:',
        recentResults: 'Búsquedas recientes:',
        period: 'Período',
        periodicity: 'Periodicidad',
        interval: 'Intervalo',
        range: 'Rango',
        Min: 'Min',
        Hour: 'Hora',
        Hours: 'Horas',
        seeMore: 'VER MÁS',
        seeLess: 'VER MENOS',
        purchase: 'Precio de compra',
        sale: 'Precio de venta',
        purchaseVsSale: 'Compra/Venta',
        spread: 'Spread',
        value: 'Valor actual',
        postureBidTime: 'Hora compra',
        postureAskTime: 'Hora venta',
        purchaseDate: 'Fecha compra',
        saleDate: 'Fecha venta',
        instrument: 'Instrumento',
        description: 'Descripción',
        monthlyNumber: 'Rend. Mensual',
        annualNumber: 'Rend. Anual',
        weeklyNumber: 'Rend. Semanal',
        volumen: 'Volumen',
        rendEnAnoNumber: 'Rend. en el Año',
        precioliquido: 'Precio de liquidación',
        precioanteriorliquido: 'Precio anterior de liquidación',
        volumenacumulado: 'Volumen acumulado',
        valormaxdia: 'Máximo',
        valormindia: 'Mínimo',
        contratosabiertos: 'Contratos Abiertos',
        fechaposturaventa: 'Fecha Postura de Venta',
        horapventa: 'Hora p. Venta',
        fechaposturacompra: 'Fecha Postura de Compra',
        horapcompra: 'Hora p. Compra',
        fechaexpiracion: 'Fecha de expiracón',
        reseña: 'Descripción',
        lsconnection: 'Se conecto al servidor de LS: ',
        lsconnectionfail: 'No se pudo conectar al servidor: ',
        innerValue: 'Valor Anterior',
        operation: 'Operación',
        maxPrice12Months: 'Precio máx. (Ult. 12 Meses)',
        minPrice12Months: 'Precio min. (Ult. 12 Meses)',
        peakPrice: 'Último precio',
        averagePrice: 'Precio promedio',
        monthlyYield: 'Rendimiento mensual',
        weeklyYield: 'Rendimiento semanal',
        annualYield: 'Rendimiento anual',
        yieldPerYear: 'Rendimiento del año',
        maxVol52s: 'Vol. Max. 52(s)',
        minVol52s: 'Vol. Min. 52(s)',
        averageVol3a: 'Vol. Prom. 3(a)',
        averageVol3m: 'Vol. Prom. 3(m)',
        isin: 'ISIN',
        serie: 'Serie',
        expirationDate: 'Fecha vencimiento',
        interest24H: 'Interés 24H',
        scoreFitch: 'Calificación Fitch',
        scoreMoodys: "Calificación Moody's",
        scoreSP: 'Calificación S&P',
        scoreHR: 'Calificación HR',
        daysToExpire: 'Días por vencer',
        fixedIncomeDate: 'Fecha',
        fixedIncomeHour: 'Hora',
        dayMaxValue: 'Valor Máx. Día',
        dayMinValue: 'Valor Min. Día',
        openingPrice: 'Precio apertura',
        yieldRate: 'Tasa de rendimiento',
        valueType: 'Tipo de valor',
        fixedIncomeTM: 'TM',
        fixedIncomeTV: 'TV',
        quotationKey: 'Clave de Cotización',
        emissionDate: 'Fecha de emisión',
        cleanPrice: 'Precio limpio',
        dirtyPrice: 'Precio sucio',
        surtaxPIP: 'Sobretasa',
        mdCleanPrice: 'Precio limpio MD',
        mdDirtyPrice: 'Precio sucio MD',
        volumeMade: 'Volumen hecho',
        postureDate: 'Fecha Postura',
        postureHour: 'Hora Postura',
        unitVariation: 'Variación U.',
        stockpAsk: 'Bolsa P. Venta',
        stockpBid: 'Bolsa P. Compra',
        stockValue: 'Bolsa hecho',
        noMoreInformationMessage: 'No hay más información para mostrar.',
        pppValue: 'Precio Promedio Ponderado',
        pppNumber: 'PPP',
        minutes: 'minuto',
        day: 'dia',
        widgetNotFound: 'Información del widget no encontrada',
        basic: 'BÁSICO',
        sortAZ: 'Ordenar A → Z',
        sortZA: 'Ordenar Z → A',
        moveRight: 'Mover a la derecha',
        moveLeft: 'Mover a la izquierda',
        copy: 'Copiar',
        copyToClipboard: 'Copiado en el portapapeles',
        blockColumn: 'Bloquear columna',
        deleteColumn: 'Eliminar columna',
        unblockColumn: 'Desbloquear columna',
        compare: 'Comparar',
        addComparison: 'Añadir comparación',
        compareSmallSize: 'cf.',
        draw: 'Dibujo',
        studies: 'Estudios',
        styles: 'Estilos',
        view: 'Vista',
        withoutTool: 'Sin herramienta',
        measure: 'Medida',
        fibArc: 'FibArc',
        fibFan: 'FibFan',
        gannFan: 'GannFan',
        channel: 'Canal',
        fibTimeUse: 'Fib uso horario',
        resistanceArc: 'Arco de resistencia',
        resistanceLine: 'Línea de resistencia',
        timeCycle: 'Ciclo de tiempo',
        shapes: 'Formas',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        trendline: 'Trendline',
        diagonal: 'Diagonal',
        regression: 'Regresión',
        annotation: 'Anotación',
        text: 'Texto',
        trend: 'Tendencia',
        momentum: 'Momentum',
        volatility: 'Volatilidad',
        menuStudiesVolume: 'Volumen',
        duplicate: 'Duplicar',
        block: 'Bloquear',
        noConnection: 'Sin conexión',
        deafultWorkspaceName: 'Espacio',
        newWorkspace: 'Nuevo espacio',
        viewDetails: 'Ver detalles',
        copyRow: 'Copiar fila',
        duplicateRow: 'Duplicar fila en',
        blockRow: 'Bloquear fila',
        deleteRow: 'Eliminar fila',
        moveUp: 'Mover arriba',
        moveDown: 'Mover abajo',
        addInstrument: 'Agregar instrumento...',
        graph: 'Graficar',
        workspaceHasBeenDeleted: 'Se ha eliminado el espacio ',
        workspaceDuplicated: 'Se ha duplicado el',
        duplicateWorkspaceModal: '¿Estás seguro de que quieres duplicar',
        deleteWorkspaceModal: '¿Estás seguro de que quieres eliminar',
        agree: 'Aceptar',
        disagree: 'Cancelar',
        workspace: 'espacio',
        removeStudy: 'Eliminar estudio',
        configStudy: 'Editar configuración...',
        btnSave: 'Guardar',
        deleteAll: 'Borrar todo',
        sortNumberHighLow: 'Ordenar de mayor a menor',
        sortNumberLowHigh: 'Ordenar de menor a mayor',
        price: 'Precio',
        valor: 'Valor',
        purchaseVolumbmv: 'Volumen de compra',
        tickerSmall: 'Reducir tamaño',
        tickerLarge: 'Ampliar tamaño',
        hide: 'Ocultar',
        workspacesLimitExceeded: 'Has alcanzado el límite de Workspaces permitidos.',
        instrumentAdded: 'Instrumento añadido',
        instrumentsAdded: 'Instrumentos añadidos',
        salePrice: 'Precio de venta',
        yearShortcut: 'A',
        bmvStocks: 'BMV',
        bivaStocks: 'BIVA',
        bmvSic: 'BMV (SIC)',
        nyseStocks: 'NYSE, NASDAQ y AMEX',
        currencies: 'Divisas',
        bivaSic: 'BIVA (SIC)',
        indices: 'Índices',
        fixedIncome: 'DEUDA',
        derivatives: 'DERIVADOS',
        nyse: 'NYSE',
        amex: 'AMEX',
        nasdaq: 'NASDAQ',
        newChartTitle: 'Agregar gráfica nueva',
        newTableTitle: 'Agregar tabla nueva.',
        predefinedTitle: 'Agregar tabla predefinida.',
        chooseMarket: 'Elige un mercado o instrumento.',
        chooseTableMarket: 'Tienen el contenido de todos los mercados',
        add: 'Agregar',
        bmvStocksInTitle: '¿Qué acción de BMV quieres graficar?',
        bivaStocksInTitle: '¿Qué acción de BIVA quieres graficar?',
        bmvSicInTitle: '¿Qué acción de BMV - SIC quieres graficar?',
        nyseStocksInTitle: '¿Qué acción de NYSE quieres graficar?',
        currenciesInTitle: '¿Qué acción de Divisas quieres graficar?',
        bivaSicInTitle: '¿Qué acción de BIVA - SIC quieres graficar?',
        indicesInTitle: '¿Qué acción de Indices quieres graficar?',
        fixedIncomeInTitle: '¿Qué acción de Deuda quieres graficar?',
        derivativesInTitle: '¿Qué acción de Derivados quieres graficar?',
        generalIndicatorsInTitle: '¿Qué acción de Indicadores quieres graficar?',
        nasdaqInTitle: '¿Qué acción de NASDAQ quieres graficar?',
        amexInTitle: '¿Qué acción de AMEX quieres graficar?',
        addTitleTable: '¿Qué instrumento deseas agregar a tu tabla?',
        show: 'Mostrar ticker',
        onError: 'Ha ocurrido un error',
        noChartDataMessage: 'Cargando información del instrumento...',
        deleteCard: 'Eliminar tarjeta',
        moveCard: 'Mover tarjeta',
        addNewInstrumentTitle: 'Agregar los nuevos instrumentos:',
        duplicateCard: 'Se duplicó esta tarjeta en',
        movedCard: 'Se ha movido la tarjeta',
        movedCardToNewWS: 'a un nuevo espacio de trabajo',
        undoMoveCard: 'Has eliminado la tarjeta',
        pricesMaxAndMin: 'Máx/Mín',
        capitalization: 'Capitalización',
        companyValue: 'Valor de la Empresa',
        annualPerformance: 'Rendimiento anual',
        last12MonthsPerformance: 'Rendimiento Ult. 12M',
        maxOfTheYear: 'Max Of The Year',
        maxLast12Months: 'Máx. Ult. 12M',
        minOfTheYear: 'Min Of The Year',
        minLast12Months: 'Min. Ult. 12M',
        averageDailyVolume: 'Volumen Promedio Diario (3M)',
        divYield: 'Div. Yield',
        targetPrice: 'Precio Objetivo',
        recommendation: 'Recomendación',
        opening: 'Apertura',
        numOrder: 'Núm. Órdenes Compra',
        purchaseVolumePP: 'Volumen Compra',
        purchaseDepth: 'Compra',
        saleDepth: 'Venta',
        volSale: 'Volumen venta',
        numOrders: 'Núm. Órdenes Venta',
        move: 'Mover',
        options: 'OPCIONES',
        ticker: 'Ticker',
        addInstrumentTicker: '¿Qué instrumento quieres agregar?',
        quantity: 'Cantidad',
        ppp: 'PPP',
        liquidation: 'Liquidación',
        graphDesc: 'Gráfica',
        sevenDays: '7 días',
        thirtyDays: '30 días',
        ofYear: 'Del año',
        lastTwelveMonths: 'Últ. 12 m.',
        pupa: 'P/UPA',
        pvl: 'PVL',
        transmitter: 'Emisora',
        limitInstrumentsTicker: 'El ticker debe de tener al menos 5 instrumentos.',
        addInstrumentsTicker: 'Se agregaron X instrumentos al ticker.',
        limitMaxInstrumentsTicker: 'No se puede agegar más de 20 instrumentos al ticker.',
        instrumentAlreadyInTicker: 'El instrumento ya existe en el ticker.',
        suggestedForex: 'Divisas sugeridas',
        suggestedStocks: 'Capitales sugeridos',
        predefinedIpc: 'IPC',
        predefinedBanorte: 'Fondos Banorte',
        spreadVariation: 'Spread (%)',
        instrumentType: 'Tipo de instrumento',
        lastDone: 'Último hecho',
        closingPrice: 'Precio de cierre',
        change: 'Variación',
        instrumentsNotFound: 'No se encontraron instrumentos para esta tarjeta.',
        brokerageHouse: 'Casa de bolsa',
        percentageShare: 'Participación %',
        startWithTheName: 'Comencemos con el nombre',
        searchAndChooseInstruments: 'Busca y elige los instrumentos de tu interés',
        searchAndChooseInstrument: 'Busca y elige el instrumento de tu interés',
        selectDetails: 'Elegir detalle',
        continue: 'Continuar',
        leftArrow: 'Flecha izquierda',
        rightArrow: 'Flecha derecha',
        calendarIcon: 'Calendario',
        studiesLists: 'Listas de Estudios',
        deleteList: 'Borrar listado',
        addNewList: 'Agregar nueva lista',
        deselectAll: 'Deseleccionar todo',
        couponRate: 'Tasa cupón',
        saleRate: 'Tasa de venta',
        purchaseRate: 'Tasa de compra',
        createYourPortfolio: 'Genera tu portafolio en 3 sencillos pasos',
        newPortfolio: 'Nuevo portafolio',
        inputPlaceholder: 'Ej. Portafolio Bolsa',
        instruments: 'Instrumentos',
        titlesPerInstrument: 'Elige la cantidad de títulos por instrumento',
        movementDate: 'Fecha de movimiento: ',
        toComplete: 'Completar',
        composicion: 'Composición',
        newOperation: 'Nueva operación',
        performance: 'Rendimiento',
        calendar: 'Calendario',
        maxInstrumentsMessage: 'Sólo puedes tener máximo 30 instrumentos',
        maxInstrumentsMessageTerminal: 'Sólo puedes tener máximo 40 instrumentos',
        withOutResults: 'Sin resultados que coincidan con el criterio de búsqueda',
        applySameCommission: '¿Deseas aplicar esta comisión a todos los instrumentos de la lista?',
        dateLabel: 'Fecha',
        choose: 'Elegir',
        labelFrom: 'Desde',
        labelTo: 'Hasta',
        governmentDebt: 'Deuda Gubernamental',
        instrumentsOfYourInterest: 'Instrumento de tu interés',
        orderDetailsTitle: 'Detalles de la orden',
        selectInstrument: 'Elegir instrumento',
        buyTitle: 'Compra',
        saleTitle: 'Venta',
        dateTitle: 'Fecha',
        tenTitles: '10 títulos',
        titles: 'Títulos',
        comission: 'Comisión',
        netAmount: 'Importe neto',
        registeredOrder: 'Órden registrada exitosamente',
        type: 'Tipo',
        titlesNumber: 'No. de títulos',
        addAnother: 'Agregar otro',
        goToPortfolio: 'Ir a Portafolio',
        portfolio: 'Cartera',
        movements: 'Movimientos',
        instrumentNewOperation: 'Usar instrumento para nueva operación',
        minimumOneTitle: 'Ingresa como mínimo un título',
        percentageLessThanOneHundred: 'El porcentaje de la comisión debe ser menor al 100%',
        aggregateOrder: 'Orden agregada',
        portfolioCreated: 'Portafolio creado',
        max30Instruments: 'Puedes tener hasta 30 instrumentos',
        noInstrumentsInPortfolio: '¡El portafolio no tiene instrumentos!',
        reportAProblem: 'Reportar un problema',
        myMarkets: 'Mis mercados',
        portfolioOption: 'Portafolio',
        describeYourProblem: 'Describe tu problema',
        addScrenshot: 'Adjuntar captura de pantalla',
        duplicatedPortfolioName: 'El nombre ya existe, ingresa otro.',
        addGraphTo: 'Agregar gráfica a...',
        thisSpace: 'Este espacio',
        InThisSpace: 'En este espacio',
        otherSpace: 'Otro espacio',
        newSpace: 'Nuevo espacio',
        yourSpaces: 'Tus espacios',
        addNewCardToSpace: 'Has agregado una nueva tarjeta, en el espacio',
        comissionWarning: 'El porcentaje de la comisión debe ser menor al 100%',
        titulosWarning: 'Ingresa como mínimo un título para cada instrumento',
        precioActualWarning: 'Ingresa un precio mayor a 0',
        onlySellTitlesHaveBuy: 'Solo se puede vender los títulos que has comprado',
        informationNotAvailable: 'Información no disponible',
        rentability: 'RENTABILIDAD',
        valuation: 'Valuación',
        yearToYearGrowth: 'Crecimiento año con año',
        showDetail: 'AMPLIAR DETALLES',
        incomeStatement: 'Estado de resultados',
        balance: 'Balance general',
        cashFlow: 'Flujo de caja',
        allLabel: 'Todo',
        noteLabel: 'Nota',
        financialReport: 'Reporte financiero',
        keyCSV: 'CLAVE',
        textCSV: 'TEXTO',
        sendReport: 'Enviar reporte',
        chooseAView: 'Elige una vista para tus necesidades de lectura',
        appearance: 'Apariencia',
        changeInterfaceStyle: 'Cambia el estilo de la interfaz',
        textSize: 'Tamaño de texto',
        chooseSuitableSize: 'Elige el tamaño más apto para tu visión',
        colors: 'Colores',
        chooseColor: 'Elige una paleta de colores',
        compact: 'Compacta',
        commode: 'Cómoda',
        dark: 'Oscuro',
        light: 'Claro',
        small: 'Pequeño',
        medium: 'Mediano',
        big: 'Grande',
        default: 'Predeterminada',
        colorful: 'Colorida',
        traditional: 'Tradicional',
        colorBlind: 'Daltónica',
        monochromatic: 'Monocromatica',
        restore: 'Restaurar',
        customization: 'Personalización',
        settingsUpdated: 'Configuración actualizada',
        maximumDayPrice: 'Máximo del día',
        minimumDayPrice: 'Mínimo del día',
        accumulatedVolume: 'Volumen acumulado',
        maxNumberMessage: 'Te recordamos que el máximo de imagenes que puedes elegir y subir son 3',
        maxFileSizeMessage: 'Tu archivo debe tener un peso máximo de 1 Mb, por favor selecciona otra imagen.',
        acceptTypeMessage:
          'Sólo se pueden subir archivos de tipo: .JPEG, .JPG, .PNG y con un peso máximo de 1Mb por archivo',
        resolutionMessage: 'El archivo seleccionado no tiene la resolución esperada',
        successSubMessage: 'Muchas gracias, en breve revisaremos tus comentarios.',
        feedbackMessageError: 'Ingresa un mensaje válido',
        sent: 'Enviado',
        generateAnotherOne: 'Generar otro',
        reportSent: 'Hemos enviado tu reporte',
        sector: 'Sector',
        executives: 'Ejecutivos',
        website: 'Página web',
        reportType: 'Tipo de reporte',
        internationalBags: 'Bolsas Internacionales',
        loginError: 'Usuario o contraseña incorrectos.',
        deleteInstrument: 'Instrumento eliminado',
        xbrlTitleReport: 'Comparativos de Reportes Financieros Nominales',
        multiplesTitleReport: 'Reportes Múltiplos',
        finalYear: 'Año final',
        initialYear: 'Año inicial',
        socialReason: 'Razón social',
        allLabels: 'Todos',
        monthToDate: 'Mes a la fecha',
        monthly: 'Mensual',
        yearToDate: 'Año a la fecha',
        last2lveMonths: 'Últimos 12 meses',
        fromText: 'Desde: ',
        toText: 'Hasta: ',
        currentLevel: 'Nivel actual',
        newList: 'Nueva Lista',
        myLists: 'Mis listas',
        newListName: 'Elige un nombre para identificar la lista de estudios que deseas guardar',
        analysisOption: 'Análisis',
        drawingOption: 'Dibujos y anotaciones',
        marketCapitalization: 'Capitalización de mercado',
        marketValue: 'Valor de mercado de la empresa.',
        formulaMarketCapitalization: 'Cap. de Mercado= Precio x Acciones en circulación',
        cardMaximumMessage: 'Sólo se puede tener un máximo de 12 tarjetas por espacio de trabajo.',
        sameDay: 'Mismo día',
        enterAValidListName: 'Ingresa un nombre válido (menor a 20 caracteres)',
        posturesDepth: 'Profundidad de Posturas',
        convertTable: 'Convertir a tabla personalizada',
        convertedTable: 'Se convirtió la tabla',
        studyKagi: 'Set reversal percentange',
        studyLineBreak: 'Set price lines',
        studyRenko: 'Set brick size',
        studyRange: 'Set range',
        enterANumber: 'Ingresa un valor numérico por favor',
        newComparisonTableTitle: 'Agregar tabla comparativa',
        searchAndChooseInstrumentsSic: 'Busca y elige los instrumentos del SIC de tu interés',
        todayLabel: 'Hoy',
        infoByInfosel: 'Datos calculados por Infosel',
        infoLastQuarter: 'Información actualizada al último trimestre reportado',
        last12Months: 'Últ.12M',
        economicIndicators: 'Indicadores',
        previousLevel: 'Nivel anterior',
        units: 'Unidades',
        lastUpdate: 'Última actualización',
        serieType: 'Tipo de serie',
        frecuency: 'Frecuencia',
        indicators: 'Indicadores',
        purchaseCash: 'Cmp. Cash',
        saleCash: 'Vta. Cash',
        purchaseCash24hrs: 'Cmp. 24h',
        saleCash24hrs: 'Vta. 24h',
        purchaseCash48hrs: 'Cmp. 48h',
        saleCash48hrs: 'Vta. 48h',
        interbankDollar: 'Dólar Interbancario',
        sourceFactset: 'Fuente: Factset',
        informationWithClosingPrices: 'Información con precios al cierre',
        Comprar: 'Compra',
        Vender: 'Venta',
        Mantener: 'Mantener',
        labelRecomendation: 'El precio y la recomendación mostrada son sobre la serie más bursátil de cada emisora',
        investmentFunds: 'Fondos de inversión',
        doneLabel: 'done',
        referenceRates: 'Tasas de Referencia',
        expiration: 'Vencimiento',
        availability: 'Disponibilidad',
        source: 'Fuente',
        priceWithDelay: 'Precio con retraso de [20] min.',
        viewInformation: '¿Deseas ver esta información?',
        noViewInformation: '¿Por qué no puedo ver esta información?',
        unlockInformation: '¿Desear desbloquear esta información?',
        youDoNotHaveThisInformation: '¿Por qué no puedo ver esta información?',
        total: 'Total (Ver todos)',
        participacion: 'Participación',
        verTabla: ' Ver tabla',
        verGrafica: ' Ver Gráfica',
        sampleSize: 'Tamaño de la muestra',
        targetPriceInformation: 'El precio objetivo mostrado es el precio promedio de la muestra',
        investmentHorizon: 'Horizonte de inversión',
        liquidity: 'Liquidez',
        qualification: 'Calificación',
        possibleAcquirers: 'Posibles adquirentes',
        minimumAmount: 'Monto mínimo',
        typeOfInvestmentFund: 'Tipo de fondo de inversión',
        investorType: 'Tipo de inversionista',
        operator: 'Operadora',
        netAsset: 'Activo neto',
        totalAmount: 'Monto total',
        closingTime: 'Horario de cierre',
        authorizationDate: 'Fecha de autorización',
        category: 'Categoría',
        investmentRegime: 'Regimen de inversión',
        sharpeRatio: 'Sharpe ratio',
        rendYearAnnualized: 'Rend. en el Año (Anualizado)',
        rendMonthlyAnnualized: 'Rend. Mensual (Anualizado)',
        rendWeeklyAnnualized: 'Rend. Semanal (Anualizado)',
        copyNews: 'Copiar noticia',
        copiedNews: 'Noticia copiada',
        reportsBrokerageHouses: 'Reportes: casa de bolsa',
        reportCenterTitle: 'Centro de reportes',
        latestReports: 'Últimos reportes',
        myReports: 'Mis reportes',
        searchNews: 'Buscar noticia',
        noMatches: 'Sin coincidencias',
        relatedNews: 'Noticias relacionadas',
        percentage: 'Porcentaje',
        X: 'X (Cruces)',
        C: 'C (Compras)',
        V: 'V (Ventas)',
        Totale: 'Total',
        chooseTheStations: 'Para comenzar, elige las emisoras',
        chooseTheBrokerageHouse: 'Elige la casa de bolsa',
        consult: 'Consultar',
        results: 'Resultados',
        edit: 'Editar',
        financialReasons: 'Razones financieras',
        reportBy: 'Reporte Por',
        numRecords: 'Nro. Max',
        effectivePerformance: 'Rendimiento efectivo. No considera comisiones',
        median: 'Mediana',
        purchaseVariation: 'Purchase variation',
        salesVariation: 'Sales variation',
        informationInterbankDollar:
          'Precios de referencia. Cotizaciones proporcionadas por los intermediarios financieros bajo su propia responsabilidad.',
        expressedMillions: 'La información está expresada en millones',
        multiples: 'Múltiplos',
        intraday: 'Intradía',
        localAnalysts: 'Analistas locales',
        recommendations: 'Recomendaciones',
        updateOnceAQuarterLabel: 'Esta información se actualiza una vez al trimestre',
        repeatedInformation: `Esta sección puede mostrar información repetida con la sección "Análistas locales"`,
        operationData: 'Datos de operación',
        summary: 'Resumen',
        labelconversionFromPOtoUSDorMXN:
          'La conversión de PO a USD o MXN toma como referencia el nivel del cierre anterior',
        expectedReturn: 'El rendimiento esperado toma como referencia el precio del cierre anterior',
        expectedPerformance: 'Rendimiento esperado',
        lastDoneDate: 'Fecha último hecho',
        lastDoneTime: 'Hora último hecho',
        openingRate: 'Tasa apertura',
        maximumRateDay: 'Tasa máxima del día',
        minimumRateDay: 'Tasa mínima del día',
        preClosingRate: 'Tasa cierre previo',
        netRate: 'Tasa neta',
        yield: 'Yield',
        doneMax12M: 'Máx. 12M (Hecho)',
        doneDateMax12M: 'Fecha máx. 12M (Hecho)',
        doneMin12M: 'Mín. 12M (Hecho)',
        doneDateMin12M: 'Fecha mín. 12M (Hecho)',
        marketSurcharge: 'Sobretasa de mercado',
        newQueryTitle: 'Agregar tabla de consultas',
        chooseOptionQueries: 'Elige de entre las opciones disponibles',
        currentRate: 'Tasa actual',
        surcharge: 'Sobretasa',
        dueDate: 'Fecha de vencimiento',
        paymentFrequency: 'Frecuencia de pago',
        issuanceAmount: 'Monto emisión',
        amountCirculation: 'Monto en circulación',
        currentFaceValue: 'Valor nominal actual',
        interest24h: 'Interéses 24H',
        issuerInformation: 'Información del emisor',
        trade: 'Ramo',
        subbranch: 'Subramo',
        country: 'País',
        instrumentInformation: 'Información del instrumento',
        model: 'Modelo',
        couponStart: 'Inicio de cupón',
        couponEnd: 'Fin de cupón',
        currency: 'Moneda',
        duration: 'Duración',
        convexity: 'Convexidad',
        originalParValue: 'Valor nominal original',
        validCoupon: 'Cupón vigente',
        expiringCoupons: 'Cupones x vencer',
        couponPeriod: 'Periodo cupón',
        reviewRate: 'Tasa/Revisión',
        emissionInformation: 'Información de la emisión',
        countryHolidays: 'Convención (Country Holidays)',
        placementDate: 'Fecha de colocación',
        issuedSecurities: 'Títulos emitidos',
        titlesCirculation: 'Títulos en circulación',
        discountRate: 'Tasa de descuento',
        amortization: 'Amortización',
        amortizationType: 'Tipo de amortización',
        amortizationAmount: 'Monto de amortización',
        investmentType: 'Tipo de inversión',
        quoteType: 'Tipo de Cotización',
        qualifications: 'Calificaciones',
        spLabel: 'S&P',
        hrlabel: 'HR',
        addAlert: 'Agregar alerta',
        newAlert: 'Nueva alerta',
        greater: 'Mayor a',
        equal: 'Igual a',
        less: 'Menor a',
        errorMessageInstrumentKey: 'Error al obtener el intrumento',
        errorExpirationDate: 'Fecha requerida',
        required: 'Requerido',
        onlyNumbersPositives: 'Sólo números positivos',
        by: 'Por',
        validity: 'Vigencia',
        zeroNotAllowed: 'Cero no es permitido',
        createAlertSuccess: 'Alerta creada correctamente',
        createAlertError: 'Error al crear la alerta',
        createAlertErrorExpirationDate: '¡ Alerta no creada ! La hora actual es mayor al cierre del mercado',
        marketClosed: 'Mercado cerrado, por favor selecciona otra fecha.',
        saving: 'Guardando...',
        configurationQuerie: 'Configurar contenido',
        inQueries: 'En tarjeta de consultas',
        apply: 'Aplicar',
        checkboxEmptys: 'Debe seleccionar al menos un grupo de acciones',
        checkboxEmptysBrokerage: 'Debe seleccionar al menos una casa de bolsa',
        minimumSample: 'La muestra mínima es de',
        maximumSample: 'La muestra máxima es de',
        actionGroup: 'Elige los grupos de acciones',
        maximTopInstruments: 'Número de instrumentos a la alza',
        maximBottomInstruments: 'Número de instrumentos a la baja',
        see: 'Ver',
        internationalDebt: 'Deuda Internacional',
        currentRateDate: 'Fecha tasa actual',
        previousClosingRate: 'Tasa de cierre anterior',
        maximumVariations: 'Variaciones máximo',
        minimumVariations: 'Variaciones mínimo',
        openingVariations: 'Variaciones apertura',
        percentageChange: 'Variación porcentual',
        privateDebt: 'Deuda Privada',
        dayCountFraction: 'Convención (Day Count Fraction)',
        markToSeen: 'Marcar como leída',
        validateDrop: 'No se puede realizar la acción',
        helpUsImprove: 'Ayúdanos a mejorar',
        submitAndParticipate: 'Enviar y participar',
        titleModalNps: 'Participa y entra al sorteo por una tarjeta de Amazon por $1,000 MXN',
        subtitleModalNps: '¿Recomendarías esta plataforma a un amigo?',
        tellusAbaoutYouExperience: 'Cuéntanos tu experiencia (opcional)',
        youWould: 'No lo harías.',
        yesWidely: 'Sí, ampliamente.',
        finishResponseFormNps: '¡Gracias por ayudarnos a seguir mejorando!',
        errorResponseFormNps: 'Error al enviar la respuesta',
        maxPriceOfTheDay: 'Precio máx. del dia',
        minPriceOfTheDay: 'Precio mín. del dia',
        alert: 'Alerta para',
        created: 'creada',
        deleteWidgetLabel: '¿Estás seguro de eliminar esta tarjeta de manera definitiva?',
        showMore: 'Mostrar más',
        myalerts: 'Mis alertas',
        allAlerts: 'Mis alertas',
        copyClipboard: 'Copiar contenido',
        copyChartToClipboard: 'Gráfica copiada en el portapales',
        errorCopyToClipboard: 'Ocurrió un error al copiar en el portapapeles',
        copyTableToClipboard: 'Tabla copiada en el portapales',
        alertPrice: 'Precio de la alerta',
        validateSIC: 'Sólo se pueden agregar instrumentos del SIC a esta tarjeta',
        newQueries: 'Consultas nuevas',
        addNewQuery: 'Agregar consulta nueva',
        marketType: 'Tipo de mercado',
        chooseLeastOneCondition: 'Elige al menos una condición',
        chooseOneCondition: 'Elige una condición',
        market: 'Mercado',
        outstanding: 'Destacada',
        printWidget: 'Imprimir tarjeta',
        errorPrint: 'Ocurrió un error al imprimir la tarjeta',
        load: 'Cargar',
        compareMultiple: 'Comparar instrumentos elegidos',
        limitCompare: 'No se pueden comparar más de 6 instrumentos',
        mostRead: 'Más leídas',
        latestNews: 'Noticias más recientes',
        seeAllResults: 'Ver todos los resultados',
        refineYourSearchUsing: 'Refina tu búsqueda usando',
        conditionSearch: `“y”, “o”, “sin” o la palabra entre comillas “”`,
        typeOneOrMoreKeywords: 'Escriba una o más palabras clave',
        historic: 'Histórico',
        noInformation: 'No hay información disponible para esta emisora',
        writeSearchInstrument: 'Escriba la clave o descripción...',
        outstandingShares: 'Acciones en circulación*',
        timeDone: 'Hora hecho',
        dateDone: 'Fecha hecho',
        noAlerts: 'Sin alertas',
        BMVInformation: 'Reporte con información de BMV',
        alls: 'Todos',
        graphs: 'Gráficas',
        consolidates: 'Consolidados',
        consolidate: 'Consolidado',
        selectAll: 'Seleccionar todo',
        selected: 'Seleccionados',
        newTable: 'Nueva tabla',
        preciousMetals: 'Metales Preciosos',
        sevenDayQuote: 'Cotización 7 días',
        thirtyDayQuote: 'Cotización 30 días',
        previousYearPrice: 'Cotización año anterior',
        newGraphicTitle: 'Agregar gráfica nueva',
        newGraphic: 'Nueva gráfica',
        level: 'Nivel',
        infoLastDay: 'La información de esta sección se actualiza al cierre del día',
        typeFund: 'Tipo de fondo',
        capitals: 'Capitales',
        graphic: 'Gráfica',
        compairInstrument: 'Comparar instrumentos',
        reason: 'Razón',
        infoByMorningStar: 'Fuente: Morning Star',
        limitInstrumentConvert: 'Debe de tener al menos un instrumento',
        infoQueries: 'La información se actualiza cada 90 segundos, últ. actualización',
        infoQueriesCapitals: 'La lista de instrumentos se actualiza cada 90 segundos, últ. actualización',
        infoOperations: 'Se muestran las últimas 4,000 operaciones',
        changeInstrument: 'Cambiar instrumento',
        effectiveYield: 'Rendimiento efectivo',
        annualizedYield: 'Rendimiento anualizado',
        calendarTitle: 'Calendario',
        hub: 'Hub',
        contact: 'Contáctanos para ampliar tu paquete actual:',
        noReports: 'Sin reportes',
        forgotPassword: 'Olvidaste tu contraseña',
        back: 'Regresar',
        validate: 'Validar',
        whatEmailYouHave: '¿Cuál es el email que tienes registrado?',
        responseRecoverPasswordSuccess:
          'Revisa en tu bandeja de entrada (ó spam) y sigue las instrucciones que hemos enviado a ',
        backToHome: 'Regresar al inicio',
        emailValid: 'Ingresa un email válido',
        editInstruments: 'Editar instrumentos',
        update: 'Actualizar',
        addSectoralAnalysisTo: 'Agregar análisis sectorial a...',
        improveYourPlan: '¿Quieres mejorar tu plan? contáctanos...',
        realTime: 'Tiempo Real',
        delayedPrice: 'Retraso de',
        onlyShowActiveInstruments: 'Solo mostrar instrumentos activos',
        resetPassword: 'Reestablecer contraseña',
        errorUpdatePassword: '¡Ocurrio un error al actualizar la contraseña!',
        errorSecondPassword: 'La segunda contraseña no coincide con la primera, escríbela nuevamente',
        enterNewPassword: 'Ingresa tu nueva contraseña',
        repeatPassword: 'Repite la contraseña',
        mustContain: 'Debe contener',
        updatePasswordSuccess: '¡Se ha cambiado la contraseña exitosamente!',
        Online: 'Conectado',
        closeMovement: 'Cierre',
        GetInto: 'Ingresar',
        petroleum: 'Petróleo',
        columnManager: 'Administrador de columnas',
        configurationFor: 'Configuración para',
        manageColumns: 'Administrar columnas',
        search: 'Buscar',
        findAvailableColumns: 'Buscar columnas disponibles',
        emptyStateSearch: 'No existen coincidencias en la búsqueda, intenta nuevamente',
        emptyStateInfo: 'Busca entre todas las columnas disponibles para la configuración de tu tabla',
        availableColumns: 'Columnas disponibles',
        myColumns: 'Mis columnas',
        searchResult: 'Resultados de la búsqueda',
        Offline: 'Sin conexión',
        applyChanges: 'Aplicar cambios',
        numberOfOperations: 'Núm. de operaciones',
        seeMoreData: 'Ver más datos',
        seeLessData: 'Ver menos datos',
        deleteAlert: 'Eliminar alerta',
        deleteConfirm: '¿Estás seguro de eliminar esta alerta de manera definitiva?',
        generalDirector: 'Director  general',
        finantialDirector: 'Director financiero',
        updateInstruments: 'Actualizando instrumentos',
        errorUpdateInstruments: 'Error al actualizar instrumentos',
        deleteAllImages: 'Eliminar todas las imagenes',
        hideOptions: 'Ocultar opciones',
        moreOptions: 'Más opciones',
        retry: 'Reintentar',
        errorGetInstruments: 'Error al obtener los instrumentos',
        setting: 'Configuración',
        colorTheme: 'Tema del color',
        subtitleTheme: 'Elige el tema acorde a tus necesidades y comodidad.',
        system: 'Sistema',
        tableTitlePredefined: 'Tabla predefinida',
        tableTitleNewTable: 'Tabla nueva',
        queries: 'Consultas predefinidas',
        subtitleTicker: 'Esta se visualiza en la parte inferior de la pantalla.',
        updateColumns: 'Los cambios han sido aplicados',
        analysis: 'Análisis',
        subsector: 'Subsector',
        selectOneOption: 'Selecciona una opción',
        snapshotTitle: 'Agregar foto del día',
        snapshot: 'Foto del día',
        downloadFile: 'Descargando archivo...',
        downloadError: 'Hubo un problema con la descarga, vuelve a intentarlo',
        downloadSuccess: 'Se ha descargado el archivo con éxito',
        showMoreDetail: 'Ver detalles',
        financial: 'Financieros',
        graphicalAdvance: 'Gráficas avanzadas',
        reduce: 'Reducir',
        increase: 'Aumentar',
        maximize: 'Pantalla completa',
        minimize: 'Minimizar'
      }
    }
  },
  fallbackLng: 'es',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
})

export default i18n
