import { Theme, ComponentsOverrides } from '@mui/material/styles'
import { DEEP_200_LIGHT, DEEP_600_LIGHT, DEEP_500_LIGHT } from 'constants/colorsLightMode'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { heightRowHeaderTable } from 'constants/theme'

const MuiDataGridOverride: ComponentsOverrides<Theme>['MuiDataGrid'] = {
  root: {
    border: 'none',
    padding: '0px 8px',
    height: '100%',
    '& .Mui-selected > .MuiDataGrid-rowReorderCellContainer': {
      display: 'flex !important',
      minWidth: '24px !important',
      maxWidth: '24px !important'
    },
    '& .MuiDataGrid-columnHeaderReorder': {
      display: 'none !important'
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: `${DEEP_500_LIGHT} !important`,
      '& .is-table__row-menu': {
        display: 'initial !important'
      }
    },
    '& .MuiDataGrid-pinnedRows': {
      backgroundColor: `${DEEP_600_LIGHT} !important`
    }
  },
  filler: {
    border: 'none !important'
  },
  columnHeaderTitle: {
    fontFamily: 'Roboto Flex,Helvetica,Arial,sans-serif!important',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    fontSize: '10px !important',
    lineHeight: '12px !important',
    letterSpacing: '0.25px !important',
    color: DEEP_200_LIGHT
  },
  columnHeader: {
    height: `${heightRowHeaderTable}px !important`,
    minHeight: `${heightRowHeaderTable}px !important`,
    borderWidth: '2px !important',
    borderColor: `${DEEP_500_LIGHT} !important`,
    outline: 'none !important'
  },
  columnHeaders: {
    height: `${heightRowHeaderTable}px !important`,
    minHeight: `${heightRowHeaderTable}px !important`,
    borderWidth: '2px !important',
    borderColor: `${DEEP_500_LIGHT} !important`,
    outline: 'none !important',
  },
  cell: {
    fontFamily: 'Roboto Flex,Helvetica,Arial,sans-serif!important',
    color: `${DEEP_200_LIGHT} `,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: 0.2,
    borderTop: `none !important`,
    borderBottom: `1px solid ${DEEP_500_LIGHT} !important`,
    padding: '0px !important'
  },
  row: {
    cursor: 'pointer',
    focus: {
      outline: 'none !important'
    }
  },
  columnHeaderDraggableContainer: { flexDirection: 'row-reverse' },
  columnHeaderTitleContainer: { flexDirection: 'row-reverse', justifyContent: 'left' },
  menuIcon: {
    backgroundColor: 'transparent !important',
    button: {
      width: 20,
      height: 20,
      backgroundColor: 'transparent !important'
    }
  },
  iconButtonContainer: {
    backgroundColor: 'transparent !important',
    button: {
      width: 20,
      height: 20,
      backgroundColor: 'transparent !important'
    }
  },
  'columnHeader--alignRight': {
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'right !important',
      flex: 'initial !important',
      left: 'auto'
    },
    '& .MuiDataGrid-menuIcon': {
      marginRight: 'inherit !important'
    }
  },
  rowReorderCellContainer: {
    display: 'none !important'
  },
  'cell--textRight': {
    textAlign: 'right'
  }
}

const MuiDataGrid = {
  styleOverrides: MuiDataGridOverride
}

export default MuiDataGrid
