import moment from 'moment'

import i18n from 'i18n/index'

import { MONTHS, DATE_FORMAT, configMomentLocaleEs, DATE_FORMAT_YYYY } from 'constants/globals'

import type { calculateNewsTimestampFunc, getJulianDateStringFromDateFunc, getHourStringFromNumberFunc } from './types'

/**
 *
 * @param {*} date new Date()
 * @returns => 28 jul 2022 11:45
 */
export const getDateFormatFull = (date: Date) => {
  const options = { year: 'numeric', month: 'short', day: '2-digit' }
  const dateString = date.toLocaleDateString('es-ES', options as any).replace('.', '')
  const min = date.getMinutes()
  const tempMin = min > 9 ? `${min}` : `0${min}`
  return `${dateString} ${date.getHours()}:${tempMin}`
}

/**
 *
 * @param {*} date => new Date()
 * @param {*} shortYear => undefined | true | false
 * @returns => 28 jul 2022 | 28 jul 22
 */
export const getDateFormatShort = (date: Date, shortYear?: boolean) => {
  const options = {
    year: shortYear ? '2-digit' : 'numeric',
    month: 'short',
    day: '2-digit'
  }
  const dateString = date.toLocaleDateString('es-ES', options as any).replace('.', '')
  return dateString
}

/**
 *
 * @param {*} dt => new Date()
 * @param {*} n => 1
 * @returns => Fri Jul 28 2023 12:47:47 GMT-0500 (hora de verano central)
 */
export const addYears = (dt: any = new Date(), n: number = 0) => {
  return new Date(dt.setFullYear(dt.getFullYear() + n))
}

/**
 *
 * @param {*} date
 * @returns
 */
export const getFormatDate = (date: string) => {
  const dateSplit = String(date).split('-')

  if (dateSplit.length < 3) {
    return date
  }

  return new Date(Number(dateSplit[2]), Number(dateSplit[1]) - 1, Number(dateSplit[0]))
}

/**
 *
 * @param {*} dt => new Date()
 * @param {*} hour  => 9
 * @param {*} minutes => 59
 * @param {*} seconds = 0
 * @returns
 */
export const addHour = (dt: any = new Date(), hour: number = 0, minutes: number = 0, seconds: number = 0) => {
  return new Date(dt.setUTCHours(hour, minutes, seconds))
}

export const filterDate = (date: any, nonWorkingDays: Array<any>, validateHourWorking: number) => {
  const tempFormatDate = new Date(date)
  if (
    validateHourWorking &&
    tempFormatDate.getMonth() === new Date().getMonth() &&
    tempFormatDate.getDate() === new Date().getDate() &&
    tempFormatDate.getFullYear() === new Date().getFullYear()
  ) {
    return !(Number(new Date().getHours()) >= Number(validateHourWorking))
  }
  if (nonWorkingDays && nonWorkingDays.length > 0 && tempFormatDate.getDay() !== 0 && tempFormatDate.getDay() !== 6) {
    const day = `0${tempFormatDate.getDate()}`.slice(-2)
    const month = `0${tempFormatDate.getMonth() + 1}`.slice(-2)
    const stringDate = `${tempFormatDate.getFullYear()}-${month}-${day}`
    return !nonWorkingDays.includes(stringDate)
  }
  return tempFormatDate.getDay() !== 0 && tempFormatDate.getDay() !== 6
}

export const validateDay = (date: any) => {
  const tempFormatDate = new Date(date)
  if (
    tempFormatDate.getMonth() === new Date().getMonth() &&
    tempFormatDate.getDate() === new Date().getDate() &&
    tempFormatDate.getFullYear() === new Date().getFullYear()
  ) {
    return true
  }
  return false
}

/**
 *
 * @param {*} date => DD/MM/YY (17/06/22)
 * @param {*} fotmat => DD MMM YY | DD/MM/YYYY
 * @returns => 17 Jun 22 | 17/06/2022
 */
export const getDateFullByDateShort = (date: string, fotmat?: string) => {
  if (!date) return new Date()
  return moment(date, fotmat || 'DD/MM/YYYY').toDate()
}

/**
 *
 * @param {*} date => new Date()
 * @param {*} formatdate
 * @returns
 */
// returns a valid in formatDate: 'YYYY-MM-DD'
export const formatCustomDate = (date: Date, formatdate: string) => {
  moment.updateLocale('es', configMomentLocaleEs)
  return moment(date).utc().format(formatdate)
}

/**
 *
 * @param {*} date
 * @param {*} formatdate
 * @returns
 */
export const getFormatCustomDate = (date: string, formatdate: string) => {
  if (!date) return ''
  let newDate: string | Date = ''
  if (date.length === 10) {
    newDate = getFormatDate(date)
  } else {
    newDate = new Date(date)
  }

  if (newDate && newDate.toString() === 'Invalid Date') return date

  return formatCustomDate(newDate as Date, formatdate)
}

/**
 *
 * @param {*} date => new Date()
 * @returns YYYY-MM-DD
 */
export const formatDateYYYY = (date: Date) => {
  return moment(date).utc().format(DATE_FORMAT_YYYY)
}

// returns a valid in format: DD-MM-YYYY
export const formatDateUTC = (date: Date) => {
  return moment(date).utc().format(DATE_FORMAT)
}

const getValueDate = (value: string) => {
  if (!value) return ''
  const valueArray = value.split('-')
  if (valueArray && valueArray.length && valueArray[0].length === 2) {
    return `${valueArray[2]}-${valueArray[1]}-${valueArray[0]}`
  }
  return value
}

/**
 *
 * @param {*} value => 2022-07-25
 * @param {*} separator => / | -
 * @param {*} shortYear => undefined | true | false
 * @returns => 25 Jul 22 | 25-Jul-22 | 25/Jul/22
 */
export const convertToDayMonthYear = (value: string, separator?: string, shortYear?: boolean) => {
  if (!value) return ''
  const tmpDayString = formatDateUTC(new Date(getValueDate(value)))
  let tmpDay = tmpDayString.split('-')
  const stringMonth = MONTHS.find((month) => month.id === tmpDay[1])
  let formatedDate = tmpDay[1]
  if (stringMonth) {
    let year = tmpDay[2]
    if (shortYear && year.length === 4) {
      year = year.substring(2)
    }
    formatedDate = `${tmpDay[0]}${separator || ' '}${stringMonth.name}${separator || ' '}${year}`
  }

  return formatedDate
}

export const calculateNewsTimestamp: calculateNewsTimestampFunc = (timestamp) => {
  const date = new Date()
  const currentTime = date.getHours() * 60 + date.getMinutes()

  const hour = timestamp ? timestamp.slice(0, 2) : null
  const minutes = timestamp ? timestamp.slice(3, 5) : null

  if (!hour || !minutes) {
    return null
  }

  const timestampTime = Number(hour) * 60 + Number(minutes)

  let diff = currentTime - timestampTime
  diff = diff < 0 ? 1440 + diff : diff

  return diff < 60 && diff > 0 ? `${diff} ${i18n.t('timestampMinutes')}` : `${hour}:${minutes}`
}

export const calculatePassedTimeInHours: calculateNewsTimestampFunc = (timestamp) => {
  const date = new Date()
  const currentTime = date.getHours() * 60 + date.getMinutes()

  const hour = timestamp ? timestamp.slice(0, 2) : null
  const minutes = timestamp ? timestamp.slice(3, 5) : null

  if (!hour || !minutes) {
    return null
  }

  const timestampTime = Number(hour) * 60 + Number(minutes)

  let diff = currentTime - timestampTime
  diff = diff < 0 ? 1440 + diff : diff

  if (diff > 0 && diff < 60) {
    return `${diff} ${i18n.t('timestampMinutes')}`
  }
  if (diff >= 60 && diff < 120) {
    return `${(diff / 60).toFixed(0)} ${i18n.t('hour')}`
  }

  return `${(diff / 60).toFixed(0)} ${i18n.t('hours')}`
}

export const getJulianDateStringFromDate: getJulianDateStringFromDateFunc = (dateJulian: number) => {
  let date = new Date()

  if (!dateJulian) {
    // eslint-disable-next-line no-param-reassign
    dateJulian = 0
  }
  date = new Date(1960, 12, 31)
  date = new Date(date.setDate(dateJulian))

  let dateMonth: string | number = date.getMonth() + 1
  let dateDay: string | number = date.getDate()
  const dateYear = date.getFullYear()

  if (dateMonth < 10) dateMonth = `0${dateMonth}`
  if (dateDay < 10) dateDay = `0${dateDay}`

  return `${dateDay}-${dateMonth}-${dateYear}`
}

export const getHourStringFromNumber: getHourStringFromNumberFunc = (time: string) => {
  let timeString = ''
  let hour
  let minutes
  if (time.length <= 5) {
    hour = time ? time.slice(0, 1) : null
    minutes = time ? time.slice(1, 3) : null
    timeString = `0${hour || '0'}:${minutes || '00'}`
  }
  if (time.length >= 6) {
    hour = time ? time.slice(0, 2) : null
    minutes = time ? time.slice(2, 4) : null
    timeString = `${hour || '00'}:${minutes || '00'}`
  }
  return timeString
}

/**
 *
 * @param value // 2022-09-29T00:00:00.000Z esta mal la fecha por lo solo tomamos los 10 primeros dijitos
 * @returns
 */
export const getDateString = (value: string) => {
  if (!value) return ''

  return value.substring(0, 10)
}

export const getDateByString = (value: string) => {
  return moment(getValueDate(value)).utc().toDate()
}
