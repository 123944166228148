export const DEEP_100 = '#F5F7FF'
export const DEEP_200 = '#D9DFEC'
export const DEEP_300 = '#979FB2'
export const DEEP_400 = '#48505D'
export const DEEP_500 = '#29303A'
export const DEEP_600 = '#1E232B'
export const DEEP_700 = '#161B22'
export const DEEP_800 = '#101419'
export const DEEP_900 = '#000'

export const TREND_UP_100 = '#85E29C'
export const TREND_UP_200 = '#59CA75'
export const TREND_UP_300 = '#183620'

export const TREND_DOWN_100 = '#F78D85'
export const TREND_DOWN_200 = '#F05146'
export const TREND_DOWN_300 = '#3C120F'

export const TREND_ACENT_100 = '#518CFF'
export const TREND_ACENT_200 = '#1967FD'
export const TREND_ACENT_300 = '#0A51DA'

export const CHART_CANARY = '#F0C75C'
export const CHART_SALMON = '#F59267'
export const CHART_ORCHID = '#E381F3'
export const CHART_GRAPE = '#887DFF'
export const CHART_ROYAL = '#2E50F2'
export const CHART_MINT = '#6ECFC2'
